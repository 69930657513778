<template>
  <v-menu location="start">
    <template v-slot:activator="{ props }">
      <v-row class="px-0">
        <v-col class="px-0" :class="newView? 'ml-4':''" :cols="newView? '4':'2'" >
          <button flat v-bind="stage != 'closed' ? props : ''" v-if="!nextActionData" class="show-cursor">
            <img src="//synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/user_plus.svg" />
          </button>
          <!-- <CustomAvatar  class="avatrBig ma-0 mr-2 show-cursor" v-else :userId="nextActionData.id"  :size="newView? 'medium':'small'"
            v-bind="stage != 'closed' ? props : ''" /> -->
            <CustomAvatar2 class="avatrBig ma-0 mr-2 show-cursor" v-else v-bind="stage != 'closed' ? props : ''" :fName="nextActionData.first_name" :lName="nextActionData.last_name" :theme="nextActionData.theme" :imgUrl="nextActionData.image_profile_id ? nextActionData.image_profile_id : null"  :size="newView? 'medium':'small'" />
        </v-col>
        <v-col class="px-0" cols="10" v-if="!newView">
          <span class="pl-4" style="flex-wrap: nowrap;display: flex;">
            {{ nextActionData && nextActionData.name }}
          </span>
        </v-col>
      </v-row>
    </template>

    <v-card v-if="childStage != 'closed' && childStage != 'completed'" class="listPosition" min-width="170">
      <v-list dense left max-height="300px" max-width="200px">
        <div dense class="px-2 py-0">
          {{ childStage }}
          <TextFieldComponent dense @input="Searching" @click.stop :placeholder="search" v-model="search"
            append-inner-icon="mdi-magnify" />
        </div>

        <v-list-item dense class="pt-0  mt-0" @click="$emit('userSelected', item)" v-for="(item, index) in filteredUsers"
          :key="index">
          <div style="display: flex;">
            <span>
              <!-- <CustomAvatar class="" :userId="item.id" :size="'small'" /> -->
              
              <CustomAvatar2  :fName="item.first_name" :lName="item.last_name" :theme="item.theme" :imgUrl="item.image_url"  :size="'small'" />

            </span>
            <span class="ml-2">{{ item.name }}</span>
          </div>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
import UserService from '@/api/service/UserService.js';
import TextFieldComponent from '../components/TextFieldComponent.vue';
export default {
  props: ['key_type','childStage', 'key_id', 'username', 'theme', 'nextActionId', 'stage', "showEdit" ,'newView'],

  components: {
    TextFieldComponent,
    CustomAvatar2
  },
  data() {
    return {
      users: [],
      search: "",
      nextActionData: null

    }
  },
  watch: {
    refreshOnUser() {
      if (this.refreshOnUser) {
        this.getNextActionData()
      } else {
        this.getNextActionData()
      }
    },
    nextActionId() {
      if (this.nextActionId) {
        this.nextActionData = null
        this.getNextActionData()
      }
    }
  },

  created() {

    if (this.nextActionId) {
      this.getNextActionData()
    }
    this.getUser()
  },

  computed: {
    refreshOnUser() {
      return this.$store.getters.getAssignee;
    },
    filteredUsers() {
      return this.users.filter(user => {
        const username = user.username.toLowerCase();
        const searchQuery = this.search.toLowerCase();
        return username.includes(searchQuery);
      });
    }
  },



  methods: {
    async getNextActionData() {
      try {
        let res = await UserService.findOne({ id: this.nextActionId })
        this.nextActionData = res[0]
        console.log("nexxttttdata",this.nextActionData)
      }
      catch (e) {
        this.nextActionData = {}
      }


    },
    async getUser() {
      try {
        let wsDefaults = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
        let workSpaceId = localStorage.getItem("workSpaceId")
        let res = await UserService.getNextAction({ work_space_id: workSpaceId, account_id: wsDefaults.account.id, project_id: wsDefaults.project.id })
        this.users = res.length ? res : [];
      }
      catch (e) {
        this.users = []
      }

    },
    getAvatarName(value) {
      console.log(value, "vicash")
      let fName =
        value && value.first_name
          ? value.first_name.charAt(0) : ""
      let lName = value && value.last_name
        ? value.last_name.charAt(0)
        : "";
      let name = fName + lName
      return name;
    },

    Searching() {
      console.log('Search', this.search);
    },
  }

}
</script>

<style scope>
.textUser {
  font-size: 14px;
  margin-left: 30px;
}

.avatrSmall {
  position: relative;
  top: 12px;
  left: 15px;
}
</style>