<template>
  <v-card width="100% " style="border-radius: 12px ; overflow :hidden;">
    <v-form @submit.prevent="addLocation" ref="form" v-model="value">
      <v-card-item>
        <v-card-title>{{ viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add" }}<b> Location</b>

          <v-btn @click="cancel()" elevation="0" density="comfortable" icon="mdi-close"
            class="x-closeIcon"></v-btn></v-card-title>
      </v-card-item>

      <v-card-text>

        <v-row no-gutters class="mt-3">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="4">
            <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Code
              <span v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Code" v-model="formData.code" :rules="codeRule"
              :disabled="showReadOnly" @keyup="checkCode()" @keydown="prevent($event)"/>
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>



          <v-col cols="9">
            <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Name
              <span v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Name" v-model="formData.name" :rules="nameRule"
              :disabled="showReadOnly" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="cancel()">Cancel</v-btn>

          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>

  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import LocationService from "@/api/service/Location/LocationService";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
  },
  data() {
    return {
      showReadOnly: false,
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      codeError: false,
      dialog: false,
      type: null,
      // size: {},
      formData: {},
    }
  },
  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
      }
      if (this.viewType) {
        this.showReadOnly = this.viewType == 'edit' ? false : true;
        console.log(this.viewType, "viewwwwwwwwww type");
      }
    },

  },
  created() {
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == 'edit' ? false : true;
      console.log(this.viewType, "viewwwwwwwwww type");
    }
  },
  methods: {
    prevent(e) {
  if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
},
    checkCode() {
      if(this.formData.code && this.formData.code.length>0){
      this.formData.code = this.formData.code.toUpperCase();
      LocationService.findAll({ code: this.formData.code }).then((res) => {
        let $exist = res.filter(key => key.code == this.formData.code)
        this.codeExistFlag = $exist.length > 0 ? (this.formData.id && $exist[0].id == this.formData.id) ? false : true : false;
        if (this.codeExistFlag) {
          this.codeError = true
        }
        else {
           this.codeError = false
        }
      });
    }
    },

    async getById(id) {
      let res = await LocationService.findOne({ id: id });
      console.log(res)
      this.formData = res[0];
    },

    async addLocation() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {

        console.log(validation.valid)
      }
      else {
        if (this.id) {
          let output = await LocationService.update(this.formData);
          console.log(output);
          this.$store.dispatch('setSnackbar', { text: 'Updated Successfully' })
          this.dialog = false;
          this.cancel();
          this.$emit("reload", true);
        } else {
          try {
            let output = await LocationService.create(this.formData);
            console.log(output);
            this.$store.dispatch('setSnackbar', { text: 'Saved Successfully' })
            this.dialog = false;
            this.cancel();
            this.$emit("reload", true);
          } catch (e) {
            this.$store.dispatch('setSnackbar', { color: 'error', text: 'Something Went Wrong' })
          }
        }
      }
    },
    cancel() {
      this.$emit('closeDialog')
    },
  },
};
</script>
<style>
.bold {
  font-weight: bold;
}

.error-message {
  font-size: xx-small;
  color: red;
}


/* element.style {
} */

.v-label {
  font-size: 13px;
}
</style>
