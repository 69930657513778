<template>
  <div>
    <AutoComplete v-model="selectData" :items="enumData" item-title="display_value" item-value="data_value" :rules="rules"
      @update:modelValue="changeEnumData" :disabled="showReadOnly" :placeholder="placeholder" />
  </div>
</template>

<script>
import AutoComplete from "../ui/AutoComplete.vue";
import EnumService from "@/api/service/Enum/EnumService";
import commonService from "@/common.service";

export default {
  components: {
    AutoComplete,
  },
  props: ["domain", 'placeholder','selected','showReadOnly','rules'],
  data() {
    return {
    
      selectData:null,
      enumData: [],
      holder: "Task Type"
    };
  },
  
  watch: {
    selected() {
      // alert('workinggg')
      if (this.selected) {
        this.selectData =this.selected
      }
      else{
        this.selectData =null
      }
    },
  },
  created() {
    if(this.selected){
       this.selectData = this.selected
    }
    this.getEnumData();
  },

  methods: {
    // async getEnum() {
    //   let res = await EnumService.findAll();
    //   this.data = res;
    //   console.log(this.data, "enummmmm");
    // },
    changeEnumData() {
      this.$emit('changeEnumData', this.selectData)
    },
    async getEnumData() {
      let res = await EnumService.findAll({ 'domain': this.domain,active:1 })
      console.log(this.domain.enum, "dfghjk")
      this.enumData = res.length > 0 ? await commonService.getSort(res,'display_value','asc') : [];
      console.log(this.enumData, 'lllll')
    },
    setData(data){
      console.log(data,"pppppppppppp7777")
    }
  },
};
</script>

<style>

>>>.v-field--disabled {
    pointer-events: none;
    opacity: 0.8 !important;
}
</style>