<template>
  <v-container style="width: 70%">
    <v-sheet class="rounded-lg backbg pl-5" :height="80">
      <!-- <v-avatar size="130" class="mt-4 ml-9"> -->
      <div v-if="showImage" style="float: left; margin-top: 25px">
        <uploadImages
          @uploadDocument="uploadImage($event)"
          @removeFile="removeFile"
          :imageId="userData.image_profile_id"
          :firstName="userData.first_name"
          :lastName="userData.last_name"
          :themeId="this.userData.theme"
          :url="userData.image_url"
        />
      </div>
      <!-- </v-avatar> -->
      <span class="headername">{{
        userData && userData.name ? userData.name : ""
      }}</span>
      <v-btn
        @click="$router.go(-1)"
        elevation="0"
        class="headericon"
        icon="mdi-close"
        size="large"
      ></v-btn>
    </v-sheet>
    <v-row no-gutters class="content">
      <!-- <v-col class="right" cols="3" md="4" sm="5" lg="3" xl="2">
        <v-sheet class="rounded-lg" style="border: 0px solid #dee0f4">
        </v-sheet>
      </v-col> -->
      <v-col class="left">
        <v-sheet
          style="
            border-right: 0px solid #dee0f4;
            border-bottom: 0px solid #dee0f4;
          "
          class="rounded-lg mt-3"
        >
          <v-tabs v-model="tab">
            <v-tab value="Personal">Personal</v-tab>
            <v-tab value="Credential">Credential</v-tab>
            <v-tab value="Region Setting">Region Setting</v-tab>
            <v-tab value="Notification">Notification</v-tab>
            <v-tab value="Organisation">Organisation</v-tab>
            <v-tab value="Skill">Skill</v-tab>
          </v-tabs>

          <v-card-text>
            <v-window v-model="tab">
              <v-window-item value="Personal">
                <Personal :profileId="userData.image_profile_id" />
              </v-window-item>

              <v-window-item value="Credential">
                <Credentinal />
              </v-window-item>

              <v-window-item value="Region Setting">
                <RegionSetting />
              </v-window-item>

              <v-window-item value="Notification">
                <Notification />
              </v-window-item>
              <v-window-item value="Organisation">
                <Organisation />
              </v-window-item>
              <v-window-item value="Skill">
                <Skill />
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Personal from "../userProfile/Personal.vue";
import Credentinal from "../userProfile/Credentinal.vue";
import Notification from "../userProfile/Notification.vue";
import RegionSetting from "../userProfile/RegionSetting.vue";
import Organisation from "../userProfile/Organisation.vue";
import Skill from "../userProfile/Skill.vue";
import UserService from "@/api/service/UserService";
import uploadImages from "@/components/uploadImages.vue";
export default {
  components: {
    Personal,
    Credentinal,
    Notification,
    RegionSetting,
    Organisation,
    Skill,
    uploadImages,
  },
  data() {
    return {
      showImage: true,
      showAvatar: false,
      tab: null,
      userData: [],
      userDetails: JSON.parse(localStorage.getItem("user_details")),
    };
  },
  created() {
    this.getUserData(this.userDetails.id);
  },
  methods: {
    async removeFile() {
      this.userData.image_profile_id =null;
      let output = await UserService.update(this.userData);
      console.log("profile is gone", output);
    },
    async getUserData(id) {
      let res = await UserService.findOne({ id: id });
      this.userData = res[0];
      console.log(this.userData, "check444");
    },
    async uploadImage($event) {
      this.userData.image_url = $event.url;
      let output = await UserService.update(this.userData);
      console.log(output);
    },
  },
};
</script>

<style scoped>
>>>.Imag {
  position: relative;
  left: 38px;
  bottom: 30px;
}
.backbg {
  background-image: linear-gradient(
    to right,
    rgba(18, 88, 171, 0.1),
    rgb(18, 88, 171)
  );
}
.headername {
  color: #0d0d67;
  font-size: 21px;
  font-weight: 800;
  position: relative;
  top: 25px;
  left: 10px;
}
.headericon {
  float: right;
  margin-right: 20px;
  margin-top: 13px;
  color: #ffffff;
  background: none;
}
.editbtn {
  margin-top: 70px;
  margin-left: 62px;
  padding-left: 28px;
  padding-right: 28px;
  text-transform: capitalize;
  color: #1231c9;
}
.content.left.right {
  min-height: 100vh;
}
</style>
