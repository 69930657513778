<template>
  <v-card elevation="0">
    <v-form @submit.prevent="save" ref="form">
      <!-- <v-container style="background:#fff;margin-top:100px;"> -->
      <!-- <div style="margin-top: 100px;"> -->
        <v-card-item style="padding-top:1%">
        <v-card-title>{{
          viewType == "edit"
          ? "Edit"
          : viewType == "select"
            ? "View"
            : viewType == "view"
              ? "View"
              : "Add"
        }}
          <b>Issue</b>
          <v-btn style="float: right;" @click="close()" elevation="0" density="comfortable" icon="mdi-close"></v-btn>
        </v-card-title>
      </v-card-item>

      <!-- <v-row style="padding-left: 1%;">
        <v-breadcrumbs :items="items">
          <template v-slot:title="{ item }">
            <span style="text-transform: capitalize;
    color: #7d7d7dbf;">{{ item.title }}</span>
          </template>
        </v-breadcrumbs>
      </v-row> -->

      <v-row>
        <v-col cols="8" style=" margin: -46px 100px 100px;">
          <v-card-text>

            <v-row no-gutters class="mt-3">
            </v-row>
            <v-row>
              <v-col cols="10" class="pt-0">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Subject
                  <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                <TextFieldComponent placeholder="Subject" :rules="subjectRule" :disabled="showReadOnly"
                  v-model="formData.name" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="pt-0 dropdown">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Category
                  <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                  <v-icon size="25px" class="pl-4" color="#3C5AAA" @click="addCategoryModel()"
                >mdi-plus-circle</v-icon >
                <AutoComplete :items="categoryData" :placeholder="'Category'" :@change="selectCategory()"
                  :item-title="'name'" :disabled="showReadOnly" :item-value="'id'" v-model="formData.category_id"
                  :rules="categoryRule" />
              </v-col>

              <v-col cols="5" class="pt-0 dropdown">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Category
                  <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                  <v-icon size="25px" class="pl-4" color="#3C5AAA" @click="addSubCategoryModel()"
                >mdi-plus-circle</v-icon >
                <AutoComplete :items="subCategoryData" :placeholder="'Sub Category'" :item-title="'name'"
                  :disabled="showReadOnly" :item-value="'id'" v-model="formData.sub_category_id"
                  :rules="subCategoryRule" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="pt-0 dropdown">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Task
                  <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                  <v-icon size="25px" class="pl-4" color="#3C5AAA" @click="addTaskModel()"
                >mdi-plus-circle</v-icon >
                <AutoComplete :items="taskData" :placeholder="'Task'" :item-title="'name'" :disabled="showReadOnly"
                  :item-value="'id'" v-model="formData.task_id" :rules="taskRule" />
              </v-col>

              <v-col cols="5" class="pt-0 dropdown">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Issue Type
                  <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                  <v-icon size="25px" class="pl-4" color="#3C5AAA" @click="addIssueModel()"
                >mdi-plus-circle</v-icon >
                <AutoComplete :items="issueTypeData" :placeholder="'Issue Type'" :item-title="'name'"
                  :disabled="showReadOnly" :item-value="'id'" v-model="formData.issue_type_id" :rules="issueTypeRule" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="5" class="pt-0 dropdown">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Next Action
                  <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                <AutoComplete :items="userData" :placeholder="'Next Action'" :item-title="'name'" :disabled="showReadOnly"
                  :item-value="'id'" v-model="formData.next_action" :rules="nextActionRule" />
              </v-col>

              <v-col cols="5" class="pt-0 dropdown">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Priority [E]
                  <span v-if="!showReadOnly"
                    style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                <EnumAutoCompleteVue density="compact" v-model="formData.priority" :selected="formData.priority"
                  :showReadOnly="viewType == 'add' || this.viewType == 'view'" :domain="'PRIORITY'" placeholder="Priority"
                  @changeEnumData="changePriority($event)" :rules="stageRule" />

              </v-col>

              <!-- <v-col cols="5" class="pt-0">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Stage
                  <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                <AutoComplete :items="stageData" v-model="formData.stage" item-title="name" item-value="name"
                  :disabled="showReadOnly" :placeholder="'Stage'" :rules="stageRule" />
              </v-col> -->
            </v-row>
            <v-row v-if="formData && formData.id">
              <v-col cols="5" class="pt-0 dropdown">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Stage [E]
                  <span v-if="!showReadOnly"
                    style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                <EnumAutoCompleteVue density="compact" ref="stageRef" v-model="formData.stage" :selected="formData.stage"
                  :showReadOnly="viewType == 'add' || this.viewType == 'view'" :domain="'ISSUESTAGE'" placeholder="Stage"
                  @changeEnumData="changeStage($event)" :rules="stageRule" />

              </v-col>
            </v-row>

            <v-row>
             
              <v-col cols="5" class="pt-0 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Due Date
              <!-- <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <DatePicker :showReadOnly="showReadOnly"  :data="formData.end_date"  :placeholder="'Date'" @selectDate="selectDate($event)" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="8"  class="pt-7 pl-4 dropdown attachments">
    <strong><b>Attachment</b></strong>
                 
                  <uploadFiles @documentsData="documentsData($event)" />
              </v-col>
            </v-row>
            <v-row style="margin-left: 0%;">
              <v-cols v-for="(file, index) in attachmentData" :key="index">
                <v-chip class="selectedChip" :close="true" closable close-icon="mdi-close-circle"
                  close-transition="scale-transition" @click="remove(index)">
                  <span @click="downloadDoc(file)">{{ file.doc_name }}</span>
                </v-chip>
              </v-cols>
            </v-row>



            <v-row class="mt-8">
              <v-col cols="10" class="pt-0">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Description
                  <span style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
                <TextArea :data="formData.description" :showReadOnly="showReadOnly"
                  @textData="changeDescription($event)"></TextArea>
              </v-col>
             
              <v-dialog persistent v-model="taskDialog" width="700px">
                 <AddTask ref="AddTask" @closeDialog="setFalse()" @reload="getTaskData()" />
              </v-dialog>

              <v-dialog v-model="issueDialog" width="700px" persistent>
                 <AddIssueType ref="AddIsuueType" @closeDialog="setFalse()" @reload="getIssueTypeData()"/>
              </v-dialog>

              <v-dialog persistent v-model="categoryDialog" max-width="500px">
                 <AddCategory ref="AddCategory" @closeDialog="setFalse()" @reload="getCategoryData()" />
              </v-dialog>

              <v-dialog persistent v-model="subCategoryDialog" width="700px">
                <AddSubCategory :viewType="viewType" @closeDialog="setFalse()" @reload="getSubCategoryData()" ref="AddSubCategory"/>
              </v-dialog>

            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom pr-2" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="close()">Cancel</v-btn>

          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-actions>
      <!-- </div> -->
      <!-- </v-container> -->
    </v-form>
  </v-card>
</template>
<script>
import uploadFiles from '@/components/uploadFiles.vue';
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import UserService from "@/api/service/UserService";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import ProjectService from "@/api/service/Project/ProjectService";
import AccountService from "@/api/service/Account/AccountService";
import TaskService from "@/api/service/Task/TaskService";
import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
import IssueService from "@/api/service/Issue/IssueService";
import CategoryService from "@/api/service/Category/CategoryService";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
import TextArea from "@/components/ui/TextArea.vue";
import StageService from "@/api/service/Stage/StageService";
import documentService from "@/api/service/Document/DocumentService"
import EnumAutoCompleteVue from '@/components/others/EnumAutoComplete.vue';
import commonService from "@/common.service";
import AddTask from '@/modules/settings/Project/Task/AddTask.vue';
import AddIssueType from "@/modules/settings/Project/IssueType/AddIssueType.vue";
import AddCategory from "@/modules/settings/Project/Category/AddCategory.vue";
import AddSubCategory from "@/modules/settings/Project/SubCategory/AddSubCategory.vue"
import DatePicker from '@/components/ui/DatePicker.vue';


// import NextAction from '../modules/issues/NextAction.vue'

export default {
  name: "addProject",
  components: {
    TextFieldComponent,
    uploadFiles,
    AutoComplete,
    TextArea,
    EnumAutoCompleteVue,
    AddTask,
    AddIssueType,
    AddCategory,
    AddSubCategory,
    DatePicker,
  },
  props: ["id", "via",],
  data() {
    return {
      issuesDatas:{},
      parrentIssueId:null,
      projectRule: [(value) => !!value || "Project is Required"],
      accountData: [(value) => !!value || "Account is Required"],
      taskRule: [(value) => !!value || "Task is Required"],
      subjectRule: [(value) => !!value || "Subject is Required"],
      nextActionRule: [(value) => !!value || "NextAction is Required"],
      issueTypeRule: [(value) => !!value || "IssueType is Required"],
      categoryRule: [(value) => !!value || "Category is Required"],
      subCategoryRule: [(value) => !!value || "Sub-Category is Required"],
      stageRule: [(value) => !!value || "Priority is Required"],
      taskDialog: false,
      issueDialog: false,
      categoryDialog: false,
      subCategoryDialog: false,
      add: false,
      type: "null",
      showReadOnly: false,
      size: {},
      workSpaceId: '',
      stageData: [],
      userData: [],
      issueData: [],
      mindate: '2023-06-05',
      formData: {
        description: null,
        end_date:null
      },
      common: null,
      items: [
      ],
      projectId: null,
      accountId: null,
      projectData: [],
      attachmentId: [],
      attachmentData: [],
      taskData: [],
      categoryData: [],
      subCategoryData: [],
      issueTypeData: [],
      shiftTimeData: [
        { value: "morning", display: "Morning-Shift" },
        { value: "mid", display: "Mid-Shift" },
        { value: "night", display: "Night-Shift" },
      ],
      projects: {
        project_type: "SC",
        execution_method: "S",
        stagings: [],
        customer_id: null,
        mgr_id: "",
        mgr_id2: null,
        track_stagings: "N",
      },
    };
  },

  created() {
    this.getIssuesData(),
    this.getData()
    this.common = commonService
    this.getBreadcrumbs()
    if (this.$route.query.id) {
      this.getById(this.$route.query.id);
    }
    if (this.$route.query.type) {
      this.viewType = this.$route.query.type
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
    let workSpaceId = localStorage.getItem('workSpaceId')
    this.getStageDate();
    this.getUserData(workSpaceId);
    this.getTaskData();
    this.getCategoryData();
    this.getIssueTypeData();
    if (this.id) {
      this.getById(this.id);
    }
  },
  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
      }
    },
    refreshProject() {
      if (this.$store.getters.getProjectId) {

        this.projectId = localStorage.getItem("_PROJECT_ID_")
        this.getProjectData()
      }
    },
    refreshAccount() {
      if (this.$store.getters.getAccountId) {
        this.accountId = localStorage.getItem("_ACCOUNT_ID_")
        this.getAccountData()
      }
    }
  },
  computed: {
    refreshProject() {
      return this.$store.getters.getProjectId;
    },
    refreshAccount() {
      return this.$store.getters.getAccountId;
    },
  },
  methods: {
    getData(){
    //  console.log(this.$route.query.parent_issue_id,"rock")
     this.parrentIssueId = this.$route.query.parent_issue_id
    },

    async getIssuesData(){
    let res = await IssueService.findIs();
    this.issuesDatas=res
    console.log(this.issuesDatas,"rura");
    },

    // async getDatas(){
    //   this.formData.ds_issue_list.entry_date = new Date()
    // this.formData.project_id  = this.issueData.project_id
    //   let projectRes = await ProjectService.findOne(this.formData.project_id)
    //   this.formData.project_name = projectRes ? projectRes[0].name : ''

    //   this.formData.ds_issue_list.name = this.issueData.ds_issue_list.name
    //   let SubjectRes = await ProjectService.findOne(this.formData.project_id)
    //   this.formData.project_name = projectRes ? projectRes[0].name : ''
    // },


    selectDate($event){
      this.formData.end_date =$event
    },
    addTaskModel(){
     this.taskDialog = true
    },
    addIssueModel(){
     this.issueDialog = true
    },
    addCategoryModel(){
     this.categoryDialog = true
    },
    addSubCategoryModel(){
     this.subCategoryDialog = true
    },
    changePriority($event) {
      this.formData.priority = $event
    },
    changeStage($event) {
      this.formData.stage = $event
    },
    getBreadcrumbs() {
      this.accountId = localStorage.getItem("_ACCOUNT_ID_")
      this.projectId = localStorage.getItem("_PROJECT_ID_")
      this.getProjectData();
      this.getAccountData();
    },
    documentsData($event) {
      this.attachmentId.push($event.id)
      this.getAttachementData()
    },
    async getAttachementData() {
      try {
        let res = await documentService.getAllDocuments({ doc_id: JSON.stringify(this.attachmentId) })
        this.attachmentData = res
      }
      catch (e) {
        console.log(e)
      }
    },
    downloadDoc(item){
      documentService.download({id:item.id}).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("name", "downloadLink");
        link.setAttribute("download", response.headers["x-suggested-filename"]); //or any other extension
        document.body.appendChild(link);
        link.click();
      });


    },
    changeDescription($event) {
      this.formData.description = $event
    },
    remove(index) {
      if (index > -1) {
        this.attachmentId.splice(index, 1)
        // this.getAttachementData()
      }
    },

    async getStageDate() {
      try {
        let res = await StageService.findAll();
        this.stageData = res;
      } catch (e) {
        this.stageData = [];
        console.log(e);
      }
    },
    selectCategory() {
      if (this.formData.category_id) {
        this.formData.sub_category_id = ''
        this.getSubCategoryData();
      }
    },
    getTextData($event) {
      this.formData.description = $event;
    },
    setFalse() {
      this.$emit("closeDialog");
      this.$emit("reload", true);
      this.taskDialog = false;
      this.issueDialog = false;
      this.categoryDialog = false;
      this.subCategoryDialog = false;
    },
    async getById(id) {
      let res = await IssueService.findOne(id);
      this.formData = res[0];
      this.attachmentId = this.formData && this.formData.attachment ? JSON.parse(this.formData.attachment) : ""
      this.getAttachementData()

    },
    async getTaskData() {
      let res = await TaskService.findAll();
      this.taskData = res.length > 0 ? res : [];
    },
    async getProjectData() {
      try {
        let res = await ProjectService.findOne({ id: this.projectId });
        this.projectData = res && res.length > 0 ? res[0] : {};
        let $obj =
        {
          title: this.projectData.name,
          disabled: false,
          href: 'projectList',
        }
        this.items[0] = $obj

      }
      catch (e) {
        this.projectData = {}
      }
    },
    async getAccountData() {
      try {
        let res = await AccountService.findOne(this.accountId);
        this.accountData = res && res.length > 0 ? res[0] : {};
        let $obj =
        {
          title: this.accountData.name,
          disabled: false,
          href: 'accountList',
        }
        this.items[1] = $obj
      }
      catch (e) {
        this.accountData = {}
      }
    },
    async getUserData(id) {
      let res = await UserService.getUserByWorkSpace({ 'work_space_id': id });
      this.userData = res
    },
    async getCategoryData() {
      let res = await CategoryService.findAll({});
      this.categoryData = res.length > 0 ? res : [];
    },
    async getSubCategoryData() {
      let res = await SubCategoryService.findAll({
        category_id: this.formData.category_id,
      });
      this.subCategoryData = res.length > 0 ? res : [];
    },
    async getIssueTypeData() {
      let res = await IssueTypeService.findAll();
      this.issueTypeData = res.length > 0 ? res : [];
    },
    close() {
      this.$router.push("/issuelist?from=topMenu");
    },

    async save() {
      this.formData.attachment = JSON.stringify(this.attachmentId)
      // this.formData.stage = 'open'
      this.workSpaceId = localStorage.getItem('workSpaceId')
      let userData = JSON.parse(localStorage.getItem("user_details"));

      let validation = await this.$refs.form.validate()
      if (!validation.valid) {
        console.log(validation.valid)
      }
      else {
        if (this.formData.id) {

          await IssueService.update(this.formData);
          this.dialog = false;
          this.close();
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
          if (this.via) {
            this.$store.commit("RELOAD_ACTIVITY", true);
          }
        } else {
          this.formData.owner_id = userData.id;
          this.formData.project_id = this.projectId;
          this.formData.account_id = this.accountId;
          this.formData.workSpaceId = this.workSpaceId
          this.formData.user_id = userData.id;
          this.formData.watchers = JSON.stringify([]);
          this.formData.udf = JSON.stringify([]);
          if (this.$route.query.parent_id) {
            this.formData.parent_issue_id = this.$route.query.parent_id
          }
          this.formData.stage = 'open'
          await IssueService.create(this.formData);
          this.dialog = false;
          this.close();
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
          if (this.via) {
            this.$store.commit("RELOAD_ACTIVITY", true);
          }
        }
      }
    },
    cancel() {
      this.$emit("closeDialog");
      // this.issuetype=[];
    },
  },
};
</script>
<style>
.attachments{
  display: flex;
  align-items: baseline;
  /* padding-bottom: 100%; */
}
.box {
  background-color: #eef0ff;
  list-style: none;
  padding: 0px 20px;
  border-radius: 12px;
  margin-top: 10px;
  height: 50px;
}
</style>
