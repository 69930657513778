<template>
    <PrimeDataTable
      :title="'ScoreCards'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="true"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="SCORECARD"
     
    />
  
    <v-dialog persistent v-model="dialog" width="700px">
      <CreateScoreCards
        @closeDialog="close()"
        @reload="getScoreData()"
        :id="editId"
        :viewType="viewType"
        
      />
    </v-dialog>
  </template>
  
  <script>
  import CreateScoreCards from "./CreateScoreCards.vue";
  import PrimeDataTable from "@/components/PrimeDataTable";
  import ScoreCardService from "@/api/service/ScoreCard/ScoreCardService";
  export default {
    components: {
        CreateScoreCards,
      PrimeDataTable,
    },
    data() {
      return {
        data: [],
        columns: [],
        date: new Date(),
        editId: null,
        dialog: false,
        viewType: false,
      };
    },
    created() {
      this.loadColumns();
      this.download = true;
      this.search = true;
      this.checkbox = true;
      this.getScoreData();
    },
    methods: {
      loadColumns() {
        this.columns = [
          {
            field: "code",
            header: "Code",
            style: { width: "100px", color: "" },
            toolTip: "Code",
            sortfield: true,
          },
          {
            field: "name",
            header: "Name",
            style: { width: "100px", color: "" },
            toolTip: "Name",
            sortfield: true,
          },
          {
            field: "action",
            header: "Action",
            style: { width: "100px", color: "" },
            toolTip: "Action",
            sortfield: false,
            rowActions: [{ action: "view" }, { action: "edit" }],
          },
        ];
      },
      rowActions($event) {
        if ($event.type == "view") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType = $event.type;
        }
        if ($event.type == "edit") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType = $event.type;
        }
  
        if ($event.type == "select") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType = "view";
        }
      },
      async getScoreData() {
        let res = await ScoreCardService.findAll();
        this.data = res;
        console.log(this.data, "kassss");
      },
      
      close() {
        this.dialog = false;
      },
      addDiolog() {
        this.dialog = true;
        this.editId = null;
        this.viewType = false;
      },
    },
  };
  </script>
  
  
  