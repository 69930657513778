<template>
    <v-card flat class="pa-3">
    <!-- <v-container> -->
    <PrimeTable
      :title="'Jobs'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="true"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="jobs"
    />
    <!-- </v-container> -->
    <v-dialog v-model="dialog" width="650px"  persistent>
      <CreateJob
        style="overflow: auto;"
        ref="createjob"
        :jobId="getJobId"
        @reload="getJobData()"
        :id="projectId"
        :viewType="viewType"
        @closeDialog="cancel()"
      ></CreateJob>
    </v-dialog>
  </v-card>
  </template>
  
  <script>
  import PrimeTable from "@/components/PrimeDataTable.vue";
  //   import TextFieldComponent from '@/components/TextFieldComponent.vue'
  import CreateJob from "./Create.vue";
  // import commonService from '@/common.service'
  import JobsService from "@/api/service/Jobs/JobsService";
  export default {
    name: "projectList",
    components: {
      PrimeTable,
      // TextFieldComponent,
      CreateJob,
    },
    data() {
      return {
        date: new Date(),
        data: [],
        projectId: '',
        dialog:false,
        viewType: false,
      };
    },
    computed:{
      getJobId() {
        if(this.recurringData && this.recurringData.length>0) {
          return this.recurringData[0].id
        }
        return null
      }
    },
    created() {
      if(this.$route.query.from=='topMenu'){
        this.addDiolog()
      }
      this.loadColumns();
      this.download = true;
      this.search = true;
      this.checkbox = true;
      this.getJobData();
    },
    methods: {
      loadColumns() {
        this.columns = [
          {
            field: "end_point",
            header: "End Point",
            style: { width: "100px", color: "" },
            toolTip: "Endpoint",
            sortfield: true,
          },
          {
            field: "interval_type",
            header: "Interval Type",
            style: { width: "100px", color: "" },
            toolTip: "IntervalType",
            sortfield: true,
          },
          {
            field: "interval_frequency",
            header: "Frequency",
            style: { width: "100px", color: "" },
            toolTip: "Frequency",
            sortfield: true,
          },
          {
            field: "next_date",
            header: "Next Date",
            style: { width: "100px", color: "" },
            toolTip: "NextDate",
            sortfield: true,
          },
          {
            field: "modified",
            header: "Modified",
            style: { width: "100px", color: "" },
            toolTip: "Modified",
            sortfield: true,
          },
          {
            field: "action",
            header: "Action",
            style: { width: "100px", color: "" },
            toolTip: "Action",
            sortfield: true,
            rowActions: [
              
              { action: "view" },
              { action: "edit" },
              // { action: "delete" },
            ],
          },
        ];
      },
      addDiolog() {
        this.dialog = true;
        this.viewType = false;
        this.projectId = null;
        // this.viewType='add'
      },
       rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.projectId = $event.data.id;
        this.viewType = $event.type;
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.projectId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.projectId = $event.data.id;
        this.viewType = "view";
      }
    },
      async getJobData() {
        this.data = [];
        try {
          let res = await JobsService.findAll();
          this.data = res
        } catch (e) {
          console.log(e);
          this.data = [];
        }
      },
      cancel() {
        this.dialog = false;
        // this.issuetype=[];
      },
    },
  };
  </script>
  
  <style></style>
  