<template>
  <v-dialog v-model="showDialog" persistent width="40%">
    <v-card
    justify="center"
      style="border-radius: 12px; overflow: hidden"
      class="mainDialog"
    >
      <v-card-title> Confirmation 
        <v-btn
            @click="setFalse()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn>
      </v-card-title>
      <v-card-subtitle>
       Your shift time is already over. Do you want to extend you shift ?
      </v-card-subtitle>

      <v-card-content class="text-center">
        <v-form v-if="showField">
          <v-row class="pl-5">
            <v-col cols="4">
              <v-label
                class="pb-1 mt-2 mb-1 text-start"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >How long are you going to extend ?
                <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                ></v-label
              >
              <TextFieldComponent
                density="compact"
                placeholder="Give Hours"
                v-model="formData.ot_hour"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-content>
      <v-card-actions class="pl-3" :class="!showField ? 'cardAction' : ''">
        <v-row class="dialogBottom " justify="center">
          <v-btn v-if="!showField" class="save extend" @click="giveHour()"
            >Extend My Hours</v-btn
          >
          <v-btn v-if="showField" style="width: auto !important;" class="cancel" @click="showField = false"
            >Cancel </v-btn
          >
          <v-btn v-if="showField" style="width: auto !important;" class="save" @click="updateSession(true)"
            >Update </v-btn
          >
          <v-btn v-if="!showField" class="save" @click="updateSession(false)"
            >LogOut</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import moment from "moment";
import UserService from "@/api/service/UserService";
export default {
  components: {
    TextFieldComponent,
  },
  data() {
    return {
      formData: {},
      showField: false,
      showDialog: true,
    };
  },
  methods: {
    moment,
    setFalse(){
      this.showDialog = false
    },

    giveHour() {
      this.showField = true;
    },
    async updateSession($type) {
     /* let $val = JSON.parse(localStorage.getItem("USER_SESSIONS"));
      let $obj = {};
      if($val){
        $obj.id = $val.id;
      if($type){
        $obj.proposed_end_date = moment($val.proposed_end_date)
        .add(this.formData.ot_hour, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      $obj.end_date = $obj.proposed_end_date;
      $obj.ot_hour = this.formData.ot_hour
      }else{
        $obj.end_date =moment().format("YYYY-MM-DD HH:mm:ss");
      }*/
      if(!$type)  {
        await UserService.clearSessions({"sessionId":localStorage.getItem('sessionId')})
        this.setLogout()
      } else {
        await UserService.updateSessions({"ot_hour":this.formData.ot_hour,"sessionId":localStorage.getItem('sessionId')})
      }
      this.showDialog = false
      //await UserService.updateSessions($obj);
    },
    setLogout() {
      this.$store.commit("SET_OT_DIALOGUE", false);
      this.$router.push("/logout");
      localStorage.removeItem("USER_SESSIONS");
      localStorage.removeItem("timer")
    },
  },
};
</script>
<style scoped>
.extend{
  padding: 0px 75px;
}
</style>