<template>
    <v-card>
        <v-form @submit.prevent="save" ref="form">
            <v-card-item>
                <v-card-title>
                    <b> Update Next Action</b>
                    <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close"
                        class="x-closeIcon"></v-btn>
                </v-card-title>
            </v-card-item>

            <v-card-text>
                <v-row class="px-2 py-0 my-0" no-gutters>

                    <v-col cols="12" class="pr-2">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Next Action
                        </v-label>

                        <AutoComplete :items="userData" item-value="id" item-title="name" density="compact"
                            placeholder="Change Next Action" v-model="formData.user_id" 
                            :rules="nextActionRule" />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-action class="mr-3">
                <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
                    <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
                    <v-btn class="save" type="submit">Save</v-btn>
                </v-row>
            </v-card-action>
        </v-form></v-card>
</template>
<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import UserService from "@/api/service/UserService";


export default {
    props: ["stageData"],
    components: {
        AutoComplete
    },
    data() {
        return {
            formData: {},
            changeData: null,
            nextActionRule: [(value) => !!value || "Next Action is required"],
            userData: []
        }
    },
    created() {
        this.getUsers()
    },
    methods: {
        close() {
            this.$emit("closeDialog");
        },
        changeUser($event) {

            this.changeData = $event
        },
        async getUsers() {
            try {
                let wsDefaults = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
                let res = await UserService.getNextAction({ work_space_id: wsDefaults.workspace.id, account_id: wsDefaults.account.id, project_id: wsDefaults.project.id })
                this.userData = res.length ? res : [];
            }
            catch (e) {
                this.workSpaceUsers = []
            }
        },
        async save() {
            let validation = await this.$refs.form.validate();
            if (!validation.valid || this.codeError) {
                console.log("Not valid");
            }
            else {
                let data =await this.userData.filter(k=>k.id==this.formData.user_id)      
                this.$emit('update-user', data&& data.length>0?data[0]:{})
            }
        }
    }
}
</script>
