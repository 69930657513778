<template>
  <v-card width="100%" height="100%" style="overflow: auto" flat>
    <v-form @submit.prevent="save" ref="form" v-model="value">
      <v-card-item>
        <v-card-title>
          {{ viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add" }}
          <b> Schedule Report</b>
          <v-btn @click="close()" elevation="0" density="comfortable" class="x-closeIcon">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn class="newSave" type="submit" elevation="0">Save</v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text class="pl-7 pt-7 pr-7">
        <v-row dense>
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Choose Report
              <!-- <span v-if="!showReadOnly"
                                  style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <AutoComplete
              :items="listModelData"
              item-title="name"
              v-model="formData.report_id"
              item-value="id"
              :disabled="showReadOnly"
              :placeholder="'Choose Report'"
            />
          </v-col>

          <v-col cols="2" class="mx-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Report Name
              <!-- <span v-if="!showReadOnly"
                                  style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Report Name"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="py-0">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
            >
              Interval Type
            </v-label>
            <v-radio-group
              :disabled="showReadOnly"
              v-model="formData.interval_type"
              inline
            >
              <v-radio
                v-for="intervalType in intervalTypes"
                color="primary"
                v-bind:key="intervalType"
                :label="intervalType.name"
                :value="intervalType.id"
                :v-bind:key="intervalType"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
            >
              Interval Frequency
            </v-label>
            <TextFieldComponent
              :disabled="showReadOnly"
              hide-details
              v-model="formData.interval_frequency"
            />
          </v-col>
          <v-col cols="12">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
            >
              Include Days{{ include_days }}
            </v-label>
            <v-row no-gutters dense>
              <v-col class="pa-0 ma-0" cols="3" v-for="day in days" v-bind:key="day">
                <v-switch
                  :disabled="showReadOnly"
                  color="primary"
                  inline
                  v-model="include_days[day.id]"
                  density="compact"
                  :label="day.name.substr(0, 3)"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="2">
            <v-label style="font-size: 14px; color: #272727; font-weight: 600">
              Next Date
            </v-label>
            <TextFieldComponent
              :disabled="showReadOnly"
              type="datetime-local"
              :showtime="false"
              v-model="formData.nextDate"
            />
          </v-col>
          <v-col cols="2" class="mx-2">
            <v-label style="font-size: 14px; color: #272727; font-weight: 600">
              Expires on
            </v-label>
            <TextFieldComponent
              :disabled="showReadOnly"
              type="datetime-local"
              :showtime="false"
              v-model="formData.expire_date"
            />
          </v-col>
          <v-col cols="2" class="mr-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Export As
              <!-- <span v-if="!showReadOnly"
                                  style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <AutoComplete
              :items="ExportData"
              item-title="name"
              v-model="formData.export_options"
              item-value="id"
              :disabled="showReadOnly"
              :placeholder="'Export As'"
            />
          </v-col>
          <v-col cols="2" class="mr-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >List Model Filter
              <!-- <span v-if="!showReadOnly"
                                  style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <AutoComplete
              :items="listModelFilterData"
              item-title="name"
              v-model="formData.filter_options"
              item-value="id"
              :disabled="showReadOnly"
              :placeholder="'List Model Filter'"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Email
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Email"
              :rules="emailRule"
              v-model="formData.email_to"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Cc Email
              <!-- <span v-if="!showReadOnly"
                                  style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Cc Email"
              v-model="formData.cc_email"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import JobService from "@/api/service/Jobs/JobsService";
import ListModelService from "@/api/service/ListModel/ListModelService";
import ListModelFilterService from "@/api/service/ListModelFilter/ListModelFilterService";

import moment from "moment";
export default {
  components: {
    AutoComplete,
    TextFieldComponent,
  },

  data() {
    return {
      dateError: false,
      listModelData: [],
      listModelFilterData: [],
      showReadOnly: false,
      intervalData: [
        { id: 1, name: "Week" },
        { id: 1, name: "Month" },
        { id: 1, name: "PerDay" },
        { id: 1, name: "PerHour" },
        { id: 1, name: "Minutes" },
      ],
      ExportData: [
        { id: "pdf", name: "Pdf" },
        { id: "excel", name: "Excel" },
        { id: "json", name: "Json" },
      ],
      intervalTypes: [
        { id: "minutes", name: "Minutes" },
        { id: "hourly", name: "Hourly" },
        { id: "daily", name: "Daily" },
        { id: "weekly", name: "Weekly" },
        { id: "monthly", name: "Monthly" },
      ],
      include_days: {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
      },
      days: [
        { id: "sun", name: "Sunday" },
        { id: "mon", name: "Monday" },
        { id: "tue", name: "Tuesday" },
        { id: "wed", name: "Wednesday" },
        { id: "thu", name: "Thursday" },
        { id: "fri", name: "Friday" },
        { id: "sat", name: "Saturday" },
      ],
      nameRule: [(value) => !!value || "Name is Required."],
      // ccRule: [(value) => !!value || "CC is Required."],
      reportRule: [(value) => !!value || "Report Name is Required."],
      emailRule: [(value) => !!value || "Email is Required."],
      frequencyRule: [(value) => !!value || "Frequency is Required."],
      dateRule: [(value) => !!value || "Date is Required."],
      timeRule: [(value) => !!value || "Time is Required."],
      intervalRule: [(value) => !!value || "Interval is Required."],
      // intervalRule: [(value) => !!value || "Interval is Required."],
      formData: {
        report_id: null,
        name: null,
        filter_options: null,
        export_options: null,
        schedule_my_report: false,
        email_to: null,
        cc_email: null,
        schedule_day: null,
        schedule_at: null,
        interval: null,
        frequency: null,
        active: null,
      },
    };
  },

  watch: {
    $route: {
      handler(newRoute) {
        if (newRoute.query.type) {
          this.viewType = newRoute.query.type;
          this.showReadOnly = this.viewType === "edit" ? false : true;
        }

        if (newRoute.query.id) {
          this.getById(newRoute.query.id);
        }
      },
      immediate: true,
    },
  },

  // watch: {
  //     id() {
  //         if (this.id) {
  //             this.formData = {};
  //             this.getById(this.id);
  //         }
  //     },
  // },
  created() {
    this.formData.active == "1";
    this.id = this.$route.params.id;
    if (this.id) {
      this.getById(this.id);
    }
    this.createTimelines();
    this.getListData();
    this.getListFilterData();

    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    moment,
    close() {
      this.$router.go(-1);
    },
    async getById(id) {
      let res = await JobService.reportListOne(id);
      this.formData = res[0];
      this.formData.schedule_day = res[0].schedule_day
        ? moment(res[0].schedule_day).format("YYYY-MM-DD ")
        : moment().format("YYYY-MM-DD ");
    },

    async getListData() {
      let res = await ListModelService.findAll();
      this.listModelData = res;
    },
    async getListFilterData() {
      let res = await ListModelFilterService.findAll();
      this.listModelFilterData = res;
    },
    createTimelines() {
      let $fromDate = this.nextDate ? moment(this.nextDate) : moment();
      let $start = $fromDate.toObject();
      console.log($start);
      let $timelines = [];
      if ($fromDate.get("minutes") <= 30) {
        $timelines.push(moment($fromDate).format("HH:mm"));
        $fromDate.set("minutes", 30);
        $timelines.push(moment($fromDate).format("HH:mm"));
      } else {
        $fromDate.set("hours", $fromDate.get("hours") + 1);
        $fromDate.set("minutes", 0);
        $timelines.push(moment($fromDate).format("HH:mm"));
      }
      do {
        $fromDate = moment($fromDate).add(0.5, "hour");
        $timelines.push(moment($fromDate).format("HH:mm"));
      } while ($fromDate.get("hours") < 23);
      $timelines.push("23:30");
      $timelines.push("00:00");
      this.timelines = $timelines;
    },

    async save() {
      this.dateError = this.formData.schedule_day ? false : true;
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation.valid);
      } else {
        this.formData.include_days = JSON.stringify(this.include_days);
        let paramsData = {
          filterId: this.formData.filter_options,
          reportName: this.formData.name,
          email: this.formData.email_to,
          cc: this.formData.cc_email,
          report_id: this.formData.report_id,
        };
        console.log(paramsData, "paramkishore");
        // Set the prepared data to formData.params
        this.formData.params = JSON.stringify(paramsData);
        if (this.formData.id) {
          await JobService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.close();
          this.$emit("reload", true);
          console.log(this.formData, "hhhhhhhhhhhh");
          this.formData = {};
        } else {
          try {
            await JobService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.close();
            this.$emit("reload", true);
            this.formData = {};
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>
<style scoped>
v-error {
  color: red;
  padding-left: -16px;
  padding-left: 2%;
  font-size: 12px;
  padding-left: unset;
  position: relative;
  /* margin-top: 8px; */
  top: -15px;
}

.newSave {
  position: absolute;
  right: 75px;
  background: #1258ab;
  color: #fff;
}
</style>
