
<template>
  <v-card flat class="card">
    <v-card-title align="start">By Customers</v-card-title>
    <Chart type="pie" align="center" :options="chartOptions" class="Chart w-full md:w-30rem"  :data="chartData" />
  </v-card>
</template>

<script>
import Chart from "primevue/chart";
export default {
  components: {
    Chart,
  },
  data() {
    return {
      chartData: {
        labels: ['A', 'B', 'C'],
        datasets: [
          {
            data: [540, 325, 702],
          },
        ],
      },
      chartOptions: {
                plugins: {
                    legend: {
                        labels: {
                            usePointStyle: true
                        }
                    }
                }
            }
    };
  },
};
</script>
<style scoped>
.Chart {
  width: 236px;
  height: 146px;
}
.card{
  text-align: -webkit-center;
    
}
</style>