<template>
    <div class="cardTitle px-4 pt-3 mb-2 d-flex justify-space-between">
        <span><v-icon id="#filter-activator">mdi-filter-outline</v-icon>
            Assigned to me
            <v-menu v-model="menu" activator="parent" transition="scale-transition" :close-on-content-click="false" location="bottom">
                <v-list width="350px" >
                    <DashboardFilter :type="'openIssue'" :existFilterData="filterData" @applyChange="filterTheData" />
                </v-list>
            </v-menu>
        </span>
        <span style="display: flex;">
            <v-icon v-if="false">mdi-download-outline</v-icon>

            <span ref="searchContainer" v-if="searchClicked">
                <TextFieldComponent v-model="searchQuery" @input="getSearch()" flat append-inner-icon="mdi-magnify">
                </TextFieldComponent>
            </span>
            <v-icon v-else @click.stop="toggleSearch">mdi-magnify</v-icon>
        </span>
    </div>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import DashboardFilter from "../Widgets/DashboardFilter.vue";
export default {
    components: {
        TextFieldComponent,
        DashboardFilter
    },
    props: ['filterData'],
    data() {
        return {
            menu:false,
            searchClicked: false,
            searchQuery: ''
        };
    },
    methods: {
        getSearch() {
            this.$emit('getSearch', this.searchQuery);
        },
        filterTheData(data) {
            // console.log(data)
            this.$emit('filterTaskData', data);
            this.menu = false
        },
        toggleSearch() {
            this.searchClicked = !this.searchClicked;
            if (this.searchClicked) {
                document.addEventListener('click', this.handleOutsideClick);
            } else {
                document.removeEventListener('click', this.handleOutsideClick);
            }
        },
        handleOutsideClick(event) {
            const textField = this.$refs.searchContainer;
            if (textField && !textField.contains(event.target)) {
                this.searchClicked = false;
                document.removeEventListener('click', this.handleOutsideClick);
            }
        },
    },
};
</script>


<style scoped>
/* >>>.v-text-field input.v-field__input {
    min-height: 35px;
    padding-top: 0px;
} */

/* >>>.v-field {
    display: grid;
    grid-template-areas: "prepend-inner field clear append-inner";
    grid-template-columns: min-content minmax(0, 1fr) min-content min-content;
    font-size: 16px;
    letter-spacing: 0.009375em;
    max-width: 100%;
    border-radius: 4px;
    contain: layout;
    flex: 1 0;
    grid-area: control;
    position: relative;
    --v-field-padding-start: 16px;
    --v-field-padding-end: 16px;
    --v-field-padding-top: 10px;
    --v-field-padding-bottom: 5px;
    --v-field-input-padding-top: 0px !important;
    --v-field-input-padding-bottom: 0px !important;
    height: 35px;
    background: transparent;
    width: 30px;
} */

>>>.v-field {
    background: #fff;
    width: 200px !important;
    z-index: 3;
}

/* >>>.v-field__field .v-field__input {
    background-color: transparent !important;
}

>>>.v-field.v-field--appended {
    background-color: transparent !important;
} */

>>>.v-text-field .v-input__details {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    display: contents;
}
</style>