<template>
  <!-- <v-card style="border-radius: 12px;color:white" flat  > -->
  <input
    accept=".JPEG,.PNG,.JPG,.GIF,.TIFF,.PSD"
    type="file"
    ref="fileInput"
    @change="handleFileUpload"
    style="display: none"
    :disabled="showReadOnly"
  />

  <div v-if="AccountId" class="mainAcc">

    <div class=" image">
      <img
        @click="triggerFileInput"
        v-if="imageUrl && AccountId"
        :src="imageUrl ? imageUrl : ''"
        class="imagePreviewWrapper img"
      />
     
          <v-icon v-if="imageUrl && AccountId &&!showReadOnly" class="btn px-2 ml-1" @click="removeFile" color="#1258AB" size="18">mdi-delete-forever</v-icon>
      <v-icon
        v-if="!imageUrl"
        color="primary"
        class="pl-4 mt-5"
        style="position: relative; top: 18px; right: 10px"
        @click="triggerFileInput"
      >
        mdi-cloud-upload
      </v-icon>
    </div>
  </div>
    <!-- <div></div> -->


  <div class="mainDiv">
    <v-avatar
      :color="Theme?Theme:'#3c5aaa'"
      v-if="!imageUrl && !AccountId"
      size="120"
      style="font-size: 40px; border-radius: 50%; color: white; cursor: pointer"
    >
      {{
        first_name
          ? first_name.slice(0, 1) + (last_name ? last_name.slice(0, 1) : "")
          : ""
      }} <br>
    <img
    v-if="!showReadOnly"
   @click="atClick()"
   class="addImage avatr"
   src="https://www.gstatic.com/images/icons/material/system/2x/photo_camera_white_24dp.png"/>
    </v-avatar> 
  </div>

  <div class="mainDiv">
    <img
      v-if="imageUrl && !AccountId"
      :src="imageUrl ? imageUrl : ''"
      class="imagePreviewWrapper"
    />  
    <img
      v-if="!showReadOnly"
      @click="atClick()"
      class="addImage Imag"
      src="https://www.gstatic.com/images/icons/material/system/2x/photo_camera_white_24dp.png"/>
  </div>

  <v-dialog v-model="dialog" width="250">
    <v-card height="300" class="px-4">
      <v-title
        class="py-0 pt-3"
        style="display: flex; justify-content: space-between; color: #1258ab"
      >
        <b> Synergy</b>
        <v-icon @click="close()">mdi-close</v-icon>
        <!-- <v-btn  elevation="0" density="comfortable" icon="" ></v-btn> -->
      </v-title>

      <v-avatar
        :color="Theme"
        v-if="!imageUrl && !AccountId"
        size="120"
        class="ml-12 mt-4"
        style="
          font-size: 40px;
          border-radius: 50%;
          color: white;
          cursor: pointer;
        "
      >
        {{
          first_name
            ? first_name.slice(0, 1) + (last_name ? last_name.slice(0, 1) : "")
            : ""
        }}
      </v-avatar>

      <img
        v-if="imageUrl && !AccountId"
        :src="imageUrl ? imageUrl : ''"
        class="imagePreviewWrapperD dialogImage"
      />

      <div class="btnPosition">
        <button
          v-if="!imageUrl"
          class="btnAdd px-2 ml-1"
          @click="triggerFileInput"
        >
          <v-icon color="#1258AB" size="18">mdi-plus</v-icon> &nbsp; Add Profile
          Picture
        </button>

        <button v-else class="btn px-2 mr-1" @click="triggerFileInput">
          <v-icon color="#1258AB" size="18">mdi-pencil</v-icon> &nbsp; Change
        </button>

        <button v-if="imageUrl" class="btn px-2 ml-1" @click="removeFile">
          <v-icon color="#1258AB" size="18">mdi-pencil</v-icon> &nbsp; Remove
        </button>
      </div>
    </v-card>
  </v-dialog>

  <!-- </v-card> -->
</template>

<script>
import documentService from "@/api/service/Document/DocumentService";
import UserService from "@/api/service/UserService";
export default {
  props: [
    "imageId",
    "firstName",
    "lastName",
    "showEdit",
    "themeId",
    "AccountId",
    "showReadOnly",
    "theme",
    "url"
  ],
  data() {
    return {
      dialog: false,
      previewImage: null,
      // firstName:'',
      first_name: "",
      last_name: "",
      file: {},
      theme_id: "",
      Theme: "",
      imageUrl: "",
      defaultimage: true,
      uploadimage: false,
      imgData: null,
      userTheme: {},
      userDetails: JSON.parse(localStorage.getItem("user_details")),
      //  emits:[]
    };
  },

  watch: {
    url(){
      if(this.url){
        this.imageUrl =this.url
      }
    },
    themeId() {
      if (this.themeId) {
        this.Theme = this.themeId;
      }
    },
    imageId() {
      if (this.imageId) {
        this.getImage(this.imageId);
      }
    },
    firstName() {
      if (this.firstName) {
        this.first_name = this.firstName;
        console.log(this.first_name, "check22222222222222222222");
      }
    },
    lastName() {
      if (this.lastName) {
        this.last_name = this.lastName;
      }
    },
  },
  created() {
    // console.log(this.firstName,'propsssssssssssssssssssssss')
    console.log(this.AccountId, "check22222");
    if (this.userDetails.id) {
      this.getTheme(this.userDetails.id);
    }
    if (this.imageId) {
      this.getImage(this.imageId);
    }
    
    if(this.url){
        this.imageUrl =this.url
      }
  },
  methods: {
    isImageId(){

    },
    removeFile() {
      this.imageUrl = null;
      this.$emit("removeFile");
    },

    atClick() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    triggerFileInput() {
      {
        this.$refs.fileInput.click();
      }
    },
    async getTheme(id) {
      let res = await UserService.findOne({ id: id });
      this.userTheme = res[0];
    },
    async getImage(id) {
      try {
        let data = await documentService.getImage({ id: id });
        this.imageUrl = data ? data : null;
      } catch (e) {
        this.imageUrl = null;
      }
    },
    handleFileUpload(event) {
      const file = event.target.files;
      if (file[0] !== undefined) {
        var $file = this.file;
        this.file.name = file[0].name;
        const fr = new FileReader();
        fr.readAsDataURL(file[0]);
        // fr.onload = e => {
        //     this.previewImage = e.target.result

        //   }
        fr.addEventListener("load", async () => {
          $file.url = fr.result;
          $file.imagefile = file[0];
          var $json = {
            name: $file.name,
            doc_name: $file.name,
            file_name: $file.name,
            key_type: "uploadFiles",
            key_id: 1,
            file_type: $file.imagefile.type,
            active: 1,
            src: $file.url,
            data: $file.url,
            profile:true
          };
          let res = await documentService.upload($json);
          console.log(res.data,"oooooooooooooooooooooopppprrrrr")
          this.$emit("uploadDocument", res.data);
          this.imageUrl =null
          // if (res.data.id) {
          //   this.getImage(res.data.id);
          // }
        });
      }
    },
  },
};
</script>

<style>
.imagePreviewWrapper {
  width: 100px;
  height: 100px;
  display: block;
  cursor: pointer;
  margin: 0 auto 0px;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
}
.imagePreviewWrapperD {
  width: 120px;
  height: 120px;
  display: block;
  cursor: pointer;
  margin: 0 auto 0px;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
}
.image {
  height: 100px;
  margin-left: 64px;
  background-color: #ececec;
  border: 1px solid dodgerblue;
  border-radius: 8px;
  width: 70%;
  margin-top: 12px;
}
.img {
  width: 100%;
  background-size: cover;
  height: 100%;
}
.mainAcc {
  display: flex;
  text-align: center;
  align-items: center;
}
/* .add-Image{
  display: none;
}
.add-Image:hover{
  display: flex;

} */
.mainDiv:hover .addImage {
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  height: 27px;
  opacity: 0.8;
  cursor: pointer;
}
.mainDiv .addImage {
  display: none;
}
.avatr{
  position: absolute;
  bottom: 10px;
  left: 48px;
}
/* .Imag{
  position: relative;
  top: 211px;
  bottom: 30px;
  left: 5px;
} */

.btn {
  color: #1258ab;
  border-radius: 3px;
  padding: 4px;
  text-align: center;
  border: 1px solid #1258ab;
  height: 32px;
}
.btnAdd {
  color: #1258ab;
  border-radius: 3px;
  /* padding: 4px; */
  text-align: center;
  border: 1px solid #1258ab;
  height: 32px;
  width: 210px;
}
.btnPosition {
  position: absolute;
  bottom: 20px;
  display: flex;
}
.dialogImage {
  position: relative;
  top: 20px;
}
</style>
