<template>
    <v-card class="card">
        <v-form @submit.prevent="save" ref="form">
            <v-card-item class="mb-4">
                <v-card-title>
                    <v-icon>mdi-filter-outline</v-icon><b> Filters</b>
                    <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close-circle-outline"
                        class="x-closeIcon mt-3 mr-2"></v-btn>
                </v-card-title>
            </v-card-item>

            <v-card-text>
                <AutoComplete clearable chips placeholder="Resource"
                    :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']" multiple
                    variant="outlined"></AutoComplete>

                <DatePicker placeholder="Date"
                    variant="outlined"></DatePicker>
                <AutoComplete clearable chips placeholder="Project"
                    :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']" multiple
                    variant="outlined"></AutoComplete>

                <AutoComplete clearable chips placeholder="Team"
                    :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']" multiple
                    variant="outlined"></AutoComplete>
                    <AutoComplete clearable chips placeholder="Clients"
                    :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']" multiple
                    variant="outlined"></AutoComplete>
            </v-card-text>

            <v-card-action class="mr-3">
                <v-row justify="center" class="" v-if="!showReadOnly">
                    <v-btn class="save" type="submit">Apply Filter</v-btn>
                </v-row>
            </v-card-action>
        </v-form></v-card>
</template>

<script>
import AutoComplete from '../../components/ui/AutoComplete.vue'
import DatePicker from '../../components/ui/DatePicker.vue'
export default {
    components:{
        AutoComplete,
        DatePicker
    },
    data() {
        return {
            formData: '',
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
>>>.card{
    border-radius: 0px;
}
.save{
background-color: #1258AB;
color: #fff;
width: 240px;
height: 44px;
border-radius: 22px
}
.v-dialog > .v-overlay__content > .v-card {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 45px;
    right: 434px;
}
</style>