<template>
  <v-card style="border-radius: 8px">

    <v-row class="diolog-header">
      <v-card-title>{{ viewType == 'edit' ? 'Edit' : viewType == 'view' ? 'View' : "Add" }}
        ScoreCard Template</v-card-title>
      <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn></v-row>

    <v-container class="mt-8">
      <v-form @submit.prevent="save" ref="form">
        <v-card-text>
          <v-row no-gutters class="mt-3 px-0">

            <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

            <v-col cols="6">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <TextFieldComponent density="compact" placeholder="Code" :rules="codeRule" v-model="formData.code"
                :disabled="showReadOnly" @keyup="checkCode()" @keydown="prevent($event)" />
              <v-error v-if="codeError">Already Exists!</v-error>
            </v-col>

            <v-col cols="6" class="pl-3">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <TextFieldComponent density="compact" placeholder="Name" :rules="nameRule" v-model="formData.name"
                :disabled="showReadOnly" />
            </v-col>
            <v-col cols="16" class="dropdown">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Score Card
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
              <AutoComplete :items="scoreCardData" item-value="id" item-title="name" density="compact"
                placeholder="Score Card" v-model="scoreId" @update:modelValue="selectScoreCardData"
                :disabled="showReadOnly" />
            </v-col>
          </v-row>

          <v-row class="px-3">
            <table style="width: 100%;padding-bottom: 30px;border-radius: 4px;background: #FCF9F9;
            border-collapse: collapse;overflow: hidden;">

              <thead style="border-radius: 4px;background: rgba(238, 238, 238, 0.93);">
                <tr style="height: 40px;">
                  <th style="text-align: start;padding-left: 30px;">
                    Name
                  </th>
                  <th style="text-align: end;padding-right: 30px;">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in selectScoreCard" :key="item.name">
                  <td style="text-align: start;padding-left: 30px;">{{ item.name }}</td>
                  <td style="display: flex;justify-content: center;">
                    <v-switch v-model="item.status" class="Switch" color="primary" :disabled="showReadOnly">
                    </v-switch>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-row>
        </v-card-text>

        <v-row justify="end" class="dialogBottom my-3" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" :loading="saveClicked" type="submit">Save</v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import ScoreCardTemplateService from "@/api/service/ScoreCardTemplate/ScoreCardTemplateService";
import ScoreCardService from "@/api/service/ScoreCard/ScoreCardService";


export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete
  },

  data() {
    return {
      scoreId: null,
      selectScoreCard: [],
      scoreCardData: [],
      backUpScoreCardData: [],
      saveClicked: false,
      showReadOnly: false,
      dialog: false,
      type: null,
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      formData: {
        id: null,
        code: null,
        name: null,
        score_ids: null
      }
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    this.getScoreCardData()
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {

    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        ScoreCardTemplateService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    close() {
      this.$emit("closeDialog");
    },
    selectScoreCardData($event) {
      if ($event) {
        let data = this.backUpScoreCardData.filter(k => k.id == $event)
        if (data && data.length > 0) {
          let obj = {
            id: data[0].id,
            name: data[0].name,
            status: true
          }
          this.selectScoreCard.push(obj)
        }
      }
      this.scoreId = null
      this.reloadTheData()
    },
    reloadTheData() {
      if (this.selectScoreCard && this.selectScoreCard.length) {
        for (let e of this.selectScoreCard) {
          console.log(e)
          let index = this.scoreCardData.findIndex(k => k.id == e.id)
          if (index > -1) {
            this.scoreCardData.splice(index, 1)
          }
        }
      }
    },
    async getScoreCardData() {
      try {
        let res = await ScoreCardService.findAll()
        this.scoreCardData = res && res.length > 0 ? res : []
        this.backUpScoreCardData = res && res.length > 0 ? res : []
      }
      catch (e) {
        this.scoreCardData = []
        this.backUpScoreCardData = []
        console.log(exports)

      }
    },
    async getById(id) {
      let res = await ScoreCardTemplateService.findOne({ id: id });
      this.formData = res[0];
      this.selectScoreCard = this.formData && this.formData.score_ids ? JSON.parse(this.formData.score_ids) : []
      this.reloadTheData()
    },

    async save() {
      this.formData.score_ids = JSON.stringify(this.selectScoreCard)
      if (this.formData.id) {
        this.saveClicked = true
        await ScoreCardTemplateService.update(this.formData);
        this.dialog = false;
        this.close();
        this.$emit("reload", true);
        this.formData = {};
      } else {
        await ScoreCardTemplateService.create(this.formData);
        this.dialog = false;
        this.close();
        this.$emit("reload", true);
        this.formData = {};
      }

    },
  },
};
</script>
<style scoped>
>>>.v-switch .v-selection-control {
  min-height: 0px;
}

/* <style> */
/* >>>.v-selection-control--density-default {
    --v-selection-control-size: 10px;
} */

>>>.v-selection-control {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 30px;
}

>>>.v-switch>.v-input__details {
  align-items: flex-end;
  display: none;
  font-size: 0.75rem;
  font-weight: 400;
  grid-area: messages;
  letter-spacing: 0.0333333333em;
  line-height: normal;
  min-height: 22px;
  padding-top: 6px;
  overflow: hidden;
  justify-content: space-between;
}

tr:hover {
    background: none;
}
</style>