<template>
    <PrimeDataTable
      :title="'UserScoreCard'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="true"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="USERSCORECARD"
     
    />
  
    <v-dialog persistent v-model="dialog" width="700px">
      <CreateUserScoreCard
        @closeDialog="close()"
        @reload="getUserScoreCard()"
        :id="editId"
        :viewType="viewType"
        
      />
    </v-dialog>
  </template>
  
  <script>
  import CreateUserScoreCard from "./CreateUserScoreCard.vue";
  import PrimeDataTable from "@/components/PrimeDataTable";
  import UserScoreCardService from "@/api/service/UserScoreCards/UserScoreCardsService";
  // import commonService from "@/common.service";
  export default {
    components: {
        CreateUserScoreCard,
      PrimeDataTable,
    },
    data() {
      return {
        data: [],
        columns: [],
        date: new Date(),
        editId:null,
        dialog: false,
        viewType:false
      };
    },
    created() {
      this.loadColumns();
      this.download = true;
      this.search = true;
      this.checkbox = true;
      this.getUserScoreCard();
    },
    methods: {
  
      loadColumns() {
        this.columns = [
          // {
          //   field: "id",
          //   header: "Id",
          //   style: { width: "100px", color: "" },
          //   toolTip: "id",
          //   sortfield: true,
          // },
          {
            field: "score_card_name",
            header: "Score",
            style: { width: "100px", color: "" },
            toolTip: "score_card_name",
            sortfield: true,
          },
          {
            field: "user_name",
            header: "User",
            style: { width: "100px", color: "" },
            toolTip: "user_name",
            sortfield: true,
          },
          // {
          //   field: "kra_review_id",
          //   header: "Kra Review Id",
          //   style: { width: "100px", color: "" },
          //   toolTip: "kra_review_id",
          //   sortfield: true,
          // },
          {
            field: "score",
            header: "Score",
            style: { width: "100px", color: "" },
            toolTip: "score",
            sortfield: true,
          },
          {
            field: "review_date",
            header: "Review Date",
            style: { width: "100px", color: "" },
            toolTip: "review_date",
            sortfield: true,
            type: "date",
          },
          {
            field: "fy",
            header: "Fy",
            style: { width: "100px", color: "" },
            toolTip: "fy",
            sortfield: true,
          },
          {
            field: "reviewer_name",
            header: "Reviewer",
            style: { width: "100px", color: "" },
            toolTip: "reviewer_name",
            sortfield: true,
          },
          {
            field: "assignee_name",
            header: "Assigned",
            style: { width: "100px", color: "" },
            toolTip: "assignee_name",
            sortfield: true,
          },
        ];
      },
  
      close() {
        this.dialog = false;
      },
      rowActions($event) {
        if ($event.type == "view") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }
        if ($event.type == "edit") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }   
  
        if ($event.type == "select") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType="view";
        }
      },
      async getUserScoreCard() {
        let res = await UserScoreCardService.findAllName();
        this.data = res;
      },
      addDiolog() {
        this.dialog = true;
        this.editId = null;
      }
    },
  };
  </script>
  
  
  