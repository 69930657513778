import { io } from "socket.io-client";
//const { uuid } = require('uuidv4');
//import { uuid } from 'uuidv4';
import store from '@/store/index'

const URL = process.env.VUE_APP_SOCKET_URL
let socket2
const join = (sessionId) => {
    //import apiConfig from '@/api/config/config.js'

    let $auth = localStorage.getItem('TOKEN')


    if ($auth && sessionId) {
        console.log(URL, "connectioncheck url")
        // let $user= JSON.parse(localStorage.getItem('_LOGGED_USER_'))
        socket2 = io(URL, {
            autoConnect: true,
            'reconnection': true,
            'reconnectionDelay': 50,
            'reconnectionAttempts': 20
        });
        //let session =  { sessionId:$data.id,userId:$uid,userName:$currentUserName } ;
        socket2.auth = { sessionId: sessionId, token: $auth, from: 'front' }
        socket2.connect()
        console.log(socket2.connect(), 'connectioncheckttttttttttttttttttttttt')
        //console.log(socket,'getsocketconnectino')
        socket2.on("_FORCE_LOGOUT_", (message) => {
            console.log(message)
            localStorage.removeItem("TOKEN");
            localStorage.removeItem("sessionId");
            localStorage.removeItem("user_details");
            localStorage.removeItem("workSpaceId");
            localStorage.removeItem("_PROJECT_ID_");
            localStorage.removeItem("_ACCOUNT_ID_");
            localStorage.removeItem("_WORK_TIMING_");
            localStorage.removeItem("_SESSION_ID_");
            localStorage.removeItem("USER_SESSIONS")
            window.location = "/"
        })
        socket2.on("_APP_NOTIFY_", (notificationId) => {
            store.commit("SET_NOTIFY_ID", notificationId);

        })
        
        socket2.on("_RELOAD_ACTIVITY_", (issueId) => {        
            let status = store.getters.getReloadForums;
            store.commit("RELOAD_FORUMS", !status);
            console.log(issueId)
            // alert(status)    

        })

        socket2.on("_FCM_NOTIFICATION_", (payload) => {
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
                body: payload.notification.body,
                icon: '/firebase-logo.png',
                data:payload.data
            };
            self.registration.showNotification(notificationTitle,
                notificationOptions);            
            self.addEventListener('notificationclick', function (event) {
              console.log("notificationclick", event)
              var urlToRedirect = event.notification.data.url;
              event.notification.close();
              event.waitUntil(self.clients.openWindow(urlToRedirect));
            })
           console.log("_FCM_NOTIFICATION_",payload)

        })

        socket2.on("_RELOAD_ISSUE_", () => {
            let status = store.getters.getReloadTheIssue;
            store.commit("RELOAD_THE_ISSUE", !status);

        })
        socket2.on("OLD_ISSUE_UDPATED", (payload) => {
            console.log(payload,"SET_UPDATED_ISSUE_ID")
            store.commit("SET_OLD_ISSUE_UPDATED", true);
            store.commit("SET_UPDATED_ISSUE_ID",payload)

        })
        socket2.on("NEW_ISSUE_CREATED", (payload) => {
            console.log(payload,"NEW_ISSUE_CREATED")
            store.commit("SET_ISSUE_CREATED", true);
            store.commit("SET_UPDATED_ISSUE_ID",payload)
        })
        socket2.onAny((event, ...args) => {

            console.log(event)
            console.log(args)
        });
        socket2.on((sessionId, message) => {
            console.log('Got a message for me', sessionId, message)
        })
        socket2.on("connect_error", (err) => {
            if (err.message === "invalid username") {
                console.log('Connection error')
            }
        });
        return socket2
    }
}
const sendMessage = (topic, message) => {
    console.log(socket2)
    console.log(topic)
    //socket.io.to($user[0].userId).emit('jobstatus',$job)

    socket2.emit(topic, message)
    console.log('message sent to ', topic)

}

export default { join, socket2, io, sendMessage }
