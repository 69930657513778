<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title
          >{{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }}
          Activity Group
          <v-btn
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn
        ></v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Code
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Code"
              :rules="codeRule"
              v-model="formData.code"
              :disabled="showReadOnly"
              @keyup="checkCode()"
            />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>

          <v-col cols="8" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Name
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Name"
              :rules="nameRule"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
            >
              Module Group
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Module Group"
              v-model="formData.module_groups"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="4" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Module Order
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Module Order"
              v-model="formData.module_order"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="4" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Group Order
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Group Order"
              v-model="formData.group_order"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Module Icon
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Module Icon"
              v-model="formData.module_icon"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="4" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Group Icon
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Group Icon"
              v-model="formData.group_icon"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
  <script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import AutoComplete from "@/components/ui/AutoComplete.vue";
import ActivityGroupService from "@/api/service/ActivityGroup/ActivityGroupService";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    // AutoComplete
  },

  data() {
    return {
      codeError:false,
      showReadOnly: false,
      dialog: false,
      type: null,
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      formData: {
        code: null,
        name: null,
        module_groups: null,
        module_order: null,
        group_order: null,
        module_icon: null,
        group_icon: null,
        service_id: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    checkCode() {
      // this.formData.code = this.formData.code.toUpperCase();
      console.log(this.formData.code,"11111111111111")
      ActivityGroupService.findAll({ code: this.formData.code }).then((res) => {
        console.log(res,"222222222222")
        if (res.length > 0) {
          this.codeError = true;
        } else {
          this.codeError = false;
        }
      });
    },
    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await ActivityGroupService.findOne(id);
      console.log(res,"11111111111x")
      this.formData = res[0];
    },

    async save() {
      if (this.formData.name && this.formData.code){
        if (this.formData.id) {
          await ActivityGroupService.update(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          await ActivityGroupService.create(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        }
      }
    },
  },
};
</script>
  