<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form" v-model="value">
      <v-card-item>
        <v-card-title
          >{{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }}
          Sub
          <v-btn
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Code
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Code"
              :rules="codeRule"
              v-model="formData.code"
              :disabled="showReadOnly"
              @keyup="checkCode()"
            />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>

          <v-col cols="9">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Name
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Name"
              :rules="nameRule"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
  <script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import AutoComplete from "@/components/ui/AutoComplete.vue";
import SbusService from "../../../../api/service/Sbus/SbusService";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    // AutoComplete
  },

  data() {
    return {
      showReadOnly: false,
      dialog: false,
      type: null,
      // codeRule: [(value) => !!value || "Code is Required."],
      codeRule: [
        value => {
          if (value.length<=0){ 
          this.codeError = null
          return 'Code Is Required'
        }
          else if(this.codeError){
           this.codeError = true
          }
        },
      ],
      nameRule: [(value) => !!value || "Name is Required."],
      formData: {
        code: null,
        name: null,
      },
      codeError:false,
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    checkCode() {
      this.formData.code = this.formData.code.toUpperCase();
      SbusService.findAll({ code: this.formData.code }).then((res) => {
        if (res.length > 0) {
          this.codeError = true;
        } else {
          this.codeError = false;
        }
      });
    },
    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await SbusService.findOne(id);
      this.formData = res[0];
    },

    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid) {
        console.log(validation.valid)
      }
      else{
        if (this.formData.id) {
          await SbusService.update(this.formData);
          console.log(this.formData,"formdadaadadaadada");
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          
          this.formData = {};
        } else {
          await SbusService.create(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        }
    }
  },
  },
};
</script>
  