<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form" v-model="value">
      <v-card-item>
        <v-card-title
          >{{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }}
         <b>Account</b>
          <v-btn
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn
        ></v-card-title>
      </v-card-item>
      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-row no no-gutters="" class="pa-0">
              <v-col cols="6">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Code
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Code"
              :rules="codeRule"
              v-model="formData.code"
              :disabled="showReadOnly"
              @keyup="checkCode()"
              @keydown="prevent($event)"
            />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>

          <!-- Input Field -->

          <v-col cols="12">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Name
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Name"
              :rules="nameRule"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </v-col>
         

          <v-col cols="12" class="dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Country
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <country :item-value="'code'" :item-title="'name'" v-model="formData.country_id" :disabled="showReadOnly" :placeholder="'Country'"
            :rules="countryRule"/>
          </v-col>
          <v-col cols="12">
            <TextFieldComponent
              density="compact"
              placeholder="Email"
              v-model="email"
              @change="addEmail"
              :disabled="showReadOnly"
              append-inner-icon="mdi-plus-circle"
              @click:append="addEmail"
            />
            <br/>
            <v-chip label closable v-for="email in formData.emails" class="mx-2"
            @click:close="removeEmail(email)" v-bind:key="email">
              {{email}}
            </v-chip>
          </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <uploadImages @uploadDocument="uploadImage($event)" @removeFile="removeFile" :showReadOnly="showReadOnly" :imageId="formData.image_profile_id" :AccountId="'Account'"></uploadImages>
            <v-col cols="6" align="center">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Color
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <br>
            <input class=" colorPick" type="color" v-model="formData.theme" >
          </v-col>
          </v-col>
          
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" :loading='saveClicked' type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import uploadImages from "@/components/uploadImages.vue";
// import AutoComplete from "@/components/ui/AutoComplete.vue";
import AccountService from "@/api/service/Account/AccountService";
import country from "@/components/others/country.vue";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    uploadImages,
    country
  },

  data() {
    return {
      showReadOnly: false,
      saveClicked: false,
      dialog: false,
      type: null,
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      countryRule: [(value) => !!value || "Country is Required."],
      codeError: false,
      formData: {
        code: null,
        emails:[],
        name: null,
        // address: null,
        country_id: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    prevent(e) {
  if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
},
    checkCode() {
      if(this.formData.code && this.formData.code.length>0){
      this.formData.code = this.formData.code.toUpperCase();
      AccountService.findAll({ code: this.formData.code }).then((res) => {
        let $exist = res.filter(key => key.code == this.formData.code)
        this.codeExistFlag = $exist.length > 0 ? (this.formData.id && $exist[0].id == this.formData.id) ? false : true : false;
        if (this.codeExistFlag) {
          this.codeError = true
        }
        else {
           this.codeError = false
        }
      });
    }
    },
    addEmail() {
      if(this.email) {
        let $index = this.formData.emails.findIndex((obj)=>{return obj==this.email});
        if($index<0) {
          this.formData.emails.push(this.email)
        }
        this.email=''
      }
    },
    removeEmail($email) {
      let $index = this.formData.emails.findIndex((obj)=>{return obj==$email});
        if($index>=0) {
          this.formData.emails.splice($index,1)
        }
    },
    close() {
      this.$emit("closeDialog");
    },

    UpperCase($value) {
      this.formData.code = $value ? $value.toUpperCase() : null;
    },

    async getById(id) {
      let res = await AccountService.findOne(id);
      this.formData = res[0];
        this.formData.emails = this.formData.emails ? JSON.parse(this.formData.emails) : []
    },
    async uploadImage($event){
      this.formData.image_url=$event.url
      if(this.formData && this.formData.id){
         
      let obj={
        id:this.formData.id,
        image_url:$event.url
      }
         await AccountService.update(obj)
      }
    },
    removeFile(){
      this.formData.image_profile_id=null
    },
    
    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {
        console.log(validation.valid)
      }
      else{
        this.saveClicked = true
        let $formData = this.formData
        $formData.emails =  JSON.stringify(this.formData.emails)
      //  alert(this.formData.emails)
        if (this.formData.id) {
          await AccountService.update($formData);
          this.$store.dispatch('setSnackbar',{text:'Updated Successfully'})
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          try{
          await AccountService.create($formData);
          this.$store.dispatch('setSnackbar',{text:'Saved Successfully'})
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
          }catch(e){
            this.$store.dispatch('setSnackbar',{ color:'error',text:'Something Went Wrong'})
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.v-autocomplete .v-field:not(.v-field--focused) input {
    min-width: 0;
    font-family: sans-serif;
    font-size: 14px;
}
>>>.btn {
  position: relative;
  left: 96px;
  bottom: 96px;
}

</style>