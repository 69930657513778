<template>
  <div class="filterMain">
    <div v-for="item in filterCard" :key="item">
      <v-card flat class="filterCard">
        <v-card-text align="center">
          <v-img
            :src="require('@/assets/image/coolicon.png')"
            width="35px"
            class="mb-5"
          ></v-img>
          <span class="text-h6">{{ item.name }}</span>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      filterCard: [
        {
          name: "Clients",
          bgcolor: "#F8ECCE",
          src: require("../../assets/image/coolicon.png"),
        },
        {
          name: "Team",
          bgcolor: "#D7EAF4",
          src: require("@/assets/image/command.svg"),
        },
        {
          name: "Shifts",
          bgcolor: "#F9E3F5",
          src: require("@/assets/image/cupcake.svg"),
        },
        {
          name: "Resources",
          bgcolor: "#F8F6CE",
          src: require("@/assets/image/color.svg"),
        },
        {
          name: "Year",
          bgcolor: "#F9E3F5",
          src: require("@/assets/image/cupcake.svg"),
        },
        {
          name: "Tasks",
          bgcolor: "#F8F6CE",
          src: require("@/assets/image/color.svg"),
        },
      ],
    };
  },
};
</script>

<style>
.filterMain {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 50px;
  width: 610px;
}

.filterCard {
  width: 170px;
  height: 140px;
  display: flex;
  align-items: center;
  background-color: #ececec;
}
</style>