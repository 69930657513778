<template>
  <v-container fluid justify-center>
    <p class="text-h5">
      <b>Identity</b>
    </p>
    <v-row class="pa-0 ma-0" no-gutters dense>
      <v-col lg="2" md="2" sm="4" cols="12" class="pa-0 ma-0 pr-2 pt-3">
        <v-list class="settingsList" style="height: 84vh;">
          <div v-for="(value, key) in modules" :key="key">
            <v-list-item  @click="showModule(key)" :class="key == selectedModule ? 'activeSettingList' : ''">
              {{ getMenuName(key) }}
            </v-list-item>
          </div>
        </v-list>
      </v-col>
  
      <v-col lg="10" md="10" sm="10" cols="12" class="pl-2 pr-2 pt-3 white">
        <v-card flat style="height:84vh ; overflow: auto; overflow-x: hidden;">
          <RouterView></RouterView>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//import RolePermissionService from '@/api/service/RolePermission/RolePermissionService'
export default {
  props: [],
  components: {
  },
  data() {
    return {
      selectedModule: "",
      modules: {
        "role": true,
        "user": true,
        "rolePermission": true
      }
    }
  },
  async created() {
    /*this.modules = {
      "role": await RolePermissionService.isAllowed('role','manage'),
      "user":await RolePermissionService.isAllowed('user','manage'),
      "rolePermission": await RolePermissionService.isAllowed('permission','manage')
    }
    let $keys = Object.values(this.modules)
    if($keys.indexOf(true)<0) {
      this.$router.push('/forbidden')
    }*/
    let url = this.$route.path;
    let path = url.substring(url.lastIndexOf('/') + 1);
    path = path == 'userProfile' ? 'user' : path == 'CreateRolePermission' ? 'permissionspermissions' : path
    this.getMostRecent(path);
  },
  mounted() {

  },
  updated() {

  },
  watch: {

  },
  methods: {
    getMostRecent($path) {
      let $storage = JSON.parse(localStorage.getItem('modules'))
      if (!$storage) {
        $storage = { "modules": { "default": $path } }
      }
      this.selectedModule = $storage.modules.default

    },

    getMenuName($value) {
      let value = $value.split(/(?=[A-Z])/)
      let spliteValue = value.map(key => key.charAt(0).toUpperCase() + key.slice(1));
      return spliteValue.join(" ");
    },
    showModule($module) {
      this.selectedModule = $module
      let $keys = Object.keys(this.modules)
      $keys.forEach(module => {
        console.log(module, $module)
        if (module == $module) {
          this.modules[module] = true
          this.selectedModule = $module
          let route =$module=='rolePermission'?'permissions':$module
          this.$router.push(`/setting/identity/${route}`)
        } else {
          this.modules[module] = false
        }
      })
    },
    setMostRecent($module) {
      let $storage = JSON.parse(localStorage.getItem('modules'))
      if (!$storage) {
        $storage = { "modules": "" }
      }
      if (!$storage.modules) {
        $storage.modules.default = ''
      }
      $storage.modules.default = $module
      localStorage.setItem(JSON.stringify($storage))
    }
  }
}
</script>
<style >
.v-list .v-list-item.v-list-item--link.theme--light.activeSettingList,
.v-list.settingsList .v-list-item:hover {
  background-color: #1258AB !important;
  color: white !important;
  cursor: pointer;
  margin-right: 5px;
}

.activeSettingList {
  background-color: #1258AB !important;
  color: white !important;
  cursor: pointer;
  margin-right: 5px;
}

.settingsList {
  cursor: pointer;
  /* height:95vh; */

}

/* .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: #2b2f8e !important;
    } */
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  background-color: #1258AB !important;
  color: white !important;
  cursor: pointer;
  margin-right: 5px;
}</style>