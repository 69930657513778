<template>
  <v-container>
    <v-card>
      <v-card-title> Preferences </v-card-title>
      <v-card-text>
        <v-row no-gutters dense>
          <v-col cols="12" class="title pa-3">
            When I am a working on this issue
          </v-col>
          <v-col
            cols="6"
            v-for="(item, index) in preferences.assigned"
            :key="item"
          >
            <v-checkbox-btn
              class="checkbox"
              @change="issue(item, index)"
              v-model="item.value"
              color="primary"
              :label="item.label"
            ></v-checkbox-btn>
          </v-col>
          <v-col cols="7" class="pa-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Email
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              style="width: 78%"
              :items="selectedAssignedItems"
              item-value="id"
              item-title="label"
              density="compact"
              placeholder="Select Email"
              v-model="selectIssueEmail"
              @update:modelValue="issueChanged"
              :disabled="showReadOnly"
            />
            <v-chip
              class="selectedChip"
              closable
              label
              v-for="item in selectedAssignedChips"
              :key="item"
              :close="false"
              :disabled="showReadOnly"
              @click:close="removeIssueChips(item)"
            >
              {{ item.label }}
            </v-chip>
          </v-col>
          <v-col cols="12" class="title pa-3">
            When I am member of the workspace
          </v-col>
          <v-col cols="6"  v-for="(item, index) in preferences.workspace" :key="item">
            <v-checkbox-btn
              v-if="
                item.key != 'interestedCategories' && item.key != 'notPicked'
              "
              class="checkbox"
              @change="workspace(item, index)"
              v-model="item.value"
              color="primary"
              :label="item.label"
            ></v-checkbox-btn>
            <AutoComplete
              :items="interestedCategoryData"
              item-value="id"
              item-title="name"
              class="autocomplete"
              @update:modelValue="changeInterestedCategory($event)"
              v-model="interestedCategory"
              placeholder="Interested Cateogries"
              :disabled="showReadOnly"
              v-if="item.key == 'interestedCategories'"
            />
            
            <TextFieldComponent v-if="item.key == ''" />
          </v-col>
          <v-col class="ml-4" cols="12">
          
          <v-chip
              class="selectedChip"
              closable
              label
              v-for="item in selectedInterestedCategoryData"
              :key="item"
              :close="false"
              :disabled="showReadOnly"
              @click:close="removeInterestedCategory(item)"
            >
              {{ item.name }}
            </v-chip>
            </v-col>
          <v-col cols="7" class="pa-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Email
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              style="width: 78%"
              :items="selectedWorkspaceItems"
              item-value="id"
              item-title="label"
              density="compact"
              placeholder="Select Email"
              v-model="selectWorkspaceEmail"
              @update:modelValue="workspaceChanged"
              :disabled="showReadOnly"
            />
            <v-chip
              class="selectedChip"
              closable
              label
              v-for="item in selectedWorkspaceChips"
              :key="item"
              :close="false"
              :disabled="showReadOnly"
              @click:close="removeWorkspaceChips(item)"
            >
              {{ item.label }}
            </v-chip>
          </v-col>

          <v-col cols="12" class="title pa-3"> Training Programmes </v-col>
          <v-col cols="6" :key="item">
            <!-- {{ item.key }}
            <v-checkbox-btn
              v-if="item.key != 'interestedCategories'"
              class="checkbox"
              v-model="item.value"
              color="primary"
              :label="item.label"
              :value="item.value"
            ></v-checkbox-btn> -->
            <AutoComplete
              class="autocomplete"
              :items="trainingProgramData"
              item-value="id"
              item-title="name"
              @update:modelValue="changeTrainingProgram($event)"
              v-model="trainingProgram"
              placeholder="Training Programmes"
              :disabled="showReadOnly"
            />
          </v-col>
          <v-col class="ml-4 mb-3" cols="12">

            <v-chip
              class="selectedChip"
              closable
              label
              v-for="item in selectedTrainingCategoryData"
              :key="item"
              :close="false"
              :disabled="showReadOnly"
              @click:close="removeTP(item)"
            >
              {{ item.name }}
            </v-chip>
          </v-col>
          <v-col cols="12" class="title pa-3"> Networking </v-col>
          <v-col cols="6" v-for="item in preferences.networking" :key="item">
            <!-- <v-checkbox-btn
              v-if="item.key !== 'users'"
              class="checkbox"
              v-model="item.value"
              color="primary"
              :label="item.label"
              :value="item.value"
            ></v-checkbox-btn> -->
            <AutoComplete
            style="width: 95%"
              class="pa-5"
              :items="userData"
              item-value="id"
              item-title="name"
              placeholder="Users"
              @update:modelValue="addUserToNetwork($event)"
              v-model="userId"
              :disabled="showReadOnly"
            />
          </v-col>
          
          <v-col class="ml-4" cols="12">
                <v-chip
                  class="selectedChip"
                  closable
                  label
                  v-for="item in selectedUserData"
                  :key="item"
                  :close="false"
                  :disabled="showReadOnly"
                  @click:close="removeUser(item)"
                >
                  {{ item.name }}
                </v-chip>
                </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="dialogBottom">
        <v-spacer></v-spacer>
        <v-btn class="mr-2 cancel"  @click="$router.go(-1)">Cancel</v-btn>
        <v-btn class="save" type="submit" @click="save()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import commonService from "@/common.service";
import PreferenceService from "@/api/service/Preference/PreferenceService";
import CategoryService from "@/api/service/Category/CategoryService";
import UserService from "@/api/service/UserService";

export default {
  components: {
    AutoComplete,
    TextFieldComponent,
    // personal
  },
  data() {
    return {
      backupselectedAssignedItems: [],
      selectedAssignedItemsId: [],
      selectedAssignedChips: [],
      selectedEmails: [],
      selectedAssignedItems: [],
      selectedIssues: [],
      selectIssueEmail: null,
      selectWorkspaceEmail:null,
      selectedWorkspaceItems: [],
      backupselectedWorkspaceItems: [],
      selectedWorkspaceItemsId: [],
      selectedWorkspaceChips: [],
      categoryData:[],
      interestedCategory:null,
      interestedCategoryId:[],
      interestedCategoryData:[],
      trainingProgram:null,
      selectedInterestedCategoryData:[],
      selectedInterestedCategoryId:[],
      trainingProgramId:[],
      trainingProgramData:[],
      selectedTrainingCategoryData:[],
      selectedTrainingCategoryId:[],
      userData:[],
      selectedUserId:[],
      userId:null,
      selectedUserData:[],
      backupUserData:[],
      userDetails: JSON.parse(localStorage.getItem("user_details")),
      showReadOnly:false,
      preferencesId:'',
      preferences: {
        assigned: [
          {
            id: 1,
            label: "Someone mentions about me ",
            key: "mentioning",
            value: false,
            email: false,
          },
          {
            id: 2,
            label: "New Attachments added",
            key: "attachments",
            value: false,
            email: false,
          },
          {
            id: 3,
            label: "Description changed",
            key: "descriptionChanged",
            value: false,
            email: false,
          },
          {
            id: 4,
            label: "Someone added notes",
            key: "notesAdded",
            value: false,
            email: false,
          },
          {
            id: 5,
            label: "Someone starts following your issue",
            key: "newFollowers",
            value: false,
            email: false,
          },
          {
            id: 6,
            label: "Issue is assigned to me ",
            key: "assignedToMe",
            value: false,
            email: false,
          },
          {
            id: 7,
            label: "Someone views the issue",
            key: "newViews",
            value: false,
            email: false,
          },
          {
            id: 8,
            label: "Estimate updates",
            key: "estimates",
            value: false,
            email: false,
          },
          {
            id: 9,
            label: "Issue is escalated",
            key: "escalation",
            value: false,
            email: false,
          },
        ],
        workspace: [
          {  
            id:1,
            label: "Someone mentions about me ",
            key: "mentioning",
            value: false,
            email:false
          },
          { id:2, label: "New Attachments added", key: "attachments", value: false,email:false },
          {
            id:3,
            label: "Description changed",
            key: "descriptionChanged",
            value: false,
            email:false
          },
          { id:4,label: "Someone added notes", key: "notesAdded", value:false,email:false },
          {
            id:5,
            label: "Someone starts following your issue",
            key: "newFollowers",
            value: false,
            email:false,
          },
          {id:6,
            label: "Issue is assigned to me ",
            key: "assignedToMe",
            value: false,
            email:false
          },
          { id:7,label: "Someone views the issue", key: "newViews", value: false,email:false },
          { id:8,label: "Estimate updates", key: "estimates", value: false,email:false },
          { id:9,label: "Issue is escalated", key: "escalation", value: false,email:false },
          { id:10,
            label: "New Account is added to your workspace",
            key: "newAccount",
            value: false,
            email:false
          },
          { id:11,
            label: "Account removed from your workspace",
            key: "removeAccount",
            value: false,
            email:false
          },
          { id:12,
            label: "Updates related to the project",
            key: "projectUpdates",
            value: false,
            email:false
          },
          { id:13,
            label: "Interested Categories",
            key: "interestedCategories",
            value: [],
            email:false
          },
          { id:14,label: "Not Picked", key: "notPicked", value:false,email:false },
        ],
        training: {
          interestedCategories:[]
        },
        networking: {
          users:[]
        },
      },
    };
  },
  async created(){
    await this.getCategory();
    await this.getUser();
    if(this.userDetails.id){
    this.getById(this.userDetails.id)
    }
  },
  methods: {
   async getById(id){
    let res = await PreferenceService.findAll({key_id:id});
      if(res && res.length>0){
      this.preferencesId=res[0].id
      this.preferences = JSON.parse(res[0].preferences)
      this.preferences.assigned.map(e=>{
      if(e.email==true){
        this.selectedAssignedItemsId.push(e.id)
      }
      })
      this.preferences.workspace.map(e=>{
        if(e.email==true){
          this.selectedWorkspaceItemsId.push(e.id)
        }
      })
      this.selectedUserId=this.preferences && this.preferences.networking && this.preferences.networking.users?this.preferences.networking.users:[]
      this.trainingProgramId=this.preferences && this.preferences.training && this.preferences.training.interestedCategories?this.preferences.training.interestedCategories:[]
      this.interestedCategoryId=this.preferences && this.preferences.workspace[12] && this.preferences.workspace[12].value?this.preferences.workspace[12].value:[]
      this.issue({});
      this.workspace({});
      this.getChipData("TP")
      this.getChipData("USER")
      this.getChipData("IC")
    }
      

    },
    async changeInterestedCategory($event){
      if($event){
      this.interestedCategoryId.push($event)
      this.getChipData("IC");
      let $index = this.selectedWorkspaceItems.findIndex(k=>k.key=='interestedCategories');
      if(this.interestedCategoryId.length>0 && $index==-1){
         this.selectedWorkspaceItems.unshift(this.preferences.workspace[12])
      }
      }

    },
    addUserToNetwork($event){
      if($event){
      this.selectedUserId.push($event)
      this.getChipData("USER");
      }

    },
    async changeTrainingProgram($event){
      if($event){
      this.trainingProgramId.push($event)
      this.getChipData("TP");
      }

    },
    async getCategory(){
      try{
      let res = await CategoryService.findAll()
      this.categoryData = res.length > 0 ? res : [];
      this.backupCategoryData =JSON.parse(JSON.stringify(this.categoryData))
      this.backupCategoryData1 =JSON.parse(JSON.stringify(this.categoryData))
      this.interestedCategoryData =JSON.parse(JSON.stringify(this.categoryData))
      this.trainingProgramData =JSON.parse(JSON.stringify(this.categoryData))
      }
      catch(e){
        console.log(e)
      }
    },
    async getUser(){
      try{
      let res = await UserService.findAll({active:true})
      this.userData = res.length > 0 ? res : [];
      this.userData = this.userData.filter((user) => user.active === 1);
      this.backupUserData =JSON.parse(JSON.stringify(this.userData))
      await commonService.getSort(this.userData, "name", "asc");
      }
      catch(e){
        console.log(e)
      }
    },
    async issue($item) {
      this.selectedAssignedItems = await this.getTrueValueData(
        this.preferences.assigned
      );
      this.backupselectedAssignedItems = JSON.parse(
        JSON.stringify(this.selectedAssignedItems)
      );
      let index = this.selectedAssignedItemsId.findIndex((k) => k == $item.id);
      if (index > -1) {
        this.selectedAssignedItemsId.splice(index, 1);
      }
      this.selectedAssignedItemsId = this.getValidData(
        this.selectedAssignedItems,
        this.selectedAssignedItemsId
      );
      this.getChipData("assign");
    },
    getValidData($data, idData) {
      let data = idData.filter((k) => {
        if ($data.some((e) => e.id == k)) {
          return k;
        }
      });
      return data;
    },
    async getTrueValueData($value) {
      let $data = await $value.filter((k) => k.value==true || k.value>0);
      return $data;
    },
    issueChanged() {
      this.selectedAssignedItemsId.push(this.selectIssueEmail);
      this.getChipData("assign");
    },
    workspaceChanged() {
      this.selectedWorkspaceItemsId.push(this.selectWorkspaceEmail);
      this.getChipData("workspace");
    },
    async getChipData(type) {
      if (type == "assign") {
        this.selectedAssignedChips = await commonService.getMultipleChip(
          this.preferences.assigned,
          this.selectedAssignedItemsId,
          "id"
        );
        this.selectedAssignedItems = await commonService.getObjectSliceMethod(
          JSON.stringify(this.backupselectedAssignedItems),
          this.selectedAssignedItemsId,
          "id"
        );
        this.selectedAssignedChips = [...this.selectedAssignedChips];
        this.selectedAssignedItems = [...this.selectedAssignedItems];
        this.selectIssueEmail = null;
      }
      if (type == "workspace"){
        this.selectedWorkspaceChips = await commonService.getMultipleChip(
          this.preferences.workspace,
          this.selectedWorkspaceItemsId,
          "id"
        );
        this.selectedWorkspaceItems = await commonService.getObjectSliceMethod(
          JSON.stringify(this.backupSelectedWorkspaceItems),
          this.selectedWorkspaceItemsId,
          "id"
        );
        this.selectedWorkspaceChips = [...this.selectedWorkspaceChips];
        this.selectedWorkspaceItems = [...this.selectedWorkspaceItems];
        this.selectWorkspaceEmail = null;
      }
      if (type == "TP"|| type == "ALL"){
      this.selectedTrainingCategoryData =await commonService.getMultipleChip(this.backupCategoryData,this.trainingProgramId,'id')
      this.trainingProgramData =await  commonService.getObjectSliceMethod(
        JSON.stringify(this.backupCategoryData),this.trainingProgramId,'id')
        this.selectedTrainingCategoryData = [...this.selectedTrainingCategoryData];
        this.trainingProgramData = [...this.trainingProgramData];
        this.trainingProgram = null;
      }
      if (type == "IC"|| type == "ALL"){
      this.selectedInterestedCategoryData =await commonService.getMultipleChip(this.backupCategoryData1,this.interestedCategoryId,'id')
      this.interestedCategoryData =await  commonService.getObjectSliceMethod(
        JSON.stringify(this.backupCategoryData1),this.interestedCategoryId,'id')
        this.interestedCategory =null
      }
      if (type == "USER"|| type == "ALL"){
      this.selectedUserData =await commonService.getMultipleChip(this.backupUserData,this.selectedUserId,'id')
      this.userData =await  commonService.getObjectSliceMethod(
        JSON.stringify(this.backupUserData),this.selectedUserId,'id')
        this.userId =null
      }
    },
    async removeIssueChips($item) {
      let index = this.selectedAssignedItemsId.findIndex((k) => k == $item.id);
      if (index > -1) {
        this.selectedAssignedItemsId.splice(index, 1);
      }
      this.getChipData('assign')
    },
    setEmailValue($data, idData) {
      let data = $data.filter((k) => {
        if (idData.some((e) => e == k.id)) {
          k.email = true;
          return k;
        } else {
          if(!k.value || (Array.isArray(k.value) && k.value.length==0)){
            k.email = false;
          }
          return k;
        }
      });
      return data;
    },
    async workspace(){
      this.selectedWorkspaceItems = await this.getTrueValueData(
        this.preferences.workspace
      );
      this.backupSelectedWorkspaceItems = JSON.parse(
        JSON.stringify(this.selectedWorkspaceItems)
      );
      this.selectedWorkspaceItemsId = this.getValidData(
        this.selectedWorkspaceItems,
        this.selectedWorkspaceItemsId
      );
      this.getChipData("workspace");
    },
    removeWorkspaceChips($item) {
      let index = this.selectedWorkspaceItemsId.findIndex((k) => k == $item.id);
      if (index > -1) {
        this.selectedWorkspaceItemsId.splice(index, 1);
        let $index2 = this.backupSelectedWorkspaceItems.findIndex(k=>k.id==13)
        if($item.id==13 && $index2==-1){
          let $obj = Object.assign({},$item)
          this.backupSelectedWorkspaceItems.push($obj)
        }
      }
      
      this.getChipData("workspace");
    },
    
    async removeInterestedCategory($item) {
      let index = await this.interestedCategoryId.findIndex((k) => k == $item.id);
      if (index > -1) {
        this.interestedCategoryId.splice(index, 1);
      }
      this.getChipData("IC");
      if(this.interestedCategoryId.length==0){
        // this.selectedWorkspaceItems.shift()
        let $obj = {};
        $obj.id = 13;
        this.removeWorkspaceChips($obj)
      }
      
      
    },
    
    removeTP($item) {
      let index = this.selectedTrainingCategoryId.findIndex((k) => k == $item.id);
      if (index > -1) {
        this.selectedTrainingCategoryId.splice(index, 1);
      }
      this.getChipData("TP");
    },
    removeUser($item) {
      let index = this.selectedUserId.findIndex((k) => k == $item.id);
      if (index > -1) {
        this.selectedUserId.splice(index, 1);
      }
      this.getChipData("USER");
    },
    async save() {
      this.preferences.assigned = this.setEmailValue(
        this.preferences.assigned,
        this.selectedAssignedItemsId
      );
      this.preferences.workspace=this.setEmailValue(
        this.preferences.workspace,
        this.selectedWorkspaceItemsId
      )
      this.preferences.workspace[12].value =this.interestedCategoryId
      this.preferences.training.interestedCategories =this.trainingProgramId
      this.preferences.networking.users =this.selectedUserId
      if(this.preferencesId){
          let $obj={};
          $obj.id=this.preferencesId
          $obj.preferences=JSON.stringify(this.preferences)
          await PreferenceService.update($obj)
          this.$store.dispatch('setSnackbar',{text:'Updated Successfully'})
      }else {
      try{
        let $obj = {};
      $obj.key_type='user'
      $obj.key_id=this.userDetails.id
      $obj.preferences = JSON.stringify(this.preferences)
      let $data = await PreferenceService.create($obj);
      console.log($data,'dataaaaaa')
      this.preferencesId = $data.data.data.id
      this.$store.dispatch('setSnackbar',{text:'Saved Successfully'})
      }catch(e){
        console.log(e)
        this.$store.dispatch('setSnackbar',{ color:'error',text:'Something Went Wrong'})
      } 
      
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialogBottom {
  float: right;
  text-align: end;
  /* position: fixed;*/
  bottom: 12px;
  z-index: 100;
  width: 100%;
  right: 12px;
  padding: 10px;
  white-space: break-spaces;
  border-radius: 0px 0px 0px 10px;
  background: none !important;
}

.v-input--density-default {
  --v-input-control-height: 17px;
  --v-input-padding-top: 10px;
}

.autocomplete {
  width: 95%;
  padding: 20px;
}

.title {
  background-color: #e4f5fb;
  font-weight: 600;
}
</style>
