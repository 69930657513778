<template>
  <div class="container pl-5 pr-3">
    <div class="py-7 text-h5 font-weight-bold">Consolidated lead dashboard</div>

    <div class="contents">
      <div style="width: 60%">
        <TableWithTools
          :reportData="mainData"
          @Report-Data="handleWorkspaceClick"
          @filter-Data="handleFilterData"
        />
      </div>
      <div style="width: 35%">
        <ReportDoyouknow
          :workspaceId="workspaceId"
          :accountId="accountId"
          :mainFilterData="filterData"
          @Report-Data="handleWorkspaceClick"
        />
      </div>
      <!-- <div style="width: 29%">
        <ReportWorkinprogress />
      </div> -->
    </div>
  </div>
</template>

<script>
import reportData from "./reportData.json";
import TableWithTools from "./TableWithTools.vue";
import ReportDoyouknow from "./ReportDoyouknow.vue";
//import ReportWorkinprogress from "./ReportWorkinprogress.vue";
export default {
  components: {
    TableWithTools,
    ReportDoyouknow,
    // ReportWorkinprogress,
  },
  data() {
    return {
      mainData: reportData,
      workspaceId: null,
      accountId: null,
      filterData: null,
    };
  },
  methods: {
    handleWorkspaceClick(payload) {
      const workspaceId = payload.workspaceId;
      const accountId = payload.accountId;
      this.workspaceId = workspaceId;
      this.accountId = accountId;
      console.log(workspaceId, accountId, "kishore");
    },
    handleFilterData(payload) {
      console.log(payload, "kishorepay");
      const filterData = payload.filterData;
      this.filterData = filterData;
    },
  },
};
</script>

<style>
.contents {
  display: flex;
  gap: 1.5%;
}
.details {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
}

.cardTitle {
  color: #272727;
  font-size: 18px;
  font-weight: 700;
}

.reff {
  display: flex;
  gap: 3px;
  color: #000;
  font-size: 11px;
  font-weight: 300;
}

.dateRow {
  display: flex;
  align-items: end;
  flex-direction: column;
  justify-content: center;
}

.dateAndP {
  display: flex;
  font-size: 10px;
  font-weight: 700;
  text-align: right;
}

.issueName {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  padding-bottom: 2%;
}

.priority {
  font-size: 9px;
}

.chip {
  text-transform: capitalize;
  border: 1px solid #f9c644;
  background: #ffe8ae;
  color: #000;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 4px;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
}

.assignToPart {
  overflow: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

/* >>>::-webkit-scrollbar-track {
  background: #e8f3f9;
} */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgb(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

>>> .p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 18px;
}
</style>
