<template>
  <PrimeDataTable
     activity="ERRORCODE"
    :title="'ErrorCode'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
   
  />

  <v-dialog persistent v-model="dialog" width="700px">
    <CreateErrorCode
      @closeDialog="close()"
      @reload="getErrorCode()"
      :id="editId"
      :viewType="viewType"
      
    />
  </v-dialog>
</template>

<script>
import CreateErrorCode from "./CreateErrorCode.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import ErrorCodeService from "@/api/service/ErrorCode/ErrorCodeService";
// import commonService from "@/common.service";
export default {
  components: {
      CreateErrorCode,
    PrimeDataTable,
  },
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      editId:null,
      dialog: false,
      viewType:false
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getErrorCode();
  },
  methods: {

    loadColumns() {
      this.columns = [
      
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "name",
          sortfield: true,
        },
        {
          field: "error_group",
          header: "Error Group",
          style: { width: "100px", color: "" },
          toolTip: "error_group",
          sortfield: true,
        },
        {
          field: "impact",
          header: "Impact",
          style: { width: "100px", color: "" },
          toolTip: "impact",
          sortfield: true,
        },
        {
          field: "score",
          header: "Score",
          style: { width: "100px", color: "" },
          toolTip: "score",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" }, 
            { action: "edit" },
            // { action: "delete" },
          ],
        },
      ];
    },

    close() {
      this.dialog = false;
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType=$event.type;
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType=$event.type;
      }   

      if ($event.type == "select") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType="view";
      }
    },
    async getErrorCode() {
      let res = await ErrorCodeService.findAll();
      this.data = res;
    },
    addDiolog() {
      this.dialog = true;
      this.editId = null;
    }
  },
};
</script>


