<template>
  <PrimeDataTable
    :title="'Event'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="Event"

  />

  <v-dialog persistent v-model="dialog" width="700px">
    <CreateEvent
      @closeDialog="close()"
      @reload="getEvent()"
      :editID="editId"
      :viewType="viewType"
    />
  </v-dialog>
</template>
  
  <script>
import CreateEvent from "./CreateEvent.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import EventService from "@/api/service/Event/EventService";
// import commonService from "@/common.service";
export default {
  components: {
    CreateEvent,
    PrimeDataTable,
  },
  data() {
    return {
      selectedWorkSpaceId:null,
      data: [],
      columns: [],
      date: new Date(),
      editId: null,
      dialog: false,
      viewType: false,
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getEvent();
  },
  methods: {
    loadColumns() {
      this.columns = [
       
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "name",
          sortfield: true,
        },
        {
          field: "activities.name",
          header: "Activity ",
          style: { width: "100px", color: "" },
          toolTip: "activity_id",
          sortfield: true,
        },

        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
          ],
        },
      ];
    },

    close($event) {
      if($event=='closeMember')
      {
        this.viewType=''
      }
      this.dialog = false;
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
       
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.editId = $event.data.id;
        console.log(this.editId,'edit')
        this.viewType = $event.type;
       
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = "view"
     
      }
      if($event.type=='show-members') {
        this.dialog= false
        this.selectedWorkSpaceId = $event.data.id
        this.viewType='show-members'
        
      }
    },
    async getEvent() {
      let res = await EventService.findAll();
      this.data = res;
      console.log(this.data, "dddddddd");
    },
    addDiolog() {
      this.dialog = true;
      this.editId = null;
      this.viewType = false;
    },
  },
};
</script>
  
  
  