<template>
      <v-menu v-bind="menu">
        <template v-slot:activator="{ props }">
          <v-btn color="#fff" variant="text" v-bind="props">
            <span>Project</span>
            <i class="pi pi-angle-down" v-if="!menu" style="color: #ffff"></i>
            <i class="pi pi-angle-up" v-else style="color: #ffff"></i>
          </v-btn>
        </template>
        <v-card min-width="300">
          <v-list>
            <v-list-item
                :class="item.active ? 'selected' : ''"
              v-for="item in projectData"
              :key="item"
              @click="selectProject(item)"
              class="menus"
            >
            <v-list-item-title 
            class="workspace-title"
          
            >{{ item.name }}</v-list-item-title>
              <v-text class="subtitle">{{ item.code }}</v-text>
              <template v-slot:append>
                <v-icon
                  v-if="item.active"
                  color="light-green"
                  large
                >mdi mdi-check</v-icon>
              </template>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-title>
                    View All
                </v-list-item-title>
            </v-list-item>  
          </v-list>
        </v-card>
      </v-menu>
</template>
<script>
import ProjectService from '@/api/service/Project/ProjectService';

export default {
    data() {
        return {
            projectData:[],
            fav:false
        }
    },

    watch:{
      getRefresh() {
        if (this.getRefresh) {
              this.getProjects()
        } else {
              this.getProjects()
        }
      },
    },


  computed:{    
    getRefresh() {
      return this.$store.getters.getMySpace;
    }
  },
    async created() {
        await this.getProjects()
    },
    methods:{
      selectProject(data) {
      this.show = false;
      localStorage.setItem("_PROJECT_ID_", data.id);
      this.activeWorkSpace("pj", data.id);
      // location.reload();
    },
    async activeWorkSpace(type, id) {
      if (type == "ws") {
        this.workSpaceData.map((k) => {
          if (k.id == parseInt(id)) {
            k.active = true;
          } else {
            k.active = false;
          }
        });
        this.workSpaceData = [...this.workSpaceData];
      }
      if (type == "pj") {
        this.projectData.map((k) => {
          this.$store.commit("PROJECT_ID", id);
          if (k.id == parseInt(id)) {
            k.active = true;
          } else {
            k.active = false;
          }
        });
        this.projectData = [...this.projectData];
      }
    },
    async getProjects() {
      try {
        let workSpaceId = localStorage.getItem("workSpaceId");
        let res = await ProjectService.getWorkSpaceProject({
          work_space_id: workSpaceId,
        });
        this.projectData = res.length > 0 ? res : [];
        let projectId = localStorage.getItem("_PROJECT_ID_");
        if (!projectId && this.projectData.length > 0) {
          localStorage.setItem("_PROJECT_ID_", this.projectData[0].id);
          this.activeWorkSpace("pj", this.projectData[0].id);
        } else {
          this.activeWorkSpace("pj", projectId);
        }
      } catch (e) {
        console.log(e)
        this.projectData = [];
      }
    }
  }
}
</script>
<style>
.subtitle {
  margin: 0px;
  /* color: ; */
  font-size: 12px;
  font-weight: 400;
  text-align: start;
  color: #636363;
  border-radius: 5px;
  padding: 7px 3px 7px 0px;
}
.workspace-title {
    font-weight:500;
}
.selected {
    background-color:#E1EDFC;
}
</style>