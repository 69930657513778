<template>
  <v-card flat class="pa-3" min-height="90vh">
    <v-card-title> Accounts </v-card-title>
    <v-card-text>
      <v-row no-gutters dense class="rowheader pa-2">
        <v-col cols="3" class="text-left"> Name </v-col>
        <v-col cols="1" class="text-center"> Total </v-col>
        <v-col cols="1" class="text-center"> Open </v-col>
        <v-col cols="1" class="text-center"> Past due </v-col>
        <v-col cols="1" class="text-center"> Critcal </v-col>
        <v-col cols="1" class="text-center"> CFI </v-col>
        <v-col cols="1" class="text-center"> In Progress </v-col>
        <v-col cols="1" class="text-center"> Week end Mails </v-col>

        <v-col cols="2" class="text-left"> Last created </v-col>
      </v-row>
      <div class="ifNoAccount" v-if="accounts.length === 0">
        <h3>No account available in selected Project</h3>
      </div>
      <!-- <div > -->
      <v-row dense v-else v-for="account in accounts" :key="account">
        <v-col cols="3" class="text-left">
          <span class="link" @click="getLink(account)">
            <b>{{ account.code }}</b> - {{ account.name }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToListPage(account, 'total')">
            {{ account.no_of_issues }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToListPage(account, 'open')">
            {{ account.open_issue }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToListPage(account, 'pastDue')">
            {{ account.past_due }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToListPage(account, 'critical')">
            {{ account.critcal_issues }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToListPage(account, 'cfi')">
            {{ account.cfi }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToListPage(account, 'inprogress')">
            {{ account.in_progress }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToListPage(account, 'weekEndEmail')">
            {{ account.week_end_emails }}
          </span>
        </v-col>
        <v-col cols="2" class="text-left date">
          {{
            account.last_updated ? moment(account.last_updated).format("MM/DD/YY") : ""
          }}

          {{ account.last_updated ? moment(account.last_updated).fromNow() : "" }}
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>
      <!-- </div> -->

      <!-- <router-link :to="getLink(account)" class="link">
                   
                </router-link> -->
      <!-- </v-col>
           
            <v-divider />
        </v-row> -->
      <!-- </v-col></v-row> -->
    </v-card-text>
  </v-card>
</template>
<script>
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import moment from "moment";
export default {
  components: {
    // PrimeDataTable
  },
  data() {
    return {
      projectId: null,
      workSpaceId: 1,
      workspace: {},
      accounts: [],
      columns: [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "id",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "name",
          sortfield: true,
        },
        {
          field: "Open",
          header: "Open Issues",
          style: { width: "100px", color: "" },
          toolTip: "Open",
          sortfield: true,
        },
        {
          field: "updated",
          header: "Last Updated",
          style: { width: "100px", color: "" },
          toolTip: "Last Updated",
          sortfield: true,
        },
      ],
    };
  },
  computed: {
    workSpaceChanged() {
      return this.$route.query.workSpaceId;
    },
  },
  watch: {
    workSpaceChanged() {
      let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
      $wsDefaults = JSON.parse($wsDefaults);
      this.workSpaceId = $wsDefaults.workspace.id;
      this.projectId =
        $wsDefaults && $wsDefaults.project && $wsDefaults.project.id
          ? $wsDefaults.project.id
          : null;
      this.getAccounts();
    },
  },
  created() {
    this.$store.commit("SET_REFRESH_BREADCRUMP", false);
    let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
    $wsDefaults = JSON.parse($wsDefaults);
    this.workSpaceId =
      $wsDefaults && $wsDefaults.workspace && $wsDefaults.workspace.id
        ? $wsDefaults.workspace.id
        : null;
    this.projectId =
      $wsDefaults && $wsDefaults.project && $wsDefaults.project.id
        ? $wsDefaults.project.id
        : null;
    delete $wsDefaults.account;
    // delete $wsDefaults.project
    delete $wsDefaults.category;
    delete $wsDefaults.issue;

    localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults));

    this.$store.commit("SET_REFRESH_BREADCRUMP", true);
    this.getAccounts();
  },
  mounted() {},
  methods: {
    moment,
    getLink($account) {
      let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
      $wsDefaults = JSON.parse($wsDefaults);

      $wsDefaults.account = { id: $account.id, name: $account.name };
      localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults));
      this.$store.commit("SET_REFRESH_BREADCRUMP", true);
      this.$router.push(`/issueConsolidated`);
      //return
    },

    goToListPage($account, type) {
      let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
      $wsDefaults = JSON.parse($wsDefaults);

      $wsDefaults.account = { id: $account.id, name: $account.name };
      localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults));
      this.$store.commit("SET_REFRESH_BREADCRUMP", true);
      this.$router.push(`/issueConsolidated?pastdue=true`);
      if (type == "open") {
        this.$router.push("/issueconsolidated?stage=open");
      } else if (type == "critical") {
        this.$router.push("/issueconsolidated?priority=critical");
      } else if (type == "pastDue") {
        this.$router.push("/issueconsolidated?pastdue=true");
      } else if (type == "total") {
        this.$router.push("/issueconsolidated");
      } else if (type == "cfi") {
        this.$router.push("/issueconsolidated?cfi=true");
      } else if (type == "inprogress") {
        this.$router.push("/issueconsolidated?stage=inprogress");
      } else {
        this.$router.push("/issueconsolidated?weekEndEmail=true");
      }
      //return
    },
    getAccounts() {
      let obj = {
        workSpaceId: this.workSpaceId,
      };
      if (this.projectId && this.projectId > 0) {
        obj.projectId = this.projectId;
      }
      WorkSpaceService.getAccounts(obj)
        .then(($projects) => {
          this.workspace = $projects.workspace;
          const totalIssues = $projects.accounts.reduce(
            (obj, { no_of_issues }) => obj + no_of_issues,
            0
          );
          const totalOpenIssues = $projects.accounts.reduce(
            (obj, { open_issue }) => obj + open_issue,
            0
          );
          const totalPastDue = $projects.accounts.reduce(
            (obj, { past_due }) => obj + past_due,
            0
          );
          const totalCritical = $projects.accounts.reduce(
            (obj, { critcal_issues }) => obj + critcal_issues,
            0
          );
          const totalCFI = $projects.accounts.reduce((obj, { cfi }) => obj + cfi, 0);
          const totalWeekendemail = $projects.accounts.reduce(
            (obj, { week_end_emails }) => obj + week_end_emails,
            0
          );
          const totalInprogress = $projects.accounts.reduce(
            (obj, { in_progress }) => obj + in_progress,
            0
          );
          console.log(totalIssues);
          let $account = {
            id: 0,
            code: "All",
            name: "All Accounts",
            no_of_issues: totalIssues,
            open_issue: totalOpenIssues,
            past_due: totalPastDue,
            critcal_issues: totalCritical,
            cfi: totalCFI,
            week_end_emails: totalWeekendemail,
            in_progress: totalInprogress,
          };
          this.accounts =
            $projects.accounts.length > 1
              ? [$account].concat($projects.accounts)
              : [...$projects.accounts];
           console.log(this.accounts , 'catchh')
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.rowheader {
  background-color: #d7eaf4;
  font-weight: 600;
}
.link {
  color: #000;
  text-decoration: none;
}
.link:hover {
  color: #000;
  text-decoration: underline;
  cursor: pointer;
}
.open-issues {
  background-color: #e2d6f8;
  color: #272727;
  font-weight: 10px;
  font-weight: 400;
}
.date {
  color: orange;
}
.ifNoAccount {
  display: grid;
  place-items: center;
  height: 100px;
}
</style>
