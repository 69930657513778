<template>
    <div class="px-0 mx-0" style="width: fit-content;">
        <v-avatar v-if="url" :size="aSize&& aSize.size?aSize.size:''">
            <img style="width: 150%;" :src="url" alt="John" />
        </v-avatar>

         <v-avatar v-else  :style="{'background-color': userData&& userData.theme?userData.theme:'#172b4d'}" :size="aSize&& aSize.size?aSize.size:''">
            <span :style="{'font-size':aSize && aSize.font?aSize.font:''}" style="color:white">{{ this.getName() }}</span>
        </v-avatar>

       
    </div>
</template>
<script>

import UserService from '@/api/service/User/UserService';
import DocumentService from '@/api/service/Document/DocumentService';
import commonService from "@/common.service";
export default {
    props: ['userId', 'size'],
    data() {
        return {
            userData: null,
            url: null,
            aSize: null
        }
    },
    watch: {
        userId() {
            if (this.userId) {
                this.url=null
                this.getUserData()
            }
        },
        size() {
            if (this.size) {
                this.getSize()
            }
        }
    },
    created() {
        this.url=null
        this.getUserData()
        if (this.size) {
            this.getSize()
        }

    },
    methods: {
        async getUserData() {
            try {
                let res = await UserService.findOne({ id: this.userId })
                this.userData = res.length > 0 ? res[0] : {}
                if (this.userData && this.userData.image_profile_id) {
                    this.getImage()
                }
            }
            catch (e) {
                this.userData = {}
            }

        },
        getName() {
            if (this.userData) {
                let fName = this.userData && this.userData.first_name ? this.userData.first_name[0] : ""
                let lName = this.userData && this.userData.last_name ? this.userData.last_name[0] : ""
                let name = fName + lName
                return name
            }
        },
        async getImage() {
            try{
            let res = await DocumentService.imageProfile({ id: this.userData.id })
            // console.log(res)
            this.url = res
            }
            catch(e){
                this.url =null
            }

        },
        getSize() {
            if(this.size =='xSmall'){
                this.aSize = commonService.xSmallAvatar()
            }
            if (this.size == 'small') {
                this.aSize = commonService.smallAvatar()
            }
            if(this.size =='medium'){
                this.aSize = commonService.mediumAvatar()
            }
            if(this.size =='large'){
                this.aSize = commonService.largeAvatar()
            }

        }

    }

}

</script>
<style></style>