<template>
     <v-card justify-center flat class="center pa-3 pt-3 " width='600px'>
        <div class="header">
          <img
            :src="require('@/assets/image/Search.svg')"
            width="120"
            height="120"
          />
        </div>
      <v-card-text class="py-0 my-0">
            <v-row no-gutters dense class="header py-0">
              <v-col class="py-0 my-0" cols="12">
                Found more than one active session on multiple devices
              </v-col>
              
              <v-col cols="12">
                <span style="font-weight: 500; font-size: 14px"
                  >Please logout from other devices to continue</span
                >
              </v-col>
              <v-col cols="12"> &nbsp;</v-col>
            </v-row>
            <div cols="12" class="py-0 my-0" v-for="(value, index) in activeSessions" :key="index">
                <v-row no-gutters dense>
                <v-col cols="1" class="px-0 mx-0" >
                <div class="Icon">
                    <v-icon>mdi-laptop</v-icon>
                </div>
                </v-col>
                <v-col cols="7" class="px-0 mx-0" >
                <div class="Icon">
                <div>
                    <span style="font-weight:600">{{ value.session_id }}</span> <br/>
                    <span style="font-weight:400;color:#636363"> {{ value.device_id }}</span>
                    <span style="font-weight:500;color:#636363"> {{ value.device_info }}</span>
                </div>
                </div>
                </v-col>
                <v-col cols="4" class="px-0 mx-0">
                <div>
                    <b>Started on </b><br/>
                    {{value && value.start_date ? moment(value.start_date ).format("DD-MM-YYYY hh:mm A"): '' }}
                </div>
                <div>
                <b> Ending on </b><br/>
                    {{value && value.proposed_end_date ? moment(value.proposed_end_date ).format("DD-MM-YYYY hh:mm A"): '' }}
                </div>
                </v-col>
                <v-col cols="12">&nbsp;</v-col>
            </v-row>
            <hr/>
            </div>
              
              <v-row no-gutters dense>
                <v-col cols="12"> &nbsp;</v-col>
                <v-col cols="12"> &nbsp;</v-col>

             
              <v-col cols="12" class="text-center">
                <button  @click ="logoutOtherDevices" class="logOut">Logout</button>
                <br/><br/>
                <span>Logout from other devices to proceed further</span>
              </v-col>

              <v-col cols="12" class="py-0 text-center">
                Redirecting you the Home Page <br>
                <v-progress-circular
                  class="mt-2"
                  :rotate="-90"
                  :size="50"
                  :width="15"
                  :model-value="value"
                  color="primary"
                >
                </v-progress-circular>
              </v-col>
              </v-row>
        </v-card-text>
      </v-card>
</template>
<script>
import moment from 'moment-timezone'
import socket2 from '@/Socket-Client2.js'

export default {
    props:["activeSessions"],
    data() {
        return {

        }
    },
    methods:{
        moment,
        logoutOtherDevices() {
            for(let session of this.activeSessions) {
                if(this.$store.getters.getDeviceId!=session.device_id) { 
                    socket2.sendMessage('_FORCE_LOGOUT_',{sessionId:session.session_id})
                } 
            }
            this.$emit('sessioncleared')
        }
    }
}</script>
<style scoped>
  .center {
    display:block;
    margin:auto auto;
    margin-top:40px;
  }
  .header {
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
  }
  .box {
    background-color: #f4f4f4;
    margin-left: 250px;
    margin-right: 250px;
    /* width: fit-content; */
    border-radius: 6px;
    height: fit-content;
    display: flex;
  }
  .logOut {
    width: 100px;
    height: 32px;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    background: #1258ab;
  }
  .Icon {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
  </style>