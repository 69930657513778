<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title
        >{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
        <b>Enums</b>
      <v-btn
        @click="setFalse()"
        elevation="0"
        density="comfortable"
        icon="mdi-close"
        class="x-closeIcon"
      ></v-btn
    >
  </v-card-title>
   </v-card-item>

    <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
        <!-- Input Field -->

        <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formdata.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

        <v-col cols="12">
           <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Domain
          <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
          <TextFieldComponent
            density="compact"
            placeholder="Domain"
            :rules="domainRule"
            @keyup="checkDomain()" 
            v-model="formdata.domain"
            :disabled="showReadOnly"
          />
        </v-col>

        <!-- Input Field -->

        <v-col cols="12">
           <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Display Name
          <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
          <TextFieldComponent
            density="compact"
            placeholder="Display Name"
            :rules="displayNameRule"
            @keyup="checkDisplayValue()"
            v-model="formdata.display_value"
            :disabled="showReadOnly"
          />
        </v-col>

        <v-col cols="12"> 
           <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Data Value
          <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
          <TextFieldComponent
            density="compact"
            placeholder="Data Value"
            :rules="datavalueRule"
            v-model="formdata.data_value"
            :disabled="showReadOnly"
          />
        </v-col>
          </v-row>
      </v-card-text>
      <v-card-action class="mr-3">

        <!-- Cancle And Save Button -->

        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import EnumService from "@/api/service/Enum/EnumService";
export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
  },

  data() {
    return {
      showReadOnly: false,
      enumData: [],

      domainRule: [(value) => !!value || "Domain is Required."],
      displayNameRule: [(value) => !!value || "Display Name is Required."],
      datavalueRule: [(value) => !!value || "Data Value is Required."],
      dialog: false,
      type: null,
      formdata: {},
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formdata = {};
        this.getById(this.id);
      }
    },
  },

  created() {
    this.getEnumData();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },

  methods: {
    checkDisplayValue(){
      this.formdata.data_value = this.formdata.display_value?this.formdata.display_value.toLowerCase().split(' ').filter(Boolean).join(''):'';
    },
    setFalse() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await EnumService.findOne(id);
      this.formdata = res[0];
    },
    async getEnumData() {
      let res = await EnumService.findAll();
      this.enumData = res.length > 0 ? res : [];
    },
    checkDomain(){
      this.formdata.domain= this.formdata.domain?this.formdata.domain.toUpperCase():''
    },

    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid) {
        console.log("Not valid")
      }
      else {
        if (this.formdata.id) {
          let output = await EnumService.update(this.formdata);
          console.log(output,'output')
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formdata = [];
         
        } else {
          let output = await EnumService.create(this.formdata);
          console.log(output, "gfdgg");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formdata = [];
        }
      }
    },
  },
};
</script>

<style>
</style>