<template>
    <div class="border-round border-1 surface-border p-4 surface-card">
               <div class="flex mb-3" style="display: flex;column-gap: 18px;">
                    <Skeleton width="300px" height="50px" class="mb-2"></Skeleton>
                    <Skeleton width="300px" height="50px" class="mb-2"></Skeleton>
                    <Skeleton width="300px" height="50px" class="mb-2"></Skeleton>
                    <Skeleton width="300px" height="50px" class="mb-2"></Skeleton>
                    <Skeleton width="300px" height="50px" class="mb-2"></Skeleton>
                </div>

            <div style="display: flex;column-gap: 8px;">
                <Skeleton width="5rem" height="20px" class="mb-2"></Skeleton><Skeleton width="1rem" class="mb-2">
                </Skeleton>
            </div>

                <div style="display: flex;column-gap: 18px;">
                    <div 
                    style="border-radius:5px;width: 300px; min-height: 50vh;
                    padding: 5px; background-color: rgb(241, 241, 241);">

                    <Skeleton width="" height="100px" class="mb-2"></Skeleton>
                    <Skeleton width="" height="120px" class="mb-2"></Skeleton>
                    <Skeleton width="" height="100px" class="mb-2"></Skeleton>
                    </div>
                    
                    <div 
                    style="border-radius:5px;width: 300px; min-height: 50vh;
                    padding: 5px; background-color: rgb(241, 241, 241);">

                    <Skeleton width="" height="100px" class="mb-2"></Skeleton>
                    <Skeleton width="" height="120px" class="mb-2"></Skeleton>
                    <Skeleton width="" height="100px" class="mb-2"></Skeleton>
                    </div>

                    <div 
                    style="border-radius:5px;width: 300px; min-height: 50vh;
                    padding: 5px; background-color: rgb(241, 241, 241);">

                    <Skeleton width="" height="100px" class="mb-2"></Skeleton>
                    <Skeleton width="" height="120px" class="mb-2"></Skeleton>
                    <Skeleton width="" height="100px" class="mb-2"></Skeleton>
                    </div>

                    <div 
                    style="border-radius:5px;width: 300px; min-height: 50vh;
                    padding: 5px; background-color: rgb(241, 241, 241);">

                    <Skeleton width="" height="100px" class="mb-2"></Skeleton>
                    <Skeleton width="" height="120px" class="mb-2"></Skeleton>
                    <Skeleton width="" height="100px" class="mb-2"></Skeleton>
                    </div>

                    <div 
                    style="border-radius:5px;width: 300px; min-height: 50vh;
                    padding: 5px; background-color: rgb(241, 241, 241);">

                    <Skeleton width="" height="100px" class="mb-2"></Skeleton>
                    <Skeleton width="" height="120px" class="mb-2"></Skeleton>
                    <Skeleton width="" height="100px" class="mb-2"></Skeleton>
                    </div>
                </div>



            </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';
export default {
    components: {
        Skeleton,
    }

}
</script>

<style></style>