<template>
    <v-table height="65vh" style="line-height: 1;">
      <thead style="font-size: 14px;" >
        <tr  >
          <th class="text-left">
          </th>
          <th class="text-left">
            Date
          </th>
          <th class="text-left">
            Project
          </th>
          <th class="text-left">
            Task
          </th>
          <th class="text-left">
            Description
          </th>
          <th class="text-left">
            User
          </th>
          <th class="text-left">
            Verified
          </th>
          <th class="text-left">
            Total hours
          </th>
        </tr>
      </thead>
      <tbody >
        <tr
          v-for="item in desserts" :key="item.date"
        >
          <td><v-icon>mdi-plus</v-icon></td>
          <td>{{ item.date }}</td>
          <td>{{ item.project }}</td>
          <td>{{ item.task }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.user }}</td>
          <td>
            <v-icon color="primary">mdi-check</v-icon>
            <v-icon color="red">mdi-cancel</v-icon>
          </td>
          <!-- <td>{{ item.verified }}</td> -->
          <td>{{ item.hours }}</td>
        </tr>
      </tbody>
    </v-table>
  </template>
  <script>
  export default {
    data () {
      return {
        desserts: [
          {
            date: '02-12-2023',
            project: 'IAL',
            task: 'Special project',
            description: 'Investigation on Gross Margin differences',
            user: 'Raj',
            verified: 'verified',
            hours: 4.5,
          },
          {
            date: '02-12-2023',
            project: 'IAL',
            task: 'Special project',
            description: 'Investigation on Gross Margin differences',
            user: 'Raj',
            verified: 'verified',
            hours: 4.5,
          },
          {
            date: '02-12-2023',
            project: 'IAL',
            task: 'Special project',
            description: 'Investigation on Gross Margin differences',
            user: 'Raj',
            verified: 'verified',
            hours: 4.5,
          },
          {
            date: '02-12-2023',
            project: 'IAL',
            task: 'Special project',
            description: 'Investigation on Gross Margin differences',
            user: 'Raj',
            verified: 'verified',
            hours: 4.5,
          },
        ],
      }
    },
  }
</script>
<style scoped>
/* >>>.v-table {
  --v-table-header-height: 35px;
  border-radius: inherit;
  line-height: 2;
  max-width: 100%;
} */
.v-table .v-table__wrapper > table > thead > tr > th {
    border-bottom: none !important;
}
.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td, .v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > th {
    border-bottom:none !important;
}
</style>