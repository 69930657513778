import ApiService from '../../service/apiServiceIssue.js';
const querystring = require('querystring')
var IssueService = {

    getBackLogData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getBackLogData"
            $options.method = "get"

            if ($data) {
                $options.url = "getBackLogData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getNextAvailableUsers: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getNextAvailableUsers"
            $options.method = "get"

            if ($data) {
                $options.url = "getNextAvailableUsers?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    getIssueClosed: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getIssueClosed"
            $options.method = "get"

            if ($data) {
                $options.url = "getIssueClosed?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getIssueClosed2: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getIssueClosed2"
            $options.method = "get"

            if ($data) {
                $options.url = "getIssueClosed2?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getAllowOfQa: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getAllowOfQa"
            $options.method = "get"

            if ($data) {
                $options.url = "getAllowOfQa?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },




    getOpenIssueData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getOpenIssueData"
            $options.method = "get"

            if ($data) {
                $options.url = "getOpenIssueData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },


    findAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "issues"
            $options.method = "get"

            if ($data) {
                $options.url = "issues?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    findAllIssues: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "allIssues"
            $options.method = "get"

            if ($data) {
                $options.url = "allIssues?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getAssignToMeData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getAssignToMeData"
            $options.method = "get"

            if ($data) {
                $options.url = "getAssignToMeData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getProcessLeadData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getProcessLeadData"
            $options.method = "get"

            if ($data) {
                $options.url = "getProcessLeadData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getPresetFilters: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getPresetFilters"
            $options.method = "get"

            if ($data) {
                $options.url = "getPresetFilters?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getAllStages: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getAllStages"
            $options.method = "get"

            if ($data) {
                $options.url = "getAllStages?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },

    findIs: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "issuestages"
            $options.method = "get"

            if ($data) {
                $options.url = "issuestages?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    taskBasedIssue: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "issuesdata/taskBasedIssue"
            $options.method = "get"

            if ($data) {
                $options.url = "issuesdata/taskBasedIssue?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    accountBasedIssue: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "issuesdata/accountBasedIssue"
            $options.method = "get"

            if ($data) {
                $options.url = "issuesdata/accountBasedIssue?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getActivityList: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "issuesdata/activityList"
            $options.method = "get"

            if ($data) {
                $options.url = "issuesdata?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },


    getChildIssue: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getChildIssue"
            $options.method = "get"

            if ($data) {
                $options.url = "getChildIssue?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },


    getIssueById: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getIssueById"
            $options.method = "get"

            if ($data) {
                $options.url = "getIssueById?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },


    getIssueData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "issuesdata"
            $options.method = "get"

            if ($data) {
                $options.url = "issuesdata?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    getOneUserIssue: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getOneUserIssue"
            $options.method = "get"

            if ($data) {
                $options.url = "getOneUserIssue?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getRecentIssue: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "recentIssue"
            $options.method = "get"

            if ($data) {
                $options.url = "recentIssue?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },

    getSubTaskData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getSubTaskData"
            $options.method = "get"

            if ($data) {
                $options.url = "getSubTaskData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
  
    findOne: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "issues/" + $data
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    update: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "issues/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    
    bulkUpdateIssue: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "bulkUpdateIssue"
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    
    shareIssue: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "shareTheIssue"
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    updateFollowers: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "updateFollower";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "issues" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    updateChildIssue: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "updateChildIssue";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    create: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "issues";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    copyIssue: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "copyIssue";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    getBoardList: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getBoardList"
            $options.method = "get"

            if ($data) {
                $options.url = "getBoardList?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    getClarificationDetails: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getClarificationDetails"
            $options.method = "get"

            if ($data) {
                $options.url = "getClarificationDetails?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    
    getStageData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getStageData"
            $options.method = "get"

            if ($data) {
                $options.url = "getStageData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    }
};
export default IssueService;