const apiConfig = {
    "gatWayUrl": "https://identity.tiburaglobal.com:8080",

    // "baseUrl":" https://synergy.axo desk.com:3010",
   
 //       "baseUrl":"https://api-axodesk.tiburaglobal.com:8081",
   
"baseUrl":"https://api-axodesk.tiburaglobal.com:8081",
"baseUrlDocument":"https://api-axodesk.tiburaglobal.com:8081",
"baseUrlMaster":"https://api-axodesk.tiburaglobal.com:8081",
"baseUrlReport": "https://api-axodesk.tiburaglobal.com:8081",
"baseUrlMasterProject":"https://api-axodesk.tiburaglobal.com:8081",
"baseUrlOthers": "https://api-axodesk.tiburaglobal.com:8081",
"baseUrlIssue":"https://api-axodesk.tiburaglobal.com:8081",
"baseUrlMasterWs":"https://api-axodesk.tiburaglobal.com:8081",
"baseUrlBoard":"https://api-axodesk.tiburaglobal.com:8081",
"baseUrlIdentity": "https://api-axodesk.tiburaglobal.com:8081",
"baseUrlSecurity": "https://api-axodesk.tiburaglobal.com:8081",
       //"baseUrl":"http://localhost:3010",
   
       "appName": "Identti Front End",
   
       "apiKey": "apiKey",
   
       "secrect": "secrect"
}
export default apiConfig;
