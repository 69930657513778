
<template>
  <div class="pt-7 pl-4">
 <v-row class="mb-2" >
       <v-card-title  style="font-size: 25px;"><b>Projects</b></v-card-title>
 </v-row>
 <v-row class="mb-2">
   <v-col class="contentL" cols="3">
     <TextFieldComponent
       append-inner-icon="mdi-magnify"
       v-model="searchQuery"
       placeholder="Search"
       @input="searchTheData()"
     />
   </v-col>
  

   <v-col cols="3" >
       <AutoComplete
         :items="['Billable', 'Non Billable']"
         placeholder="All Projects"
       />
   </v-col>
 </v-row>
   
   <DataTable paginator showGridlines :rows="6" class="databale" :value="projects" dataKey="id" tableStyle=" background: inherit;" @row-click="atClick($event)">
     <Column   field="name" header="Name"></Column>
     <Column field="code" header="Code"></Column>
     <Column field="category" header="Category Name"></Column>
     <Column field="stage" header="Stage"></Column>
   </DataTable>

 </div>
</template>


<script>
import DataTable from "primevue/datatable";
import ProjectService from "@/api/service/Project/ProjectService";
import Column from "primevue/column";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import ColumnGroup from 'primevue/columngroup';   // optional
// import Row from 'primevue/row';
export default {
 components: {
   DataTable,
   Column,
   AutoComplete,
   TextFieldComponent,
   // ColumnGroup,
   // Row,
 },
 data() {
   return {
     // columns: [],
     projects:[],

   };
 },

 created() {
  
   this.getProjectData()
   // this.loadColumns();
 },
 methods: {
  searchTheData() {
    const searchQuery = this.searchQuery.toLowerCase().trim();

    if (searchQuery === '') {
      this.getProjectData();
    } else {
      this.projects = this.projects.filter(project => {
        const name = project.name ? project.name.toLowerCase() : '';
        const code = project.code ? project.code.toLowerCase() : '';
        const category = project.category ? project.category.toLowerCase() : '';
        const stage = project.stage ? project.stage.toLowerCase() : '';

        return (
          name.includes(searchQuery) ||
          code.includes(searchQuery) ||
          category.includes(searchQuery) ||
          stage.includes(searchQuery)
        );
      });
    }
  },

   async getProjectData() {
       let workSpaceId = localStorage.getItem('workSpaceId')
       let res = await ProjectService.getWorkSpaceProject({ work_space_id: workSpaceId });
       this.projects = res;
   },
   atClick(){
   this.$router.push({ path: "/board"});
},
  
 },
};
</script>

<style scoped>
>>>.p-datatable .p-datatable-tbody>tr>td {
   text-align: left;
   border: 0px solid #dee2e6 !important;
   border-width: 0 0 1px 0;
   padding: 1rem 1rem;
   padding-top: 20px;
   padding-bottom: 7px;
   font-size: 14px;
   cursor: pointer;
}
>>>.p-datatable .p-datatable-tbody>tr {
 background: none !important;
}
>>>.p-datatable .p-datatable-thead>tr>th {
   background: none !important;
   width: 15% !important;
   font-weight: 800;
   border: 0px;
   border-bottom: 2px solid #ECECEC ;
}
>>>.p-paginator {
   background:none;
   color: #6c757d;
   border: solid #e9ecef;
   border-width: 0;
   /* padding: 0.5rem 1rem;  */
   border-radius: 6px;
   width: 100% !important;
   position: absolute;
   right:0;
   /* left: 50%; */
}
/*   
   width: 15%;
   border-bottom : 1px solid inherit; */
</style>
