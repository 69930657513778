<template>
  <!-- <div>
    <AutoComplete v-model="selected" :items="enumData" item-title="display_value" item-value="data_value"/>
  </div> -->

  <PrimeDataTable
    :title="'Enums'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="enum"
  />
  <v-dialog persistent v-model="dialog" width="400px">
    <AddEnum
      ref="AddEnum"
      @reload="getEnumData()"
      @closeDialog="setFalse()"
      :id="enumId"
      :viewType="viewType"
    />
  </v-dialog>
</template>

<script>
// import AutoComplete from '../ui/AutoComplete.vue'
import EnumService from "@/api/service/Enum/EnumService";
import PrimeDataTable from "@/components/PrimeDataTable";
import AddEnum from "./AddEnum.vue";
import commonService from "@/common.service";
export default {
  components: {
    // AutoComplete,
    PrimeDataTable,
    AddEnum,
  },
  props: ["domain"],
  data() {
    return {
      data: [],
      columns: [],
      enumData: [],
      date: new Date(),
      enumId: "",
      dialog: false,
      viewType: false,
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getEnumData();
  },
  methods: {
    loadColumns() {
      this.columns = [
        {
          field: "domain",
          header: "Domain",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
        },
        {
          field: "display_value",
          header: "Display Name",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },

         {
          field: "data_value",
          header: "Data Values",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },

        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
          ],
        },
      ];
    },

    setFalse() {
      this.dialog = false;
    },

    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.enumId = $event.data.id;
        this.viewType = $event.type;
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.enumId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.enumId = $event.data.id;
        this.viewType = "view";
      }
    },

    async getEnumData() {
      let res = await EnumService.findAll();
      this.data = res;
      console.log(this.data, "dataaaaaaaaa");
    },
    // async  getEnumData(){
    //   let res =await EnumService.findAll({'domain':this.domain.enum})
    //   this.enumData=res
    //   console.log(this.enumData,'lllll')
    addDiolog() {
      this.dialog = true;
      this.enumId = null;
      this.viewType = false;
    },
  },

  addFilter() {
    this.$refs.FilterDrawer.dialog = true;
    this.$refs.FilterDrawer.size = commonService.largeDialog();
  },
};
</script>

<style>
</style>