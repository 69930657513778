<template>
    <v-card>
        <v-form @submit.prevent="save" ref="form">
            <v-card-item>
                <v-card-title>
                    <b> Edit Description</b>
                    <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close"
                        class="x-closeIcon"></v-btn>
                </v-card-title>
            </v-card-item>

            <v-card-text>
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Description</v-label>
                <TextArea class="pb-4" editorStyle="height: 200px" v-model="formData.description" :value="IssueDescription">
                </TextArea>
            </v-card-text>

            <v-card-action class="mr-3">
                <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
                    <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
                    <v-btn class="save" type="submit">Save</v-btn>
                </v-row>
            </v-card-action>
        </v-form></v-card>
</template>

<script>
import TextArea from "@/components/ui/TextArea.vue";
import IssueService from "@/api/service/Issue/IssueService";
export default {
    props: ["id"],
    components: {
        TextArea,
    },
    data() {
        return {
            dialog: false,
            formData: {
                description: ''
            },
        }
    },
    created() {
        if (this.id) {
            this.getById(this.id);
        }
    },
    methods: {
        close() {
            this.$emit("closeDialog");
        },
        async getById(id) {
            let res = await IssueService.findOne(id);
            this.formData.description = res[0].description;
            this.formData.id = res[0].id;
        },
        async save() {
                await IssueService.update(this.formData);
                this.$store.dispatch('setSnackbar', { text: 'Updated Successfully' })
                this.dialog = false;
                this.close();
                this.$emit("reload", this.formData.description);
                this.formData = {};
            }
        }
    }
</script>

<style></style>