<template>
  <!-- <v-row>
    <v-col cols='6'><h2 class='pa-2'>Issue Type</h2></v-col>
    <v-col cols='1'></v-col>
    <v-col cols='4' ><div class='pa-1'><TextFieldComponent append-inner-icon="mdi-magnify" placeholder="Search" /></div></v-col>
    <v-col cols='1' ><div class='pa-2'><v-btn elevation="0" width="22" height="22" size="small"   @click="ok()" density="compact" color="indigo-darken-1" icon="mdi-plus" ></v-btn></div></v-col>
  </v-row> -->

  <PrimeTable
    :title="'Task Type'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="false"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)" 
    activity="issuetype"

  />
  <v-dialog v-model="dialog" width="700px" persistent>
 <AddIssueType ref="AddIsuueType" :id="issueTypeId" :viewType="viewType" @closeDialog="cancel()" @reload="getIssueType()"/>
</v-dialog>
</template>

<script>
import PrimeTable from "@/components/PrimeDataTable.vue";
// import TextFieldComponent from '@/components/TextFieldComponent.vue'

import AddIssueType from "./AddIssueType.vue";
// import IssueTypeService from "@/api/service/Issue/IssueTypeService";
import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
// import commonService from "@/common.service";
export default {
  components: {
    PrimeTable,
    // TextFieldComponent,
    AddIssueType,
  },
  data() {
    return {
      date: new Date(),
      data:[],
      issueTypeId:'',
      dialog:false,
      viewType: false,
      
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getIssueType();
  },
  methods: {
    // ok() {
    //   this.dialog = true;
    //   // this.$refs.AddIsuueType.dialog = true;
    // },
    loadColumns() {
      this.columns = [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [{action:"view"},{action:"edit"},],
        },
      ];
    },
    addDiolog() {
      // this.$refs.AddIsuueType.dialog = true;
      this.viewType = false;
      this.dialog=true;
      this.issueTypeId=null;
      // this.$refs.AddIsuueType.size = commonService.largeDialog();
    },

    async getIssueType(){
      let res=await IssueTypeService.findAll();
      this.data=res
      console.log(this.data,'adding datassssss')
    },
    rowActions($event) {
            if ($event.type == 'view') {
                // this.$refs.AddIsuueType.dialog = true
                // this.$refs.AddIsuueType.size = commonService.largeDialog()
                this.dialog = true
                // this.$refs.AddIsuueType.type = $event.
                this.viewType = $event.type;
                this.issueTypeId = $event.data.id;
                console.log('view')
            }
            if ($event.type == 'edit') {
                // this.$refs.AddIsuueType.dialog = true
                // this.$refs.AddIsuueType.size = commonService.largeDialog()
                this.dialog = true
                // this.$refs.AddIsuueType.type = $event.type
                this.viewType = $event.type;
                this.issueTypeId =$event.data.id
                console.log($event.data.id,'edit')

            }
            if ($event.type == 'select') {
                // this.$refs.AddIsuueType.dialog = true
                // this.$refs.AddIsuueType.size = commonService.largeDialog()
                this.dialog = true
                // this.$refs.AddIsuueType.type = $event.type
                this.viewType = $event.type;
                this.issueTypeId =$event.data.id

                console.log('select')
            }

        },    
        cancel() {
          this.dialog = false;
      // this.issuetype=[];
    },

  },
};
</script>

<style></style>
