<template>
  <v-card flat>
    <v-card-item>
      <v-card-title>
        {{ viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add" }}
        {{
          jobs.end_point == "reports"
            ? "Schedule Report"
            : jobs.end_point == "recurring"
            ? "Setup Recurring"
            : "Schedule Job"
        }}
        <v-btn
          @click="close()"
          elevation="0"
          density="comfortable"
          icon="mdi-close"
          class="x-closeIcon"
        ></v-btn>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <v-form @submit.prevent="save" ref="form" v-model="value">
        <v-row dense>
          <v-col v-if="jobs.id" cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="jobs.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" v-if="from">
            <v-label>
              Report
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <AutoComplete
              :disabled="showReadOnly"
              :items="reportDataList"
              item-title="name"
              item-value="id"
              :rules="reportRule"
              v-model="formData.report_id"
              placeholder="Select Report"
              @update:modelValue="selectReport"
            />
          </v-col>
          <v-col cols="6" v-if="jobs.end_point == 'reports'">
            <v-label>
              Saved Filter
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <AutoComplete
              :disabled="showReadOnly"
              :items="filter"
              item-title="name"
              item-value="id"
              :rules="filterRule"
              v-model="formData.filterId"
              placeholder="Select Saved Filter"
              @update:modelValue="selectFilter"
            />
          </v-col>
          <v-col cols="12" v-if="jobs.end_point == 'reports'">
            <v-label>
              Name
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TextFieldComponent
              :disabled="showReadOnly"
              :rules="nameRule"
              placeholder="Name"
              v-model="this.formData.reportName"
            />
          </v-col>
          <v-col cols="12" class="py-0" v-if="!endPoint">
            <v-label> End Point </v-label>
            <AutoComplete
              :disabled="showReadOnly"
              v-model="jobs.end_point"
              :items="endPoints"
              item-title="name"
              item-value="id"
              placeholder="Select endpoint"
              @update:modelValue="endPointChanged"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <v-label> Interval Type </v-label>
            <v-radio-group :disabled="showReadOnly" v-model="jobs.interval_type" inline>
              <v-radio
                v-for="intervalType in intervalTypes"
                color="primary"
                v-bind:key="intervalType"
                :label="intervalType.name"
                :value="intervalType.id"
                :v-bind:key="intervalType"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-label>
              Interval Frequency
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TextFieldComponent
              :disabled="showReadOnly"
              placeholder="Interval Frequency"
              :rules="intervalfrequencyRule"
              v-model="jobs.interval_frequency"
            />
          </v-col>
          <v-col cols="12" v-if="false">
            <v-label> Include Days </v-label>
            <v-row no-gutters dense>
              <v-col class="pa-0 ma-0" cols="3" v-for="day in days" v-bind:key="day">
                <v-switch
                  :disabled="showReadOnly"
                  color="primary"
                  inline
                  v-model="include_days[day.id]"
                  density="compact"
                  :label="day.name.substr(0, 3)"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-label>
              Next Date
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TextFieldComponent
              :disabled="showReadOnly"
              :rules="nextDateRule"
              type="date"
              placeholder=" Next Date"
              :showtime="false"
              v-model="nextDate"
            />
          </v-col>

          <v-col cols="6">
            <v-label>
              Next Date Time
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <AutoComplete
              placeholder="Select Time"
              :disabled="showReadOnly"
              :rules="nextDateTimeRule"
              :items="timelines"
              item-title="name"
              item-value="id"
              v-model="nextTime"
              @update:modelValue="nextTimeLineChanged"
            />
          </v-col>

          <v-col cols="3" v-if="false">
            <v-label>
              Expires on
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              :disabled="showReadOnly"
              :rules="expiresRule"
              type="date"
              :showtime="false"
              placeholder=" Expires on "
              v-model="expireDate"
            />
          </v-col>
          <v-col cols="3" v-if="false">
            <v-label>
              Expires on Time
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <AutoComplete
              placeholder="Select time"
              :disabled="showReadOnly"
              :items="timelines"
              item-title="name"
              :rules="expiresTimeRule"
              item-value="id"
              v-model="expireTime"
              @update:modelValue="expireTimeLineChanged"
            />
          </v-col>

          <v-col cols="12" v-if="jobs.end_point == 'reports'">
            <v-label>
              Email
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TextFieldComponent
              :disabled="showReadOnly"
              placeholder="Email"
              :rules="emailRule"
              v-model="formData.email"
              @input="trimEmailSpaces"
            />
          </v-col>
          <v-col cols="12" v-if="jobs.end_point == 'reports'">
            <v-label> CC </v-label>
            <TextFieldComponent
              :disabled="showReadOnly"
              placeholder="CC"
              v-model="formData.cc"
              @input="trimCcSpaces"
            />
          </v-col>
          <v-col cols="12" v-if="!showReadOnly">
            <v-btn @click="submit" variant="flat" class="save" block>Submit </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
import { TextFieldComponent } from "@tiburadev/vue-comp";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import JobsService from "@/api/service/Jobs/JobsService";
// import commonService from "@/common.service";
import ListModelService from "@/api/service/ListModel/ListModelService";
import ListModelFilterService from "@/api/service/ListModelFilter/ListModelFilterService";

export default {
  components: {
    AutoComplete,
    TextFieldComponent,
  },
  props: [
    "endPoint",
    "params",
    "id",
    "jobId",
    "viewType",
    "filterData",
    "reportData",
    "from",
  ],
  data() {
    return {
      filter: [],
      formData: {},
      filterList: [],
      showReadOnly: false,
      reportDataList: [],
      tempData: {},
      days: [
        { id: "sun", name: "Sunday" },
        { id: "mon", name: "Monday" },
        { id: "tue", name: "Tuesday" },
        { id: "wed", name: "Wednesday" },
        { id: "thu", name: "Thursday" },
        { id: "fri", name: "Friday" },
        { id: "sat", name: "Saturday" },
      ],
      intervalTypes: [
        { id: "MI", name: "Minutes" },
        { id: "H", name: "Hourly" },
        { id: "D", name: "Daily" },
        { id: "W", name: "Weekly" },
        { id: "M", name: "Monthly" },
      ],
      endPoints: [
        { id: "recurring", name: "Setup Recurring issue" },
        { id: "report", name: "Schedule a report" },
        { id: "job", name: "Schedule a job" },
      ],
      include_days: {
        sun: true,
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: false,
      },
      nextDate: "",
      nextTime: "",
      expireDate: "",
      expireTime: "",
      jobs: {
        end_point: this.end_point,
        params: this.params ? this.params : {},
        interval_type: "day",
        interval_frequency: 1,
        include_days: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
        next_date: null,
        expire_date: null,
      },
      nameRule: [(value) => !!value || "Name is Required"],
      reportRule: [(value) => !!value || "Report is Required"],
      intervalfrequencyRule: [(value) => !!value || "Interval Frequency is Required"],
      nextDateRule: [(value) => !!value || "Next Date is Required"],
      nextDateTimeRule: [(value) => !!value || "Next Date Time is Required"],
      expiresRule: [(value) => !!value || "Expires On is Required"],
      expiresTimeRule: [(value) => !!value || "Expires On Time is Required"],
      filterRule: [(value) => !!value || "Filter  is Required"],
      emailRule: [(value) => !!value || "Email  is Required"],
    };
  },
  created() {
    this.jobs.end_point = this.endPoint;
    this.jobs.params = this.params;
    this.generateTimeArray();
    if (this.from == "list") {
      this.getReportList();
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
    if (this.jobId) {
      this.getJob(this.jobId);
    }
    this.filter = this.filterData && this.filterData.length > 0 ? this.filterData : [];
  },
  watch: {
    filterData() {
      this.filter = this.filterData && this.filterData.length > 0 ? this.filterData : [];
    },
    jobId() {
      if (this.jobId) {
        this.getJob(this.jobId);
      }
    },
  },
  mounted() {},
  methods: {
    getReportList() {
      ListModelService.findAll()
        .then((res) => {
          this.reportDataList = res;
          console.log(this.reportDataList, "uuuuuuuuueeerrr");
        })
        .catch((e) => {
          this.reportDataList = [];
          console.log(e);
        });
    },
    trimEmailSpaces() {
      this.formData.email = this.formData.email.trim();
    },
    trimCcSpaces() {
      this.formData.cc = this.formData.cc.trim();
    },
    async getCurrentDateToUtc(date) {
      var someDate = new Date(date);
      var utcDate = someDate.toUTCString();
      let formatDate = moment(utcDate).format("YYYY-MM-DD HH:mm:ss");
      // console.log(someDate, "hhhhhhhhhhhhh444", formatDate)
      return formatDate;
      //   }
    },

    generateTimeArray() {
      var timeArray = [];

      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          var formattedHour = hour.toString().padStart(2, "0");
          var formattedMinute = minute.toString().padStart(2, "0");
          var timeValue = `${formattedHour}:${formattedMinute}`;
          let obj = {
            id: timeValue,
            name: timeValue,
          };
          timeArray.push(obj);
        }
      }

      this.timelines = timeArray;
    },
    selectReport() {
      ListModelFilterService.findAll({ model_id: this.formData.report_id })
        .then((res) => {
          this.filter = res;
        })
        .catch((e) => {
          this.reportDataList = [];
          console.log(e);
        });
    },
    moment,
    selectFilter($event) {
      if ($event) {
        let $filterData = this.filter.filter((key) => key.id == $event);
        // let name1 = this.reportData && this.reportData.name ? this.reportData.name : ''
        // let name2 = $filterData && $filterData.length > 0 ? $filterData[0].name : ''
        // console.log(($filterData && $filterData.length > 0 ? $filterData[0].name : '' + name))
        // this.formData.reportName = name1 + '-' + name2
        this.formData.filterId =
          $filterData && $filterData.length > 0 ? $filterData[0].id : "";
      }
    },
    changeinData() {
      alert(this.this.nextDate);
    },
    close() {
      this.$emit("closeDialog");
    },
    async getJob($id) {
      let $job = await JobsService.findOne($id);
      this.include_days =
        $job && $job.include_days ? JSON.parse($job.include_days) : this.include_days;
      $job.params = JSON.parse($job.params);
      this.tempData.model_id;
      let $next_time = this.formatDate($job.next_date, "date");
      console.log($next_time, "uuuueeeee22", $job.next_date);
      this.nextTime = moment($job.next_date).format("HH:mm");
      this.expireTime = moment($job.expire_date).format("HH:mm");
      this.nextDate = $job.next_date
        ? moment($job.next_date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.expireDate = $job.expire_date
        ? moment($job.expire_date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.jobs = $job;
      this.formData = $job.params;
      if (this.from) {
        this.selectReport();
      }

      // console.log(this.jobs , 'balabro',this.nextDate,this.nextTime,this.expire_date,this.expireTime)
    },
    postClose($message) {
      this.$store.dispatch("setSnackbar", { text: $message });
      this.close();
      this.$emit("reload", true);
      this.jobs = {};
    },

    formatDate(dateString, type) {
      if (!dateString) {
        return "";
      } else if (type == "date") {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // moment(date).utcOffset('+05:30')
      } else {
        const date = new Date(dateString);
        //return moment(date).toLocal()
        return date.toLocaleString();
      }
    },
    async submit() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log(validation.valid);
      } else {
        let $jobs = Object.assign({}, this.jobs);
        $jobs.next_date = moment(this.nextDate + " " + this.nextTime);
        $jobs.expire_date = moment(this.expireDate + " " + this.expireTime);
        $jobs.include_days = JSON.stringify(this.include_days);
        if (this.jobs.end_point == "reports") {
          $jobs.params = { ...this.formData, ...$jobs.params };
        }
        $jobs.params = JSON.stringify($jobs.params);

        if ($jobs.id) {
          // this.formData.next_date = commonService.changeTheDateToUtc(
          //     this.formData.next_date,
          //     Intl.DateTimeFormat().resolvedOptions().timeZone
          // );
          // this.formData.expire_date = commonService.changeTheDateToUtc(
          //     this.formData.expire_date,
          //     Intl.DateTimeFormat().resolvedOptions().timeZone
          // );
          await JobsService.update($jobs);
          this.postClose("Updated Successfully");
        } else {
          await JobsService.create($jobs);
          this.postClose("Saved Successfully");
        }
      }
    },
    nextTimeLineChanged($data) {
      this.nextTime = $data;
      this.jobs.next_date = moment(this.nextDate + " " + this.nextTime);
    },
    expireTimeLineChanged($data) {
      this.expireTime = $data;
      this.jobs.expire_date = moment(this.expireDate + " " + this.expireTime);
    },
    endPointChanged($data) {
      this.jobs.end_point = $data;
    },
    createTimelines() {
      let $fromDate = this.nextDate ? moment(this.nextDate) : moment();
      let $start = $fromDate.toObject();
      console.log($start);
      let $timelines = [];
      if ($fromDate.get("minutes") <= 30) {
        $timelines.push(moment($fromDate).format("HH:mm"));
        $fromDate.set("minutes", 30);
        $timelines.push(moment($fromDate).format("HH:mm"));
      } else {
        $fromDate.set("hours", $fromDate.get("hours") + 1);
        $fromDate.set("minutes", 0);
        $timelines.push(moment($fromDate).format("HH:mm"));
      }
      do {
        $fromDate = moment($fromDate).add(0.5, "hour");
        $timelines.push(moment($fromDate).format("HH:mm"));
      } while ($fromDate.get("hours") < 23);
      $timelines.push("23:30");
      $timelines.push("00:00");
      this.timelines = $timelines;
    },
  },
};
</script>
<style scoped>
>>> .v-btn--icon .v-icon {
  --v-icon-size-multiplier: 0.7;
}

.save {
  background-color: #3c5aaa;
  color: #fff;
}
</style>
