<template>
   <v-card flat class="pa-3 pt-0 mt-0 ">
  <PrimeTable
    :title="'Issue List'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    :avatarData="workSpaceData"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="issue"
  />
</v-card>
  <!-- <v-dialog v-model="dialog" width="1200px" persistent>
    <createActivity
      ref="createActivity"
      @closeDialog="setFalse()"
      @reload="getIssueList()"
      :id="IssueId"
      :viewType="viewType"
    />
  </v-dialog> -->
</template>

<script>
// import createActivity from "../settings/CreateActivity.vue";
import PrimeTable from "@/components/PrimeDataTable.vue";
import IssueService from "@/api/service/Issue/IssueService";
import commonService from "@/common.service";
export default {
  components: {
    PrimeTable,
    // createActivity,
  },
  data() {
    return {
      data: [],
      workSpaceData:[],
      date: new Date(),
      //   showName: "",
      //   issueData: [],
      columns: [],
      IssueId: "",
      dialog: false,
      viewType: false,
      end_date: new Date()
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getIssueList();
  },

  methods: {
    loadColumns() {
      this.columns = [
        {
          field: "name",
          header: "Name",
          style: { width: "300px", color: "" },
          toolTip: "Code",
          sortfield: true,
        },
        {
          field: "issue_type_name",
          header: "Issue Type",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        
        {
          field: "next_action_name",
          header: "Next Action",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
          avatar:true,
          f_name:'first_Name',
          l_name:'first_Name',
          theme:'theme'
        },
       
      
        // {
        //   field: "user_name",
        //   header: "User",
        //   style: { width: "100px", color: "" },
        //   toolTip: "Category Name",
        //   sortfield: true,
        // },
        {
          field: "end_date",
          header: "Due Date",
          style: { width: "100px", color: "" },
          toolTip: "Due Date",
          sortfield: true,
          type: "date"
        },
        {
          field: "stage",
          header: "Stage",
          style: { width: "100px", color: "" },
          toolTip: "Stage",
          sortfield: true,
        },
        {
          field: "task_name",
          header: "Task",
          style: { width: "100px", color: "" },
          toolTip: "description",
          sortfield: true,
        },
        {
          field: "owner_name",
          header: "Reported By",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
          avatar:true,
          f_name:'first_Name',
          l_name:'first_Name',
          theme:'theme'
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
           
            { action: "view" }
          ],
        },
      ];
    },
  

    setFalse() {
      this.dialog = false;
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.IssueId = $event.data.id;
        this.viewType = $event.type;
        console.log("view");
        this.$router.push({ path:"/issueView2", query: { type: this.viewType,id:this.IssueId } });

      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.IssueId = $event.data.id;
        this.viewType = $event.type;
        this.$router.push({ path:"/AddIssue", query: { type: this.viewType,id:this.IssueId } });

        console.log("edit");
      }
      if ($event.type == "select") {
        this.dialog = true;
        this.IssueId = $event.data.id;
        this.viewType = $event.type;
        this.$router.push({ path:"/issueView2", query: { type: this.viewType,id:this.IssueId } });

        console.log("select");
      }
    },
  

    async getIssueList() {

        this.data=[]
        try {
            let accountId =localStorage.getItem("_ACCOUNT_ID_")
            let projectId =localStorage.getItem("_PROJECT_ID_")
            let res = await IssueService.getIssueData({accountId:accountId,projectId:projectId})
            for (let i = 0; i < res.length; i++) {
                this.data.push(res[i].ds_issue_list)
            }
            this.data = [...this.data]
        }
        catch (e) {
            console.log(e)
            this.data = []
        }
    },

    addDiolog() {
      this.dialog = true;
      this.IssueId = null;
      this.viewType=false;
        this.$router.push({ path:"/AddIssue"});
    },

    addFilter() {
      this.$refs.FilterDrawer.dialog = true;
      this.$refs.FilterDrawer.size = commonService.largeDialog();
    }
}
  }

</script>

<style>
</style>