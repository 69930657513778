<template>
    <v-row class="main_row">
        <v-col cols="6"><workSpaceCard class="workspace"/>
  <ErrorTrends class="errortrends"/></v-col>
        <v-col cols="6"><AssignedToMe /></v-col>
    </v-row>
  
</template>

<script>
import workSpaceCard from './workSpaceCard.vue'
import ErrorTrends from './ErrorTrends.vue';
import AssignedToMe from '@/modules/issues/AssignedToMe.vue';
export default {
    components:{
        workSpaceCard,
        ErrorTrends,
        AssignedToMe
    }

}
</script>

<style>
.workspace{
    height: 300px;
    overflow-y: auto;
}
.errortrends{
    padding-left: 8px;
}
.main_row{
    min-height: 100vh !IMPORTANT;
    overflow: scroll;
}

</style>