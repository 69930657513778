<template>
  <v-card width="100%" style="border-radius: 8px; overflow: auto">
    <v-form @submit.prevent="save" ref="form" v-model="value">
      
      <v-card-item>
        <v-card-title>
          {{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }}
         <b> Holiday</b>
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>
      <v-divider class="mt-2"></v-divider>


      <v-card-text class="pl-7 pt-7 pr-7">

       

        <v-row no-gutters >
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Date
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <DatePicker :showReadOnly="showReadOnly"  :data="formData.date" :rules="dateRule" :placeholder="'Date'" @selectDate="selectDate($event)" :disabled="showReadOnly" />
            <v-error v-if="dateError">Date Is Required</v-error>
          </v-col>

          <v-col cols="12">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Name" :rules="nameRule" v-model="formData.holiday"
              :disabled="showReadOnly" />
          </v-col>

          <v-col  >
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Mandatory
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <!-- <v-switch v-model="formData.mandatory" > </v-switch> -->
           
            <!-- <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Mandatory</v-label> -->
            <v-switch 
              color="primary"
              v-model="formData.mandatory"
              :disabled="showReadOnly "
              :label="formData.mandatory ? 'Yes' : 'No'" 
              :model-value ="formData.mandatory ? true : false"
             
            ></v-switch>
          
          </v-col>

          <v-col cols="12">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Holiday Description
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label> <span  @click="showTextArea=!showTextArea" style="color:#3C5AAA;cursor:pointer;text-decoration: underline;"><i>{{showTextArea ?'Show':'Hide' }}</i></span>
              <TextArea
              v-if="!showTextArea"
              class="pb-4"
              editorStyle="height: 100px"
                :data="formData.holiday_description"
                :rules="holidayRule"
                :showReadOnly="showReadOnly"
                @textData="changeDescription($event)"
                ></TextArea>
          </v-col>
        </v-row>
        
      </v-card-text>

      <!-- <v-divider class="mb-4"></v-divider> -->
        <v-card-action class="mr-3">
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
            <v-btn class="save" type="submit">Save</v-btn>
          </v-row>
        </v-card-action>

    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import DatePicker from "@/components/ui/DatePicker.vue";
import TextArea from "@/components/ui/TextArea.vue";
import HolidayService from "@/api/service/Holiday/HolidayService";

export default {
  props: ["id", "viewType"],
  components: {
    DatePicker,
    TextFieldComponent,
    TextArea,
  },

  data() {
    return {
      showTextArea:true,
      dateError: false,
      type: null,
      mindate: '2023-05-11',
      showReadOnly: false,
      dialog: false,
      nameRule: [(value) => !!value || "Name is Required."],
      //  holidayRule: [(value) => !!value || "Holiday Description is Required."],

      formData: {
        id: null,
        holiday_description: null,
        mandatory: "true",
        date: null,
        holiday: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {

    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {

    changeDescription($event){
      // alert('workingggggg')
      this.formData.holiday_description =$event
      // console.log($event,'check11111111111')
    },

    getTextData($event) {
      this.formData.holiday_description = $event;
    },
    // text($value){

    //    this.formData.holiday_description =$value
    //    alert( this.formData.holiday_description)
    // },
    selectDate($event){
      this.dateError = false
      this.formData.date =$event
       console.log($event,"vicky")
    },
    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await HolidayService.findOne(id);
      this.formData = res[0];
      // mandatory: res[0].mandatory === "true"
    },
//     async getById(id) {
//   let res = await HolidayService.findOne(id);
//   this.formData = {
//     ...res[0],
//     mandatory: res[1].mandatory === "true",
//   };
// },


    async save() {
      this.dateError = this.formData.date ? false : true;
      let validation = await this.$refs.form.validate()
      if (!validation.valid) {
        console.log(validation.valid)
      }
      else{
      if (this.formData.id) {
        // this.formData.mandatory = this.formData.mandatory === 1 ? "Yes" : "No";
        await HolidayService.update(this.formData);
        this.$store.dispatch('setSnackbar',{text:'Updated Successfully'})
        this.dialog = false;
        this.close();
        this.$emit("reload", true);
        console.log(this.formData, "hhhhhhhhhhhh")
        this.formData = {};
      } else {
        try{
        // this.formData.mandatory = this.formData.mandatory ? "Yes" : "No";
        await HolidayService.create(this.formData);
        this.$store.dispatch('setSnackbar',{text:'Saved Successfully'})
        this.dialog = false;
        this.close();
        this.$emit("reload", true);
        this.formData = {};
        }catch(e){
            this.$store.dispatch('setSnackbar',{ color:'error',text:'Something Went Wrong'})
          }
      }
       }
    },
  },
}
</script>
  <style>

v-error {
    color: red;
    padding-left: -16px;
    padding-left: 2%;
    font-size: 12px;
    padding-left: unset;
    position: relative;
    /* margin-top: 8px; */
    top: -15px;
}
</style>