<template>
  <div>
    <v-card
      elevation="0"
      style="height: 100%; min-height: 750px; overflow: auto"
      density="compact"
      class="assignToPart"
      :style="{
        overflow: issueType && issueType.length > 0 ? 'auto' : 'hidden',
      }"
    >
      <AllIssuesHeader
        @getSearch="getSearch"
        :filterData="filterData"
        @filterTaskData="getFilterData"
      />

      <!-- <div class="cardTitle pl-4 pt-3 mb-2">
        Assigned to me
      </div> -->
      <template v-if="issueType.length > 0">
        <v-card
          elevation="0"
          @click="goIssueView(item)"
          v-for="(item, index) in issueType"
          :key="index"
        >
          <div style="display: flex; gap: 12px">
            <div class="pl-3 pt-2 pr-0">
              <!-- <Avatar
                style="scale: 0.8"
                v-if="item && item.a_image_url"
                shape="circle"
                size="large"
                v-badge="3"
              >
                <img :src="item.a_image_url" style="width: 150%" />
              </Avatar>
              <Avatar
                v-else
                size="large"
                style="scale: 0.8"
                :style="{
                  'background-color':
                    item && item.account_theme ? item.account_theme : '#172b4d',
                  color: '#ffff',
                }"
                class="text-uppercase pl-1 pr-1"
                shape="circle"
                >{{ item && item.account_code }}</Avatar
              > -->
              <CustomAvatar2
                class="avatrBig ma-0 mr-2 show-cursor"
                :acCode="item.account_code"
                :fName="item.account_name"
                :theme="item.account_theme"
                :imgUrl="item.a_image_url ? item.a_image_url : null"
                :size="'large'"
              />
            </div>

            <div class="pr-3 py-3 pl-0" style="width: 100%">
              <div style="display: flex; justify-content: space-between">
                <div class="reff">
                  {{ item.reference_num }}
                  <div
                    class="priority"
                    v-if="
                      (item && item.priority == 'high') || item.priority == 'critical'
                    "
                  >
                    <v-icon v-if="item && item.priority == 'high'"
                      >mdi-exclamation-thick</v-icon
                    >

                    <i
                      v-if="item && item.priority == 'critical'"
                      class="pi pi-exclamation-triangle"
                      style="color: red; font-size: 8px; font-weight: 800"
                    />
                    <v-icon
                      v-if="item.week_end_mails == 'yes'"
                      class="mdi mdi-circle-half-full"
                      color="blue"
                    >
                    </v-icon>
                  </div>
                </div>

                <div style="display: flex">
                  <span style="position: relative; bottom: 7px; right: 5px">
                    <v-icon
                      @click.stop="copyTextToClipboard(item.name)"
                      class="pi pi-copy"
                      style="font-size: 1rem"
                    >
                    </v-icon>
                  </span>
                  <div class="chip" v-if="item && item.stage">
                    {{ item.stage ? getStage(item.stage) : "" }}
                  </div>
                </div>
              </div>

              <div
                style="display: flex; justify-content: space-between; line-height: 1.2"
              >
                <span style="width: 75%">
                  <v-row>
                    <v-col cols="12">
                      <span class="issueName">{{ item.name }} </span>
                      <span class="chip" v-if="item && item.sub_category_name">
                        {{ item.sub_category_name }}
                      </span>
                    </v-col>
                  </v-row>
                </span>

                <span style="display: flex">
                  <div class="dateAndP" v-if="item.mail_date">
                    <span class="dateRow" :class="isDateOverDue(item.mail_date)">
                      <span>
                        {{
                          item &&
                          item.mail_date &&
                          moment(item.mail_date).format("MM/DD/YYYY hh:mm A") !=
                            "Invalid date"
                            ? moment(item.mail_date).format("MM/DD/YYYY")
                            : ""
                        }}
                      </span>
                      <div>
                        {{
                          item &&
                          item.mail_date &&
                          moment(item.mail_date).format("MM/DD/YYYY hh:mm A") !=
                            "Invalid date"
                            ? moment(item.mail_date).format("ddd hh:mm A")
                            : ""
                        }}
                      </div>
                    </span>
                  </div>
                  <div class="dateAndP" v-else>
                    <span class="dateRow" :class="isDateOverDue(item.created)">
                      <span>
                        {{
                          item &&
                          item.created &&
                          moment(item.created).format("MM/DD/YYYY hh:mm A") !=
                            "Invalid date"
                            ? moment(item.created).format("MM/DD/YYYY")
                            : ""
                        }}
                      </span>
                      <div>
                        {{
                          item &&
                          item.created &&
                          moment(item.created).format("MM/DD/YYYY hh:mm A") !=
                            "Invalid date"
                            ? moment(item.created).format("ddd hh:mm A")
                            : ""
                        }}
                      </div>
                    </span>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <v-divider style="background-color: #adadad"></v-divider>
        </v-card>
      </template>
      <template v-else>
        <v-card
          elevation="0"
          rounded="lg"
          class="pa-4 ml-4 mt-4 pt-0"
          style="height: 100vh; border-radius: 4px !important"
        >
          <v-card-text>
            <v-container class="text-center" justify-center>
              <v-icon style="font-size: 124px" class="mt-3">
                <img :src="require('@/assets/no-record.png')" width="100" height="100" />
              </v-icon>
              <br />
              <span class="text-body2 mt-3">
                <b>Assignment Not Found</b>
              </span>
            </v-container>
          </v-card-text>
        </v-card>
      </template>
    </v-card>
  </div>
</template>

<script>
// import Avatar from "primevue/avatar";
import moment from "moment";
import StageService from "@/api/service/Stage/StageService";
import IssueService from "@/api/service/Issue/IssueService";
import DocumentService from "@/api/service/Document/DocumentService";
import AllIssuesHeader from "@/modules/dashboard/user/AllIssuesHeader.vue";
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
import lodash from "lodash";

export default {
  props: ["userId"],
  components: {
    // Avatar,
    AllIssuesHeader,
    CustomAvatar2,
  },

  data() {
    return {
      stageData: [],
      issueType: [],
      originalIssueType: [],
      userData: JSON.parse(localStorage.getItem("user_details")),
    };
  },
  computed: {
    reloadIssue() {
      return this.$store.getters.getIssueUpdated;
    },
    reloadTheData() {
      return this.$store.getters.getReloadTheIssue;
    },
    getRefresh() {
      return this.$store.getters.getInProgress;
    },
    getTimerHide() {
      return this.$store.getters.getHideTimer;
    },
    getPending() {
      return this.$store.getters.getPending;
    },
    issueUpdated() {
      return this.$store.getters.getUpdatedIssueStatus;
    },
    issueCreated() {
      return this.$store.getters.getCreatedIssueStatus;
    },
  },
  watch: {
    reloadIssue() {
      if (this.$store.getters.getIssueUpdated != 0) {
        this.getUpdatedData(this.$store.getters.getIssueUpdated);
      }
    },
    issueCreated() {
      if (this.issueCreated) {
        this.getissuetype();
        this.$store.commit("SET_ISSUE_CREATED", false);
        this.getIssueById(this.$store.getters.getUpdatedIssueId, "new");
      }
    },
    issueUpdated() {
      if (this.issueUpdated) {
        this.getissuetype();
        this.$store.commit("SET_OLD_ISSUE_UPDATED", false);
        this.getIssueById(this.$store.getters.getUpdatedIssueId, "update");
      }
    },
    getTimerHide() {
      if (this.getTimerHide) {
        this.getissuetype();
      } else {
        this.getissuetype();
      }
    },
    reloadTheData() {
      this.getStageData();
      this.getissuetype();
    },
    getPending() {
      if (this.getPending) {
        this.getStageData();
        this.getissuetype();
      }
    },
    userId() {
      if (this.userId) {
        this.getissuetype();
      }
    },

    getRefresh() {
      if (this.getRefresh) {
        this.getStageData();
        this.getissuetype();
      }
    },
  },

  mounted() {
    this.getStageData();
    this.getissuetype();
  },

  methods: {
    async getIssueById($id, $type) {
      let $res = await IssueService.getAssignToMeData({ id: $id });
      if ($res && $res.length > 0) {
        if ($type == "new") {
          this.issueType.push($res[0]);
        } else {
          let $index = this.issueType.findIndex((k) => k.id == $res[0].id);
          if ($index > -1) {
            this.issueType.splice($index, 1, $res[0]);
          }
        }
      }
    },
    getSearch(query) {
      console.log(query, "whatareweseacrching");

      if (!this.originalIssueType.length) {
        this.originalIssueType = JSON.parse(JSON.stringify(this.issueType));
      }

      if (query && query.length > 0) {
        let searchStr = new RegExp(query, "gi");
        let filteredData = this.originalIssueType.filter(
          (item) =>
            (item.name && item.name.search(searchStr) > -1) ||
            (item.ref_num && item.ref_num.search(searchStr) > -1)
        );

        this.issueType = [...filteredData];
      } else {
        this.issueType = JSON.parse(JSON.stringify(this.originalIssueType));
      }
    },

    isDateOverDue(date) {
      if (date) {
        let currentDate = new Date();
        let dueDate = new Date(date);
        return currentDate > dueDate ? "text-red-lighten-1" : "text-orange-lighten-1";
      }
    },
    moment,

    copyTextToClipboard(data) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(data)
          .then(() => {
            console.log("Text successfully copied to clipboard");
          })
          .catch((err) => {
            console.error("Unable to copy text: ", err);
          });
      }
    },
    async getStageData() {
      try {
        let res = await StageService.findAll();
        this.stageData = res.length > 0 ? res : [];
      } catch (e) {
        this.stageData = [];
      }
    },
    getStage(value) {
      let $stage = value.replaceAll(" ", "").toLowerCase();
      let opt = this.stageData.filter((k) => k.code == $stage);
      return opt && opt.length > 0 ? opt[0].name : "";
    },
    getAvatarName(value) {
      let name =
        value && value.first_name
          ? value.first_name.charAt(0)
          : "" + value && value.last_name
          ? value.last_name.charAt(0)
          : "";
      return name;
    },
    async getFilterData(data) {
      this.filterData = data;
      this.filterDialog = false;
      this.getissuetype(this.filterData);
    },
    goIssueView(item) {
      let status = this.$store.getters.getMyspace;

      localStorage.setItem(
        "_WS_DEFAULTS_",
        JSON.stringify({
          workspace: { id: item.work_space_id, name: item.work_space_name },
        })
      );
      localStorage.setItem("workSpaceId", item.work_space_id);

      // this.$router.push({ path: "/issueView2", query: { id: item.id } });
      this.$store.dispatch("setIssueInView", item.id);
      let wsDefaults = {
        workspace: { id: item.work_space_id, name: item.work_space_name },
        account: { id: item.account_id, name: item.account_name },
        project: { id: item.project_id, name: item.project_name },
      };
      let wsDefaultsData = JSON.stringify(wsDefaults);
      localStorage.setItem("_WS_DEFAULTS_", wsDefaultsData);
      // console.log(wsDefaultsData,'alert111111111')
      this.$store.commit("SET_REFRESH_BREADCRUMP", true);
      this.$store.commit("CHANGE_MY_SPACE", !status);
    },

    async getImage(id) {
      try {
        let res = await DocumentService.imageProfileAccount({
          image_profile_id: id,
        });
        // this.url = res
        console.log(res);
        return res;
      } catch (e) {
        return null;
      }
    },
    async filteredData($data) {
      let $filteredRows = await lodash.orderBy($data, ["mail_date", "created"], ["asc"]);
      console.log($filteredRows, "hhhiiihhhh");
      this.issueType = [...$filteredRows];
    },
    async getUpdatedData($id) {
      var res = await IssueService.getAssignToMeData({ id: $id });
      let $findIndex = lodash.findIndex(this.issueType, { id: $id });
      if ($findIndex >= 0) {
        this.issueType[$findIndex] = res[0];
      } else {
        this.issueType.push(res[0]);
      }
      this.$store.dispatch("issueUpdated", 0);
    },
    async getissuetype($obj) {
      let obj = {};
      if ($obj) {
        obj = $obj;
      }
      obj.next_action = this.userId;
      var res = await IssueService.getAssignToMeData(obj);
      // this.filteredData(res)
      this.issueType = res && res.length ? res : [];
      // console.log(this.issueType, "issueType");
      // console.log(issuesWithout,inProgressIssue ,this.issueType, 'jjjsssssssssg')

      // await this.issueType.forEach(async (k) => {
      //   if (k && k.account_image_profile_id > 0) {
      //     k.url = await this.getImage(k.account_image_profile_id);
      //   }
      // });
    },
  },
};
</script>

<style scoped>
.details {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
}

.cardTitle {
  color: #272727;
  font-size: 18px;
  font-weight: 700;
}

.reff {
  display: flex;
  gap: 3px;
  color: #000;
  font-size: 11px;
  font-weight: 300;
}

.dateRow {
  display: flex;
  align-items: end;
  flex-direction: column;
  justify-content: center;
}

.dateAndP {
  display: flex;
  font-size: 10px;
  font-weight: 700;
  text-align: right;
}

.issueName {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  padding-bottom: 2%;
}

.priority {
  font-size: 9px;
}

.chip {
  text-transform: capitalize;
  border: 1px solid #f9c644;
  background: #ffe8ae;
  color: #000;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 4px;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
}

.assignToPart {
  overflow: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

/* >>>::-webkit-scrollbar-track {
  background: #e8f3f9;
} */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgb(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

>>> .p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 18px;
}
</style>
