<template>
  <v-icon size="18px" class="pl-3 mdi mdi-information" style="cursor: pointer">
    <v-menu activator="parent" open-on-hover transition="slide-x-transition">
      <v-list style="width: 250px">
        <v-list-title class="px-4"
          ><span style="font-size: 14px; font-weight: 600"
            >Mail Details</span
          ></v-list-title
        >
        <v-list-item class="px-4 pt-0">
          <v-row no-gutters>
            <v-col cols="3" class="detailsHeadingFont"><b>From :</b></v-col>
            <v-col cols="9" class="detailsFont">{{
              mailData && mailData.from && mailData.from.displayName
                ? mailData.from.displayName
                : ""
            }}</v-col>

            <v-col cols="3" class="detailsHeadingFont"><b>On :</b></v-col>
            <v-col cols="9" class="detailsFont">{{
              mailData &&
              mailData.received_date &&
              moment(mailData.received_date).format("MM/DD/YYYY hh:mm A") !=
                "Invalid date"
                ? moment(mailData.received_date).format("MM/DD/YYYY hh:mm A")
                : ""
            }}</v-col>

            <v-col cols="3" class="detailsHeadingFont"><b>To :</b> </v-col>
            <v-col cols="9" class="detailsFont">{{
              mailData && mailData.to && mailData.to[0] && mailData.to[0].displayName
                ? mailData.to[0].displayName
                : ""
            }}</v-col>

            <v-col cols="3" class="detailsHeadingFont"><b>CC :</b></v-col>
            <v-col cols="9" class="detailsFont">
              <span v-for="(item, index) in mailData.cc" :key="index">
                <span class="mr-1">{{
                  item && item.displayName ? item.displayName : ""
                }}</span
                >,
              </span>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-icon>
</template>

<script>
import moment from "moment";
export default {
  props: ["issueData", "activator"],
  data() {
    return {
      mailData: null,
      activateMenu: null,
    };
  },

  created() {
    let data =
      this.issueData && this.issueData.mail_details ? this.issueData.mail_details : "";
    let parsedData = data ? JSON.parse(data) : {};
    this.mailData = parsedData ? parsedData : {};
    console.log(this.mailData, "bdcjbjsc");
    this.activateMenu = this.activator;
  },

  watch: {
    activator() {
      this.activateMenu = this.activator;
    },
    issueData() {
      let data =
        this.issueData && this.issueData.mail_details ? this.issueData.mail_details : "";
      let parsedData = data ? JSON.parse(data) : {};
      this.mailData = parsedData ? parsedData : {};
    },
  },
  methods: {
    moment,
  },
};
</script>

<style>
.cardStyle {
  width: 250px;
  height: fit-content;
  /* position: absolute;
    top: -300px;
    left: 310px; */
  border: 2px solid #adadad;
}

.detailsHeadingFont {
  font-size: 12px;
  font-weight: 500;
}

.detailsFont {
  font-size: 12px;
  font-weight: 400;
}
</style>
