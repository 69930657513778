<template>
  <v-card class="ml-2 mt-1 mb-1">
    <v-container>
      <!-- <v-btn elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn> -->
      <v-card-text>
        <v-row class="mt-10">
          <v-col align="center" class="text-h5 pa-10 ">What would like to do ?</v-col>
        </v-row>
        <v-row class="pb-8 mt-15">
          <v-col align="center">
            <v-btn class="dialog-button" color="indigo-darken-1" @click="completeTheIssue()">Complete</v-btn>
          </v-col>
          <v-col align="center">
            <v-btn class="dialog-button" color="indigo-darken-1" @click="openHoldAction()">Hold</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
  <v-dialog persistent v-model="dialog" :width="size.width">
    <ContineueMeetingOrBreak ref="ContineueMeetingOrBreak" @issueComplete="completeTheIssue()"
      @continueIssue="continueIssue()" @startNextIssue="startNextIssue()"></ContineueMeetingOrBreak>
  </v-dialog>
</template>
<script>
import commonService from '../common.service.js'
import ContineueMeetingOrBreak from './ContineueMeetingOrBreak.vue';
import IssueService from "@/api/service/Issue/IssueService";
import TimeSheetService from '@/api/service/TimeSheet/TimeSheetService.js'
export default {
  components: {
    ContineueMeetingOrBreak
  },
  data() {
    return {
      dialog: false,
      size: {}
    }
  },
  created() {
    let type = localStorage.getItem('_TIME_SHEET_TYPE_')
    if(type){
    this.dialog = true
    console.log(this.dialog,"ppppppee44")
    this.size = commonService.smallDialog()
    }
  },
  methods: {
    async openHoldAction() {
      let issueId = localStorage.getItem('_CURRENT_ISSUE_ID_')
      let timeSheetId = localStorage.getItem('_TIME_SHEET_ID_')
      await IssueService.update({ id: issueId, end_date: new Date(), stage: 'open' })
      await TimeSheetService.update({ id: timeSheetId, end_date: new Date(), stage: 'open' })
      this.dialog = true
      this.size = commonService.smallDialog()
    },
    async completeTheIssue() {
      this.$emit('issueComplete', true)
    },
    continueIssue() {
      this.$emit('continueIssue')
    },

    startNextIssue() {
      this.dialog = false
      this.$emit('startNextIssue')
    },
  }
}

</script>
<style></style>