<template>
  <div class="mt-5 ml-2" style="overflow-y: auto; overflow-x: hidden;">
    <v-row class="pt-4 pb-5 pl-3"><v-text class="sub-title">
        Child Tasks</v-text>
      <v-icon class="pl-10" color="primary" @click="addChildIssue()" v-if="subTaskDefaults.stage!='closed'">mdi-plus-circle</v-icon>
    </v-row>
    <v-table fixed-header>
      <thead>
        <tr>
          <th class="text-left" style="background-color: rgba(224, 243, 253)">Id</th>
          <th class="text-left" style="background-color: rgba(224, 243, 253)">
            Name
          </th>
          <th class="text-left" style="background-color: rgba(224, 243, 253)">
            Sub Task
          </th>
          <th class="text-left" style="background-color: rgba(224, 243, 253)">
            Next Action
          </th>
        </tr>
      </thead>
      <tbody>

        <template v-if="childIssue.length > 0">
          <tr v-for="item in childIssue" :key="item.name" @click="viewChildIssue(item), atClick(item)"
            style="cursor: pointer">
            <td>
              {{ issueData && issueData.reference_num ? issueData.reference_num : "" }}{{
                item && item.reference_num ? item.reference_num : ""
              }}
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.task_name }}</td>
            <td>{{ item.next_action_name }}</td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="4" class="text-center">There is no data</td>
          </tr>
        </template>
      </tbody>
    </v-table>
  </div>
  <v-dialog class="Dialog" v-model="addDialog" :width="850" persistent>
    <AddQuickIssue ref="AddQuickIssueDialog" :viewType="viewType" childIssue=true :defaultData="subTaskDefaults" @closeDialog="closeDialog($event)">
    </AddQuickIssue>
  </v-dialog>
</template>

<script>
import AddQuickIssue from '../AddQuickIssue.vue'
import IssueService from '@/api/service/Issue/IssueService';
export default {
  props: ['parentId', 'subTaskDefaults'],
  components: {
    AddQuickIssue
  },
  data() {
    return {
      childIssue: [],
      issueData: {},
      viewType:'',
      addDialog: false
    }
  },
  created() {
    if (this.parentId) {
      this.getChildIssue()
    }
  },
  watch: {
    parentId() {
      this.getChildIssue()
    }
  },
  methods: {

    addChildIssue() {
      this.addDialog = true
      this.viewType="child"
      /*  this.$router.push({
         path: "/addQuickIssue",
         query: { parent_issue_id: this.parentId },
       }); */
    },
    closeDialog($event){
      console.log($event,"kkkk")
      this.addDialog =false
      this.getChildIssue()
    },
    async viewChildIssue(data) {
      this.$router.push({
        path: "/issueView2",
        query: { type: "view", id: data.id, parent_id: this.$route.query.id },
      });
      // await this.getIssueData();
      window.scrollTo(0,0)
    },
    async getChildIssue() {
      try {
        let res = await IssueService.getChildIssue({
          parent_issue_id: this.parentId,
        });
        this.childIssue = res;
      } catch (e) {
        this.childIssue = null;
        console.log(e);
      }
    },
    
    atClick(item) {
      let selectedId = item.id;
      this.$router.push({ path: "/issueView2", query: { id: selectedId } });
      console.log("xxxxxxxs", selectedId);

    },



  },


}
</script>

<style scoped>
.padding-table-columns td {
  padding: 0 30px 0 0;
}

.file-input-container .v-input__slot {
  height: 40px;
  /* Adjust the height value as per your requirements */
}


>>>.pi-download:before:hover {
  content: "\e956";
  background-color: aliceblue;
  padding: 41%;
  border-radius: 13%;
}

.view-all {
  text-align: center;
  background: #dddbdb70;
  font-weight: bold;
  color: #4f63ff;
  border-radius: 7px;
  cursor: pointer;
}

.download-icon {
  font-size: 1rem;
  /* margin: 1%; */
  padding: 4%;
  border-radius: 3px;
  cursor: pointer;
}

.download-icon:hover {
  font-size: 1rem;
  background-color: aliceblue;
  border-radius: 3px;
  width: 30px;
  height: 30px;
}

.sub-title {
  font-weight: 600;
  font-size: 15px;
  color: #8e8a8a;
}


>>>.v-table {
  --v-table-header-height: 35px;
  border-radius: inherit;
  line-height: 1.5;
  max-width: 100%;
}

>>>.v-table.v-table--fixed-header>.v-table__wrapper>table>thead>tr>th {
  background: rgb(var(--v-theme-surface));
  box-shadow: inset 0 -1px 0 rgba(var(--v-border-color), var(--v-border-opacity));
  z-index: 1;
  font-size: 15px;
  font-weight: bold;
}
/* >>>.v-table.v-table--fixed-header>.v-table__wrapper>table>tbody>tr>td {
  z-index: -10000;
} */

>>>.v-table__wrapper {
  border-radius: inherit;
  overflow: auto;
  max-height: 150px !important;
}

>>>::-webkit-scrollbar {
  width: 0px;
  /* height: 6px;  */
}

/* Track */
>>>::-webkit-scrollbar-track {
  background: #e8f3f9;
}

/* Handle */
>>>::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
}

</style>