<template>
    <v-container fluid class="pa-10">
        <div class="grid-container-child" v-for="rowData in layouts.rows" v-bind:key="rowData"
            :style="getColumnLayout(rowData)">
            <div v-for="columnData in rowData.columns" v-bind:key="columnData" class="grid-item-child" style="display:flex"
                :style="getColumnStyle(columnData)">
                <Board v-if="columnData.widget" :config="columnData.widget" />
                &nbsp;
                <!-- {{ columnData.rows }} -->
                <LayoutDesigner v-if="columnData.rows" :layout="columnData.rows" />
            </div>
        </div>
    </v-container>
</template>
<script>
import Board from '../dashboard/Widgets/Board.vue'
import LayoutDesigner from './Children.vue'
export default {
    components: {
        LayoutDesigner,
        Board
    },
    props: ["layout"],
    data() {
        return {
            widget: { "type": "issue", "grouping": ["user", "stage"], "groupWithin": true, "defaultFilters": { "work_space_id": null, "project_id": null, "account_id": null }, "filters": {}, "work_timing_id": 0, "work_space_id": 0, "project_id": 0, "account_id": 0, "widget_type": "teamboard", "group1": "user", "group2": "stage", "title": "Test" },
            layouts: {
                "rows": [
                    {
                        "height": "85vh",
                        "columns": [
                            {
                                "width": "325px",
                                "widget": { "type": "issue", "grouping": ["user", "stage"], "groupWithin": true, "defaultFilters": { "work_space_id": null, "project_id": null, "account_id": null }, "filters": {}, "work_timing_id": 0, "work_space_id": 0, "project_id": 0, "account_id": 0, "widget_type": "teamboard", "group1": "user", "group2": "stage", "title": "Test" }
                            },
                            {
                                "width": "auto",
                                // "widget": { "type": "issue", "grouping": ["user", "stage"], "groupWithin": false, "defaultFilters": { "work_space_id": null, "project_id": null, "account_id": null }, "filters": {}, "work_timing_id": 0, "work_space_id": 0, "project_id": 0, "account_id": 0, "widget_type": "teamboard", "group1": "user", "group2": "stage", "title": "Test" },
                                "rows": [
                                    {
                                        "height": "55vh",
                                        "id":'1',
                                        "columns": [
                                            {
                                                "width": "auto",
                                                "widget": { "type": "issue", "grouping": ["user", "stage"], "groupWithin": false, "defaultFilters": { "work_space_id": null, "project_id": null, "account_id": null }, "filters": {}, "work_timing_id": 0, "work_space_id": 0, "project_id": 0, "account_id": 0, "widget_type": "teamboard", "group1": "user", "group2": "stage", "title": "Test" },
                                            },
                                        ]
                                    },
                                    {
                                        "height": "29vh",
                                        "id":'2',
                                        "columns": [
                                            {
                                                "width": "auto",
                                                "widget": { "type": "issue", "grouping": ["user", "stage"], "groupWithin": false, "defaultFilters": { "work_space_id": null, "project_id": null, "account_id": null }, "filters": {}, "work_timing_id": 0, "work_space_id": 0, "project_id": 0, "account_id": 0, "widget_type": "teamboard", "group1": "user", "group2": "stage", "title": "Test" },
                                            }
                                        ]
                                    }
                                ]

                            },
                            {
                                "width": "325px",
                                "widget": { "type": "issue", "grouping": ["user", "stage"], "groupWithin": true, "defaultFilters": { "work_space_id": null, "project_id": null, "account_id": null }, "filters": {}, "work_timing_id": 0, "work_space_id": 0, "project_id": 0, "account_id": 0, "widget_type": "teamboard", "group1": "user", "group2": "stage", "title": "Test" }
                            }

                        ]
                    }
                ]
            }
        }
    },
    created() {
        console.log('Haouat', this.layout)
        if (this.layout) {
            this.layouts = this.layout
        }
    },
    methods: {
        createColumn($row) {
            alert('second')
            $row.columns.push({ "width": "50vw" })
        },
        getColumnLayout($column) {
            let $gridColumns = 'grid-template-columns: '

            for (let column of $column.columns) {
                console.log(column)
                $gridColumns = $gridColumns + ` ${column.width} `
            }
            $gridColumns = $gridColumns + `; height: ${$column.height}`;

            return $gridColumns;

        },
        getColumnStyle($column) {
            return `width:${$column.width} important!`
        }
    }
}
</script>
<style>
.grid-container-child {
    display: grid;
    background-color: #e3dee3;
    padding: 1px;
    height: 85vh;
}

.grid-item-child {
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px dashed rgba(0, 0, 0, 0.8); */
    padding: 5px;
    /* text-align: center; */
    overflow: auto;
}
</style>