<template>
  <v-card flat dense class="pa-0 ma-0 rounded" :elevation="0">
    <v-card-title class="pl-0 pa-0 ma-0" dense>
      <span style="font-size: 16px; font-weight: 600; color: #272727">
        {{ forumSelected.name }}
        <v-icon
          size="x-small"
          id="forum-menu-activators"
          class="mdi mdi-arrow-down-drop-circle"
        />
      </span>
    </v-card-title>
    <v-card-text class="pa-0 ma-0">
      <v-row no-gutters dense>
        <v-col cols="12" class="pa-0 ma-0">
          <div ref="commentsContainer">
            <!-- <div v-if="showTextArea"> -->

            <!-- <QuillEditor :data="mentionValues" :content="comment" :showReadOnly="showReadOnly"
                editorStyle="'min-height:150px'" @inputContent="($event) => {
                  comment = $event;
                }" />
            </div> -->
            <TextArea
              autofocus
              v-if="showTextArea"
              :data="comment"
              :showReadOnly="showReadOnly"
              :editorStyle="'min-height:150px'"
              @textData="
                ($event) => {
                  comment = $event;
                }
              "
            ></TextArea>
          </div>
          <TextFieldComponent
            v-if="!showTextArea"
            @focus="
              () => {
                showTextArea = true;
                showSaveButton;
              }
            "
            @input="showSaveButton"
            class="pt-2"
            placeholder="Add a commment"
            v-model="comment"
          />
        </v-col>
        <v-col cols="12" class="pt-1" v-if="showTextArea">
          <v-btn
            v-if="comment && comment.length > 0"
            class="mr-6 ml-3 save"
            type="submit"
            @click="saveTheComments()"
            >Save</v-btn
          >
          <v-btn
            v-if="comment && comment.length > 0"
            @click="cancel()"
            class="mr-6 cancel"
            >Cancel</v-btn
          >
          <uploadFiles
            v-if="showTextArea && comment && comment.length > 0"
            @documentsData="documentsData($event)"
            :command="true"
            keyType="IS"
            :keyId="issueId"
          />
        </v-col>
      </v-row>
      <v-row
        style="margin-left: 0%; margin: 1%"
        v-if="attachmentData && attachmentData.length > 0"
      >
        <v-cols v-for="(file, index) in attachmentData" :key="index">
          <v-chip
            class="selectedChip"
            :close="true"
            closable
            close-icon="mdi-close-circle"
            close-transition="scale-transition"
            @click="remove(index)"
          >
            <span @click="downloadDoc(file)">{{ file.doc_name }}</span>
          </v-chip>
        </v-cols>
      </v-row>
      <v-row
        v-for="item in filteredData"
        :key="item"
        style="min-height: 35px; overflow-y: auto"
      >
        <v-col
          cols="12"
          class="pa-0 ma-0"
          v-if="item.action == 'comments' || item.action == 'comment'"
        >
          <div class="pb-0 ml-5" style="display: flex; justify-content: space-between">
            <span class="d-flex">
              <span>
                <CustomAvatar2
                  :size="'small'"
                  :fName="item.first_name"
                  :lName="item.last_name"
                  :imgUrl="item.n_image_url ? item.n_image_url : null"
                  :theme="item.theme"
                ></CustomAvatar2>
              </span>
              <span class="commentUsername">
                {{ getUserName(item) }}
              </span>
            </span>
            <span class="commentFromNow mr-5">
              {{
                item.modified
                  ? dayjs().diff(dayjs(item.modified), "hour") < 24
                    ? dayjs().diff(dayjs(item.modified), "hour") < 0
                      ? "just now"
                      : dayjs(item.modified).fromNow()
                    : dayjs().diff(dayjs(item.modified), "hour") < 48
                    ? "yesterday"
                    : dayjs(item.modified).format("MMM-DD-YY,h:mma")
                  : null
              }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="pa-0 ma-0"
          v-if="item.action != 'comments' && item.action != 'comment'"
        >
          <div class="pb-0 ml-5" style="display: flex">
            <span>
              <CustomAvatar2
                :size="'small'"
                :fName="item.first_name"
                :lName="item.last_name"
                :imgUrl="item.n_image_url ? item.n_image_url : null"
                :theme="item.theme"
              ></CustomAvatar2>
            </span>

            <span
              v-if="
                (item.action == 'clarification') |
                  (item.action == 'followup') |
                  (item.action == 'itfollowup') |
                  (item.action == 'hold')
              "
              class="commentUsername"
              v-html="item.description"
            ></span>
            <span
              v-else-if="item.action == 'activity'"
              v-html="item.description"
              class="commentUsername"
            >
            </span>
            <span v-else class="commentUsername">
              {{ item.description }}
            </span>
            <span class="commentFromNow">
              {{ 
                item.modified
                  ? dayjs().diff(dayjs(item.modified), "hour") < 24
                    ? dayjs().diff(dayjs(item.modified), "hour") < 0
                      ? "just now"
                      : dayjs(item.modified).fromNow()
                    : dayjs().diff(dayjs(item.modified), "hour") < 48
                    ? "yesterday"
                    : dayjs(item.modified).format("MMM-DD-YY,h:mma")
                  : null}}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          v-if="
            (item.action == 'comments' || item.action == 'comment') &&
            !item.showEditor &&
            item.description != null
          "
        >
          <div class="comment bottom" ref="zoomImage">
            <!-- <v-icon @click="zoomImage">mdi-plus</v-icon> -->
            <v-card-text
              @click="initializeZoom"
              v-html="item.description"
              class="commentDescription pr-2 pt-0"
            >
            </v-card-text>
          </div>
        </v-col>
      </v-row>
      <MenuOptions
        activator="#forum-menu-activators"
        :id="issueId"
        :options="forumOptions"
        @menu-selected="menuSelected"
        :showAvatar="false"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import lodash from "lodash";
import MenuOptions from "../../menus/MenuOptions.vue";
import dayjs from "dayjs";
// import { QuillEditor } from '@tiburadev/vue-comp';
import TextArea from "@/components/ui/TextArea.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import ForumService from "@/api/service/Forum/ForumService";
import documentService from "@/api/service/Document/DocumentService";
import uploadFiles from "@/components/uploadFiles.vue";
import IssueService from "@/api/service/Issue/IssueService";
import CustomAvatar2 from "../../components/ui/CustomAvatar2.vue";
import UserService from "@/api/service/UserService";
import mediumZoom from "medium-zoom";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
export default {
  props: ["issueId", "newComment"],
  components: {
    CustomAvatar2,
    // QuillEditor,
    // Avatar,
    MenuOptions,
    uploadFiles,
    TextFieldComponent,
    TextArea,
  },
  data() {
    return {
      showTextArea: false,
      mentionValues: "",
      comment: "",
      forumSelected: { id: "comments", name: "Comments" },
      showButton: false,
      commentData: [],
      userData: {},
      showViewAll: false,
      attachmentId: [],
      attachmentData: [],
      forumOptions: [
        { id: "comments", name: "Comments" },
        { id: "views", name: "Views" },
        { id: "follow", name: "Followers" },
        { id: "share", name: "Shares" },
        { id: "activity", name: "Activities" },
        { id: "clarification", name: "Clarifications" },
        { id: "followup", name: "Follow Up" },
        { id: "itfollowup", name: "IT Support Follow Up" },
        { id: "hold", name: "Hold" },
        { id: "all", name: "All" },
      ],
      displayData: [
        {
          id: 1,
          showEditor: false,
          modified: new Date(),
          description: "Testing for the display",
          users: {
            first_name: "Karpaga",
            last_name: "Moorthy",
            username: "KarpagaMoorthy",
            image_profile_id: null,
            theme: "blue",
          },
        },
      ],
    };
  },
  computed: {
    getReloadForums() {
      return this.$store.getters.getReloadForums;
    },
    filteredData() {
      if (this.forumSelected.id == "all") {
        return this.commentData;
      }
      return this.commentData.filter((obj) => {
        if (this.forumSelected.id == "follow") {
          return obj.action == "follow" || obj.action == "unfollow";
        } else {
          return obj.action == this.forumSelected.id;
        }
      });
    },
  },
  watch: {
    newComment: {
      deep: true,
      handler() {
        // alert(JSON.stringify(this.newComment,null,4))
        this.commentData.splice(0, 0, this.newComment);
        this.isNewViewForUser();
      },
    },
    comment() {
      this.showButton;
    },
    issueId() {
      this.getForumData();
    },
    getReloadForums() {
      this.getForumData();
    },
  },
  created() {
    this.allUserData();
    this.userData = JSON.parse(localStorage.getItem("user_details"));
    if (this.issueId) {
      this.getForumData();
      this.initializeZoom();
    }
  },
  beforeMount() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    // this.initializeZoom();
  },
  methods: {
    async initializeZoom() {
      await this.getForumData();
      const commentsContainer = document.querySelectorAll(".commentDescription img");
      // alert('hi')
      // console.log(commentsContainer, 'bdbvjbvbvb')
      if (commentsContainer && commentsContainer.length > 0) {
        mediumZoom(commentsContainer);
      }
    },
    dayjs,
    getUserName($item) {
      return $item.first_name && $item.last_name
        ? `${$item.first_name} ${$item.last_name}`
        : $item.first_name;
    },
    async updateComment($item) {
      let $obj = {};
      $obj.id = $item.id;
      $obj.description = $item.description;
      await ForumService.update($obj);
      $item.showEditor = !$item.showEditor;
    },
    getAvatar() {
      let first_name =
        this.issueData && this.issueData.first_Name ? this.issueData.first_Name[0] : "";
      let last_name =
        this.issueData && this.issueData.last_name ? this.issueData.last_name[0] : "";
      return first_name + last_name;
    },

    formatDate(dateString, type) {
      if (!dateString) {
        return "";
      } else if (type == "date") {
        const date = new Date(dateString);
        return date.toLocaleDateString();
      } else {
        const date = new Date(dateString);
        // alert(date)
        return date.toLocaleString();
      }
    },
    menuSelected($item) {
      this.forumSelected = $item;
    },

    downloadDoc(item) {
      documentService.download({ id: item.id }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("name", "downloadLink");
        link.setAttribute("download", response.headers["x-suggested-filename"]); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },

    documentsData($event) {
      this.attachmentId.push($event.id);
      this.getAttachementData();
    },

    remove(index) {
      if (index > -1) {
        this.attachmentId.splice(index, 1);
      }
    },

    async getAttachementData() {
      try {
        let res = await documentService.getAllDocuments({
          doc_id: JSON.stringify(this.attachmentId),
        });
        this.attachmentData = res;
      } catch (e) {
        console.log(e);
      }
    },
    ViewLess() {
      if (!this.showViewAll) {
        this.showViewAll = false;
        this.commentData = this.backupComment.slice(0, 3);
        this.showViewAll = true;
      }
    },

    getAvatarName(value) {
      return value.first_name &&
        value.first_name.length > 1 &&
        value.last_name &&
        value.last_name.length > 1
        ? `${value.first_name.substr(0, 1)}${value.last_name.substr(0, 1)}`
        : value.first_name.substr(0, 2);
    },

    async allUserData() {
      let res = await UserService.findAll();
      let users = [];
      res.forEach((obj) => {
        users.push({ id: obj.id, value: obj.name });
      });
      this.mentionValues = users;
      console.log("Allusers", users);
    },

    showSaveButton() {
      this.showButton = this.comment.length > 0 ? true : false;
    },
    cancel() {
      this.comment = null;
      this.getForumData();
      this.showTextArea = false;
      console.log(this.issueStage, "cdhbcjh");
    },
    cancel1() {
      this.getForumData();
      this.showTextArea = false;
    },
    async saveTheComments() {
      if (this.attachmentId && this.attachmentId.length > 0) {
        let res = await IssueService.findOne(this.issueId);
        res[0].attachment = JSON.parse(res[0].attachment);
        let concateData = res[0].attachment.concat(this.attachmentId);
        let obj = {
          id: res[0].id,
          attachment: JSON.stringify(concateData),
        };
        await IssueService.update(obj);
        this.attachmentData = [];
        this.attachmentId = [];
      }
      let data = {
        key_type: "issue",
        key_id: this.issueId,
        action: "comments",
        description: this.comment,
      };
      await ForumService.create(data);

      this.comment = "";
      this.showTextArea = false;
      this.showButton = false;
      this.getForumData();
      this.$emit("uploadComment");
    },
    async getForumData() {
      // alert("hi")
      try {
        let res = await ForumService.getForumList({
          key_id: this.issueId,
          key_type: "issue",
        });
        await res.sort(function (a, b) {
          return new Date(b.created) > new Date(a.created);
        });
        let $index = 0;
        await res.forEach((e) => {
          e.showEditor = false;
          if (e.created_by == this.userData.id && $index == 0) {
            e.showEditIcon = true;
            $index++;
          } else {
            e.showEditIcon = false;
          }
          // console.log(e, this.userData, "8888888888888")
        });
        this.commentData = res;
        // console.log(this.commentData, "hhhkkkkaaa");
        this.isNewViewForUser();
        this.$forceUpdate();
        // alert(this.commentData.modified)
      } catch (e) {
        this.commentData = [];
      }
    },
    getActionData($type) {
      let $filter = this.commentData.filter((item) => {
        return (
          item.action &&
          item.action.toLowerCase() == $type &&
          item.created_by == this.userData.id
        );
      });
      return $filter;
    },
    getActionData2($type) {
      let $filter = this.commentData.filter((item) => {
        return item.action && item.action.toLowerCase() == $type;
      });
      return $filter;
    },
    async isNewViewForUser() {
      let $filter = this.getActionData2("views");
      let checkViewofUser = this.getActionData("views");
      // console.log($filter,"viewer dataaaaaaaa")
      // let $filter = this.commentData.filter({"action":"views","user_id":this.userData.id})
      if (checkViewofUser.length == 0) {
        let data = {
          key_type: "issue",
          key_id: this.issueId,
          action: "views",
          description: `${this.userData.username} viewed the issue `,
        };
        console.log(data);
        let $response = await ForumService.create(data);
        $filter.splice(0, 0, $response.data.data[0]);
        this.commentData.splice(0, 0, $response.data.data[0]);
      }
      let $filter2 = this.getActionData("follow");
      let $filter21 = this.getActionData("unfollow");
      let $filter3 = this.getActionData("share");
      $filter2 = $filter2.concat($filter21);
      $filter2 = lodash.sortBy($filter2, "id");
      let $totalFollowers = [];
      let $users = lodash.map($filter2, "created_by");
      $users = lodash.uniq($users);
      for (let $user of $users) {
        let $statusData = lodash.filter($filter2, { created_by: $user });

        if ($statusData.length > 0) {
          //alert(JSON.stringify($statusData[$statusData.length-1],null,4))
          if ($statusData[$statusData.length - 1].action == "follow") {
            console.log(this.getUserName($statusData[$statusData.length - 1]));
            let $obj = {
              id: $user,
              name: this.getUserName($statusData[$statusData.length - 1]),
              subtitle: dayjs($statusData[$statusData.length - 1].created).fromNow(),
            };
            $totalFollowers.push($obj);
          }
        }
      }

      console.log(JSON.stringify($filter2, null, 4));
      let $totalViews = $filter.map((obj) => {
        return {
          id: obj.created_by,
          name: this.getUserName(obj),
          subtitle: dayjs(obj.created).fromNow(),
        };
      });

      /*let $totalFollowers= $filter2.map(obj=>{
          return {"id":obj.created_by,"name": this.getUserName(obj),"subtitle":dayjs(obj.created).fromNow()}
      })*/

      let $totalShares = $filter3.map((obj) => {
        return {
          id: obj.created_by,
          name: this.getUserName(obj),
          subtitle: dayjs(obj.created).fromNow(),
        };
      });
      this.$emit("viewsUpdated", $totalViews, $totalFollowers, $totalShares);
    },
    ViewAll() {
      let data = this.backupComment.slice(
        this.commentData.length,
        this.commentData.length + 3
      );
      this.commentData = this.commentData.concat(data);
      if (this.commentData.length > 3) {
        this.showViewAll =
          this.commentData.length == this.backupComment.length ? false : true;
      } else {
        this.showViewAll = false;
      }
    },

    handleOutsideClick(event) {
      if (
        !this.$refs.commentsContainer ||
        (!this.$refs.commentsContainer.contains(event.target) &&
          (!this.comment || this.comment.length == 0))
      ) {
        this.showTextArea = false;
      }
    },
  },
};
</script>

<style scoped>
>>> .ql-mention-list-container {
  position: absolute;
  visibility: visible;
  top: -95px !important;
  left: 41.4375px;
  overflow: auto;
  height: 200px;
}

.save {
  background-color: #3c5aaa;
  color: #fff;
  width: 90px;
}

.cancel {
  background-color: #e1e1e1;
  width: 90px;
}

.modifiedDate {
  margin-left: 10px;
  font-size: var(--ds-font-size-100, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--ds-font-lineHeight-200, 1.42857142857143);
  -ms-overflow-style: -ms-autohiding-scrollbar;
  text-decoration-skip-ink: auto;
}

.editBtn {
  margin-left: 50px;
}

.commentUsername {
  margin-left: 10px;
  font-size: 12px;
  color: var(--ds-text-subtle, #42526e);
  font-weight: var(--ds-font-weight-medium, 500);
  overflow-wrap: break-word;
  width: 81%;
}

.commentFromNow {
  font-size: 9px;
  color: #42526e;
  font-weight: 600;
  overflow-wrap: break-word;
}

.commentCardActions {
  margin-top: -15px;
  margin-left: 10px;
}

.view-all {
  text-align: center;
  background: #dddbdb70;
  font-weight: bold;
  color: #4f63ff;
  border-radius: 7px;
  cursor: pointer;
}

.commentDescription {
  font-size: 12px;
  line-height: 1.714;
  font-weight: normal;
  margin-top: 0.75rem;
  margin-bottom: 0px;
  letter-spacing: -0.005em;
}

>>> p {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #272727 !important;
  overflow: visible;
}

>>> p img {
  border-style: none;
  object-fit: contain;
  width: 100%;
}

div.comment {
  /* height: 60px; */
  min-width: 200px;
  float: left;
  background-color: #ececec;
  position: relative;
  color: #ccc;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  min-height: 50px;
  width: -webkit-fill-available;
}

.comment::before {
  content: "";
  width: 0px;
  height: 0px;
  border: 0.5em solid transparent;
  position: absolute;
}

.comment.bottom::before {
  left: 12px;
  top: -15px;
  border-bottom: 10px solid #ececec;
}

>>> ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

>>> ::-webkit-scrollbar-track {
  background: #e8f3f9;
}

>>> ::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e8f3f9;
}
</style>
