<template>
    <v-container fluid style="width:90vw;overflow:hidden">
        <v-card flat style="overflow: hidden;">
            <v-card-item>
                <v-card-title style="display: flex;justify-content: space-between;">
                    <div>
                        Resource Utilization Dashboard {{timelines.length}}
                    </div>
                    <div>{{startTime}} - {{endTime}}</div>
                    <div style="display: flex;gap: 15px;">
                        <AutoComplete style="width: 250px;" :items="workTimings" item-title="name" v-model="workTimingId"
                            item-value="id" placeholder="Select Worktiming" @update:modelValue="getRUTData" />

                        <TextFieldComponent style="width: 250px;" density="compact" placeholder="Start date" type="date"
                            v-model="startDate" @update:modelValue="getRUTData" />
                    </div>

                </v-card-title>
            </v-card-item>
            <v-card-text>
                {{timesheets}}
                {{user}}
                <v-table fixed-header height="70vh">
                    <thead>
                        <tr>
                            <th class="text-left" width="10">Resource</th>

                            <th width="15px" class="timelines pa-1 text-caption" v-for="timeInterval in timelines"
                                v-bind:key="timeInterval">
                                {{ timeInterval }}
                            </th>
                            <th class="text-left fixed-hours" width="25px">Hours</th>
                            <th class="text-left fixed-production" width="25px">Production</th>
                            <th class="text-left fixed-break" width="25px">Break</th>
                            <th class="text-left fixed-meeting" width="25px">Meeting</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" v-bind:key="user">
                            <template v-if="user && user.first_name">
                                <td style="text-transform: capitalize;">
                                    {{ user.first_name }}
                                </td>
                                <template v-for="timesheet in getTimeSheet(user.resource_id)" v-bind:key="timesheet">
                                    <td class="bg-yellow" :colspan="timesheet.intervals">
                                        
                                    </td>
                                    <td class="bg-green" :colspan="timesheet.event">

                                        <v-icon>mdi-briefcase-outline
                                            <!-- <v-tooltip activator="parent" location="start">{{ timesheet.issue_name }}
                                            </v-tooltip> -->
                                        </v-icon>
                                    </td>
                                </template>
                            </template>
                             <td class="bg-yellow" :colspan="(timelines && timelines.length) - user.finishingAt -2 ">
                                  &nbsp;
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import moment from 'moment-timezone'
import RutService from '@/api/service/RUT/RutService'
import WorkTimingService from '@/api/service/WorkTiming/WorkTimingService'
import AutoComplete from '@/components/ui/AutoComplete.vue'
import TextFieldComponent from "../../../components/TextFieldComponent.vue";
export default {
    components: {
        AutoComplete,
        TextFieldComponent
    },
    data() {
        return {
            interval: 5,
            intervalType: "minutes",
            workTimingId: null,
            startDate: new Date(),
            endDate: "",
            startTime: "00:00",
            endTime: "24:00",
            timelines: [],
            timesheets: [],
            workTimings: []
        }
    },
    created() {
        this.getRUTData({})
        this.getWorkTimings()
    },
    mounted() {
    },
    watch:{
        workTimingId() {
            this.setTimingInterval(this.workTimingId)
        }
    },
    methods: {
        moment,
        async getRUTData() {
            let $data = {}
            if(this.workTimingId) {
                $data.work_timing_id= this.workTimingId
            }
            if(this.startDate) {
                $data.entry_date = this.startDate
            }
            let $res = await RutService.getRUT($data);
            this.timesheets = $res && $res.length > 0 ? $res : []
            this.getIntervals()
            this.getUsers()
            this.mapIntervals()
        },
        setTimingInterval() {
           //alert(this.workTimingId)
            let $filteredData = this.workTimings.filter(obj=>{
                return obj.id == this.workTimingId
            })
            $filteredData = $filteredData[0]
            this.startTime =  moment(moment().format('YYYY-MM-DD') + ' ' + $filteredData.start_time, 'YYYY-MM-DD HH:mm').add(-.5,'hours').format('HH:mm')
            this.endTime =  moment(moment().format('YYYY-MM-DD') + ' ' + $filteredData.end_time, 'YYYY-MM-DD HH:mm').add(.5,'hours').format('HH:mm')
            this.getIntervals()
            this.getUsers()
            this.mapIntervals()
            
            
        },
        mapIntervals() {
            for (let $user of this.users) {
                let $data = this.timesheets.filter(obj => {
                    return obj.resource_id == $user.resource_id
                })
                let $timeLineIndex = 0
                for (let $time of $data) {
                    let $startTime = moment(moment().format('YYYY-MM-DD') + ' ' + this.timelines[$timeLineIndex], 'YYYY-MM-DD HH:mm')
                    let $endTime = moment(moment().format('YYYY-MM-DD') + ' ' + $time.start_date, 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm')
                    let $duration = moment.duration($endTime.diff($startTime))
                    let $intervals = $duration.asMinutes() / this.interval
                    $time.intervals = $intervals
                    let $startTime2 = moment(moment().format('YYYY-MM-DD') + ' ' + $time.start_date, 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm')
                    let $endTime2 = moment(moment().format('YYYY-MM-DD') + ' ' + $time.end_date, 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm')
                    let $duration2 = moment.duration($endTime2.diff($startTime2))
                    let $intervals2 = $duration2.asMinutes() / this.interval
                    $time.event = $intervals2
                    $time.timeLineIndex =  parseInt($timeLineIndex + $intervals + $intervals2)
                    $timeLineIndex = parseInt($timeLineIndex + $intervals + $intervals2)

                    //alert($intervals2)
                }
                $user.finishingAt = $timeLineIndex
            }
        },

        async getWorkTimings() {
            let res = await WorkTimingService.findAll()
            this.workTimings = res
        },
       
      
        getTimeSheet($resourceId) {
            let $data = this.timesheets.filter(obj => {
                return obj.resource_id == $resourceId
            })
            return $data
        },
        getUsers() {
            let $users = []
            for (let $time of this.timesheets) {
                let $findIndex = $users.findIndex(x => x.resource_id == $time.resource_id);
                if ($findIndex < 0) {
                    $users.push({ "resource_id": $time.resource_id, "first_name": $time.first_name, "last_name": $time.last_name })
                }
            }
            this.users = $users;
        },
        getIntervals() {
            let $startTime = moment(moment().format('YYYY-MM-DD') + ' ' + this.startTime)
            let $endTime = moment(moment().format('YYYY-MM-DD') + ' ' + this.endTime)
            let $duration = moment.duration($endTime.diff($startTime))
            let $intervals = $duration.asMinutes() / this.interval
            let $startIndex = 0
            this.timelines = []
            this.timelines.push($startTime.format('HH:mm'))

            while ($startIndex < $intervals) {

                this.timelines.push($startTime.add(this.interval, 'minutes').format('HH:mm'))
                $startIndex++
            }
        }
    }
}
</script>
<style scoped>
table,
th,
td {
    border: 1px solid black;
}

>>>.v-table--fixed-header>.v-table__wrapper>table>thead {
    position: sticky;
    top: 0;
    z-index: 2;
}

>>>.v-table--fixed-header>.v-table__wrapper>table>thead>tr>th:first-child {
    z-index: 3;
}

tbody tr td {
    height: 35px;
    width: 15px;
    word-break: break-word !important;
    line-height: 1.2 !important;
}

.timelines {
    writing-mode: vertical-lr;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.fixed-hours {
    position: sticky;
    width: 30px;
    background-color: #fff;
    z-index: 2;
    right: 250px;
}

.fixed-production {
    position: sticky;
    width: 50px;
    background-color: #fff;
    z-index: 2;
    right: 150px;
}

.fixed-break {
    position: sticky;
    width: 50px;
    background-color: #fff;
    z-index: 2;
    right: 87px;
}

.fixed-meeting {
    position: sticky;
    width: 50px;
    background-color: #fff;
    z-index: 2;
    right: 0;
}

tr>th:first-child,
tr>td:first-child {
    position: sticky;
    width: 50px;
    background-color: #fff;
    z-index: 1;
    left: 0;
}

>>>::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

>>>::-webkit-scrollbar:hover {
    width: 10px;
    height: 10px;
}

>>>::-webkit-scrollbar-track {
    background: #e8f3f9;
}

>>>::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.12);
    border-radius: 8px;
}
</style>