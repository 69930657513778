<template>
    <v-card>
        <v-card-item>
            <v-card-title>
                <h3 class="pa-2" style="font-weight:normal">Filter</h3>
                <v-spacer />
                <v-btn @click="applyFilter()" class="apply-filter" variant="outlined">Apply</v-btn>
                <!-- <v-btn @click="closeFilter()" elevation="0" density="comfortable" icon="mdi-close"
                            class="x-closeIcon"></v-btn> -->
            </v-card-title>
        </v-card-item>
        <v-divider class="border-opacity-50" />
        <v-card-text>
            <v-row no-gutters dense class="mb-3">
                <!-- <v-col cols="4">
                            <v-form @submit.prevent="submit" ref="form">
                                <v-row>
                                    <v-col cols="12">
                                        <span class="ma-2 mb-2"><b>Save this Filter</b></span>

                                        <TextFieldComponent placeholder="Name" v-model="filterName"
                                            :disabled="showReadOnly" />
                                    </v-col>
                                    <v-col cols-12>
                                        <v-btn type="submit" class="save-filter" variant="outlined">Save</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col> -->
                <!-- <v-divider style="margin-left: 15px;margin-top: -16px;margin-bottom: -11px;"
                            class="border-opacity-50" vertical /> -->
                <v-col cols="7" class="ml-5">
                    <v-row v-for="item in CustomFilterData" :key="item">
                        <v-col cols="12" v-if="item.field_type == 'input'">
                            <TextFieldComponent v-model="item.defaults" :placeholder="item.label">
                            </TextFieldComponent>
                        </v-col>
                        <v-col cols="12" v-if="item.field_type == 'autoComplete'">
                            <v-autocomplete v-if="item.source_data && item.source_data.length > 1" v-model="item.defaults"
                                density="compact" :label="item.label" variant="outlined" :items="item.source_data"
                                :item-title="item.text" :item-value="item.value" :placeholder="item.label"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" v-if="item.field_type == 'enum'">
                            <v-autocomplete v-if="item.source_data && item.source_data.length > 1" v-model="item.defaults"
                                density="compact" :label="item.label" variant="outlined" :items="item.source_data"
                                :item-title="item.text" :item-value="item.value" :placeholder="item.label"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" v-if="item.field_type == 'status'">
                            <v-autocomplete v-model="item.defaults" density="compact" label="Status" variant="outlined"
                                :items="statusData" item-title="name" item-value="id" placeholder="Status"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" v-if="item.field_type == 'textArea'">
                            <v-textarea v-model="item.defaults" density="compact" class="elevation-0" variant="solo"
                                :label="item.label"></v-textarea>
                        </v-col>
                        <v-col cols="12" v-if="item.field_type == 'date'">
                            <span class="p-float-label">
                                <Calendar style="width: 100%;" :inputId="item.label" selectionMode="range"
                                    :manualInput="false"
                                    :panelStyle="{ width: '400px', zIndex: '9999999999', transformOrigin: 'center bottom', top: '170px', borderRadius: '10px' }"
                                    panelClass="calendar-container" v-model="item.defaults">
                                </Calendar>
                                <label :for="item.label">{{ item.label }}</label>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- <v-divider class="border-opacity-50 mt-0" /> -->
        </v-card-text>
    </v-card>
    <PrimeDataTable :title="'Report'"  :searchOption="true" :selectAll="true" :showDownload="true"
        :showUpload="true"  :columnData="columns"  :data="data" activity="report" />
</template>

<script>
import listModelDetailService from '@/api/service/ListModelDetail/ListModelDetailService.js'
import listModelService from '@/api/service/ListModel/ListModelService.js'
import listModelFilterService from '@/api/service/ListModelFilter/ListModelFilterService'
import listModelFilterDetailService from '@/api/service/ListModelFilterDetail/ListModelFilterDetailService'
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import lodash from "lodash";
import Calendar from 'primevue/calendar';
import commonService from '@/api/service/Common/CommonService'
import enumService from '@/api/service/Enum/EnumService'
import PrimeDataTable from "@/components/PrimeDataTable";
import ReportService from '@/api/service/Report/ReportService';

export default {
    // props: ['model', 'drawerStatus'],
    components: {
        Calendar,
        TextFieldComponent,
        PrimeDataTable
    },
    data() {
        return {
            data:[],
            columns:[],
            CustomFilterData: [],
            filterName: '',
            modelName: '',
            modelId: null,
            existFilters: [],
            nameRule: [(value) => !!value || "Name is Required."],
            drawer: false,
            statusData: [
                { id: 0, name: 'InActive' },
                { id: 1, name: 'Active' }
            ]

        }
    },
    created() {
        this.modelId =this.$route.query.model_id
        this.getListModelDetails()
        this.loadColumns()
    },
    methods: {
        async loadColumns() {
        let $model=    await listModelDetailService.findAll({ model_id:  this.modelId });
        $model = lodash.orderBy($model, ['id'], ['asc'])
        $model.forEach(e=>{
        if(e.column_required ==true){
        var data = {
          field: e.column_name,
          header:e.label,
          style: { width: "100px", color: "" },
          toolTip:e.column_name,
          sortfield: true,
        }
         this.columns.push(data)
    }
        })
        },
        async getListModelDetails() {
            let $model = await listModelService.findAll({id: this.modelId});
            this.modelId = $model.length > 0 ? $model[0].id : 0;
            if (this.modelId > 0) {
                let $modelDetails = await listModelDetailService.findAll({ model_id: this.modelId});
                $modelDetails = lodash.orderBy($modelDetails, ['sequence'], ['asc'])
                this.getDropdownData($modelDetails)
            }
        },
        async getExistFilters($id) {
            let $exist = await listModelFilterService.findAll({ modelId: $id });
            this.existFilters = $exist;
        },
       
        async getDropdownData($data) {
            let filterData = []
            for (let i = 0; i < $data.length; i++) {
                if ($data[i].filter_required == 1) {
                    let str = $data[i].label
                    $data[i].label = str.charAt(0).toUpperCase() + str.slice(1);
                    if ($data[i].field_type == "autoComplete") {
                        $data[i].source_data = await this.getValues($data[i].source, $data[i].column_name);
                        if ($data[i].source_data.length == 1) {
                            $data[i].defaults = $data[i].source_data[0][$data[i].value];
                        }
                    } else if ($data[i].field_type == "enum") {
                        $data[i].source_data = await this.getEnumValues($data[i].source);
                    }
                    filterData.push($data[i])
                }
            }
            this.CustomFilterData = JSON.parse(JSON.stringify(filterData));
        },
        async getValues($source) {
            let res = await commonService.findAll({ tableName: $source })
            return res ? res : [];
        },
        async getEnumValues(source) {
            let res = await enumService.findAll({ domain: source })
            return res ? res : [];
        },
        async applyFilter() {
          let $filters =[]
            let $obj = {}
            this.CustomFilterData.forEach(ele => {
             
                if (ele && ele.defaults) {
                    $obj[ele.column_name] = ele.data_type.includes('varchar') ? `"${ele.defaults}"` : ele.defaults
                    $filters.push(ele) 
                }
            })
        let $data = await ReportService.getFilterData({list_model:this.modelId,filter:JSON.stringify($filters) })
      this.data=$data
    
       
        },
        async getTableData(){
            let res = await commonService.findAllColumnData({ tableName: this.modelName })
           res.forEach(e=>{
            this.data.push(e.issues)
           })
            console.log( this.data,'nnnnnnn')
           
        },
        async submit() {
            let validation = await this.$refs.form.validate()
            if (!validation.valid) {
                console.log("Not valid")
            }
            else {
                let $data = {};
                $data.name = this.filterName; //this.filterName
                $data.modelId = this.modelId;
                let $filter = await listModelFilterService.create($data);
                this.CustomFilterData.forEach(async element => {
                    let $obj = {};
                    $obj.modelId = this.modelId;
                    $obj.columnName = element.column_name;
                    $obj.defaults = element.defaults;
                    $obj.modelFilterId = $filter.id;
                    await listModelFilterDetailService.create($obj);
                })
                this.applyFilter()
                // this.getExistFilters(this.modelId)
            }
        }
    }
}
</script>
<style scoped>
.apply-filter {
    font-size: 15px;
    position: absolute;
    background: #3C5AAA;
    color: white;
    top: 10px;
    right: 60px;
    cursor: pointer;
}

.save-filter {
    font-size: 15px;
    color: white;
    width: 100%;
    text-align: center;
    background: #3C5AAA;

}
</style>