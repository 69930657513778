
<template>
  <div class="main">
    <v-card class="card">
      <div class="text-center progress" v-if="value < 100">
        <v-progress-circular class="mt-7" :rotate="360" :size="75" :width="15" :model-value="value" color="green">
          {{ }}
        </v-progress-circular>
        <v-card-text v-if="!linkExpired" style="text-align: center;">Please wait while we verify your account.
        </v-card-text>
        <v-card-text v-if="linkExpired" style="text-align: center;color:red;">Please Contact Your Administrator,your link
          is Expired.
        </v-card-text>
      </div>

      <card v-else class=" mt-3 checkbox Verified ">
        <v-icon class="icon" size="75">mdi-check-circle</v-icon>
        <v-card-title class="pt-0 textM">Account Verified</v-card-title>
        <v-card-text class="pt-7" style="text-align: center;">Your account has been successfully verified</v-card-text>
      </card>
    </v-card>
  </div>
</template>

<script>
import UserService from '@/api/service/UserService';
export default {
  data() {
    return {
      linkExpired: false,
      interval: {},
      value: 25,
      router: null
    }
  },
  async mounted() {
    let data = this.$route.query.authorize
    this.value = 50
    await UserService.getVerifyAccount({ authorize: data }).then(res => {
      this.value = 75
      if (res.message == 'success') {
        this.value = 100
        setTimeout(() => {
          window.location = `${process.env.VUE_APP_LOGIN_URL}resetpassword?uid=${res.uid}&redirectUrl=${process.env.VUE_APP_REDIRECT_URL}&appId=${process.env.VUE_APP_APP_ID}`
        }, 1500)
      }
      else {
        this.linkExpired = true
      }
    })
  },
  methods: {

  }
}
</script>

<style scoped>
.main {
  background: #3c5aaa;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  background: #272727;
}

.card2 {
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.textM {
  text-align: center;
  position: relative;
  top: 35px;

}

.icon {
  position: relative;
  top: 20px;
  left: 40%;
}

>>>.v-progress-circular {
  margin-left: 1rem;
}

.progress {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 200px;
}

.Verified {
  width: 350px;
  height: 200px;
  color: green;
}

/* >>>.v-card .v-card-title {
    line-height: 7rem;
} */
</style>




