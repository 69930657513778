<template>
  <v-card elevation="0" style="border-radius:10px">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>
          QA Task
          <v-btn style="float: right;" @click="close()" elevation="0" density="comfortable" icon="mdi-close"></v-btn>
        </v-card-title>
      </v-card-item>


      <v-col cols="12" class="pl-5 pr-2">
        <v-card-text>
          <v-row>
            <v-radio-group inline v-model="formData.pass">
              <v-radio label="Pass" value="1" color="primary" :disabled="showReadOnly"></v-radio>
              <v-radio label="Fail" value="0" color="primary" :disabled="showReadOnly"></v-radio>
            </v-radio-group>
          </v-row>

          <v-row v-if="formData.pass === '1' || formData.pass === '0'">
            <v-col cols="6" class="dropdown" style="margin-bottom: -14px;">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Date
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
              </v-label>
              <DatePicker :data="formData.created" :placeholder="'Date'" :readonly="true" showReadOnly />
            </v-col>
            <v-col cols="6" class="dropdown" v-if="formData.pass === '1' || formData.pass === '0'">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">User
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
              </v-label>
              <AutoComplete :placeholder="'User For This Issue'" :items="users" :item-title="'username'"
                :disabled="showReadOnly" :item-value="'id'" v-model="formData.user_id" :rules="userRule" :v-if="true"
               />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" v-if="formData.pass === '0'">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Impact [E]
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <EnumAutoCompleteVue class="dropdown" density="compact" v-model="formData.impact"
                :selected="formData.impact" :domain="'IMPACT'" placeholder="Impact" @changeEnumData="changeImpact($event)"
                :rules="impactRule" :showReadOnly="showReadOnly" />
            </v-col>

            <v-col cols="6" v-if="formData.pass === '0'">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Severity [E]
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <EnumAutoCompleteVue class="dropdown" density="compact" v-model="formData.severity"
                :selected="formData.severity" :domain="'SEVERITY'" placeholder="Severity"
                @changeEnumData="changeSeverty($event)" :rules="severityRule" :showReadOnly="showReadOnly" />
            </v-col>
            <v-col cols="6" v-if="formData.pass === '0'">
              <!-- <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Error Code
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <AutoComplete :items="errorCodeData" :placeholder="'Error Code'" :item-title="'name'"
                :disabled="showReadOnly" :item-value="'id'" @update:modelValue="selectionChange" v-model="formData.error_code_id" />
           
            -->
             
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Error Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <AutoComplete :items="errorCodeData" item-value="id" item-title="name" density="compact"
              placeholder="Error Code"  v-model="formData.error_code_id"
              @update:modelValue="selectionChange" :disabled="showReadOnly" />
              </v-col>
              <v-col cols="6" v-if="formData.pass === '0'" style="padding-top: 6%;">
                        <v-radio-group :disabled="showReadOnly" v-model="formData.qa_external" inline>
                            <v-radio color="primary" label="Internal" :value="0"></v-radio>
                            <v-radio color="primary" label="External" :value="1"></v-radio>
                        </v-radio-group>
              </v-col>

          </v-row>
          <v-row style="padding-bottom: 15px;">
            <v-col cols="6" v-if="formData.pass === '0'">
            <v-chip class="selectedChip" label closable v-for="item in chipData" :key="item" :close="false"
              close-icon="mdi-close-circle"  :disabled="showReadOnly" close-transition="scale-transition" @click:close="cancelError(item)">
              {{ item.name }}
            </v-chip>
            </v-col>
          </v-row>
          <v-row v-if="formData.pass === '1' || formData.pass === '0'">
            <v-col cols="2" class="">
              <strong class="">Attachment</strong>
            </v-col>
            <v-col class="pa-0 ml-0 pl-0 pt-3" v-if="!showReadOnly">
              <uploadFiles @documentsData="documentsData($event)" :type="'icon'" />
            </v-col>
          </v-row>

          <v-row style="margin-left: 0%;"
            v-if="(formData.pass === '1' || formData.pass === '0') && attachmentId.length > 0 && attachmentData.length > 0">
            <v-cols v-for="(file, index) in attachmentData" :key="index">
              <v-chip class="selectedChip" :close="true" closable close-icon="mdi-close-circle" :disabled="showReadOnly"
                close-transition="scale-transition" @click.stop="remove(index)"
                v-if="formData.pass === '1' || formData.pass === '0'">
                <span @click.stop="downloadDoc(file)">{{ file.doc_name }}</span>
              </v-chip>
            </v-cols>
          </v-row>

          <v-row v-if="formData.pass === '0'">
            <v-col cols="12 mb-0">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Root Cause</v-label>
              <TextArea class="pb-0" editorStyle="height: 120px" :data="formData.cause" :showReadOnly="showReadOnly"
                @textData="changeCause($event)"></TextArea>
            </v-col>
          </v-row>

          <v-row v-if="formData.pass === '1' || formData.pass === '0'">
            <v-col cols="12">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">QC Remarks</v-label>
              <TextArea class="pb-0" editorStyle="height: 120px" :data="formData.description" :showReadOnly="showReadOnly"
                @textData="changeDescription($event)"></TextArea>
            </v-col>
          </v-row>

        </v-card-text>
      </v-col>
      <div v-if="viewType != true">
        <v-card-action class="mr-3" v-if="formData.pass === '1' || formData.pass === '0'">
          <v-row justify="end" class="dialogBottom">
            <v-btn style=" --v-btn-height: 35px;" class="mr-2 cancel" @click="close()">Cancel</v-btn>
            <v-btn style=" --v-btn-height: 35px;" class="save" type="submit">Save</v-btn>
          </v-row>
        </v-card-action>
      </div>
    </v-form>
  </v-card>
</template>
    
<script>
import uploadFiles from '@/components/uploadFiles.vue';
import DatePicker from "@/components/ui/DatePicker.vue";
import TextArea from "@/components/ui/TextArea.vue";
import documentService from "@/api/service/Document/DocumentService";
import QaService from '@/api/service/Qa/QaService';
import EnumAutoCompleteVue from '@/components/others/EnumAutoComplete.vue';
import AutoComplete from "@/components/ui/AutoComplete.vue";
import UserService from '@/api/service/UserService';
import ErrorCodeService from "@/api/service/ErrorCode/ErrorCodeService";
import commonService from "@/common.service";


export default {
  props: ['timeSheetDefaults', 'viewType', 'id','issueId'],
  components: {
    uploadFiles,
    DatePicker,
    TextArea,
    EnumAutoCompleteVue,
    AutoComplete,
  },
  data() {
    return {
      impactRule: [(value) => !!value || "Impact is Required"],
      severityRule: [(value) => !!value || "Severity is Required"],
      userRule: [(value) => !!value || "User is Required"],
      attachmentData: [],
      showReadOnly: false,
      attachmentId: [],
      document_id: [],
      pass: 1,
      formData: {
      },
      tempId:null,
      users: [],
      QAData: {},
      errorCodeData: [],
      selectedErrorCodeId: [],
      chipData:[],
      backupErrorCodeData: []
      // impact: "taskType",
      // severity:"severity" ,

    }
  },
  watch: {
    timeSheetDefaults() {
      this.formData = this.getDefaults()
    }

  },
  created() {
    if (this.id) {
      this.getById(this.id)
    }

    this.formData = this.getDefaults();
    this.getUser()
    this.getErrorCodeData()
    // if(this.timeSheetDefaults.id){
    //   this.getById(this.timeSheetDefaults.id)
    // }
    if (this.viewType) {
      this.showReadOnly = this.viewType
    }
    this.formData.qa_external =0
  },
  methods: {

    selectionChange($value) {
      this.selectedErrorCodeId.push($value)
      this.setTheData()
    },

    setTheData() {
      let res = commonService.getObjectSliceMethod(JSON.stringify(this.backupErrorCodeData), this.selectedErrorCodeId, 'id')
      let chipData = commonService.getMultipleChip(this.backupErrorCodeData, this.selectedErrorCodeId, 'id')
      this.errorCodeData = [...res]
      this.chipData =[...chipData]
      this.formData.error_code_id =''
    },
      async getErrorCodeData() {
      try {
        let res = await ErrorCodeService.findAll()
        this.errorCodeData = res && res.length > 0 ? res : []
        this.backupErrorCodeData = JSON.parse(JSON.stringify(this.errorCodeData))

      } catch (e) {
        this.errorCodeData = []
        this.backupErrorCodeData = []
        console.log(e)
      }
    },
    
    cancelError(data) {
      let index = this.selectedErrorCodeId.findIndex(k => k == data.id)
      if (index > -1) {
        this.selectedErrorCodeId.splice(index, 1)
      }
      this.setTheData()

    },
    getDefaults() {
      return {
        "created": new Date(),
        "issue_id": this.timeSheetDefaults.issueId,
        "user_id": this.timeSheetDefaults.user_id,
        "pass": this.timeSheetDefaults.pass ? this.timeSheetDefaults.pass : null,
        "severity": this.timeSheetDefaults.severity ? this.timeSheetDefaults.severity : null,
        "cause": this.timeSheetDefaults.cause ? this.timeSheetDefaults.cause : null,
        "impact": this.timeSheetDefaults.impact ? this.timeSheetDefaults.impact : null,
        // "description":this.timeSheetDefaults.description ? this.timeSheetDefaults.description : null,
        "qa_user_id": this.timeSheetDefaults.qa_user_id ? this.timeSheetDefaults.qa_user_id : null,
      }
    },
    async getUser() {
      let $res = await UserService.findAll()
      this.users = $res && $res.length>0 ? $res : []
      this.$forceUpdate()
      this.formData.userName = $res && $res.length > 0 ? $res[0].name : ''
    },
    async getById(id) {

      let res = await QaService.findOne({ id: id });
      this.QAData = res[0];
      this.formData.doc_id = JSON.parse(this.QAData.doc_id)
      this.attachmentId.push(this.formData.doc_id)
      this.formData.description = this.QAData.description
      this.selectedErrorCodeId =this.formData && this.formData.error_code_id?JSON.parse(this.formData.error_code_id):[]
      this.setTheData()
      this.getAttachementData()
    },

    async save() {
      let validation = await this.$refs.form.validate()
      let status = this.$store.getters.getQaUpdate;
      this.formData.error_code_id =this.formData && this.formData.error_code_id?JSON.stringify(this.formData.error_code_id):JSON.stringify([])
      if(this.formData && this.formData.pass==0){
        this.$emit('updateData',{id:this.issueId,stage:'errorlog'},'stage')
      }
      if (!validation.valid) {

        console.log(validation.valid)
      }

      else {
        if (this.timeSheetDefaults.id) {
          // alert('old')
          // this.formData.doc_id=
          this.formData.id = this.timeSheetDefaults.id;
          delete this.formData.userName
          await QaService.update(this.formData)
        } else {
          // alert('new')
          await QaService.create(this.formData)
        }

        this.$store.dispatch('setSnackbar', { text: 'Saved Successfully' })
        this.$emit("closeDialog");
        this.$store.commit("QATASK_CHANGE", !status);
        this.$emit("reload", true);
      }
    },
    documentsData($event) {
      this.attachmentId.push($event.id)

      this.getAttachementData()
    },
    async getAttachementData() {
      try {
        // console.log(this.attachmentId,"uuuuuuuuuuuuuuu")
        let res = await documentService.getAllDocuments({ doc_id: JSON.stringify(this.attachmentId) })
        this.attachmentData = res
        if (res.length == 1) {
          this.document_id = res[0].id
        } else {
          this.document_id = []
          for (let i = 0; i < res.length; i++) {
            this.document_id.push(res[i].id)
          }
        }
        this.formData.doc_id = JSON.stringify(this.document_id)
      }
      catch (e) {
        console.log(e)
      }
    },

    downloadDoc(item) {
      if (!this.viewType) {
        documentService.download({ id: item.id }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("name", "downloadLink");
          link.setAttribute("download", response.headers["x-suggested-filename"]); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
    },
    changeDescription($event) {
      this.formData.description = $event;
    },
    changeCause($event) {
      this.formData.cause = $event;
    },
    changeImpact($event) {
      this.formData.impact = $event
    },
    changeSeverty($event) {
      this.formData.severity = $event
    },
    close() {
      this.$emit("closeDialog");
    },
    remove(index) {
      if (index > -1) {
        this.attachmentId.splice(index, 1)
      }
    },
  },
}
</script>
    
<style scoped>
>>>.v-chip.v-chip--size-default {
  --v-chip-size: 0.875rem;
  --v-chip-height: 26px;
  font-size: 0.875rem;
  padding: 0 12px;
  background-color: aliceblue;
}

>>>.align {
  margin-left: -12px;
}

>>>.v-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: -19px;
  padding-bottom: 1px;
}

>>>[data-v-b13e3b0c] .v-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: -16px;
  margin-top: -17px;
  padding-bottom: 1px;
}

>>>[data-v-b13e3b0c] [data-v-b13e3b0c] .v-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: -16px;
  margin-top: -4px;
  padding-bottom: 1px;
}
</style>