<template>
  <v-card elevation="0" style="border-radius: 10px">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>
          {{
            this.type === "action" && !showReadOnly
              ? "Edit"
              : showReadOnly
              ? "View"
              : "Add"
          }}
          Clarification
          <v-avatar size="x-small" color="primary" v-if="no_of_followups">
            {{ no_of_followups }}
          </v-avatar>
          <v-btn
            style="float: right"
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group
              :disabled="showReadOnly"
              v-model="formData.followup_type"
              inline
              @change="getUsers(formData.followup_type)"
              v-if="true"
            >
              <v-radio color="primary" label="Internal" :value="1"></v-radio>
              <v-radio color="error" label="External" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col v-if="false" cols="6" class="px-0 py-0" style="display: grid">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Clarification Type
            </v-label>
            <div style="font-size: 14px; color: #272727; font-weight: 600">
              {{ formData.followup_type == 1 ? "Internal" : "External" }}
            </div>
          </v-col>

          <!-- <v-col cols="6" class="pr-1" v-if="true">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Clarification [E]
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <EnumAutoCompleteVue
              :showReadOnly="showReadOnly"
              class="dropdown"
              density="compact"
              v-model="formData.clarification"
              :selected="formData.clarification"
              :domain="'CLARIFICATION'"
              placeholder="Clarification"
              @changeEnumData="changeClarification($event)"
              :rules="clarificationRule"
            />
          </v-col> -->

          <v-col cols="6" class="pr-1" v-if="true">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
            >
              Pending With
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :disabled="showReadOnly"
              class="dropdown"
              :items="users"
              :item-title="'name'"
              :item-value="'id'"
              density="compact"
              v-model="formData.user"
              placeholder="User"
              :rules="userRule"
              v-if="true"
            />
          </v-col>
          <v-col v-if="false" cols="6">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Pending With
            </v-label>
            <div style="font-size: 14px; color: #272727; font-weight: 600">
              {{ formData.pending_with_name ? formData.pending_with_name : "null" }}
            </div>
          </v-col>
          <!-- <v-col cols="6" v-if="!switchMe && !showReadOnly" class="pt-4 pl-2">
            <v-switch v-model="switchMe" :disabled="showReadOnly" color="primary">
              <template v-slot:label> Followup Date Required ? </template>
            </v-switch>
          </v-col> -->

          <v-col cols="6" class="pl-1" v-if="!showReadOnly">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >FollowUp Date
              <span
                v-if="true"
                style="color: #cf0707; padding-left: 2px; font-weight: 600"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              :showReadOnly="showReadOnly"
              density="compact"
              placeholder="FollowUp date"
              :rules="dateRule"
              v-model="formData.followup_date"
              type="date"
            />
          </v-col>

          <v-col v-if="showReadOnly && formData.followup_date">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >FollowUp Date
            </v-label>
            <div style="font-size: 14px; color: #272727; font-weight: 600">
              {{
                formData &&
                formData.followup_date &&
                moment(formData.followup_date).format("MM/DD/YYYY") != "Invalid date"
                  ? moment(formData.followup_date).format("MM/DD/YYYY")
                  : ""
              }}
            </div>
          </v-col>

          <v-col cols="12" v-if="true">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Description</v-label
            >
            <!-- <TextArea :showReadOnly="showReadOnly" class="pb-0" editorStyle="height: 120px" :data="formData.description"
                            @textData="changeDescription($event)"></TextArea> -->
            <DescriptionVue
              :data="formData.description"
              :showReadOnly="showReadOnly"
              @textData="changeDescription($event)"
              ref="descriptionVue"
            >
            </DescriptionVue>

            <!-- <TextArea
              :data="formData.description"
              :showReadOnly="showReadOnly"
              @textData="changeDescription($event)"
            ></TextArea> -->
          </v-col>
          <v-col v-if="false" cols="12">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Description
            </v-label>
            <v-card style="background-color: rgba(224, 243, 253, 0.325)" flat>
              <v-card-text>
                <div v-html="formData.description"></div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3" v-if="!showReadOnly">
        <v-row justify="end" class="dialogBottom">
          <v-btn style="--v-btn-height: 35px" class="mr-2 cancel" @click="close()"
            >Cancel</v-btn
          >
          <v-btn
            style="--v-btn-height: 35px"
            :loading="saveClicked"
            class="save"
            type="submit"
            >Save</v-btn
          >
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import moment from "moment";
// import TextArea from "@/components/ui/TextArea.vue";
import IssueService from "@/api/service/Issue/IssueService";
import ForumService from "@/api/service/Forum/ForumService";
// import EnumAutoCompleteVue from "@/components/others/EnumAutoComplete.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import ContactService from "@/api/service/Contact/ContactService";
import UserService from "@/api/service/UserService";
import DescriptionVue from "@/components/ui/Description.vue";

export default {
  components: {
    // TextArea,
    // EnumAutoCompleteVue,
    AutoComplete,
    TextFieldComponent,
    DescriptionVue,
  },
  props: ["issueId", "followUpNo", "showDetails", "showReadOnly", "type"],
  data() {
    return {
      saveClicked: false,
      switchMe: false,
      users: [],
      description: null,
      availableData: {},
      formData: {
        followup_type: null,
        user: null,
        followup_date: null,
        clarification: null,
        description: null,
      },
      clarificationRule: [(value) => !!value || "Clarification is Required"],
      dateRule: [(value) => !!value || "Followup date is Required"],
      userRule: [(value) => !!value || "User is Required"],
    };
  },

  created() {
    this.getUsers();
    if (this.issueId && this.type && this.type == "action") {
      this.getById(this.issueId);
    }
    if (this.showDetails == "view") {
      this.getUsers();

      // console.log()
      // this.formData.clarification = this.availableData.clarification_type
      // this.formData.followup_date = this.availableData.followup_date ? moment(this.availableData.followup_date).format('DD-MM-YYYY') : null
    }
  },
  methods: {
    moment,

    async getById(id) {
      let res = await IssueService.getClarificationDetails({
        issueId: id,
        type: "clarification",
      });
      this.availableData = res[0];
      console.log(this.availableData, "jjjjjiiii");

      this.formData.followup_type =
        this.availableData.followup_type == "internal" ? 1 : 0;
      this.getUsers(this.availableData.followup_type);
      this.formData.user = parseInt(this.availableData.followup_user);
      this.formData.pending_with_name = this.availableData.pending_with_name;
      this.formData.clarification = this.availableData.clarification_type;
      this.formData.followup_date = this.availableData.followup_date
        ? moment(this.availableData.followup_date).format("YYYY-MM-DD")
        : null;
      // if (this.availableData.followup_date != null) {
      //   this.switchMe = true;
      // }
      this.formData.description =
        this.availableData &&
        this.availableData.forums &&
        this.availableData.forums.description
          ? this.availableData.forums.description
          : null;
      // this.description = parser.parseFromString(this.formData.description,'text/html')
      this.formData.f_id =
        this.availableData && this.availableData.forums && this.availableData.forums.f_id
          ? this.availableData.forums.f_id
          : null;
      console.log(this.availableData, "avforum");
      // let response = await ForumService.getForumList({
      //     key_id: this.issueId,
      //     key_type: "issue",
      // });
      // this.description = response.filter((obj) => obj.action == 'clarification')
      // this.formData.followup_type = this.availableData.followup_type == 'internal' ? 1 : 0
      // this.formData.user = this.availableData.followup_user
      console.log(this.formData, "jjjiiiiiiii3333");
      // this.formData.description = this.description[0].description.replace(/<\/?p>/g, '')
    },

    async getUsers(type) {
      this.formData.user = null;
      if (type == 1 || type == "internal") {
        let res = await UserService.findAll();
        this.users = res && res.length > 0 ? res : [];
        console.log(this.users, "users");
      } else if (type == 0 || type == "external") {
        let res = await ContactService.allContacts();
        this.users =
          res && res.length > 0 ? res.filter((contact) => contact.active === 1) : [];
      } else {
        this.users = [];
      }
    },

    itemProps(item) {
      return {
        title: item.first_name,
        subtitle: item.email,
      };
    },
    // clearFollowUpDate() {
    //   this.switchMe = false;
    //   this.formData.followup_date = null;
    // },
    close() {
      this.$emit("closeDialog");
    },
    changeClarification($event) {
      this.formData.clarification = $event;
    },
    changeDescription($event) {
      this.formData.description = $event;
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation.valid);
      } else {
        if (this.issueId) {
          if (!this.formData.description) {
            this.$refs.descriptionVue.showErrorMessage("Description is Required");
            return;
          }
          this.saveClicked = true;
          await IssueService.update({
            id: this.issueId,
            stage: "clarification",
            followup_date: this.formData.followup_date,
            followup_type:
              this.formData.followup_type && this.formData.followup_type == 1
                ? "internal"
                : "external",
            followup_user: this.formData.user,
            clarification_type:
              this.formData && this.formData.clarification
                ? this.formData.clarification
                : "",
          });
          let $description =
            this.formData && this.formData.description ? this.formData.description : "";
          let data = {
            id: this.formData.f_id ? this.formData.f_id : null,
            key_type: "issue",
            key_id: this.issueId,
            action: "clarification",
            description: $description,
          };
          if (data.id) {
            await ForumService.update(data);
          } else {
            await ForumService.create(data);
          }
          this.formData = {};
          this.close();
          this.$emit("saveDialog");
        }
      }
    },
  },
};
</script>

<style></style>
