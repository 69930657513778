<template>
      <Editor
        v-model="value"
        :editorStyle="editorStyle ? editorStyle : 'height: 200px'"
        :readonly="showReadOnly"
        :selection-change="changeText()"
        :modules="modules"
        ref="myQuillEditor"
        id="#activator-mention"
      >
        <template v-slot:toolbar>
          <div v-for="option in options" :key="option">
            <button :class="option" :disabled="showReadOnly"></button>
          </div>
        </template>
      </Editor>
    </template>
<script>
import Editor from 'primevue/editor';
import UserService  from '@/api/service/UserService';
import "quill-mention";

let  atValues = [
 
];

export default {
      components: {
            Editor
            //QuillBetterTable
      },
      props: ['showReadOnly','data',"editorStyle"],
      data() {
            return {
                  modules:{
                        mention: {
                              allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                              mentionDenotationChars: ["@", "#"],
                              source: function(searchTerm, renderList, mentionChar) {
                                    let values;

                                    if (mentionChar === "@") {
                                    values = atValues;
                                    } 

                                    if (searchTerm.length === 0) {
                                    renderList(values, searchTerm);
                                    } else {
                                    const matches = [];
                                    for (let i = 0; i < values.length; i++)
                                          if (
                                          ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
                                          )
                                          matches.push(values[i]);
                                    renderList(matches, searchTerm);
                                    }
                              }
                        },
                  },
                  value:null,
                  options: [
                        'table',
                        'ql-italic',
                        'ql-underline',
                        'ql-video',
                        'ql-bold',
                        'ql-color',
                        'ql-font',
                        'ql-code',
                        'ql-link',
                        'ql-size',
                        'ql-strike',
                        'ql-script',
                        'ql-indent',
                        'ql-header',
                        'ql-list',
                        'ql-align',
                        'ql-direction',
                        'ql-code-block',
                        'ql-formula',
                        'ql-image']
            }
      },
      watch:{
            data(){
                  if(this.data){
                        this.value =this.data
                  }
                  else{
                       this.value =null
                  }
            }
      },
      async created(){
            
            this.value =this.data;
            let $users = await UserService.findAll()
            let $options=[]
            $users.forEach(obj=>{
                  $options.push({"id":obj.id,"value":obj.name})
            })
            atValues =$options
      },
      updated() {
           // this.$refs.myQuillEditor.focus();
          //  this.$refs.myQuillEditor.value.getQuill().focus();

      },
      methods: {
            atOneMemberAction(item){
                  console.log('selected member');
                  console.log(item);
                  //alert(item)
            },
            changeText() {
                  this.$emit('textData', this.value)
            }
      }
}
</script>
<style>
.quill-at-member{
  width: 270px;
  display:none;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}
.ql-mention-list-container {
  width: 270px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  /* height: 550px; */
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  background-color: #d3e1eb;
  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  background-color: #d3e1eb;
  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}
</style>