<template>
  <div v-if="loading" class="skeletonBox">
    <Skeleton height="700px" width="275px" class=""></Skeleton>
  </div>
  <div
    v-else-if="!issueData || issueData.length == 0"
    style="padding: 0px 15px 15px 15px"
  >
    <v-card flat height="650px">
      <v-row no-gutters>
        <v-col class="mt-4 text-center">
          There is no un assigned issue for this workspace.
        </v-col>
      </v-row>
    </v-card>
  </div>
  <div v-else>
    <div class="parentCard">
      <v-col
        cols="12"
        class="pa-1 ma-0 pl-2 font-weight-bold text-caption bg-teal-darken-2"
      >
        Estimated
        {{
          getTotalMinutes !== "null"
            ? parseFloat(getTotalMinutes).toFixed(2) + " mins"
            : "0 mins"
        }}
      </v-col>
      <div class="diffStages" v-for="issue in issueData" :key="issue">
        <div
          class="detail issue-detail"
          @click="atClick(issue)"
          :draggable="true"
          @dragstart="dragStart($event, issue)"
          style="height: auto"
        >
          <div style="display: flex; gap: 8px">
            <div v-if="issue && issue.account_code">
              <v-avatar class="account-avatar" color="#08929B" rounded="0" size="x-small">
                <span style="color: #fff; font-size: 10px">
                  {{
                    issue && issue.account_code
                      ? issue.account_code.toUpperCase().substr(0, 3)
                      : ""
                  }}</span
                >
              </v-avatar>
            </div>
            <span>
              <div class="reff">
                <span>
                  {{ issue && issue.reference_num ? issue.reference_num : "" }}
                </span>
                <div class="pl-1">
                  <v-avatar
                    :color="issue.skill_theme ? issue.skill_theme : ''"
                    rounded="0"
                    size="7"
                  >
                  </v-avatar>
                  <v-icon
                    v-if="issue && issue.priority == 'critical'"
                    class="pi pi-exclamation-triangle ml-1"
                    style="color: red; font-size: 8px; font-weight: 800"
                  />
                </div>
                <div class="priority" v-if="issue && issue.priority == 'high'">
                  <v-icon size="9">mdi-exclamation-thick</v-icon>
                  <v-icon
                    v-if="issue.week_end_mails == 'yes'"
                    class="mdi mdi-circle-half-full"
                    size="9"
                    color="blue"
                  ></v-icon>
                </div>
              </div>
              <div class="issueName">{{ issue && issue.name ? issue.name : "" }}</div>
            </span>
          </div>
          <div>
            <span class="chip" v-if="issue && issue.estimate">
              {{ issue && issue.estimate ? issue.estimate : "" }}m
            </span>
          </div>
        </div>
        <hr
          v-if="issueData && issueData.length > 1"
          style="border-style: dashed; border-color: #c6c6c6"
        />
      </div>
    </div>
  </div>
</template>
<script>
import lodash from "lodash";
import AccountService from "@/api/service/Account/AccountService";
import IssueService from "@/api/service/Issue/IssueService";
import moment from "moment";
import Skeleton from "primevue/skeleton";
import getHourMinutes from "@/api/service/Common/Utils";

export default {
  props: ["filterData", "searchData"],
  components: {
    Skeleton,
  },
  watch: {
    reloadIssue() {
      if (this.$store.getters.getIssueUpdated != 0) {
        this.getUpdatedData(this.$store.getters.getIssueUpdated);
      }
    },
    searchData() {
      if (this.searchData && this.searchData.length > 0) {
        this.gettingSearchData(this.searchData);
      } else {
        this.gettingSearchData(null);
      }
    },
    getRefresh() {
      this.refreshTheAccount();
    },

    reloadTheData() {
      this.refreshTheAccount();
    },
    filterData() {
      if (this.filterData) {
        this.getIssueData(this.filterData ? this.filterData : {}, false);
      }
    },
  },
  data() {
    return {
      loading: true,
      accountData: [],
      issueData: [],
      groupeData: [],
      mainData: [],
      issueFilterData: [],
    };
  },
  computed: {
    getTotalMinutes() {
      let $total = lodash.sumBy(this.issueData, function (o) {
        return o.estimate;
      });
      return getHourMinutes($total);
    },
    reloadIssue() {
      return this.$store.getters.getIssueUpdated;
    },
    getRefresh() {
      return this.$store.getters.getIssueUpdate;
    },
    reloadTheData() {
      return this.$store.getters.getReloadTheIssue;
    },
  },
  async created() {
    await this.getSelectselectValue();
    let obj ={}
    if(this.filterData){
        obj =this.filterData
    }
    await this.getIssueData(obj, true);
    this.getAccounts();
    // this.getIssueData()
  },
  methods: {
    getHourMinutes,
    async getUpdatedData($id) {
      var res = await IssueService.getAssignToMeData({ id: $id });
      let $findIndex = lodash.findIndex(this.issueData, { id: $id });
      if (res[0].next_action) {
        this.issueData.splice($findIndex, 1);
      } else if ($findIndex >= 0) {
        this.issueData[$findIndex] = res[0];
      } else {
        this.issueData.push(res[0]);
      }
      this.$store.dispatch("issueUpdated", 0);
    },
    isDateOverDue(date) {
      let currentDate = new Date();
      let dueDate = new Date(date);
      return currentDate > dueDate ? "text-red" : "text-orange";
    },

    getSelectselectValue() {
      let $wsDetails = localStorage.getItem("_WS_DEFAULTS_");
      if ($wsDetails) {
        $wsDetails = JSON.parse($wsDetails);
        this.section = [];
      }
    },
    refreshTheAccount() {
      this.getIssueData(this.filterData ? this.filterData : {}, false);
    },

    gettingSearchData(query) {
      let data = []; // Move the declaration outside of the if block
      if (query && query.length > 0) {
        let searchStr = new RegExp(query, "gi");
        for (let i = 0; i < this.backUpIssueData.length; i++) {
          if (
            (this.backUpIssueData[i].name &&
              this.backUpIssueData[i].name.search(searchStr) > -1) ||
            (this.backUpIssueData[i].ref_num &&
              this.backUpIssueData[i].ref_num.search(searchStr) > -1)
          ) {
            data.push(this.backUpIssueData[i]);
          }
        }
        this.issueData = [...data];
      } else {
        this.issueData = JSON.parse(JSON.stringify(this.backUpIssueData));
      }
      this.issueData = [...this.issueData];
      console.log(this.issueData, "kishorequery1");
      this.$emit(
        "open-issue-update",
        lodash.sumBy(this.issueData, function (o) {
          return o.estimate;
        })
      );
      this.$forceUpdate();
      //this.getTheCountOfIssue();
    },

    dragStart(event, issue) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("issueId", issue.id);
    },
    moment,
    atClick(item) {
      this.$emit("draweView", item);
      // this.$router.push({ path: "/issueView2", query: { id: selectedId } });
    },
    async getAccounts() {
      let res = await AccountService.findAll();
      this.accountData = res;
    },
    async getIssueData(obj) {
      console.log(obj,"hhhhhhhhhhh2222222")

      try {
        let wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
        wsDefaults = wsDefaults ? JSON.parse(wsDefaults) : null;
        if (wsDefaults && !obj.work_space_id && !obj.project_id) {
          obj.work_space_id =
            wsDefaults && wsDefaults.workspace && wsDefaults.workspace.id
              ? wsDefaults.workspace.id
              : "";
          obj.project_id =
            wsDefaults && wsDefaults.project && wsDefaults.project.id
              ? wsDefaults.project.id
              : "";
        }
        let res = await IssueService.getProcessLeadData(obj);
        if (res && res.length > 0) {
          // let data = res.map(k => k.issues)
          this.issueData = res;
          this.backUpIssueData = JSON.parse(JSON.stringify(this.issueData));
        } else {
          this.issueData = [];
          this.backUpIssueData = [];
        }
        this.loading = false;
        this.$emit(
          "open-issue-update",
          lodash.sumBy(this.issueData, function (o) {
            return o.estimate;
          })
        );

        console.log(this.issueData, "oooooppp");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.skeletonBox {
  width: 275px;
  height: 100vh;
}

>>> .p-skeleton {
  background-color: #ececec;
  border-radius: 4px;
}

.parentCard {
  position: relative;
  background: #fff;
  border-radius: 4px;
  width: 275px;
  min-height: 650px;
  max-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}

.detail {
  display: flex;
  justify-content: space-between;
  min-height: 30px;
  padding: 10px 10px;
}

.chip {
  text-transform: capitalize;
  border: 1px solid #f9c644;
  background: #ffe8ae;
  color: #000;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 4px;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
}

.reff {
  display: flex;
  color: #000;
  font-size: 9px;
  font-weight: 300;
}

.issueName {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

>>> .diffStages .v-avatar.v-avatar--density-default {
  border-radius: 5px !important;
}

>>> ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

>>> ::-webkit-scrollbar:hover {
  width: 10px;
  height: 10px;
}

>>> ::-webkit-scrollbar-track {
  background: #e8f3f9;
}

>>> ::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
  border-radius: 8px;
}

.issue-detail:hover {
  background-color: #f5f1cb;
  border-radius: 8px;
}
.issue-detail:hover .hrline {
  display: none;
}
.issue-detail:hover .issueName {
  font-weight: 600;
  overflow: visible;
  -webkit-line-clamp: 10;
}
.issue-detail:hover .account-avatar {
  background-color: #2d2d2d !important;
}
</style>
