<template>
    <v-row class="mt-2">
        <v-col cols="" class="column">
            <v-chip v-for="(year, index) in yearName" :key="index" @click.stop="checkActiveYear(index)"
                :class="{ active1: year.showActive }" class="mr-2 chip year">
                {{ year.name }}
            </v-chip>
        </v-col>
        <v-col cols="7" class="column">
            <v-chip v-for="(month, index) in monthName" :key="index" @click.stop="checkActiveMonth(index)"
                :class="{ active: month.showActive }" class="mr-2 chip month">
                {{ month.name }}
            </v-chip>
        </v-col>
        <v-col  cols="2" align="end">
            <AutoComplete placeholder="Today" />
        </v-col>
    </v-row>
</template>
  
<script>
import AutoComplete from "../../components/ui/AutoComplete.vue";

export default {
    components: {
        AutoComplete,
    },
    data() {
        return {
            yearName: [
                { name: "2023", showActive: false },
                { name: "2022", showActive: false },
                { name: "2021", showActive: false },
            ],
            monthName: [
                { name: "Jan", showActive: false },
                { name: "Feb", showActive: false },
                { name: "Mar", showActive: false },
                { name: "Apr", showActive: false },
                { name: "May", showActive: false },
                { name: "Jun", showActive: false },
                { name: "Jul", showActive: false },
                { name: "Aug", showActive: false },
                { name: "Sep", showActive: false },
                { name: "Oct", showActive: false },
                { name: "Nov", showActive: false },
                { name: "Dec", showActive: false },
            ],
        };
    },
    methods: {
        checkActiveYear(selectedYear) {
            this.yearName.forEach((year, index) => {
                year.showActive = index === selectedYear;
            });
        },
        checkActiveMonth(selectedIndex) {
            this.monthName.forEach((month, index) => {
                month.showActive = index === selectedIndex;
            });
        },
    },
};
</script>
  
<style scoped>

.chip {
    height: 24px;
    font-size: 14px;
    font-weight: 400;
}

.month {
    border: .5px solid #fcc02c;
    background-color: rgba(252, 192, 44, 0.4);
}

>>>.active {
    color: white;
    font-size: 10px;
    font-weight: 600;
    background-color: rgba(252, 192, 44, 1);
}
.year {
    border: .1px solid #16a9f6;
    background-color: rgba(22, 169, 246, 0.4);
}
>>>.active1 {
    color: white;
    font-size: 10px;
    font-weight: 600;
    background-color: #16A9F6;
}
>>>.v-field {
    cursor: text;
    font-size: 14px;
    color: #777777;
    border-radius: 25px;
    border: 1px solid #696868;
    background: #FFF;
    height: 35px; 
}
>>>.v-field:focus-within {
    cursor: text;
    border-radius: 25px;
    border: 1px solid #696868;
    background: #FFF;
} 

</style>
  