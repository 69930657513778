<template>
  <v-card class="py-3 cardScroll" density="compact" flat min-height="500" max-height="88vh" max-width="543">
    <div v-for="item in workSpaceData" :key="item">
      <div v-if="item && item.work_spaces.name && item && item.account_data && item.account_data.length > 0">
        <v-text class="ml-3 px-11" :class="{ 'px-11': item.account_data.length > 3 }">
          <b>{{ item && item.work_spaces && item.work_spaces.name }}</b>
        </v-text>

        <div v-if="item.account_data.length > 3">
          <Carousel
            :value="item.account_data"
            :numVisible="3"
            :numScroll="1"
            :responsiveOptions="responsiveOptions"
          >
            <template #item="slotProps">
              <v-card
                elevation="0"
                density="compact"
                class="mainCard mx-3 my-3 px-3"
                @click="getLink(slotProps.data, item.work_spaces)"
                :class="{ active: slotProps.data.showActive }"
              >
                <v-card-title class="workSpace pb-1">{{ slotProps.data.name }}</v-card-title>
                <v-card-text class="chipTxt">
                  Open issues
                  <div class="chipD">
                    <v-chip
                      :class="{ active1: slotProps.data.showActive }"
                      class="chip mt-2"
                      label
                    >
                      <span class="chipTxtNO">{{ slotProps.data.no_of_issues }}</span>
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </Carousel>
        </div>

        <div  v-else class="else-card-container px-11 " :class="{ 'px-11': item.account_data.length > 3 }">
          <div  v-for="data in item.account_data" :key="data.id">
            <v-card
              elevation="0"
              density="compact"
              class="mainCard mx-3 my-3 px-3"
              @click="getLink(data, item.work_spaces)"
              :class="{ active: data.showActive }"
            >
              <v-card-title class="workSpace pb-1">{{ data.name }}</v-card-title>
              <v-card-text class="chipTxt">
                Open issues
                <div class="chipD">
                  <v-chip
                    :class="{ active1: data.showActive }"
                    class="chip mt-2"
                    label
                  >
                    <span class="chipTxtNO">{{ data.no_of_issues }}</span>
                  </v-chip>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

  
<script>
import Carousel from 'primevue/carousel';
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";

export default {
  components:{
    Carousel
  },
  data() {
    return {
      workSpaceData: [],
      responsiveOptions: [
                {
                    breakpoint: '1199px',
                    numVisible: 3,
                    numScroll: 3
                },
                {
                    breakpoint: '991px',
                    numVisible: 2,
                    numScroll: 2
                },
                {
                    breakpoint: '767px',
                    numVisible: 1,
                    numScroll: 1
                }
            ]
    };
  },
  created() {
    this.getWorkSpace();
  },
  methods: {
    getLink($account , item) {    
        let status = this.$store.getters.getMyspace;
        localStorage.setItem("_WS_DEFAULTS_",JSON.stringify({"workspace":{"id":item.id,"name":item.name}}))
        localStorage.setItem("workSpaceId", item.id);
        this.$store.commit("SET_REFRESH_BREADCRUMP",true)
            let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_")
            $wsDefaults = JSON.parse($wsDefaults)
            $wsDefaults.account ={"id":$account.id,"name":$account.name}
            localStorage.setItem("_WS_DEFAULTS_",JSON.stringify($wsDefaults))
            this.$store.commit("SET_REFRESH_BREADCRUMP",true)
            this.$store.commit("CHANGE_MY_SPACE", !status);
            this.$router.push(`/showProjectForWorkspaces`)
            this.$emit("closeMenu") 
            // location.reload();
        },

    async getWorkSpace() {
      let res = await WorkSpaceService.getMySpace();
      this.workSpaceData = res;
      console.log(this.workSpaceData , 'kakkakka')
    },
  },
};
</script>
  
<style scoped>
 >>>.p-carousel .p-carousel-indicators {
    padding: 1rem;
    display: none;
}
.column {
  max-width: fit-content;
}
.chipTxt {
  font-size: 12px;
  font-weight: 400;
}
.chipTxtNO {
  font-size: 12px;
  font-weight: 700;
}
.mainCard {
  width: 125px;
  height: 105px;
  border-radius: 4px !important;
  background-color: rgb(241, 241, 241);
}

.active {
  background-color: #c7f0d0;
  color: black;
  width: 125px;
  height: 105px;
  border-radius: 4px !important;
}
.workSpace {
  font-weight: 700;
  font-size: 14px;
  color: #636363;
}

>>> .v-chip--variant-tonal .v-chip__underlay {
  background-color: inherit;
  color: #272727 !important;
}

>>> .v-chip__content {
  color: #272727 !important;
}

.chip {
  background: #dfdbf6;
}
.chipD {
  display: flex;
  justify-content: center;
}

.chip.active1 {
  background-color: #f0e7f8 !important;
}
.cardScroll::-webkit-scrollbar {
  width: 6px;
}

.cardScroll::-webkit-scrollbar-track {
  background: #e8f3f9;
}
.cardScroll::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
}

.else-card-container{
  display: flex;
}
</style>
  