<template>
  <v-card flat class="pa-3">
  <!-- <v-container> -->
  <PrimeTable
    :title="'Project'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="project"
  />
  <!-- </v-container> -->
  <v-dialog v-model="dialog" width="700px"  persistent>
    <AddProject
      style="overflow: auto;"
      ref="AddProject"
      @reload="getProjectData()"
      :id="projectId"
      :viewType="viewType"
      @closeDialog="cancel()"
    ></AddProject>
  </v-dialog>
</v-card>
</template>

<script>
import PrimeTable from "@/components/PrimeDataTable.vue";
//   import TextFieldComponent from '@/components/TextFieldComponent.vue'
import AddProject from "./AddProject.vue";
// import commonService from '@/common.service'
import ProjectService from "@/api/service/Project/ProjectService";
export default {
  name: "projectList",
  components: {
    PrimeTable,
    // TextFieldComponent,
    AddProject,
  },
  data() {
    return {
      date: new Date(),
      data: [],
      projectId: '',
      dialog:false,
      viewType: false,
    };
  },
  created() {
    if(this.$route.query.from=='topMenu'){
      this.addDiolog()
    }
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getProjectData();
  },
  methods: {
    loadColumns() {
      this.columns = [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        {
          field: "category",
          header: "Category Name",
          style: { width: "100px", color: "" },
          toolTip: "Category Name",
          sortfield: true,
        },
        {
          field: "stage",
          header: "Stage",
          style: { width: "100px", color: "" },
          toolTip: "Stage",
          sortfield: true,
        },
        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            
            { action: "view" },
            { action: "edit" },
            // { action: "delete" },
          ],
        },
      ];
    },
    addDiolog() {
      this.dialog = true;
      this.viewType = false;
      this.projectId = null;
      this.viewType='add'
      // this.$refs.AddProject.size = commonService.largeDialog()
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        // this.$refs.AddProject.size = commonService.largeDialog()
        this.viewType = $event.type;
        this.projectId = $event.data.id;
        console.log("view");
      }
      if ($event.type == "edit") {
        this.dialog = true;
        // this.$refs.AddProject.size = commonService.largeDialog()
        this.viewType = $event.type;
        this.projectId = $event.data.id;

        console.log("edit");
      }
      if ($event.type == "select") {
        this.dialog = true;
        // this.$refs.AddProject.size = commonService.largeDialog()
        this.viewType = 'view';
        this.projectId = $event.data.id;

        console.log("select");
      }
    },
    async getProjectData() {
      this.data = [];
      try {
        let res = await ProjectService.findAll();
        this.data = res
        console.log(this.data,'check222222222')
       /*  for (let i = 0; i < res.length; i++) {
          this.data.push(res[i].ds_project_list);
        }
        this.data = [...this.data]; */
      } catch (e) {
        console.log(e);
        this.data = [];
      }
    },
    cancel() {
      this.dialog = false;
      // this.issuetype=[];
    },
  },
};
</script>

<style></style>
