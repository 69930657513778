<template>
  <v-card>
    <v-container>
      <!-- <v-btn @click="isActive.value = false" elevation="0" density="comfortable" icon="mdi-close"
        class="x-closeIcon"></v-btn> -->
      <v-card-text v-if="type == 'default'">
        <v-row class="mt-10">
          <v-col align="center" class="text-h5 pa-12 ">What would like to do ?</v-col>
        </v-row>
        <v-row class="pb-8 mt-10">
          <v-col align="center">
            <v-btn class="dialog-button" color="indigo-darken-1" @click="openMeeting()">Meeting</v-btn>
          </v-col>
          <v-col align="center">
            <v-btn class="dialog-button" color="indigo-darken-1" @click="openBreakDialog()">Break</v-btn>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-text v-if="type == 'break'">
        <v-row class="mt-10">
          <v-col align="center" class="text-h5 pa-12 ">Start Over again</v-col>
        </v-row>

        <v-row class="mt-10" style="display: flex;
                            justify-content: center;">
          <h1>
            <Timer :startAndStop="showStart" :startTime="startSeconds"></Timer>
          </h1>
        </v-row>
        <v-row class="pb-8">
          <v-col align="center">
            <v-btn class="dialog-button" color="indigo-darken-1" @click="stopBreak()">Complete Break</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="type == 'next'">
        <v-row class="mt-10">
          <v-col align="center" class="text-h5 pa-12 ">What would like to do ?again</v-col>
        </v-row>



        <v-row class="pb-8 mt-10">
          <v-col align="center">
            <v-btn class="dialog-button" color="indigo-darken-1" @click="continueIssue()">Continue where I left</v-btn>
          </v-col>
          <v-col align="center">
            <v-btn class="dialog-button" color="indigo-darken-1" @click="startNextIssue()">New Task</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="type == 'meeting'">
        <v-row class="mt-10">
          <v-col align="center" class="text-h5 pa-12 ">Start Meeting </v-col>
        </v-row>

        <v-row class="mt-10" style="display: flex;
                                    justify-content: center;">
          <h1>
            <Timer :startAndStop="showStart" :startTime="startSeconds"></Timer>
          </h1>
        </v-row>
        <v-row class="pb-8 mt-10" style="display: flex;
    justify-content: center;">
          <v-col cols="10">
            <TextFieldComponent density="compact" placeholder="Meeting Remarks" v-model="formData.description" />
          </v-col>

        </v-row>
        <v-row class="pb-8" style="display: flex;
    justify-content: center;">

          <v-col cols="10" align="center">
            <v-btn class="dialog-button" color="indigo-darken-1" @click="stopMeeting()">Stop Meeting</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import Timer from "@/components/Timer.vue"
import commonService from '../common.service.js'
import moment from 'moment'
// import IssueService from "@/api/service/Issue/IssueService";
import TimeSheetService from '@/api/service/TimeSheet/TimeSheetService.js'
export default {
  components: {
    Timer,
    TextFieldComponent
  },
  data() {
    return {
      type: 'default',
      formData: {},
      showStart: null,
      startTime: null,
      timeSheetData: null,
      startSeconds: null,
      size: {}
    }
  },
  async created() {
    let type = localStorage.getItem('_TIME_SHEET_TYPE_')
    if (type) {
      this.dialog = true
      this.size = commonService.smallDialog()
      let timeSheetId = localStorage.getItem('_TIME_SHEET_ID_')
      let res = await TimeSheetService.findOne(timeSheetId)
      let today = new Date()
      var start_date = moment(new Date(res[0].start_date), 'YYYY-MM-DD HH:mm:ss');
      var end_date = moment(today, 'YYYY-MM-DD HH:mm:ss');
      var duration = moment.duration(end_date.diff(start_date));
      let hour = duration && duration._data && duration._data.hours > 0 ? duration._data.hours * 3600 : 0
      let munites = duration && duration._data && duration._data.minutes > 0 ? duration._data.minutes * 60 : 0
      let seconds = duration && duration._data && duration._data.seconds ? duration._data.seconds : 0
      this.startSeconds = hour + munites + seconds
      this.type = type == 'Meeting' ? 'meeting' : 'break'
    }
  },
  methods: {
    async openBreakDialog() {
      localStorage.setItem('_TIME_SHEET_TYPE_', 'Break')
      let res = await TimeSheetService.create({ description: "Start Break", entry_date: new Date(), stage: 'inprogress', start_date: new Date() })
      this.timeSheetData = res ? res : {}
      localStorage.setItem('_TIME_SHEET_ID_', this.timeSheetData.data.data.id)
      this.startTime = this.formattedTime(this.timeSheetData.entry_date)
      this.showStart = false
      this.type = 'break'
    },

    formattedTime(data) {
      let date = new Date(data)
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      return date.toLocaleTimeString("en-US", options);
    },
    async openMeeting() {
      let res = await TimeSheetService.create({ description: "Start Meeting", entry_date: new Date(), stage: 'inprogress', start_date: new Date() })
      this.timeSheetData = res ? res : {}
      localStorage.setItem('_TIME_SHEET_ID_', this.timeSheetData.data.data.id)
      localStorage.setItem('_TIME_SHEET_TYPE_', 'Meeting')
      this.startTime = this.formattedTime(this.timeSheetData.data.data.start_date)
      this.showStart = false
      this.type = 'meeting'
    },
    async stopMeeting() {
      let timeSheetId = localStorage.getItem('_TIME_SHEET_ID_')
      await TimeSheetService.update({ id: timeSheetId, end_date: new Date(), stage: 'completed', description: this.formData.description })
      this.type = 'next'
      localStorage.removeItem("_TIME_SHEET_ID_")
      localStorage.removeItem("_TIME_SHEET_TYPE_")
    },
    async stopBreak() {
      let timeSheetId = localStorage.getItem('_TIME_SHEET_ID_')
      await TimeSheetService.update({ id: timeSheetId, end_date: new Date(), stage: 'completed' })
      this.type = 'next'
      localStorage.removeItem("_TIME_SHEET_TYPE_")
      localStorage.removeItem("_TIME_SHEET_ID_")
    },
    continueIssue() {
      this.$emit('continueIssue')
    },
    startNextIssue() {
      localStorage.removeItem('_CURRENT_ISSUE_ID_')
      localStorage.setItem("_RUNNING_TASK", false)
      localStorage.removeItem("_TIME_SHEET_ID_")
      this.$emit('startNextIssue')
    }

  }
}

</script>
<style></style>