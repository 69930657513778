<template>
     <AvatarGroup>
          <Avatar
            v-for="(item, index) in accountData"
            :key="index"
            :size="item.active ? 'small' : 'small'"
            shape="circle"
            @click="selectAccount(item)"
            style="border: 0px;font-size:12px"
            :style="{ 'background-color': getRandomColor(index) }"
            >

            {{ item.code.slice(0,3) }}
            <v-tooltip activator="parent" location="bottom">{{
              item.name
            }}</v-tooltip>
          </Avatar>
          <Avatar
            style="border: 0"
            :size="show ? 'large' : 'small'"
            shape="circle"
            v-if="show"
            >{{ avatarName ? avatarName.slice(0, 3) : "" }}
            <v-tooltip activator="parent" location="bottom">{{
              avatarName
            }}</v-tooltip>
          </Avatar>

          <v-menu v-if="menuShow()">
            <template v-slot:activator="{ props }">
              <Avatar
                shape="circle"
                v-bind="props"
                size="small"
                style="background-color: '#9c27b0', color: '#ffffff'; border:0px"
              >
                {{ getAvatarLabel() }}
                <v-tooltip activator="parent" location="bottom"
                  >Remaining WorkSpaces {{ getAvatarLabel() }}</v-tooltip
                >
              </Avatar>
            </template>

            <v-list>
              <v-list-item v-for="(item, index) in addedMenu" :key="index">
                <v-list-item-title
                  class="work-space-menu"
                  @click="selectWorkSpaceMenu(item)"
                  >{{ item.name }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </AvatarGroup>
</template>
<script>
import AccountService from '@/api/service/Account/AccountService';
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";

export default {
    components:{
        Avatar,
        AvatarGroup
    },
    data() {
        return {
            accountData:[],
            avatarName:""
        }
    },
    async created() {
        await this.getAccountData();
        this.extraAvatar()
        
    },
    methods:{ 
      extraAvatar() {
      if (
        localStorage.getItem("selectedWorkSpaceId") ==
        localStorage.getItem("workSpaceId")
      ) {
        this.avatarName = localStorage.getItem("avatarName");
        this.show = true;
      } else {
        this.show = false;
      }
    },
        menuShow() {},
        getRandomColor(index) {
            const availableColors = ["#C7D4E7", "#E7CED1", "#FDEFCA", "#F5D4DF"];
            if (index >= 0 && index < availableColors.length) {
            return availableColors[index];
            }
            return "#000000";
        },
        async activeWorkSpace(type, id) {
        if (type == "ws") {
            this.workSpaceData.map((k) => {
            if (k.id == parseInt(id)) {
                k.active = true;
            } else {
                k.active = false;
            }
            });
            this.workSpaceData = [...this.workSpaceData];
        }
        if (type == "pj") {
            this.projectData.map((k) => {
            this.$store.commit("PROJECT_ID", id);
            if (k.id == parseInt(id)) {
                k.active = true;
            } else {
                k.active = false;
            }
            });
            this.projectData = [...this.projectData];
        }
    },
        async getAccountData() {
        try {
            let workSpaceId = localStorage.getItem("workSpaceId");
            let res = await AccountService.getAccountByIssue({
              work_space_id: workSpaceId,
            });
            this.accountData = res.length > 0 ? res : [];
            let accountId = localStorage.getItem("_ACCOUNT_ID_");
            if (!accountId && this.accountData.length > 0) {
            localStorage.setItem("_ACCOUNT_ID_", this.accountData[0].id);
            this.activeWorkSpace("ac", this.accountData[0].id);
            } else {
            this.activeWorkSpace("ac", accountId);
            }
        } catch (e) {
            this.accountData = [];
        }
        }
    }
}
</script>
<style>

>>> .p-avatar-group .p-avatar:hover {
  /* border: 2px solid #ffffff; */
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  z-index:9999;
}
</style>