<template>
  <v-row no-gutters dense class="pl-7 pr-7 pt-10">
    <v-row style="width: 100%" class="pb-4">
      <div>
        <v-col class="text-medium-emphasis font-weight-bold pl-2">
          {{ issueData && issueData.reference_num ? issueData.reference_num : "" }}</v-col
        >
      </div>
      <v-col cols="12" style="display: flex; justify-content: end; column-gap: 15px">
        <div class="mt-0">
          <TimerCount :color="'rgb(18, 88, 171)'" :startStopWatch="startTimer" />
        </div>
        <div v-if="startIssue" class="mt-0">
          <v-btn
            style="color: white; background-color: rgb(67 232 67)"
            @click="createTimeSheet()"
            class="pa-2"
          >
            Start
          </v-btn>
        </div>
        <div v-if="!startIssue" class="mt-0">
          <v-btn
            style="color: white; background-color: #f04444"
            @click="updateTimeSheet()"
            class="pa-2"
          >
            Stop
          </v-btn>
        </div>
        <div class="mt-0">
          <v-btn variant="text" @click="addTask()" class="pa-2">
            <v-icon icon="mdi-playlist-plus" color="primary"></v-icon>
            QA Task
          </v-btn>
        </div>
        <div class="mt-0">
          <v-btn variant="text" @click="addTime()" class="pa-2">
            <v-icon icon="mdi-play-box-outline" color="primary"></v-icon>
            Record Time
          </v-btn>
        </div>

        <v-text>
          <v-menu>
            <template v-slot:activator="{ props: menu }">
              <v-tooltip location="top">
                <template v-slot:activator="{ props: tooltip }">
                  <v-icon
                    v-bind="mergeProps(menu, tooltip)"
                    class="onhover mr-2 pt-3"
                    color="primary"
                    >mdi-eye
                  </v-icon>
                </template>
                <span>Viewers</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in userList" :key="index">
                <div style="display: flex; padding: 0">
                  <div style="display: flex; align-items: center">
                    <Avatar
                      size="xsmall"
                      :label="getAvatarName(item)"
                      class="mr-2"
                      :style="` background:${item.theme}`"
                      style="color: #880e4f"
                      shape="circle"
                    />
                  </div>

                  <div class="pa-0 ma-0">
                    <v-card-text style="font-size: small" class="pa-0 ma-0">{{
                      item.name
                    }}</v-card-text>
                    <v-card-text style="font-size: xx-small" class="pa-0 ma-0">{{
                      item.email
                    }}</v-card-text>
                  </div>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- <b>
            {{
              issueData && issueData.watchers ? issueData.watchers.length : 0
            }}views</b> -->
        </v-text>
        <v-icon color="primary" class="starBox pt-3">mdi-star</v-icon>
        <v-icon color="primary" class="pt-3">mdi-share-variant-outline</v-icon>
        <v-icon class="pt-3">mdi-lightning-bolt-outline</v-icon>
        <v-icon class="pt-3">mdi-close</v-icon>
      </v-col>
    </v-row>

    <v-col cols="" class="pt-3">
      <v-row class="pl-2">
        <div class="pb-2">
          <pre><v-text class="pt-3 text-h6" style="font-weight: bolder;">{{ issueData && issueData.name ? issueData.name : '' }}</v-text></pre>
        </div>
      </v-row>
      <v-row>
        <v-text class="text-grey pb-7" style="margin-left: 7px"
          >Created on
          {{ issueData && issueData.name ? getTheDate(issueData.created) : "" }}
          | .
          <span class="text-uppercase" style="color: red; font-weight: bold">{{
            issueData && issueData.priority ? issueData.priority : ""
          }}</span>
          . | .
          {{ issueData && issueData.category_name ? issueData.category_name : "" }}|
          {{
            issueData && issueData.sub_category_name ? issueData.sub_category_name : ""
          }}
          |
          {{
            issueData && issueData.issue_type_name ? issueData.issue_type_name : ""
          }}</v-text
        >
        <v-icon
          class="pl-3"
          color="primary"
          style="font-size: 20px; cursor: pointer"
          @click="editIssue()"
          >mdi-square-edit-outline</v-icon
        >
      </v-row>
      <div style="display: grid; row-gap: 30px">
        <v-card style="background-color: #e2eff6" class="pa-4" :elevation="0">
          <v-icon class="DesIco" color="primary" @click="editDesc()"
            >mdi-square-edit-outline</v-icon
          >
          <v-icon class="DesIco1" color="primary" @click="openDialog"
            >mdi-arrow-expand</v-icon
          >
          <v-card-text
            class="description"
            style="height: 300px; font-size: 12px; font-weight: 400"
            v-html="issueData && issueData.description ? issueData.description : ''"
          />
        </v-card>
        <v-dialog v-model="dialogOpen" class="dialogOpen" width="55%" max-width="55%">
          <v-card class="descriptionCard">
            <v-icon class="DesIco" color="primary" @click="editDesc()"
              >mdi-square-edit-outline</v-icon
            >
            <v-card-text class="description" v-html="issueData.description" />
            <v-card-actions>
              <v-row justify="end" class="dialogBottom">
                <v-btn class="mr-2 cancel" @click="closeDialog">Close</v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div>
          <v-row class="pt-4 pb-5 pl-3"
            ><v-text class="sub-title"> Attachments</v-text>
            <!-- <v-icon class="pl-10" color="primary">mdi-plus-circle</v-icon> -->
            <uploadFiles @documentsData="documentsData($event)" :type="'icon'" />
          </v-row>

          <v-table fixed-header>
            <thead>
              <tr>
                <th class="text-left" style="background-color: #e2eff6">Name</th>
                <th class="text-left" style="background-color: #e2eff6">User</th>
                <th class="text-left" style="background-color: #e2eff6">Date</th>
                <th class="text-left" style="background-color: #e2eff6">Download</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="documents.length > 0">
                <tr v-for="item in documents" :key="item.name">
                  <td>{{ item && item.doc_name ? item.doc_name : "" }}</td>
                  <td>{{ item && item.user_name ? item.user_name : "" }}</td>
                  <td>
                    {{ item && item.user_name ? getTheDate(item.created) : "" }}
                  </td>
                  <td>
                    <i
                      class="pi pi-download download-icon"
                      @click="downloadDoc(item)"
                    ></i>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="4" class="text-center">There is no data</td>
                </tr>
              </template>
            </tbody>
          </v-table>
        </div>

        <div>
          <v-row class="pt-4 pb-5 pl-3"
            ><v-text class="sub-title"> Sub Tasks</v-text>
            <v-icon class="pl-10" color="primary" @click="addChildIssue()"
              >mdi-plus-circle</v-icon
            >
          </v-row>
          <v-table fixed-header>
            <thead>
              <tr>
                <th class="text-left" style="background-color: #e2eff6">Id</th>
                <th class="text-left" style="background-color: #e2eff6">Name</th>
                <th class="text-left" style="background-color: #e2eff6">Stage</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="childIssue.length > 0">
                <tr
                  v-for="item in childIssue"
                  :key="item.name"
                  @click="viewChildIssue(item)"
                  style="cursor: pointer"
                >
                  <td>
                    {{
                      issueData && issueData.reference_num ? issueData.reference_num : ""
                    }}/{{ item && item.reference_num ? item.reference_num : "" }}
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.stage }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="4" class="text-center">There is no data</td>
                </tr>
              </template>
            </tbody>
          </v-table>
        </div>
      </div>
      <v-row class="pt-9 pb-5 pl-3"
        ><v-text class="sub-title">Next Action</v-text>
        <v-icon class="pl-3" color="primary" @click="addNextAction()"
          >mdi-square-edit-outline</v-icon
        >
      </v-row>
      <v-card style="background-color: #e2eff6" class="pa-4" :elevation="0">
        <v-row style="padding: 1%">
          <Avatar
            size="xsmall"
            :label="getAvatar()"
            class="mr-2"
            style="color: #880e4f"
            shape="circle"
          />
          <pre
            class="mt-1"
          ><v-text>{{ issueData && issueData.next_action_name ? issueData.next_action_name : "" }}</v-text></pre>
        </v-row>
      </v-card>
      <v-row class="pt-9 pb-5 pl-3"><v-text class="sub-title">Commments</v-text></v-row>
      <TextFieldComponent
        @input="showSaveButton"
        class="pt-2"
        placeholder="Add a commment"
        v-model="comment"
      />
      <div class="mr-3">
        <v-row justify="start" class="dialogBottom" style="background-color: inherit">
          <v-btn
            v-if="showButton"
            class="mr-6 save"
            type="submit"
            @click="saveTheComments()"
            >Save</v-btn
          >
          <v-btn v-if="showButton" class="mr-6 cancel">Cancel</v-btn>
          <v-col cols="3" class="pt-0" style="display: flex">
            <v-icon class="pt-3">mdi-attachment</v-icon>
            <TextFieldComponent disabled label="Attachment.pdf" />
          </v-col>
          <!-- <v-col cols="3">
                <input 
                label="Insights"
                 type="file">
      </v-col>  -->
        </v-row>
        <v-row
          justify="start"
          class="dialogBottom mr-5"
          v-for="item in commentData"
          :key="item"
          style="background-color: inherit"
        >
          <v-col cols="1" sm="2" md="1" lg="1" xl="1">
            <Avatar
              :label="getAvatarName(item)"
              class="mr-2"
              size="large"
              :style="` background:${item.theme}`"
              shape="circle"
              style="color: #880e4f"
            ></Avatar>
          </v-col>

          <v-col cols="8" sm="6" md="8" lg="8" xl="8">
            <span
              ><b>{{ item && item.email ? item.email : "" }}</b></span
            >
            <span
              style="
                padding-left: 22px;
                font-size: 12px;
                padding-top: 1px;
                color: #9e9e9e;
              "
              >{{ minutes ? minutes : "" }}</span
            >
            <div>
              {{ item && item.description ? getCleanText(item.description) : "" }}
            </div>
          </v-col>
          <v-divider color="success"></v-divider>
        </v-row>
      </div>

      <v-col class="view-all" @click="ViewAll()" v-if="showViewAll">
        <span>3 View All</span>
      </v-col> </v-col
    ><v-divider class="divider" vertical></v-divider>

    <v-col cols="4" class="pl-15 pt-3">
      <div style="display: grid; row-gap: 30px">
        <v-card
          style="height: 400px; background-color: #e2eff6"
          :elevation="0"
          class="pa-7"
        >
          <v-row class="pb-3" style="justify-content: space-between">
            <v-cols>Reported By</v-cols>
            <v-cols justify="end"
              ><b>{{
                issueData && issueData.owner_name ? issueData.owner_name : ""
              }}</b></v-cols
            >
          </v-row>
          <v-row class="pb-3" style="justify-content: space-between">
            <v-cols>Assigned To</v-cols>
            <v-cols justify="end"
              ><b>{{
                issueData && issueData.next_action_name ? issueData.next_action_name : ""
              }}</b></v-cols
            >
          </v-row>
          <v-row class="pb-3" style="justify-content: space-between">
            <v-cols>Assigned on</v-cols>
            <v-cols justify="end"
              ><b
                >{{ issueData && issueData.name ? getTheDate(issueData.created) : "" }}
              </b></v-cols
            >
          </v-row>
          <v-row class="pb-3" style="justify-content: space-between">
            <v-cols>Estimate</v-cols>
            <v-cols justify="end"><b>4 hours</b></v-cols>
          </v-row>
          <v-row class="pb-3" style="justify-content: space-between">
            <v-cols>Estimated By</v-cols>
            <v-cols justify="end"
              ><b>{{
                issueData && issueData.next_action_name ? issueData.next_action_name : ""
              }}</b></v-cols
            >
          </v-row>
          <v-row class="pb-3" style="justify-content: space-between">
            <v-cols>Approved By</v-cols>
            <v-cols justify="end"
              ><b>{{
                issueData && issueData.owner_name ? issueData.owner_name : ""
              }}</b></v-cols
            >
          </v-row>
        </v-card>

        <v-card
          elevation="0"
          style="background-color: #e2eff6"
          v-if="issueData && issueData.stage != 'completed'"
        >
          <v-row>
            <v-col>
              <v-col class="pa-0 pl-3 pt-3 text-caption">Project</v-col>
              <v-col class="pa-0 pl-3"
                ><strong>{{
                  issueData && issueData.project_name ? issueData.project_name : ""
                }}</strong></v-col
              >
              <!-- <v-col class="pa-0 pl-3 pt-3 text-caption">Task</v-col>
              <v-col class="pa-0 pl-3"><strong>{{  issueData && issueData.task_name ? issueData.task_name : '' }}</strong></v-col> -->
            </v-col>

            <v-col>
              <v-col class="pa-0 pl-3 pt-3 text-caption">Task</v-col>
              <v-col class="pa-0 pl-3"
                ><strong>{{
                  issueData && issueData.task_name ? issueData.task_name : ""
                }}</strong></v-col
              >
            </v-col>
          </v-row>
          <!-- <v-sheet class="pa-4 ma-4" :height="79" color="#F3F9FF">Gross margin differences</v-sheet> -->
          <v-row class="mt-5" justify="space-between">
            <v-col align="center"
              ><v-card-text class="pa-0">Time Elapsed</v-card-text>
              <h1>
                <Timer
                  :startAndStop="showStart"
                  :startTime="startSeconds"
                  ref="timeRef"
                ></Timer>
              </h1>
            </v-col>
            <v-col align="center" class="pt-3 pr-8" v-if="showTimerButton"
              ><v-btn
                block
                rounded="xl"
                color="indigo-darken-1"
                @click="openStartDiolag()"
                >Start</v-btn
              ></v-col
            >
            <v-col align="center" class="pt-3 pr-8" v-if="!showTimerButton"
              ><v-btn block rounded="xl" color="indigo-darken-1" @click="openStopDiolag()"
                >Stop</v-btn
              ></v-col
            >
          </v-row>

          <v-dialog persistent v-model="dialog1" :width="size.width">
            <TaskStopDialog
              @issueComplete="completeTheIssue()"
              @continueIssue="continueIssue()"
              @startNextIssue="startNextIssue()"
            ></TaskStopDialog>
          </v-dialog>
        </v-card>
      </div>
    </v-col>
  </v-row>
  <v-dialog v-model="dialog" :width="size.width">
    <NextAction
      @closeDialog="close()"
      ref="NextAction"
      :id="issueId"
      @reload="getIssueData()"
    />
  </v-dialog>
  <v-dialog v-model="editDialog" :width="size.width">
    <IssueEdit
      @closeDialog="close()"
      ref="IssueEdit"
      :id="issueId"
      @reload="getIssueData()"
    />
  </v-dialog>
  <v-dialog v-model="dDialog" :width="600">
    <EditDescription
      @closeDialog="close()"
      ref="EditDescription"
      :id="issueId"
      @reload="getIssueData()"
    />
  </v-dialog>
  <v-dialog v-model="timesheetDialog" :width="900" persistent>
    <TimeSheets
      ref="TimeSheets"
      @closeDialog="close()"
      :issueData="issueData"
      @reload="setViewData()"
    ></TimeSheets>
  </v-dialog>
  <v-dialog v-model="taskDialog" :width="700" persistent>
    <QaTask
      ref="QaTask"
      @closeDialog="close()"
      :issueData="issueData"
      @reload="setViewData()"
    ></QaTask>
  </v-dialog>
</template>

<script>
import uploadFiles from "@/components/uploadFiles.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import IssueService from "@/api/service/Issue/IssueService";
import documentService from "@/api/service/Document/DocumentService";
import ForumService from "@/api/service/Forum/ForumService";
import Avatar from "primevue/avatar";
import moment from "moment-timezone";
import Timer from "@/components/Timer.vue";
import TaskStopDialog from "@/components/TaskStopDialog.vue";
import UserService from "@/api/service/User/UserService";
import NextAction from "@/modules/issues/NextAction.vue";
import IssueEdit from "@/modules/issues/IssueEdit.vue";
import EditDescription from "./EditDescription.vue";
// import commonService from "@/common.service";
import { mergeProps } from "vue";
import lodash from "lodash";
import commonService from "@/common.service";
import TimeSheetService from "@/api/service/TimeSheet/TimeSheetService.js";
import TimeSheets from "./views/TimeSheets.vue";
import QaTask from "./views/QaTask.vue";
import TimerCount from "@/menus/Timer.vue";

export default {
  components: {
    TextFieldComponent,
    uploadFiles,
    NextAction,
    IssueEdit,
    EditDescription,
    TimerCount,
    // commonService,
    TaskStopDialog,
    Timer,
    Avatar,
    TimeSheets,
    QaTask,
  },

  data() {
    return {
      // fav: true,
      // issueData: { description: '' },
      dialogOpen: false,
      dialog1: false,
      startIssue: true,
      showTimerButton: true,
      showStart: true,
      showViewAll: true,
      showButton: false,
      dialog: false,
      editDialog: false,
      dDialog: false,
      startTimer: false,
      timesheetDialog: false,
      taskDialog: false,
      menu: false,
      startSeconds: null,
      // message: false,
      comment: null,
      //   issueData: null,
      userList: [],
      size: {},
      documents: [],
      childIssue: [],
      commentData: [],
      timeData: null,
      totalHours: [],
      minutes: null,
      timeSheetData: null,
    };
  },
  async created() {
    // this.gettime();

    if (this.$route.query.id) {
      await this.getIssueData();
    }
    this.setViewData();
    this.issueId = this.$route.query.id;
  },

  computed: {
    getRoute() {
      return this.$route.query.id;
    },
    // formattedTime() {
    //   const options = { hour: "numeric", minute: "numeric", hour12: true };
    //   return this.date.toLocaleTimeString("en-US", options);
    // },
  },
  watch: {
    async getRoute() {
      this.issueId = this.$route.query.id;
      await this.getIssueData();
    },
  },
  methods: {
    moment,
    addTask() {
      this.taskDialog = true;
    },
    addTime() {
      this.timesheetDialog = true;
    },
    mergeProps,
    setBreadCrumps() {
      let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
      if ($wsDefaults) {
        $wsDefaults = JSON.parse($wsDefaults);
        $wsDefaults.issue = { id: this.issueData.id, name: this.issueData.name };
        localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults));
        // localStorage.setItem("_WS_DEFAULTS_",JSON.stringify($wsDefaults))
        this.$store.commit("SET_REFRESH_BREADCRUMP", true);
      }
    },
    async createTimeSheet() {
      let userData = JSON.parse(localStorage.getItem("user_details"));
      let data = {};
      data.company_id = userData.company_id;
      data.customer_id = this.issueData.account_id;
      data.description = this.issueData.name;
      data.issue_id = this.issueData.id;
      data.project_id = this.issueData.project_id;
      data.resource_id = userData.id;
      data.stage = "pending";
      data.task_id = this.issueData.task_id;
      data.start_date = moment(new Date())
        .utcOffset("+05:30")
        .format("YYYY/MM/DD HH:mm:ss");
      data.entry_date = moment(new Date())
        .utcOffset("+05:30")
        .format("YYYY/MM/DD HH:mm:ss");
      console.log(data, "data.start_date ");
      await TimeSheetService.create(data);
      this.startIssue = false;
      this.startTimer = true;
    },
    async updateTimeSheet() {
      let res = await TimeSheetService.findAll({ issue_id: this.$route.query.id });
      res = lodash.orderBy(res, ["id"], ["desc"]);
      let timesheetData = res[0];
      let data = {};
      data.id = timesheetData.id;
      //let $now= moment(new Date()).tz('utc')
      // console.log('Now',$now.format('YYYY-MM_DD HH:mm:ss'))
      // console.log('From DB',timesheetData.start_date)
      // console.log('Start Date',moment(timesheetData.start_date).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss'));
      //let $start_date = moment(timesheetData.start_date,true).tz('Asia/Kolkata')
      // console.log('Start Date',$start_date)
      // console.log('Duration',$now.diff($start_date,'seconds')/60/60);
      data.end_date = moment(new Date())
        .utcOffset("+05:30")
        .format("YYYY/MM/DD HH:mm:ss");
      let end_date = moment(new Date()).tz("utc");
      let start_date = moment(timesheetData.start_date, true).tz("Asia/Kolkata");
      // console.log(moment(start_date).fromNow())
      var hours = end_date.diff(start_date, "seconds") / 60;
      // console.log(end_date,'data.end_date ')
      // console.log(start_date,'data.end_date ')
      // console.log(hours,'data.end_date ')
      data.actual_hrs = hours ? parseFloat(hours.toFixed(2)) : 0;
      console.log(data, "dhnefhnwi3finalal");
      await TimeSheetService.update(data);
      this.startIssue = true;
      this.startTimer = false;
    },
    openDialog() {
      this.dialogOpen = true;
    },
    closeDialog() {
      this.dialogOpen = false;
    },

    showSaveButton() {
      this.showButton = this.comment.length > 0;
    },
    getCleanText(str) {
      let cleanText = str.replace(/<\/?[^>]+(>|$)/g, "");
      return cleanText;
    },
    async gettime() {
      let res = await ForumService.findAll();
      for (let i = 0; i < res.length; i++) {
        // var now = new Date().getTime();
        // var then = new Date(res[i].created).getTime();
        // var diff = now - then;
        // var minutes = Math.floor(diff / (1000 * 60)) % 60;
      }
    },

    async setViewData() {
      let userData = JSON.parse(localStorage.getItem("user_details"));
      if (!this.issueData.watchers.includes(userData.id)) {
        this.issueData.watchers.push(userData.id);
      }
      IssueService.update({
        id: this.issueData.id,
        watchers: JSON.stringify(this.issueData.watchers),
      });
    },
    async viewChildIssue(data) {
      this.$router.push({
        path: "/issueView",
        query: { type: "view", id: data.id, parent_id: this.$route.query.id },
      });
      await this.getIssueData();
    },

    async completeTheIssue() {
      let issueId = localStorage.getItem("_CURRENT_ISSUE_ID_");
      let timeSheetId = localStorage.getItem("_TIME_SHEET_ID_");
      await IssueService.update({
        id: issueId,
        end_date: new Date(),
        stage: "completed",
      });
      await TimeSheetService.update({
        id: timeSheetId,
        end_date: new Date(),
        stage: "completed",
      });
      localStorage.removeItem("_CURRENT_ISSUE_ID_");
      localStorage.setItem("_RUNNING_TASK", false);
      localStorage.removeItem("_TIME_SHEET_ID_");
      this.dialog1 = false;
      this.getIssueData();
    },
    getAvatar() {
      let first_name =
        this.issueData && this.issueData.first_Name ? this.issueData.first_Name[0] : "";
      let last_name =
        this.issueData && this.issueData.last_name ? this.issueData.last_name[0] : "";
      return first_name + last_name;
    },
    // reload(){
    //   this.$router.go(this.$router.currentRoute)
    // },

    async openStartDiolag() {
      localStorage.setItem("_CURRENT_ISSUE_ID_", this.issueData.id);
      localStorage.setItem("_RUNNING_TASK", true);
      let tsRes = await TimeSheetService.findAll({ issue_id: this.issueData.id });
      if (tsRes.length == 0) {
        await IssueService.update({
          id: this.issueData.id,
          stage: "inprogress",
          start_date: new Date(),
        });
      } else {
        await IssueService.update({ id: this.issueData.id, stage: "inprogress" });
      }
      let data = {
        description: this.issueData.name,
        entry_date: new Date(),
        issue_id: this.issueData.id,
        issue_task_id: this.issueData.task_id,
        project_id: this.issueData.project_id,
        start_date: new Date(),
        stage: "inprogress",
      };
      let timeSheetRes = await TimeSheetService.create(data);
      this.timeSheetData = timeSheetRes ? timeSheetRes : {};
      localStorage.setItem("_TIME_SHEET_ID_", this.timeSheetData.data.data.id);
      // this.startTime = this.formattedTime(timeSheetRes.data.data.start_date)
      // console.log(this.startTime, "000000000bala")
      this.$refs.timeRef.timeValue = 0;
      this.$refs.timeRef.startTimer();
      this.showStart = false;
      this.showTimerButton = false;
    },

    formattedTime(data) {
      let date = new Date(data);
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      return date.toLocaleTimeString("en-US", options);
    },

    async openStopDiolag() {
      let issueId = localStorage.getItem("_CURRENT_ISSUE_ID_");
      await IssueService.update({ id: issueId, end_date: new Date(), stage: "open" });
      this.$refs.timeRef.stopTimer();
      this.showStart = true;
      this.dialog1 = true;
      this.size = commonService.smallDialog();
    },
    getAvatarName(value) {
      let name =
        value && value.first_name
          ? value.first_name.charAt(0)
          : "" + value && value.last_name
          ? value.last_name.charAt(0)
          : "";
      return name;
    },
    downloadDoc(item) {
      documentService.download({ id: item.id }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("name", "downloadLink");
        link.setAttribute("download", response.headers["x-suggested-filename"]); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    documentsData($event) {
      this.issueData.attachment =
        this.issueData && this.issueData.attachment
          ? JSON.parse(this.issueData.attachment)
          : [];
      this.issueData.attachment.push($event.id);
      this.issueData.attachment = JSON.stringify(this.issueData.attachment);
      IssueService.update({
        id: this.issueData.id,
        attachment: this.issueData.attachment,
      });
      this.getDocument();
    },
    addChildIssue() {
      this.$router.push({
        path: `AddIssue`,
        query: { parent_id: this.issueData.id },
      });
    },
    addNextAction() {
      this.dialog = true;
      this.size = commonService.mediumDialog();
    },
    editIssue() {
      this.editDialog = true;
      this.size = commonService.mediumDialog();
    },
    editDesc() {
      this.dDialog = true;
      // this.size = commonService.mediumDialog();
    },

    async getViewList() {
      try {
        var res = await UserService.getViewUser({
          userId: JSON.stringify(this.issueData.watchers),
        });
        this.userList = res ? res : [];
      } catch (e) {
        this.userList = [];
      }
    },

    getTheDate(date) {
      let $date = new Date(date);
      let formattedDate = $date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      return formattedDate;
    },
    async getChildIssue() {
      try {
        let res = await IssueService.getChildIssue({
          parent_issue_id: this.issueData.id,
        });
        this.childIssue = res;
      } catch (e) {
        this.childIssue = null;
        console.log(e);
      }
    },
    async saveTheComments() {
      let data = {
        key_type: "issue",
        key_id: this.issueData.id,
        description: this.comment,
      };
      await ForumService.create(data);
      this.comment = null;
      this.showButton = false;
      this.getForumData();
    },
    async getIssueData() {
      try {
        let res = await IssueService.getIssueById({
          issue_id: this.$route.query.id,
        });
        this.issueData = res;
        this.issueData.watchers =
          this.issueData && this.issueData.watchers
            ? JSON.parse(this.issueData.watchers)
            : [];
        let issueId = localStorage.getItem("_CURRENT_ISSUE_ID_");
        let type = localStorage.getItem("_TIME_SHEET_TYPE_");
        if (type) {
          this.dialog1 = true;
          this.size = commonService.smallDialog();
        } else if (issueId == this.issueData.id) {
          let timeSheetId = localStorage.getItem("_TIME_SHEET_ID_");
          let res = await TimeSheetService.findOne(timeSheetId);
          let today = new Date();
          var start_date = moment(new Date(res[0].start_date), "YYYY-MM-DD HH:mm:ss");
          var end_date = moment(today, "YYYY-MM-DD HH:mm:ss");
          var duration = moment.duration(end_date.diff(start_date));
          let hour =
            duration && duration._data && duration._data.hours > 0
              ? duration._data.hours * 3600
              : 0;
          let munites =
            duration && duration._data && duration._data.minutes > 0
              ? duration._data.minutes * 60
              : 0;
          let seconds =
            duration && duration._data && duration._data.seconds
              ? duration._data.seconds
              : 0;
          this.startSeconds = hour + munites + seconds;
          this.showTimerButton = false;
        }
        this.getChildIssue();
        this.getDocument();
        this.getForumData();
        this.getViewList();
        this.setBreadCrumps();
      } catch (e) {
        this.issueData = null;
        console.log(e);
      }
    },
    async getForumData() {
      try {
        let res = await ForumService.getForumList({
          key_id: this.issueData.id,
          key_type: "issue",
        });
        this.backupComment = JSON.parse(JSON.stringify(res));
        this.commentData = res.slice(0, 3);
      } catch (e) {
        this.commentData = [];
        console.log(e);
      }
    },
    async continueIssue() {
      this.openStartDiolag();
      this.$refs.timeRef.timtimeValue = 0;
      this.dialog1 = false;
    },
    startNextIssue() {
      this.dialog1 = false;
      this.$router.push("/issuelist");
    },
    // ViewAll() {
    //   let data = this.backupComment.slice(this.commentData.length - 1, this.commentData.length + 3);
    //   this.commentData = this.commentData.concat(data)
    //   this.showViewAll = this.commentData.length === this.backupComment.length ? false : true
    // },
    ViewAll() {
      let data = this.backupComment.slice(
        this.commentData.length - 1,
        this.commentData.length + 3
      );
      this.commentData = this.commentData.concat(data);
      if (this.commentData.length > 3) {
        this.showViewAll =
          this.commentData.length === this.backupComment.length ? true : false;
      } else {
        this.showViewAll = false;
      }
    },

    close() {
      this.editDialog = false;
      this.dialog = false;
      this.dDialog = false;
      this.timesheetDialog = false;
      this.taskDialog = false;
    },
    async getDocument() {
      try {
        let res = await documentService.getAllDocuments({
          doc_id: this.issueData.attachment,
        });
        this.documents = res;
      } catch (e) {
        this.documents = [];
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
* {
  font-family: sans-serif;
}

.padding-table-columns td {
  padding: 0 30px 0 0;
}

.file-input-container .v-input__slot {
  height: 40px;
  /* Adjust the height value as per your requirements */
}

.divider {
  margin-top: 640px;
  position: relative;
  left: 30px;
}

>>> .pi-download:before:hover {
  content: "\e956";
  background-color: aliceblue;
  padding: 41%;
  border-radius: 13%;
}

.view-all {
  text-align: center;
  background: #dddbdb70;
  font-weight: bold;
  color: #4f63ff;
  border-radius: 7px;
  cursor: pointer;
}

.download-icon {
  font-size: 1rem;
  /* margin: 1%; */
  padding: 4%;
  border-radius: 3px;
  cursor: pointer;
}

.download-icon:hover {
  font-size: 1rem;
  background-color: aliceblue;
  border-radius: 3px;
  width: 30px;
  height: 30px;
}

.sub-title {
  font-weight: 600;
  font-size: 15px;
  color: #8e8a8a;
}

.onhover {
  cursor: pointer;
}

.DesIco {
  right: 5px;
  position: absolute;
  top: 6px;
}

.DesIco1 {
  position: absolute;
  top: 5px;
  right: 35px;
}

>>> .v-table {
  --v-table-header-height: 35px;
  border-radius: inherit;
  line-height: 1.5;
  max-width: 100%;
}

>>> .v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th {
  background: rgb(var(--v-theme-surface));
  box-shadow: inset 0 -1px 0 rgba(var(--v-border-color), var(--v-border-opacity));
  z-index: 1;
  font-size: 15px;
  font-weight: bold;
}

>>> .v-table__wrapper {
  border-radius: inherit;
  overflow: auto;
  max-height: 150px !important;
}

/* .table{
	max-width: calc(100% - 48px);
	max-height: calc(100vh - 150px);
}
.v-table {
	overflow: auto;
}
.v-table /deep/ .v-table__wrapper {
	overflow: unset;\
} */
/* p {
    font-size: small
} */
>>> ::-webkit-scrollbar {
  width: 6px;
  /* height: 6px; */
}

/* Track */
>>> ::-webkit-scrollbar-track {
  background: #e8f3f9;
}

/* Handle */
>>> ::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
}

.description {
  font-size: 16px;
  font-weight: 400;
  color: #272727;
}

>>> p {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #272727 !important;
}

.descriptionCard {
  position: relative;
}

/* .dialogOpen{ 
  overflow: hidden; 
  position: absolute;
  right: 480px; 
  height: 100%;
  max-height: 100%; 
} */
/* Handle on hover */
/* >>>::-webkit-scrollbar-thumb:hover {
  background: #e8f3f9;
} */
/* .starBox:focus{
  background-color: black ;
} */
.starBox:active {
  color: olive;
}

.starBox:focus {
  color: olive;
}
</style>
