<template>
  <v-row no-gutters>
                <v-col cols="3" class="pa-2" >
                <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Location
                <!-- <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                > -->
                </v-label
              >
              <br>
              <span >{{ location? location:'' }}</span>
            </v-col>
            
            <v-col cols="3" class="pa-2">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Roles
                <!-- <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                > -->
                </v-label
              >
              <br>
              <span >{{ role?role:'' }}</span>
            </v-col>
            <v-col cols="6" class="pa-2">
                <v-label
                class="pb-1"
                style="font-size: 14px; font-weight: 700;color:black !important"
                >Reporting Manager
                <!-- <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                > -->
                </v-label
              >
              <br>
              <span >{{ reportingManager?reportingManager:'' }}</span>
            </v-col>
            
            <v-col cols="6" class="pa-2">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Work Timings
                <!-- <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                > -->
                </v-label
              >
              <br>
              <v-chip  class="selectedChip" label :disabled="showReadOnly"  v-for="item in worktimings"
              :key="item" >
              {{ item.name }}
            </v-chip>
            </v-col>
            </v-row>
</template>

<script>
// import TextFieldComponent from "@/components/TextFieldComponent.vue";
import UserService from "@/api/service/UserService";
import WorkTimingService from "@/api/service/WorkTiming/WorkTimingService";
import LocationService from "@/api/service/Location/LocationService";
import RoleService from "@/api/service/Role/RoleService"
export default {
  components: {
      // TextFieldComponent,
      // AutoComplete,
    },
    data(){
      return{
        userDetails: JSON.parse(localStorage.getItem("user_details")),
        worktimings:[],
        userData:[],
        reportingManager:'',
        location:'',
        role:'',
        selectedWorkTimings:{
                name:'test'


        }
      }
    },
    created(){
      
      if(this.userDetails.id){
         this.getUserData(this.userDetails.id)
      }
     
    },
    methods:{
         async getUserData(id) {
      let res = await UserService.findOne({ id: id });
      this.userData = res[0];
      let res1=await WorkTimingService.getWorkTimings({'work_timing':this.userData.work_timings})
      this.worktimings=res1
      if(this.userData.reporting_mgr_id){
        this.getReportingManagerId(this.userData.reporting_mgr_id)
      }
      this.getRole(this.userData.role_id);
      this.getLocation(this.userData.location_id)
    },
    async getReportingManagerId(id){
           try {
        let res = await UserService.findOne({id:id});
        this.reportingManager = res.length>0 && res[0].username ?res[0].username:'';
      } catch (e) {
        this.reportingManager = [];
        console.log(e);
      }
    },
    async getLocation(id){
           try {
        let res = await LocationService.findOne({id:id});
        this.location = res.length>0 && res[0].name ?res[0].name:'';
      } catch (e) {
        this.location = [];
        console.log(e);
      }
    },
    async getRole(id){
           try {
        let res = await RoleService.findOne({id:id});
        this.role = res.length>0 && res[0].name ?res[0].name:'';
      } catch (e) {
        this.role = [];
        console.log(e);
      }
    },

    }

}
</script>

<style>

</style>