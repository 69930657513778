<template>
  <div>
    <v-form @submit.prevent="save" ref="form" v-model="value">
      <v-card-item>
        <v-card-title
          >{{
            viewType == "edit"
              ? "Edit"
              : viewType == "select"
              ? "View"
              : viewType == "view"
              ? "View"
              : "Add"
          }}
          <b>WorkTiming</b>
          <v-btn
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <v-row no-gutters class="mt-3">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <!-- Input Field -->

          <v-col cols="3">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Code
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              >
            </v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Code"
              :rules="codeRule"
              v-model="formData.code"
              :disabled="showReadOnly"
              @keyup="checkCode()"
              @keydown="prevent($event)"
            />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>
          <v-col cols="5" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Name
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Name"
              :rules="nameRule"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </v-col>
          <v-col cols="3" class="pl-4" >
            <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Time Zone
                <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                ></v-label
              >

              <TimeZoneList
                :updateData="formData.timezone"
                @timeZoneChange="timeZoneChange"
                :rules="timeZoneRule"
              />
          </v-col>
          <v-col cols="2" >
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Start Time
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Start Time"
              :rules="startTimeRule"
              type="time"
              @change="caculateGrossTime"
              v-model="formData.start_time"
              :disabled="showReadOnly"
              showTime
              hourFormat="12"
              @input="calculateTimeDifference"
            />
          </v-col>
          <v-col cols="2" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >End Time
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="End Time"
              type="time"
              :rules="endTimeRule"
              :error-messages="endTimeError"
              v-model="formData.end_time"
              :disabled="showReadOnly"
              showTime
              hourFormat="12"
              @change="calculateTimeDifference(true)"
            />
          </v-col>
          <v-col cols="1" class="pl-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Mon
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Mon"
              v-model="formData.mon"
              :disabled="showReadOnly"
              @keypress="allowNumbers($event)"
              @keyup="caculateTotalHours()"
            />
          </v-col>
          <v-col cols="1" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Tue
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Tue"
              v-model="formData.tue"
              :disabled="showReadOnly"
              @keypress="allowNumbers($event)"
              @keyup="caculateTotalHours()"
            />
          </v-col>
          <v-col cols="1" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Wed
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Wed"
              v-model="formData.wed"
              :disabled="showReadOnly"
              @keypress="allowNumbers($event)"
              @keyup="caculateTotalHours()"
            />
          </v-col>
          <v-col cols="1" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Thu
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Thu"
              v-model="formData.thu"
              :disabled="showReadOnly"
              @keypress="allowNumbers($event)"
              @keyup="caculateTotalHours()"
            />
          </v-col>
          <v-col cols="1" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Fri
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Fri"
              v-model="formData.fri"
              :disabled="showReadOnly"
              @keypress="allowNumbers($event)"
              @keyup="caculateTotalHours()"
            />
          </v-col>
          <v-col cols="1" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Sat
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Sat"
              v-model="formData.sat"
              :disabled="showReadOnly"
              @keypress="allowNumbers($event)"
              @keyup="caculateTotalHours()"
            />
          </v-col>
          <v-col cols="1" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Sun
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Sun"
              @keypress="allowNumbers($event)"
              v-model="formData.sun"
              :disabled="showReadOnly"
              @keyup="caculateTotalHours()"
            />
          </v-col>
          <v-col cols="3" >
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Total Hours
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Total Hours"
              type="none"
              :rules="totalHoursRule"
              v-model="formData.total_hours"
              :disabled="true"
            />
          </v-col>
          <v-col cols="12">
            <div class="text-h6">Break 1</div>
          </v-col>
          <v-col cols="2" class="pt-3">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Minutes
            </v-label>
            <TextFieldComponent
              density="compact"
              placeholder="minutes"
              v-model="formData.br_hour1"
              @keydown="prevent1($event)"
              :disabled="showReadOnly"
              showTime
              hourFormat="12"
              @change="updateEndTime($event.target.value)"
            />
          </v-col>
          <v-col cols="12" class="mt-4">
            <div class="text-h6">Break 2</div>
          </v-col>

          <v-col cols="2" class="pt-3">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Minutes
            </v-label>
            <TextFieldComponent
              density="compact"
              placeholder="minutes"
              @keydown="prevent1($event)"
              v-model="formData.br_hour2"
              :disabled="showReadOnly"
              showTime
              hourFormat="12"
              @change="updateEndTime($event.target.value)"
            />
          </v-col>
          <v-col cols="12" class="mt-4">
            <div class="text-h6">Break 3</div>
          </v-col>

          <v-col cols="2" class="pt-3">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Minutes
            </v-label>
            <TextFieldComponent
              density="compact"
              placeholder="minutes"
              @keydown="prevent1($event)"
              v-model="formData.br_hour3"
              :disabled="showReadOnly"
              showTime
              hourFormat="12"
              @change="updateEndTime($event.target.value)"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-3">
          <v-col cols="3">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Punch Begin Before
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Punch Begin Before "
              type="time"
              v-model="formData.punch_begin_before"
              :disabled="showReadOnly"
              showTime
              hourFormat="12"
            />
          </v-col>

          <v-col cols="3" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Punch End After
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Punch End After"
              type="time"
              @change="caculateGrossTime"
              v-model="formData.punch_end_after"
              :disabled="showReadOnly"
              showTime
              hourFormat="12"
            />
          </v-col>
          <v-col cols="3" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Grace Time
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Grace Time"
              v-model="formData.grace_time"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
  </div>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import WorkTimingService from "@/api/service/WorkTiming/WorkTimingService";
import moment from 'moment';
import TimeZoneList from "@/components/others/TimeZoneList.vue";
export default {
  components: {
    TextFieldComponent,
    TimeZoneList
  },

  data() {
    return {
      grossTime:'',
      showReadOnly: false,
      codeError: false,
      viewType: true,
      codeExistFlag: false,
      duration: "",
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      startTimeRule: [(value) => !!value || "Start time is Required."],
      endTimeRule: [(value) => !!value || "End time is Required."],
      totalHoursRule: [(value) => !!value || "Total hours must not be zero."],
      dialog: false,
      type: null,
      endTimeError: "",
      originalEndTime: null,
      accumulatedBreakHours: 0,
      formData: {
        code: null,
        name: null,
        mon: null,
        tue: null,
        wed: null,
        thu: null,
        fri: null,
        sat: null,
        sun: null,
        total_hours: null,
        start_time: null,
        end_time: null,
        br_hour1: null,
        br_hour2: null,
        br_hour3: null,
        break1_end_time: null,
        break2_start_time: null,
        break2_end_time: null,
        punch_begin_before: null,
        punch_end_after: null,
        grace_time: null,
        special_days: null,
        special_days_start_time: null,
        special_days_end_time: null,
        week_off: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },

  created() {
    if (this.$route.query.id) {
      this.Id = parseInt(this.$route.query.id);
      this.getById(this.$route.query.id);
    }
    if (this.$route.query.type) {
      this.viewType = this.$route.query.type;
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    
    timeZoneChange($event) {
      this.formData.timezone = $event;
    },
 
 updateEndTime(){
  let br1 =this.formData.br_hour1?parseInt(this.formData.br_hour1) : 0
  let br2 =this.formData.br_hour2?parseInt(this.formData.br_hour2) : 0
  let br3 =this.formData.br_hour3?parseInt(this.formData.br_hour3) : 0
  let inMinuts = br1+br2+br3


     let endTime = moment(this.originalEndTime , "HH:mm");

     if(endTime){
      let newEndTime = endTime.add(inMinuts, 'minutes')
      newEndTime = newEndTime.format('HH:mm')

      this.formData.end_time = newEndTime
     }
     this.calculateTimeDifference()
 },
    
    prevent1(e) {
            if (!/^\d*\.?\d{0,2}$/.test(e.key) && e.key !== "Backspace") {
                e.preventDefault();
            }
        },
    prevent(e) {
  if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
},
    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        WorkTimingService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    allowNumbers(evt) {
  evt = evt ? evt : window.event;
  let expect = evt.target.value.toString() + evt.key.toString();
  if (expect !== '' && (expect < 0 || expect > 24) || (!/^[0-9]*\.?[0-9]*$/.test(expect)) ) {
        evt.preventDefault();
      }else {
    return true;
  }
},

    close() {
      this.$router.push("/setting/organisation/workTiming");
    },
    async getById(id) {
      let res = await WorkTimingService.findAll({ id: id });
      this.formData = res[0];
    },

    caculateTotalHours() {
      let data = [];
      data.push(this.formData.mon);
      data.push(this.formData.tue);
      data.push(this.formData.wed);
      data.push(this.formData.thu);
      data.push(this.formData.fri);
      data.push(this.formData.sat);
      data.push(this.formData.sun);
      let $total = 0;
      data.forEach((key) => {
        $total += key ? parseFloat(key) : 0;
      });
      $total = parseFloat($total).toFixed(2)
      this.formData.total_hours = $total;
    },


    caculateGrossTime(){   

      let startTime = moment(this.formData.start_time , "HH:mm");
      let punchEnd = moment(this.formData.punch_end_after , "HH:mm");
  
      let diffInPunchMin = punchEnd.diff(startTime, "minutes");
      let diffInPunchHours = diffInPunchMin / 60;
      diffInPunchHours = parseFloat(diffInPunchHours).toFixed(2);
    
      this.formData.grace_time = diffInPunchHours
    },
  
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError ) {
        console.log("Not valid");
      } else {
        if (this.formData.id) {
          await WorkTimingService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          
          try {
            console.log(this.formData, "checkdata1111111111111111111111");
            await WorkTimingService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.dialog = false;
            this.close();
            this.$emit("reload", true);
            this.formData = {};
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },


    calculateTimeDifference(value) {

      let startExact = moment(this.formData.start_time , "HH:mm");
      let startExact1 = moment(this.formData.start_time , "HH:mm");

      let punchBegin = startExact.subtract(30, 'minutes')
      let punchEnd = startExact1.add(30, 'minutes')

      punchBegin = startExact.format('HH:mm')
      punchEnd = startExact1.format('HH:mm')

      this.formData.punch_begin_before = punchBegin
      this.formData.punch_end_after = punchEnd

      const startTime = this.formData.start_time;
      const endTime = this.formData.end_time;

      if (startTime && endTime) {
        const startTimeMoment = moment(startTime, "hh:mm A");
        const endTimeMoment = moment(endTime, "hh:mm A");

        if (endTimeMoment.isBefore(startTimeMoment)) {
          this.endTimeError = "End time should be after start time";
        } else {
          this.endTimeError = "";
        }

        const diffInMinutes = endTimeMoment.diff(startTimeMoment, "minutes");
        let diffInHours = diffInMinutes / 60;
        diffInHours = parseFloat(diffInHours).toFixed(2);
         
        this.updateHours(diffInHours);
        if(value){
          this.originalEndTime =this.formData.end_time
        }
      }
    },

    updateHours(hoursDifference) {
      ["mon", "tue", "wed", "thu", "fri"].forEach((day) => {
        this.formData[day] = hoursDifference;
      });
      
      let totalHours = hoursDifference * 5; 
      totalHours = parseFloat(totalHours).toFixed(2);

      this.formData.total_hours = totalHours
    },
  },
};
</script>
