<template>
    <div style="min-height:70vh;padding-top:15%">
          <RotateSquare2 background="red" class="32d3db" style="border-radius:50%;color:yellow;margin:auto auto;zoom: 100%" />
        <br/>
        <span style="font-size:14px;color:#273974;font-weight:900;text-align: center;display: block;">
          {{displayText ?  displayText : 'Loading Data' }}
        </span>
      </div>
</template>
<script>
import { RotateSquare2 } from "vue-loading-spinner";

export default {
    props:["displayText"],
    components:{RotateSquare2}
}
</script>
<style lang="scss" scoped>
  .spinner {
    position: relative;
    * {
        line-height: 0;
        box-sizing: border-box;
        border-radius: 50%;
    }
    &:before {
      content: '';
      width: 100%;
      height: 20%;
      min-width: 5px;
      background: #000;
      opacity: 0.1;
      position: absolute;
      bottom: 0%;
      left: 0;
      border-radius: 50%;
      animation: rotate-square-2-shadow 3s linear infinite;
    }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background:#1405e4;
      background: #00B4DB;  /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, #0083B0, #00B4DB);  /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, #0083B0, #00B4DB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      background:url('@/assets/image/SynergyLogo.png') no-repeat center;
      animation: rotate-square-2-animate 3s linear infinite;
      position: absolute;
      bottom:40%;
      left: 0;
      border-radius: 3px;
    }
  }
  @keyframes rotate-square-2-animate {
   from {
    transform:  rotatey(0deg);
    transition-delay: 100ms;
  }
  to {
    transform:   rotatey(359deg);
  }
  }
  @keyframes rotate-square-2-shadow {
    from {
    transform:  rotatey(0deg);
    transition-delay: 100ms;
  }
  to {
    transform:   rotatey(359deg);
  }
  }
</style>