<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title
          >{{ viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add" }}
          <b> Contact </b>
          <v-btn
            @click="setFalse()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="mt-3">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Email
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Email"
              :rules="emailRule"
              v-model="formData.email"
              :disabled="showReadOnly"
              @keyup="checkEmail()"
            />
            <v-error v-if="emailError">Already Exists!</v-error>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >First Name
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="First Name"
              :rules="FNameRule"
              v-model="formData.first_name"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="4" class="pl-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Middle Name</v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Middle Name"
              v-model="formData.middle_name"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="4" class="pl-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Last Name
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              :rules="LNameRule"
              placeholder="Last Name"
              v-model="formData.last_name"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Contact Type
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <!-- <AutoComplete
              :items="dropdownitems"
              :placeholder="'Contact Type'"
              :item-title="'name'"
              :disabled="showReadOnly"
              :item-value="'value'"
              v-model="formData.key_type"
              :rules="contacttypeRule"
            /> -->

            <EnumAutoComplete density="compact" v-model="formData.key_type" :selected="formData.key_type"
                :showReadOnly="showReadOnly" :domain="'CONTACTTYPE'" placeholder="Contact Type"
                @changeEnumData="changeContactType($event)" :rules="contacttypeRule" />
          </v-col>

          <v-col cols="4" class="pl-2" v-if="formData.key_type === 'account'">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Account
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :items="keyIdItems"
              :placeholder="'Key ID'"
              :item-title="'name'"
              :disabled="showReadOnly"
              :item-value="'id'"
              v-model="formData.key_id"
              :rules="KeyIdRule"
            />
          </v-col>

          <v-col cols="6" class="pl-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Mobile</v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Mobile"
              v-model="formData.mobile_no"
              :disabled="showReadOnly"
              @keypress="allowNumbers($event)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
          <v-btn class="save" :loading="saveClicked" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
<script>
//   import TextFieldComponent from "@/components/TextFieldComponent.vue";
//   import RoleService from "@/api/service/Role/RoleService";
//   import EnumAutoComplete from "../../../../components/others/EnumAutoComplete.vue";
//   import commonService from "@/common.service";
import ContactService from "@/api/service/Contact/ContactService.js";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AccountService from "@/api/service/Account/AccountService";
import EnumAutoComplete from "@/components/others/EnumAutoComplete.vue";
export default {
  props: ["id", "viewType"],
  components: {
    AutoComplete,
    TextFieldComponent,
    EnumAutoComplete
  },

  data() {
    return {
      contacttypeRule: [(value) => !!value || "Contact Type is Required."],
      KeyIdRule: [(value) => !!value || " is Required."],
      FNameRule: [(value) => !!value || "First Name is Required."],
      LNameRule: [(value) => !!value || "Last Name is Required."],
      emailRule: [
        (value) => !!value || "E-mail is required.",
        (value) => {
          if (/.+@.+\..+/.test(value)) {
            this.isValidEmail = true;
            return true;
          }
          this.isValidEmail = false;
          return "E-mail must be valid.";
        },
      ],
      isValidEmail: false,
      showReadOnly: false,
      emailError: false,
      dropdownitems: [
        { id: 1, name: "Account", value: "account" },
        { id: 2, name: "Warehouse", value: "warehouse" },
        { id: 3, name: "Others", value: "others" },
      ],
      formData: {
        key_type: null,
        key_id: null,
        first_name: null,
        last_name: null,
        middle_name: null,
        mobile_no: null,
        email: null,
      },
      keyIdItems: [],
    };
  },

  watch: {
    id() {
      this.getContacts();
    },
    "formData.key_type": function () {
      if (this.formData.key_type !== "account") {
        this.formData.key_id = null;
      }
    },
  },
  created() {
    if (this.id) {
      this.getContacts();
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
    this.getkeyId();
    this.formData.active = 1;
  },
  methods: {
    allowNumbers(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    changeContactType($event) {
      this.formData.key_type = $event;
    },
    async getContacts() {
      let res = await ContactService.findAll({ id: this.id });
      this.formData = res ? res[0] : {};
    },
    async getkeyId() {
      let res = await AccountService.findAll();
      this.keyIdItems = res ? res : [];
      console.log("ressss", res);
    },
    setFalse() {
      this.$emit("closeDialog");
    },

    checkEmail() {
      if (this.formData.email && this.formData.email.length > 0) {
        this.formData.email = this.formData.email.toLowerCase();

        ContactService.findAll({ email: this.formData.email }).then((res) => {
          let $exist = res.filter((key) => key.email == this.formData.email);

          this.emailExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.emailExistFlag) {
            this.emailError = true;
          } else {
            this.emailError = false;
          }
        });
      }
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.emailError) {
        console.log("Not valid");
      } else {
        this.saveClicked = true;
        if (this.formData.id) {
          let output = await ContactService.update(this.formData);
          console.log(output);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          //   this.dialog = false;
          this.setFalse();
          //   this.$emit("reload", true);
          this.formData = [];
        } else {
          try {
            let output = await ContactService.create(this.formData);
            console.log(output);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            //   this.dialog = false;
            this.setFalse();
            //   this.$emit("reload", true);
            this.formData = [];
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>
