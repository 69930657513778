<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title
          >{{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }}
          <b>Sub Task</b>
          <v-btn
            @click="setFalse()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Code
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Code"
              :rules="codeRule"
              v-model="formData.code"
              :disabled="showReadOnly"
              @keyup="checkCode()"
              @keydown="prevent($event)"
            />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>
          <v-col cols="9">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Name
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Name"
              :rules="nameRule"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </v-col>

          <!-- Input Field -->

          <v-col cols="4" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Sub Task-Type [E]
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <EnumAutoCompleteVue
              density="compact"
              :rules="typeRule"
              v-model="formData.task_type"
              :showReadOnly="showReadOnly"
              :selected="formData.task_type"
              :domain="value"
              placeholder="Task Type"
              @changeEnumData="changeTask($event)"
            />
          </v-col>
          <v-col cols="5" class="pl-5">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Map To
              </v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Map To"
              v-model="formData.map_to"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Cancle And Save Button -->

      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
// import AutoComplete from "@/components/ui/AutoComplete.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import TaskService from "@/api/service/Task/TaskService";
import EnumAutoCompleteVue from "../../../../components/others/EnumAutoComplete.vue";

export default {
  props: ["id", "viewType"],
  components: {
    // AutoComplete,
    TextFieldComponent,
    EnumAutoCompleteVue,
  },
  data() {
    return {
      showReadOnly: false,
      TaskData: [],
      codeRule: [(value) => !!value || "Code is Required"],
      nameRule: [(value) => !!value || "Name is Required"],
      typeRule: [(value) => !!value || "Sub Task type is Required"],
      stageData: [
        { value: "open", display: "Open" },
        { value: "close", display: "Close" },
      ],
      dialog: false,
      type: null,
      size: {},
      value: "taskType",
      codeError: false,
      formData: {
        name: null,
        code: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.TaskData = {};
        this.getById(this.id);
      }
    },
  },

  created() {
    if (this.id) {
      this.getById(this.id);
    }

    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },

  methods: {
    prevent(e) {
  if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
},
    changeTaskType($value) {
      this.formData.task_type = $value;
    },

    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();

        TaskService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    changeTask($value) {
      this.formData.task_type = $value;
    },
    setFalse() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await TaskService.findOne(id);
      this.formData = res[0];
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        if (this.formData.id) {
          let output = await TaskService.save(this.formData);
          console.log(output);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.setFalse();
          this.dialog = false;
          this.$emit("reload", true);
          this.formData = [];
        } else {
          try {
            console.log(this.formData, "dataaaa");
            let output = await TaskService.create(this.formData);
            console.log(output);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.dialog = false;
            this.setFalse();
            this.$emit("reload", true);
            this.formData = [];
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>


<style scoped>
.dropdown >>> .v-messages__message {
  line-height: 12px;
  color: red;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding-left: 0px;
  transition-duration: 150ms;
  text-align: left;
  margin-left: -16px;
}
</style>