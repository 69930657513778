<template>
  <v-card flat height="100vh" class="filterCard1">
    <v-card-title><v-icon>mdi-filter-outline</v-icon> Filters</v-card-title>
    <v-card-text>
      <v-col cols="12">
        <AutoComplete
          placeholder="Clients"
          :items="clients1"
          item-title="name"
          item-value="id"
          density="compact"
          @update:modelValue="clientChanged"
        />

        <v-chip
          closable
          class="chip"
          label
          :disabled="showReadOnly"
          v-for="item in selectedClient"
          :key="item"
          @click:close="removeChips(item)"
        >
          {{ item.name }}
        </v-chip>
      </v-col>
      <!-- <v-row no-gutters>
      <v-col cols="12">
        <AutoComplete
          placeholder="Clients"
          :items="clients"
          item-title="name"
          item-value="id"
          density="compact"
          @update:modelValue="clientChanged"
          :disabled="showReadOnly"
        />

        <v-chip
          closable
          class="chip"
          label
          :disabled="showReadOnly"
          v-for="item in selectedClient"
          :key="item"
          @click:close="removeChips(item)"
        >
          {{ item.name }}
        </v-chip>
      </v-col>
      <v-col cols="12">
        <AutoComplete
          placeholder="Task"
          :items="clients"
          item-title="name"
          item-value="id"
          density="compact"
          @update:modelValue="clientChanged"
          :disabled="showReadOnly"
        />

        <v-chip
          closable
          class="chip"
          label
          :disabled="showReadOnly"
          v-for="item in selectedClient"
          :key="item"
        >
          {{ item.name }}
        </v-chip>
      </v-col>
      <v-col cols="12">
        <AutoComplete
          placeholder="Team"
          :items="clients"
          item-title="name"
          item-value="id"
          density="compact"
          @update:modelValue="clientChanged"
          :disabled="showReadOnly"
        />

        <v-chip
          closable
          class="chip"
          label
          :disabled="showReadOnly"
          v-for="item in selectedClient"
          :key="item"
        >
          {{ item.name }}
        </v-chip>
      </v-col>
      <v-col cols="12">
        <AutoComplete
          placeholder="Resource"
          :items="clients"
          item-title="name"
          item-value="id"
          density="compact"
          @update:modelValue="clientChanged"
          :disabled="showReadOnly"
        />

        <v-chip
          closable
          class="chip"
          label
          :disabled="showReadOnly"
          v-for="item in selectedClient"
          :key="item"
        >
          {{ item.name }}
        </v-chip>
      </v-col>
      <v-col cols="12">
        <AutoComplete
          placeholder="Shifts"
          :items="clients"
          item-title="name"
          item-value="id"
          density="compact"
          @update:modelValue="clientChanged"
          :disabled="showReadOnly"
        />

        <v-chip
          closable
          class="chip"
          label
          :disabled="showReadOnly"
          v-for="item in selectedClient"
          :key="item"
        >
          {{ item.name }}
        </v-chip>
      </v-col>
    </v-row> -->
    </v-card-text>
  </v-card>
</template>
<script>
import AutoComplete from "../../components/ui/AutoComplete.vue";
import commonService from "@/common.service";
export default {
  components: {
    AutoComplete,
  },
  data() {
    return {
      selectedClientId: [],
      backupClient: [],
      backupClient1:[],
      selectedClient: [],
      clients1:[],
      clients: [
        {
          id: 1,
          name: "California",
        },
        {
          id: 2,
          name: "Colorado",
        },
        {
          id: 3,
          name: "Florida",
        },
        {
          id: 4,
          name: "Georgia",
        },
        {
          id: 5,
          name: "Texas",
        },
        {
          id: 6,
          name: "Wyoming",
        },
      ],
    };
  },
  created(){
         this.clients1=this.clients
    },
    methods:{
          clientChanged($value){
            this.selectedClientId.push($value)
            this.backupClient=JSON.parse(JSON.stringify(this.clients)),
            this.backupClient1=JSON.stringify(this.clients)
            this.showChip()
          },
          showChip(){
            let chipRes=commonService.getMultipleChip(this.backupClient, this.selectedClientId, 'id')
            this.selectedClient=chipRes
            this.clients1=commonService.getObjectSliceMethod(this.backupClient1,this.selectedClientId,'id')
          },
          removeChips(data) {
            let index = this.selectedClientId.findIndex(k => k == data.id)
            if (index > -1) {
            this.selectedClientId.splice(index, 1)
          }
            this.showChip()
      },
  },

};
</script>

<style scoped>
.chip {
  height: 24px !important;
  font-size: 11px;
  border-radius: 25px;
  background-color: #fae2ee;
  color: #ffffff;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #333333;
}

>>>.v-chip.v-chip--size-default {
    --v-chip-size: 0.875rem;
    --v-chip-height: 4px;
    font-size: 12px;
    padding: 0 12px;
}
>>>.v-chip__close {
    cursor: pointer;
    flex: 0 1 auto;
    font-size: 14px;
    max-height: 0px;
    max-width: 11px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

>>> .v-field {
  cursor: text;
  font-size: 14px;
  color: #777777;
  border-radius: 25px;
  border: 1px solid #696868;
  background: #fff;
  height: 35px;
}
>>> .v-field:focus-within {
  cursor: text;
  border-radius: 25px;
  border: 1px solid #696868;
  background: #fff;
}

.filterCard1 {
  border-radius: 12px;
  border: 1px solid #1258ab;
  background-color: rgba(18, 88, 171, 0.05);
}
</style>