
<template>
    <div class="card flex justify-content-center">
        <span class="p-fluid">
        <Calendar v-model="date" :placeholder="placeholder" :baseZIndex="9999" :modelValue="date" :minDate="minimumDate"
            :maxDate="maximumDate" @update:modelValue="onDateUpdate"
            :panelStyle="{width: '400px', transformOrigin: 'center bottom', top: '170px', borderRadius: '10px' }"
            panelClass="calendar-container" dateFormat="dd/mm/yy" :disabled="showReadOnly" :showTime="showTime" :hourFormat="hourFormat"/>
        </span>

    </div>
</template>

<script>
import Calendar from 'primevue/calendar';
import moment from 'moment';



export default {
    props: ['placeholder', 'minDate', 'maxDate', 'data', 'showReadOnly','showTime','hourFormat'],
    components: {
        Calendar
    },
    data() {
        return {
            minimumDate: null,
            maximumDate: null,
            date: this.data ? moment(this.data).format('DD/MM/YYYY') : null

        }
    },
    watch: {
        data() {
            if (this.data) {
                console.log(this.data,'mmmmmmmm')
                this.date = this.data ? moment(this.data).format('DD/MM/YYYY') : null
                console.log(this.date,'mmmmmmmm333')

            }
            else{
                this.date=null
            }
        },
        minDate() {
            if (this.minDate) {
                this.minimumDate = new Date(this.minDate)

            }
        },
        maxDate() {
            if (this.maxDate) {
                this.maximumDate = new Date(this.maxDate)

            }


        },
    },
    created() {
        //alert(this.data)
    },
    methods: {

        onDateUpdate(date) {
            this.$emit('selectDate', date)
        }
    }


}

</script>
<style>
.p-inputtext {
    color: #777777 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    font-family: "Roboto", sans-serif !important;
    background: #ECECEC;
    padding: 0.75rem 0.75rem;
    border: 0px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 6px;
}

.p-inputtext:enabled:focus {
    outline: 8 none;
    outline-offset: 0;
    border: 0px solid #3C5AAA !important;
    box-shadow: 0 0 1px 2px #3C5AAA !important;
    background-color: #ffffff !important;
}

.p-button {
    color: #ffffff;
    background: #1258AB;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: #1258AB;
    border: 1px solid #1258AB;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    transition-duration: 0.2s, 0.2s, 0.2s, 0.2s;
    transition-timing-function: ease, ease, ease, ease;
    transition-delay: 0s, 0s, 0s, 0s;
    transition-property: background-color, color, border-color, box-shadow;
    border-radius: 6px;
}

.calendar-container {
    z-index: 9999;
    width: 408px;
    min-width: 209px;
    transform-origin: center bottom;
    top: 0px;
    left: 473.984px;
}
</style>