<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b> Role</b>
          <v-btn @click="setFalse()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">

          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="4">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" @keyup="checkCode()" placeholder="Code" :rules="codeRule"
              v-model="formData.code" :disabled="showReadOnly" @keydown="prevent($event)" />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>

          <v-col cols="8" class="pl-4">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Name" :rules="nameRule" v-model="formData.name"
              :disabled="showReadOnly" />
          </v-col>
          <v-col cols="7" class="dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">RoleType [E]
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <EnumAutoComplete density="compact" :showReadOnly="showReadOnly"  placeholder="RoleType" :selected="formData.role_type" :domain="value"
              @changeEnumData="changeRoleType($event)" :rules="roleTypeRule"/>
          </v-col>

          <v-col cols="7" class="dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Reporting
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <AutoComplete :items="reportRoleData" item-value="id" item-title="name" density="compact"
              placeholder="Reporting" :rules="reportingRule" v-model="formData.reporting_id"
              @update:modelValue="selectRepot" :disabled="showReadOnly" />

            <v-chip class="selectedChip" label closable v-for="item in selectReportData" :key="item" :close="false"
              close-icon="mdi-close-circle"  :disabled="showReadOnly" close-transition="scale-transition" @click:close="cancelReport(item)">
              {{ item.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
          <v-btn class="save" :loading="saveClicked" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import RoleService from "@/api/service/Role/RoleService";
import EnumAutoComplete from "../../../../components/others/EnumAutoComplete.vue";
import AutoComplete from "../../../../components/ui/AutoComplete.vue";
import commonService from "@/common.service";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    EnumAutoComplete,
    AutoComplete,
  },

  data() {
    return {
      saveClicked: false,
      showReadOnly: false,
      dialog: false,
      type: null,
      codeRule: [(value) => !!value || "Code is Required."],
      // codeRule: [
      //   value => {
      //     if (!value){ 
      //     this.codeError = null
      //     return 'Code Is Required'
      //   }
      //     else if(this.codeError){
      //      this.codeError = true
      //     }
      //   },
      // ],
      nameRule: [(value) => !!value || "Name is Required."],
      roleTypeRule:[(value) => !!value || "RoleType is Required."],
      // reportingRule: [(value) => !!value || "Reporting is Required."],
      value: "roleType",
      reportRoleData: [],
      selectReportId: [],
      codeError: false,
      formData: {
        code: null,
        name: null,
        role_type: null,
        reporting_id: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    this.getrols();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    prevent(e) {
  if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
},
checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        RoleService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    selectRepot($value) {
      this.selectReportId.push($value)
      this.setTheData()
    },
    setTheData() {
      let res = commonService.getObjectSliceMethod(JSON.stringify(this.backupReportRoleData), this.selectReportId, 'id')
      let chipData = commonService.getMultipleChip(this.backupReportRoleData, this.selectReportId, 'id')
      this.reportRoleData = res
      this.selectReportData = chipData
      this.selectReportData = [...this.selectReportData]
      this.reportRoleData = this.reportRoleData.length>0?[...this.reportRoleData]:[]
      this.formData.reporting_id = null
    },
    changeRoleType($value) {
      this.formData.role_type = $value
      this.getReporterData(this.formData.role_type)
    },
    setFalse() {
      this.$emit("closeDialog");
    },
    cancelReport(data) {
      let index = this.selectReportId.findIndex(k => k == data.id)
      if (index > -1) {
        this.selectReportId.splice(index, 1)
      }
      this.setTheData()

    },
    async getReporterData(id) {

      this.selectReportData = []
      this.selectReportData = [...this.selectReportData]
      this.selectReportId = []
      this.selectReportId = [...this.selectReportId]

      try {
        let res = await RoleService.getReporterData({ role_type: id, type: 'role' })
        this.backupReportRoleData = JSON.parse(JSON.stringify(res))
        this.reportRoleData = res.length > 0 ? await commonService.getSort(res,'name','asc') : [];
        this.reportRoleData = [...this.reportRoleData]
        console.log(this.reportRoleData, "77777")
      }
      catch (e) {
        this.reportRoleData = []
      }


    },
    async getById(id) {
      let res = await RoleService.findOne({id:id});
      this.formData = res[0]; 
      await this.getReporterData(this.formData.role_type)
      this.selectReportId = this.formData && this.formData.reporting_id ? JSON.parse(this.formData.reporting_id) : []
      this.setTheData()
    },
    async getrols() {
      let res = await RoleService.findAll();
      this.RoleData = res.length > 0 ? res : [];
    },

    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {
        console.log("Not valid")
      }
      else{
        this.saveClicked = true
        if (this.formData.id) {
          this.formData.reporting_id = JSON.stringify(this.selectReportId)
          let output = await RoleService.update(this.formData);
          console.log(output);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
        } else {
          try{
          this.formData.reporting_id = JSON.stringify(this.selectReportId)
          let output = await RoleService.create(this.formData);
          console.log(output);
          this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
        }catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};

</script>
  