<template>
  <v-card flat style="background-color: #e0f3fd53; border: 1px solid #e0f3fd">
    <div class="px-4 pt-3 pb-1" style="display: flex;justify-content: space-between;">
      <span>
        <span style="font-size: 16px;font-weight: bold;padding-top: 4px;">
          Sub Tasks
        </span>
        <v-icon v-if="showEdit && exceptStage"  class="pl-3 mb-1" color="primary" @click="addChildIssue()">mdi-plus-circle</v-icon>
      </span>

      <span class="spentTime" v-if="totalEst > 0 || totalAct > 0">
        <span v-if="totalEst > 0">{{ totalEst?totalEst:'' }} /</span> <span v-if="totalAct > 0">{{ totalAct?totalAct:'' }}</span> Mins
      </span>
    </div>
    <!-- <v-divider /> -->
    <v-card-text style="min-height: 135px" class="py-0" v-if="childIssue && childIssue.length > 0">
      <div v-for="(childData, index) in childIssue" :key="index" style="display: flex; justify-content: space-between;">
        <div style="display: flex; gap: 10px;margin: 5px 0px;" @click="viewChildIssue(childData)">
          <!-- <CustomAvatar class="pt-1" v-if="childData && childData.next_action" :userId="childData.next_action"
            :size="'medium'" /> -->

          <UserSelection :newView="true"  :childStage="childData.stage" :nextActionId="childData && childData.next_action
            ? childData.next_action
            : null
            " @userSelected="onUserSelected($event, childData.id)" />

          <div :class="!childData.next_action?'ml-3':''">
            <div class="issueTitle">
              <span v-if="childData.issue_type_name" style="text-transform: capitalize;">
                {{ childData && childData.issue_type_name ? childData.issue_type_name : "" }}/
              </span>
              {{ childData && childData.name ? childData.name : "" }}
            </div>
            <div class="issueData">
              <div>
                {{
                  childData && childData.end_date
                  ? moment(childData.end_date).format("MM-DD-YYYY hh:mm A")
                  : ""
                }}
                <div>
                  <span style="text-transform: capitalize;">
                    {{
                      childData && childData.task_name ? childData.task_name : ""
                    }}
                  </span>

                  <span v-if="childData.estimate">
                    | Est
                    {{
                      childData && childData.estimate ? childData.estimate : ""
                    }}m
                  </span>

                  <span v-if="childData.actual_hrs">
                    | Act
                    {{
                      childData && childData.actual_hrs ? parseFloat(childData.actual_hrs).toFixed(2) : ""
                    }}m
                  </span>
                  <!-- <span  style="font-weight:600">
                    | <v-chip label style="background-color:#eedc82;">{{childData && childData.stage_name}}</v-chip>
                  </span> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex;flex-direction: column" class="mt-1">
          <v-img :style="{ width: '24px', height: '24px' }" :src="getStatusImage(childData)" />
        </div>

      </div>
    </v-card-text>

    <v-container v-else class="text-center pt-0" justify-center >
            <v-icon style="font-size:100px;" class="">
              <img :src="require('@/assets/no-data-found.svg')" width="56" height="56" />
            </v-icon>
            <br/>
            <span class="text-body2 mt-2">
              No sub task found for selected task
            </span>
        </v-container>
  </v-card>

  <v-dialog class="Dialog" v-model="addDialog" :width="850" persistent>
    <AddQuickIssue ref="AddQuickIssueDialog" :parentSubject="parentSubject" :viewType="viewType" childIssue="true"  :defaultData="subTaskDefaults"
      @closeDialog="closeDialog($event)">
    </AddQuickIssue>
  </v-dialog>
</template>

<script>
import UserSelection from "@/menus/UserSelection.vue";
import moment from "moment";
// import CustomAvatar from "@/components/ui/CustomAvatar.vue";
import AddQuickIssue from "../issues/AddQuickIssue.vue";
import IssueService from "@/api/service/Issue/IssueService";
export default {
  props: ["parentId", "subTaskDefaults" ,"showEdit", "exceptStage","parentSubject"],
  components: {
    AddQuickIssue,
    // CustomAvatar,
    UserSelection
  },
  data() {
    return {
      childIssue: [],
      issueData: {},
      viewType: "",
      addDialog: false,
      totalEst: 0,
      totalAct: 0,
    };
  },
  created() {
    if (this.parentId) {
      this.getChildIssue();
    }
  },
  watch: {
    subTaskDefaults(){
      if(this.subTaskDefaults){
        console.log(this.subTaskDefaults , 'hjhjbjhbb')
      }
    },
    totalAct(){
      this.getChildIssue()
    },
    parentId() {
      this.getChildIssue();
    },
    getReloadSubTask(){      
    if (this.parentId) {
      this.getChildIssue();
    }
    }
  },
  computed: {
    getReloadSubTask() {
      return this.$store.getters.getReloadSubTask;
    }, 
  },
  methods: {
    totalEstimate() {
      this.totalEst=0;
      for (const value of this.childIssue) {
        this.totalEst += value.estimate;
      }
      this.totalEst = (Math.round(this.totalEst * 100) / 100).toFixed(0);
    },

    totalActual() {
      
     this.totalAct=0;
      for (const value of this.childIssue) {
        this.totalAct += value.actual_hrs;
      }
      this.totalAct = (Math.round(this.totalAct * 100) / 100).toFixed(0);
      console.log(this.totalAct , this.totalEst , 'ndvhbcbcdjb')
    },
    moment,

    getStatusImage(data) {
      let status = data.stage
      if (!data.next_action) {
        console.log(status, 'jdjjjj')
        return require("../../assets/image/others.svg");
      } else if (status == "completed" || status == "closed") {
        return require("../../assets/image/completed.svg");
      } else {
        return require("../../assets/image/inprogress.svg");
      }
    },

    addChildIssue() {
      this.addDialog = true;
      this.viewType = "child";
      /*  this.$router.push({
         path: "/addQuickIssue",
         query: { parent_issue_id: this.parentId },
       }); */
    },
    closeDialog($event) {
      console.log($event, "kkkk");
      this.addDialog = false;
      this.getChildIssue();
    },
    async viewChildIssue(data) {
      this.$store.dispatch('setIssueInView', data.id)
      // await this.getIssueData();
      window.scrollTo(0, 0);
    },
    async getChildIssue() {
      try {
        let res = await IssueService.getSubTaskData({
          issueId: this.parentId,
        });
        this.childIssue = res;
        this.totalEstimate()
        this.totalActual()
        // console.log("xxxxxxxs", this.childIssue);
      } catch (e) {
        this.childIssue = null;
        console.log(e);
      }
    },

    async onUserSelected(event, issueId) {
      let status = this.$store.getters.getAssignee;
      await IssueService.update({
        id: issueId,
        next_action: event.id,
      });
      await this.getChildIssue();
      await this.$store.commit("CHANGE_ASSIGNEE", !status);

      // console.log(issueId, "xxxxxxxs", event);
    },
  },
};
</script>
  
<style scoped>
.spentTime {
  color: #FFF;
  font-size: 10px;
  /* font-weight: 700; */
  background-color: indigo;
  padding: 1px 5px;
  border-radius: 4px;
  height: fit-content;
}

.issueTitle {
  width: 98%;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 3px;
}

.issueData {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
}

>>> ::-webkit-scrollbar {
  width: 0px;
  /* height: 6px;  */
}

/* Track */
>>> ::-webkit-scrollbar-track {
  background: #e8f3f9;
}

/* Handle */
>>> ::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
}
</style>