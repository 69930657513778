<template>
    <PrimeDataTable
      :title="'Skill'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="true"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="skill"

    />
  
    <v-dialog persistent v-model="dialog" width="700px">
      <CreateRisk
        @closeDialog="close()"
        @reload="getRisk()"
        :id="editId"
        :viewType="viewType"
        
      />
    </v-dialog>
  </template>
  
  <script>
  import CreateRisk from "./CreateRisk.vue";
  import PrimeDataTable from "@/components/PrimeDataTable";
  import RiskService from "@/api/service/Risk/RiskService";
  // import commonService from "@/common.service";
  export default {
    components: {
        CreateRisk,
      PrimeDataTable,
    },
    data() {
      return {
        data: [],
        columns: [],
        date: new Date(),
        editId:null,
        dialog: false,
        viewType:false
      };
    },
    created() {
      this.loadColumns();
      this.download = true;
      this.search = true;
      this.checkbox = true;
      this.getRisk();
    },
    methods: {
  
      loadColumns() {
        this.columns = [
          {
            field: "code",
            header: "Code",
            style: { width: "100px", color: "" },
            toolTip: "code",
            sortfield: true,
          },
          {
            field: "name",
            header: "Name",
            style: { width: "100px", color: "" },
            toolTip: "name",
            sortfield: true,
          },
          {
            field: "score",
            header: "Score",
            style: { width: "100px", color: "" },
            toolTip: "score",
            sortfield: true,
          },
          {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
          {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
          ],
        },
        ];
      },
  
      close() {
        this.dialog = false;
      },
      rowActions($event) {
        if ($event.type == "view") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }
        if ($event.type == "edit") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }   
  
        if ($event.type == "select") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType="view";
        }
      },
      async getRisk() {
        let res = await RiskService.findAll();
        this.data = res;
      },
      addDiolog() {
        this.dialog = true;
        this.editId = null;
        this.viewType = false;
      }
    },
  };
  </script>
  
  
  