<template>
  <v-container fluid >
    <v-row no-gutters dense justify="space-between">
      <v-col>
        <span class="text-h6">Productivity Snapshot</span>&nbsp;
        <span>
          <i @click="openFilter()" class="pi pi-filter" /> </span>&nbsp;
        <span>
          <i class="pi pi-download" /> </span>&nbsp;
        <span>
          <i class="pi pi-ellipsis-v" />
        </span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-chip class="" v-for="item in items" :key="item" color="#087BBC" closable>{{ item }}</v-chip>
      </v-col>
    </v-row>
    <v-row class="px-0">
      <v-col class="px-0" cols="12">
        <Hours1 />
      </v-col>
    </v-row>
    <v-row class="">
      <v-col>
        <PrimeDataTable :title="'Time Sheets'" :showFilter="true" :searchOption="true" :selectAll="true"
          :showDownload="true" :showUpload="false" :showAdd="false" :columnData="columns" :data="timeSheetData"
          @addDiolog="addDiolog()" @rowAction="rowActions($event)" activity="timesheet" />
      </v-col>
      <v-dialog v-model="dialog" class="dialogFilter" persistent :offset-y="32">
        <DashBoardFilter class="dialogFilter" @close="close()" ref="DashBoardFilter" />
      </v-dialog>
    </v-row>
  </v-container>
</template>
    
<script>
import DashBoardFilter from "./DashBoardFilter.vue";
import Hours1 from "../ProductivityDashboard/Hours1.vue";
import PrimeDataTable from "../../components/PrimeDataTable.vue";
export default {
  components: {
    DashBoardFilter,
    Hours1,
    PrimeDataTable,
  },
  data() {
    return {
      dialog: false,
      columns: [],
      items: ["Rajesh", "Jan-23", "IAL", "RED", "SHIFT A"],
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
  },
  methods: {
    openFilter() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    loadColumns() {
      this.columns = [
        {
          field: "created",
          header: "Date",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
          type: "datetime",
        },
        {
          field: "project_name",
          header: "Project",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        {
          field: "task_name",
          header: "Task",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },

        {
          field: "start_date",
          header: "Started by",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
          type: "datetime",
        },

        {
          field: "end_date",
          header: "Finished at",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
          type: "datetime",
        },

        {
          field: "actual_hrs",
          header: "Total hours",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
      ];
    },
  },
};
</script>
    
<style >
.dialogFilter{
  padding: 20px;
  width:380px;
  height: 541px;
  border-radius: 0px !important;
}
</style>