 <template>
  <v-container fluid justify-center>
    <p class="text-h5">
      <b>Productivity Comparision</b>
    </p>
    <ShowProductivityBy />
    <v-container
      v-if="1 == 0"
      style=" height:60vh;display: flex; justify-content: center; align-items: center"
    >
      <filterCards />
    </v-container>
    <v-row v-else class="pt-0">
      <v-col cols="3" class="pt-0">
        <Filter />
      </v-col>

      <v-col class="pt-0">
        <v-row class="pt-0">
          <v-col cols="4">
            <PiChart />
          </v-col>

          <v-col cols="4">
            <PiChart />
          </v-col>

          <v-col cols="4">
            <TrendChart />
          </v-col>

          <v-col cols="12">
            <ProductivityTable />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filterCards from "./filterCards.vue";
import ShowProductivityBy from "./ShowProductivityBy.vue";
import PiChart from "./PieChart.vue";
import TrendChart from "./TrendChart.vue";
import Filter from "./Filters.vue";
import ProductivityTable from "./ProductivityTable.vue";
export default {
  components: {
    Filter,
    filterCards,
    PiChart,
    TrendChart,
    ShowProductivityBy,
    ProductivityTable,
  },
};
</script>

<style >
</style>