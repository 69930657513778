<template>
    <table class="mx-3 tableDiv">
      <thead class="header pl-10">
        <tr  v-for="index in 8" :key="index">
          <th>
            <Skeleton width="7rem" height="20px" class=""></Skeleton>
          </th>
        </tr>
      </thead>
      <tbody class="bodyContent pl-10" >
        <div v-for="index in 7" :key="index">
          <tr style="display: flex; gap: 4.2%; height: 100%;align-items: center;" >
            <div v-for="index in 8" :key="index">
              <td style="height: 40px; display: flex;align-items: center;">
            <Skeleton width="7rem" height="20px" class=""></Skeleton>
          </td>
            </div>
        </tr>
        </div>
      </tbody>
    </table>
  </template>
  <script>
  import Skeleton from "primevue/skeleton";
  export default {
    components: {
      Skeleton,
    },
  };
  </script>
  
  <style scoped>
 .tableDiv{
  height: 63vh;
  width: 92vw;
 }

 .header{
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  gap: 4%;
  margin-bottom: 10px;
  border-bottom: 1px solid;
 }

 .bodyContent{
  height: 100%;
  display: grid;
 }
  </style>