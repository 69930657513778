<template>
    <v-card flat class="pa-3 " min-height="90vh">
        <v-card-title>
            Reports
        </v-card-title>
        <v-card-text>
            <v-row no-gutters dense v-for="report in reports" :key="report">
                <v-col cols="12" class="text-left ">
                    <span class="link" v-on:mouseover="checkReports(report)">
                        {{ report.name }} </span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import ListModelService from '@/api/service/ListModel/ListModelService';
export default {
    components: {
        // PrimeDataTable
    },
    data() {

        return {
            reports: [],
        }
    },
    created() {
        this.getReports()

    },
    mounted() {

    },
    methods: {
        async getReports() {
            this.reports = await ListModelService.findAll({ name: 'RPT%' });
        },
        checkReports(data) {
            this.$router.push({ path: '/reports', query: { model_id: data.id } });
        }
    }
}
</script>
<style>
.rowheader {
    background-color: #D7EAF4;
    font-weight: 600;
}

.link {
    color: #3c5aaa;
    text-decoration: none;
}

.link:hover {
    color: #1446ce;
    text-decoration: underline;
    cursor: pointer;
}

.open-issues {
    background-color: #E2D6F8;
    color: #272727;
    font-weight: 10px;
    font-weight: 400;
}

.date {
    color: orange;
}
</style>