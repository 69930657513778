<template>
    <div>
        <Menu style="width: fit-content;z-index: 1004;" ref="menu" :model="menuItems" :popup="true" @itemClick="handleMenuItemClick" />
    </div>
</template>
  
<script>
import Menu from 'primevue/menu';

export default {
    components: {
        Menu,
    },
    props: ['options', 'menuType'],
    computed: {
     menuItems() {
      if (this.menuType === 'issueType') {
        return this.options.map(option => ({
          label: option.name,
          command: () => this.handleMenuItemClick(option.id),
        }));
      }
      if (this.menuType === 'stage') {
        return this.options.map(option => ({
          label: option.name,
          command: () => this.handleMenuItemClick(option.code),
        }));
      } else {
        return this.options.map(option => ({
          label: option.display_value,
          command: () => this.handleMenuItemClick(option.data_value),
        }));
      }
    },
    },
    methods: {
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        handleMenuItemClick(Value) {
            console.log('Menu Item Clicked:', Value);
            this.$emit('optionselected', Value)
        },
    },
};
</script>
<!-- <style scoped>
Menu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    width: fit-content;
}
</style> -->
  

