<template>
    <PrimeDataTable
      :title="'KraReview'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="true"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      @reviewClicked="reviewClicked"
      activity="KRAREVIEW"
     
    />
  
    <v-dialog persistent v-model="dialog" width="700px">
      <CreateKraReview
        @closeDialog="close()"
        @reload="getKraReview()"
        :id="editId"
        :viewType="viewType"
        
      />
    </v-dialog>

    <v-dialog persistent v-model="dialogR" width="600px">
      <CreateUserScoreCard
        @closeDialog="close()"
        @reload="getKraReview()"
        :data = "dataUserReview"
      />
    </v-dialog>

  </template>
  
  <script>
  import CreateKraReview from "./CreateKraReview.vue";
  import PrimeDataTable from "@/components/PrimeDataTable";
  import KraReviewService from "@/api/service/KraReview/KraReviewService";
  import CreateUserScoreCard from "../UserScoreCard/CreateUserScoreCard.vue";
  // import commonService from "@/common.service";
  export default {
    components: {
        CreateKraReview,
      PrimeDataTable,
      CreateUserScoreCard,
    },
    data() {
      return {
        data: [],
        columns: [],
        dataUserReview:{},
        date: new Date(),
        editId:null,
        dialog: false,
        dialogR: false,
        viewType:false
      };
    },
    created() {
      this.loadColumns();
      this.download = true;
      this.search = true;
      this.checkbox = true;
      this.getKraReview();
    },
    methods: {
  
      loadColumns() {
        this.columns = [
          {
            field: "fy",
            header: "Fy",
            style: { width: "100px", color: "" },
            toolTip: "fy",
            sortfield: true,
          },
          {
            field: "user_name",
            header: "User",
            style: { width: "100px", color: "" },
            toolTip: "user_name",
            sortfield: true,
          },
          {
            field: "reviewer_name",
            header: "Reviewer",
            style: { width: "100px", color: "" },
            toolTip: "reviewer_name",
            sortfield: true,
          },
          {
            field: "review_date",
            header: "Review Date",
            style: { width: "100px", color: "" },
            toolTip: "review_date",
            sortfield: true,
            type: "date",
          },
          {
            field: "template_name",
            header: "Template",
            style: { width: "100px", color: "" },
            toolTip: "template_name",
            sortfield: true,
          },
          {
            field: "action",
            header: "Action",
            style: { width: "100px", color: "" },
            toolTip: "Action",
            sortfield: false,
            rowActions: [{ action: "view" }, { action: "edit" }],
          },
        ];
      },
  
      close() {
        this.dialog = false;
        this.dialogR = false;
      },

      reviewClicked(data){
        this.dialogR = true;
        this.dataUserReview = data
        console.log(this.dataUserReview,"ooooooooooooooooooooooo")
    
      },
      rowActions($event) {
        if ($event.type == "view") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }
        if ($event.type == "edit") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }   
  
        if ($event.type == "select") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType="view";
        }
      },
      async getKraReview() {
        let res = await KraReviewService.findKraByName();
        this.data = res;
      },
      addDiolog() {
        this.dialog = true;
        this.editId = null;
        this.viewType = false;
      }
    },
  };
  </script>
  
  
  