<template>
    <div class="py-3" density="compact" flat min-height="90vh">
        <v-card-title class="ml-4">
            My workspace
        </v-card-title>

        <v-row class="pl-7 pt-3">
            <v-col cols="" v-for="(value, key) in workSpace" :key="key">

                <v-card elevation="0" density="compact" class="mainCard px-3" @click="checkActive(key)" :class={active:value.showActive}>

                    <v-card-title class="workSpace pb-1  ">{{ value.code }}</v-card-title>

                  
                            <v-card-text class="chipTxt ">Open issues
                                <div class="chipD">
                                    <v-chip :class="{ active1: value.showActive }" class="chip mt-2" label><span class="chipTxtNO">{{ value.no_of_issues}}</span>
                                    </v-chip>
                                 </div>
                            </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            workSpace: [
                {
                    id: 1,
                    code: "BINO:12",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    no_of_issues: 33,
                    showActive:false,
                    last_updated: "10-06-2023"
                },
                {
                    id: 2,
                    code: "BINO:12:1",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 1,
                    code: "BINO:12",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 2,
                    code: "BINO:12:1",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 1,
                    code: "BINO:12",
                    showActive:false,
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 2,
                    code: "BINO:12:1",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 1,
                    code: "BINO:12",
                    name: "BINO:12 Product",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 2,
                    code: "BINO:12:1",
                    name: "BINO:12 Product",
                    avatar: "",
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 1,
                    code: "BINO:12",
                    name: "BINO:12 Product",
                    avatar: "",
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 2,
                    code: "BINO:12:1",
                    name: "BINO:12 Product",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 1,
                    code: "BINO:12",
                    name: "BINO:12 Product",
                    avatar: "",
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 2,
                    code: "BINO:12:1",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 1,
                    code: "BINO:12",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 2,
                    code: "BINO:12:1",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 1,
                    code: "BINO:12",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 2,
                    code: "BINO:12:1",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 1,
                    code: "BINO:12",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
                {
                    id: 2,
                    code: "BINO:12:1",
                    name: "BINO:12 Product SOP: 2153",
                    avatar: "",
                    showActive:false,
                    no_of_issues: 33,
                    last_updated: "10-06-2023"
                },
            ]
        }
    },
    methods:{
        checkActive(key){
            this.workSpace.forEach((f,index)=>{
              if(key==index){
                f.showActive = true
              }
              else{
                f.showActive = false
              }
            })
        }
    }
}
</script>
  
<style scoped>
.chipTxt {
    font-size: 12px;
    font-weight: 400;
}
.chipTxtNO {
    font-size: 12px;
    font-weight: 700;
}
.mainCard {
    width: 125px;
    height: 105px;
    border-radius: 4px !important;
    background-color: #FFFFFF;
}

.active {
  background-color: #E4C6FF;
  color: black;
  width: 125px;
    height: 105px;
    border-radius: 4px !important;
}
.workSpace {
    font-weight: 700;
    font-size: 14px;
    color: #636363;

}

>>>.v-chip--variant-tonal .v-chip__underlay {
    background-color: inherit;
    color: #272727 !important;
}

>>>.v-chip__content {
    color: #272727 !important;
}

.chip {
    background: #DFDBF6;
}
.chipD{
    display: flex;
    justify-content: center;
}

.chip.active1 {
  background-color: #F0E7F8 !important;
}


</style>
  