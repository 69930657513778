<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div style="display: flex; gap: 16px">
          <div class="titleContainer">Open issues</div>
        </div>
      </v-col>
    </v-row>

    <div style="white-space: nowrap">
      <!-- <ButtonGroup
        :items="limit"
        text="text"
        value="value"
        multiple="true"
        returnObject="false"
        @item-selected="itemSelected"
      />
      <ButtonGroup
        class="ml-7"
        :items="selectionType"
        text="text"
        value="value"
        multiple="true"
        returnObject="false"
        @item-selected="itemSelected"
      /> -->
      <div style="display: flex; gap: 16px">
        <v-row>
          <v-col cols="3">
            <v-switch
              color="primary"
              density="compact"
              v-model="switchGroup.issues"
              label="Issues"
              @change="handleSwitchChange('issues')"
            />
          </v-col>
          <v-col cols="3">
            <v-switch
              color="primary"
              density="compact"
              v-model="switchGroup.estimate"
              label="Estimate"
              @change="handleSwitchChange('estimate')"
            />
          </v-col>
        </v-row>
      </div>
      <div class="mainContent">
        <div style="display: flex; gap: 8px; flex-wrap: wrap; margin-bottom: 10px">
          <div v-for="(item, index) in estimate" :key="index">
            <button
              :class="{
                active: item.active,
                'active-button1': item.active,
                defaultBtn: !item.active,
              }"
              @click="selectItem(item, 'minutes_since_created')"
              class="estimate"
            >
              {{ item.value }}
            </button>
          </div>

          <div v-for="(priority, index) in priorityData" :key="index">
            <button
              :class="{
                active: priority && priority.active,
                'active-button': priority && priority.active,
                defaultBtn: !(priority && priority.active),
              }"
              @click="selectItem(priority, 'priority')"
              class="priority"
            >
              {{ priority.display_value }}
            </button>
          </div>
        </div>
      </div>

      <!--  <div v-for="(issueType, index) in issueTypeData" :key="index">
            <button :class="issueType.active ? 'active' : 'defaultBtn'" @click="selectItem(issueType, 'issueType')"
                class="issueType">{{ issueType.name }}</button>
        </div> -->
    </div>
  </div>
</template>

<script>
// import { ButtonGroup } from "@tiburadev/vue-comp";
import EnumService from "@/api/service/Enum/EnumService";

export default {
  components: {},
  data() {
    return {
      limit: [
        { text: "Spend", value: "spend" },
        { text: "Required", value: "required" },
      ],
      selectedTime: null,
      selectionType: [
        { text: "Issues", value: "issues" },
        { text: "Hours", value: "hours" },
      ],
      estimate: [
        { value: "30m", active: false, data_value: "30" },
        { value: "1 hr", active: false, data_value: "60" },
        { value: "2 hr", active: false, data_value: "120" },
        { value: "4 hr", active: false, data_value: "240" },
        { value: "8 hr", active: false, data_value: "480" },
        { value: ">8", active: false, data_value: ">480" },
        // { 'value': '>6 hr', 'active': false }
      ],
      priorityData: [],
      selectedPriorities: [],
      coupleButton1: "spend",
      coupleButton2: "issues",
      switchGroup: {
        issues: true,
        estimate: false,
      },
    };
  },
  created() {
    this.getPriorityData();
  },
  methods: {
    itemSelected($item) {
      this.selectedItem = $item;
    },
    handleSwitchChange(type) {
      console.log(type, "kishorefilter");
      // If one switch is turned on, turn off the other
      if (type === "issues") {
        this.switchGroup.estimate = false;
        this.switchGroup.issues = true;
      } else if (type === "estimate") {
        this.switchGroup.issues = false;
        this.switchGroup.estimate = true;
      }

      this.$emit("switch-group-change", { type, value: this.switchGroup });
    },
    selectItem(item, group) {
      console.log(item, group, "checkData22223333");
      let sendData = {};

      if (group === "priority") {
        // Toggle active state for the clicked priority
        item.active = !item.active;

        // Update the array of selected priorities
        if (item.active) {
          this.selectedPriorities.push(item.data_value);
        } else {
          const index = this.selectedPriorities.indexOf(item.data_value);
          if (index !== -1) {
            this.selectedPriorities.splice(index, 1);
          }
        }
      } else {
        // Check if this.selectedTime is not null before accessing properties
        if (this.selectedTime) {
          // Toggle active state for timeData
          this.selectedTime.active = !this.selectedTime.active;

          // If a new time is clicked, update selectedTime
          if (this.selectedTime !== item) {
            this.selectedTime = item;
            item.active = true;
          } else {
            this.selectedTime = null; // Deselect if the same time is clicked again
          }
        } else {
          // If this.selectedTime is initially null, set it to the clicked item
          this.selectedTime = item;
          item.active = true;
        }
      }

      sendData.priority = this.selectedPriorities;
      sendData.timeData = this.selectedTime;
      console.log(sendData, "777333444444");
      this.$emit("Filter-clicked", sendData);
    },
    // ... other methods ...

    async getPriorityData() {
      try {
        let res = await EnumService.findAll({ domain: "PRIORITY" });
        this.priorityData = res;
        this.priorityData.map((k) => (k.active = false));
      } catch (e) {
        this.priorityData = [];
      }
    },
  },
};
</script>

<style scoped>
.titleContainer {
  white-space: nowrap;
  font-size: 18px;
  font-weight: 700;
}

.activeBtn {
  font-size: 12px;
  font-weight: 400;
  width: 68px;
  height: 25px;
  border-radius: 4px;
  background: #d9d9d9;
}
.mainContent {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.defaultBtn {
  white-space: nowrap;
  text-transform: capitalize;
  color: #000;
  font-size: 10px;
  font-weight: 700;
  width: fit-content;
  padding: 0px 15px;
  height: 24px;
  border-radius: 4px;
  opacity: 0.5;
}

.estimate {
  border: 2px solid #ffbe65;
  background: #f9ecda;
}

.priority {
  border: 2px solid #0cb99a;
  background: #d1ffbd;
}

.issueType {
  border: 2px solid #240cb9;
  background: #d3cdf8;
}

.active {
  text-transform: capitalize;
  color: #000;
  font-size: 10px;
  font-weight: 700;
  width: fit-content;
  padding: 0px 15px;
  height: 24px;
  border-radius: 4px;
  opacity: 1;
}

.active-button {
  /* Style for the active button */
  font-weight: bold;
  background: #d1ffd5; /* Example background color for the active button */
  color: #000000; /* Example text color for the active button */
}
.active-button1 {
  /* Style for the active button */
  font-weight: bold;
  background: #f9a27d; /* Example background color for the active button */
  color: #000000; /* Example text color for the active button */
}

>>> .v-btn.v-btn--density-compact {
  height: 28px !important;
}
</style>
