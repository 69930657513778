import ApiService from '../../service/apiService.js';
const querystring = require('querystring')
var CommonService = {
    getMyRole:function() {
        let $user = localStorage.getItem('user_details')
        $user = JSON.parse($user)
        return $user.role_id
    },
    findAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "common/filter"
            $options.method = "get"

            if ($data) {
                $options.url = "common/filter?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    findAllColumnData: function($data) {
    
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getcolumnData"
            $options.method = "get"
            if ($data) {
                $options.url = "getcolumnData?" + querystring.stringify($data)
              
            }

            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
}
export default CommonService;