<template>
    <div>
  <v-row class="pa-2">
    <v-col>
        <h4>PROJECT TEAM</h4>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
    <v-card>
        <v-row class="pl-3 pt-2">
            <v-col >
                <span class="font-weight-black">Select Users</span>
                <v-chip  class="ml-4" color="red">14 User Remaining</v-chip>
                <!-- <p>Selected options: {{ selectedUsers.join(', ') }}</p> -->
            </v-col>
           
        </v-row>
       <v-row class="pl-3">
        <v-col cols="3">
            <AutoComplete v-model="selectedUsers" :items="userData" item-title="name" item-value="value" multiple/>
            <!-- <TextFieldComponent density="compact" placeholder="Select User"/> -->
        </v-col>
        <v-col cols="2">
            <v-btn class="dialog-button" color="indigo-darken-1" >ADD ALL</v-btn>
        </v-col>
       </v-row>
       <v-row>
     <v-col>
        <PrimeTable :title="''" :showFilter="true" :searchOption="true" :selectAll="true" :showDownload="true" activity="projectteam"
            :showUpload="true" :showAdd="false" :columnData="columns" :data="userData" @addDiolog="addDiolog()" @rowAction="rowActions($event)" />
<!-- <PrimeTable 
:headers="headers"
     :items="userData"
      show-select
     :items-per-page="5" 
:addButton="button" @openDialog="openDialog"/>  -->
        </v-col>
       </v-row>
    </v-card>
    </v-col>
  </v-row>
  </div>
</template>

<script>
// import TextFieldComponent from '@/components/TextFieldComponent.vue';
// import PrimeTable from '@/components/PrimeTable.vue';
import PrimeTable from '@/components/PrimeDataTable.vue';
import AutoComplete from '@/components/ui/AutoComplete.vue';
import UserService from '@/api/service/UserService';
import commonService from '@/common.service'
export default {
    components:{
        // TextFieldComponent,
        PrimeTable,
        AutoComplete
    },
    data(){
        return{
            button:false,
            userData:[],
            columns: [],
            selectedUsers:[]
        }
    },
   
    created(){
         this.getUserData()
         this.loadColumns();
        this.download = true;
        this.search = true;
        this.checkbox = true;
        //  console.log(this.selectedUsers,'check1111')
    },
    methods:{
        openDialog(){
            // alert('hello')
        },
        async getUserData() {
            let res = await UserService.findAll()
            this.userData = res
            console.log(this.userData,'check2222222')
        },
        loadColumns() {
            this.columns = [
                { field: 'username', header: 'Members',  style: { width: '100px', color: "" }, toolTip: 'Code', sortfield: true  },
                { field: 'role', header: 'Role' , style: { width: '100px', color: "" }, toolTip: 'Name', sortfield: true },
                { field: 'modified', header: 'Modified' , style: { width: '100px', color: "" }, toolTip: 'Modified', sortfield: true },
                { field: 'status', header: 'Status', style: { width: '100px', color: "" }, toolTip: 'Status', sortfield: true  },
                { field: 'action', header: 'Action', style: { width: '100px', color: "" }, toolTip: 'Action', sortfield: true ,rowActions: ["delete","view",'edit'] }
            ];
        },
        addDiolog(){
            this.$refs.AddProject.dialog = true
            this.$refs.AddProject.size = commonService.largeDialog()
        },
        rowActions($event){
            if($event.type=='view'){
              console.log('view')
            }
            if($event.type=='edit'){
              console.log('edit')
                
            }
            if($event.type=='select'){
                
              console.log('select')
            }

        }
    },
}
</script>

<style>

</style>