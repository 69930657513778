<template>
  <!-- <v-avatar color="surface-variant"></v-avatar> -->
   <p class='ml-16 pl-3'>{{ timerCount }}</p>
   <v-btn class=ml-2 color='green'> start</v-btn>    <v-btn class=ml-2  color='red'>stop</v-btn>
  
   <!-- <div>
    <p>{{ time | secondsInMinutes }}</p>
  </div> -->

  <!-- <div class="time">{{ time }}</div> -->

 
</template>

<script>

export default {
//   props: {
//     hours: Boolean,
//     minutes: Boolean,
//   },
//   data() {
//     return {
//       time: null,
//       isRunning: false,
//       startTime: null,
//       times: [0, 0, 0, 0],
//       frameId: null,
//     };
//   },
//   methods: {
//     start() {
//       if (this.isRunning) throw new Error('Stopwatch has already started.');
//       this.isRunning = true;
//       if (!this.startTime) this.startTime = performance.now();
//       this.$emit('start', this.startTime);
//       this.frameId = requestAnimationFrame(this.step);
//     },
//     lap(id) {
//       this.$emit('lap', performance.now(), this.time, id);
//     },
//     stop() {
//       if (!this.isRunning) throw new Error('Stopwatch has not been started yet.');
//       this.isRunning = false;
//       this.startTime = null;
//       this.times = [0, 0, 0, 0];
//       this.$emit('stop', performance.now(), this.time);
//       cancelAnimationFrame(this.frameId);
//     },
//     reset() {
//       this.startTime = 0;
//       this.isRunning = false;
//       this.times = [0, 0, 0, 0];
//       this.time = this.formatTimes();
//     },
//     formatTimes(times = this.times) {
//       const hours = pad0(times[0], 2);
//       const minutes = pad0(times[1], 2);
//       const seconds = pad0(times[2], 2);
//       const milliseconds = pad0(Math.trunc(times[3] % 100), 2);
//       if (this.hours) {
//         return `${hours}:${minutes}:${seconds}:${milliseconds}`;
//       }
//       if (this.minutes) {
//         return `${minutes}:${seconds}:${milliseconds}`;
//       }
//       return `${seconds}:${milliseconds}`;
//       function pad0(value, count) {
//         let result = value.toString();
//         while (result.length < count) {
//           result = '0' + result;
//           --count;
//         }
//         return result;
//       }
//     },
//     step(timestamp) {
//       if (!this.isRunning) return;
//       this.calculate(timestamp);
//       this.startTime = timestamp;
//       this.time = this.formatTimes();
//       this.frameId = requestAnimationFrame(this.step);
//     },
//     calculate(timestamp) {
//       const diff = timestamp - this.startTime;
//       this.times[3] += diff / 10;
//       if (this.times[3] >= 100) {
//         this.times[3] -= 100;
//         this.times[2] += 1;
//       }
//       if (this.times[2] >= 60) {
//         this.times[2] -= 60;
//         this.times[1] += 1;
//       }
//       if (this.times[1] >= 60) {
//         this.times[1] -= 60;
//         this.times[0] += 1;
//       }
//     },
//   },
// };



//  name: "stopwatch",
//   props: {
//     running: {
//       type: Boolean,
//       default: false
//     },
//     resetWhenStart: {
//       type: Boolean,
//       default: false
//     },
//     restWhenStop: {
//       type: Boolean,
//       default: false
//     }
//   },
//   watch: {
//     running: function(newVal, oldVal) {
//       if (newVal) this.startT();
//       else this.stopT();
//     }
//   },
//   mounted() {
//     if (this.running) this.startT();
//   },
//   data() {
//     return { time: 0, timer: null };
//   },
//   methods: {
//     stopT: function() {
//       clearInterval(this.timer);
//       if (this.restWhenStop) this.resetT();
//     },
//     startT: function() {
//       if (this.resetWhenStart) this.resetT();
//       this.timer = setInterval(() => {
//         this.time++;
//       }, 1000);
//     },
//     resetT: function() {
//       this.time = 0;
//     }
//   },
//   filters: {
//     secondsInMinutes: function(seconds) {
//       return moment("2015-01-01")
//         .startOf("day")
//         .seconds(seconds)
//         .format("HH:mm:ss");
//     }
//   }
// };

















    data() {
            return {
                timerEnabled: true,
                timerCount: 0
            }
        },

           watch: {

            timerEnabled(value) {
                if (value) {
                    setTimeout(() => {
                        this.timerCount += 1;
                    }, 1000);
                }
            },

            timerCount: {
                handler(value) {

                    if (value > 0 ||   this.timerEnabled) {
                        setTimeout(() => {
                            this.timerCount += 1;
                        }, 1000);
                    }

                },
                immediate: true // This ensures the watcher is triggered upon creation
            }

        },

         methods: {

            play() {
                this.timerEnabled = true;
            },

            pause() {
                this.timerEnabled = false;
            }

        }
}

</script>

<style>

p {
  font-weight: bold;
  font-size: x-large;
}


</style>


// <div>
// // <template>
// //     {{ timerCount }}
// // </template>

// // <script>

// //     export default {

// //         data() {
// //             return {
// //                 timerEnabled: true,
// //                 timerCount: 30
// //             }
// //         },

// //         watch: {

// //             timerEnabled(value) {
// //                 if (value) {
// //                     setTimeout(() => {
// //                         this.timerCount--;
// //                     }, 1000);
// //                 }
// //             },

// //             timerCount: {
// //                 handler(value) {

// //                     if (value > 0 && this.timerEnabled) {
// //                         setTimeout(() => {
// //                             this.timerCount--;
// //                         }, 1000);
// //                     }

// //                 },
// //                 immediate: true // This ensures the watcher is triggered upon creation
// //             }

// //         }

// //         methods: {

// //             play() {
// //                 this.timerEnabled = true;
// //             },

// //             pause() {
// //                 this.timerEnabled = false;
// //             }

// //         }

// //     }

// // </script>
// </div>