<template>
  <v-card flat dense class="pa-0 ma-0 rounded" :elevation="0">
    <v-card-title class="pl-0 pa-0 ma-0" dense>
      <v-row no-gutters dense>
        <span style="font-size: 16px; font-weight: 600; color: #272727">Attachments</span>
        <uploadFiles
          :fileForUpload="fileForUpload"
          :inputType="type"
          keyType="IS"
          :keyId="issueId"
          v-if="showEdit && exceptStage"
          @documentsData="documentUploaded"
          :type="'icon'"
        />
      </v-row>
    </v-card-title>
    <v-card-text
      class="pa-0 ma-0 pt-2"
      v-if="documents && documents.length > 0 && !noDisplay"
    >
      <v-chip
        label
        v-for="(item, index) in documents"
        :key="index"
        class="pa-3 mr-2 mb-2 outlined"
      >
        <v-icon
          @click="downloadDoc(item)"
          size="small"
          start
          icon="mdi-download"
        ></v-icon>
        {{ item && item.doc_name ? item.doc_name : "" }}
        <!-- Conditionally show the v-icon based on item.type -->
        <v-icon
          v-if="item.type !== 'email'"
          class="ml-1"
          @click.stop="docRemoved(item)"
          size="small"
          start
          icon="mdi-close"
        ></v-icon>
      </v-chip>
    </v-card-text>
  </v-card>
</template>

<script>
import documentService from "@/api/service/Document/DocumentService";
//import IssueService from "@/api/service/Issue/IssueService";
import uploadFiles from "@/components/uploadFiles.vue";
export default {
  components: {
    uploadFiles,
  },
  props: [
    "issueId",
    "refreshAmt",
    "stage",
    "showEdit",
    "exceptStage",
    "noDisplay",
    "fileForUpload",
    "type",
  ],
  watch: {
    issueId() {
      // alert(this.issueid)
      this.getDocuments();
    },
    refreshAmt() {
      if (this.refreshAmt) {
        this.getDocuments();
      } else {
        this.getDocuments();
      }
    },
  },

  data() {
    return {
      documents: [],
    };
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    getTheDate(date) {
      let $date = new Date(date);
      let formattedDate = $date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      return formattedDate;
    },
    downloadDoc(item) {
      documentService.download({ id: item.id }).then((response) => {
        console.log(response, "oooooooooooooooooopppp");
        window.open(response.url, "_blank");
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("name", "downloadLink");
        // link.setAttribute("download", response.headers["x-suggested-filename"]); //or any other extension
        // document.body.appendChild(link);
        // link.click();
      });
    },

    async docRemoved(item) {
      // alert('click')
      let obj = {
        id: item.id,
        active: 0,
        deleted: 1,
      };
      await documentService.update(obj);
      this.getDocuments();
      // this.documents = newArray
      // console.log(this.documents , 'thisshouldberemoved')
    },
    documentUploaded($data) {
      console.log($data);
      this.getDocuments();
    },

    async getDocuments() {
      // alert(this.issueId)
      documentService
        .findAll({
          key_type: "IS",
          key_id: this.issueId,
          active: 1,
        })
        .then(($documents) => {
          this.documents = $documents;
        })
        .catch((error) => {
          this.documents = [];
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.outlined {
  border: 1px solid #dfdfdf;
}
</style>
