<template>
  <div>
    <div v-if="showComponent">
      <h1 class="centered">Verification Successful</h1>
    </div>
    <div v-if="showComponentDetail">
      <h1 class="centered">User Not Found </h1>
    </div>
  </div>
</template>

<script>
import UserService from "@/api/service/UserService";

export default {
  data() {
    return {
      showComponent: false,
      showComponentDetail: false,
      user: [],
      data: []
      
    };
  },
  created() {
    this.updateUser();
  },
  methods: {
    async updateUser() {
      try {
        const url = new URL(window.location.href);
        const userId = url.searchParams.get('userId');
        const res = await UserService.update({ active: 1, id: userId });
        this.data = res
        const user = await UserService.findOne({id:userId})
        if (user && user.length > 0 && user[0].id){
        this.showComponent = true;
        }
        else{
         this.showComponentDetail = true
        }

        // Do something with the response data if needed
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  text-align: center;
}

.centered {
  color: #333;
  margin-top: 50px; /* Adjust the margin-top value to move the element down */
}

p {
  color: #666;
}
</style>
