<template>
  <YourDialogContent>
    <v-card width="100%" style="border-radius: 12px;">
      <v-form @submit.prevent="save" ref="form">
        <!-- <v-row class="diolog-header"> -->
        <v-card-item>
          <v-card-title>{{ viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add" }} <b>Project</b>
            <v-btn @click="cancel()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
          </v-card-title>
        </v-card-item>

        <v-card-text>

          <v-row no-gutters class="mt-3 pa-2">

            <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="projects.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

            <v-col cols="4">
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Code
                <span v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:300">*</span></v-label>
              <TextFieldComponent placeholder="Code" v-model="projects.code" @input="checkCode" :rules="codeRule"
                :disabled="showReadOnly" @keydown="prevent($event)" />
              <v-error v-if="codeError">Already Exists!</v-error>
            </v-col>
            <v-col cols="8" class="pl-4">
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Name
                <span v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
              <TextFieldComponent placeholder="Name" :rules="nameRule" v-model="projects.name" :disabled="showReadOnly" />
            </v-col>
            <v-col cols="12" class="dropdown mb-4">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Categories
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>

              <AutoComplete :items="categories" item-value="id" item-title="name" density="compact"
                placeholder="Select Categories" :rules="CategoryRule" v-model="projects.category_id"
                @update:modelValue="categoryChanged" :disabled="showReadOnly" />
              <v-chip class="selectedChip" closable label v-for="item in selectedCategories" :key="item" :close="false"
                :disabled="showReadOnly" @click:close="removeCategory(item)">
                {{ item.name }}
              </v-chip>
            </v-col>



            <!--<v-row>
          <v-col cols="4" class="pt-0">
            <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Project Manager
              <span v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <AutoComplete :items="userData" :placeholder="'Project Manager'" item-title="name" :disabled="showReadOnly"
              item-value="id" v-model="projects.mgr_id" />
          </v-col> -->
            <!-- <v-col cols="4" class="pt-0">
            <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Associate Manager
              <span v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <AutoComplete :items="userData" :placeholder="'Associate Manager'" item-title="name" :disabled="showReadOnly"
              item-value="id" v-model="projects.mgr_id2" />
          </v-col> 
        </v-row>-->


            <v-col cols="6" class="dropdown">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Stage [E]
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <EnumAutoCompleteVue density="compact" v-model="projects.stage" :selected="projects.stage"
                :showReadOnly="viewType == 'view'" :domain="'projectStage'" placeholder="Stage"
                @changeEnumData="changeStage($event)" :rules="stageRule" />
                <v-error v-if="projectCloseError" >The project has a pending issue You cannot close this project.</v-error>

            </v-col>
            <!-- <v-col cols="4" class="pt-0">
            <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Stage
              <span v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <AutoComplete :items="stageData" v-model="projects.stage_id" item-title="name" item-value="id"
              :disabled="showReadOnly" :placeholder="'Stage'" />
          </v-col> -->
            <!-- <v-col cols="4" class="pt-0">
            <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Category
              <span v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <AutoComplete :items="categoryData" item-title="name" item-value="id" :disabled="showReadOnly"
              v-model="projects.category_id" :placeholder="'Category'" />
          </v-col> -->
            <v-col cols="6" class="dropdown pl-4">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Category [E]
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <EnumAutoCompleteVue density="compact" :rules="categoryRule" :selected="projects.category"
                v-model="projects.category" :showReadOnly="showReadOnly" :domain="'projectCategory'"
                placeholder="Category" @changeEnumData="changeCategory($event)" />

            </v-col>
          </v-row>
          <v-row class="mt-0 pa-0 pl-6">
            <v-col cols="12">
              <v-text class="text-subtitle-1 ">I want following informations to be associated with this project. </v-text>
            </v-col>
            <v-col class="switch" cols="3" style="padding-left: 25px;">
              <v-label style="font-size: 14px; color: #272727; font-weight: 600">Skill
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
              <v-switch v-model="projects.skill" color="primary" :disabled="showReadOnly"
                @change="changeSkill()"></v-switch>

            </v-col>
            <v-col class="switch" cols="3">
              <v-label style="font-size: 14px; color: #272727; font-weight: 600">Quantity
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
              <v-switch v-model="projects.quantity" color="primary" :disabled="showReadOnly"
                @change="changeQuentity()"></v-switch>

            </v-col>
            <v-col class="switch" cols="3">
              <v-label style="font-size: 14px; color: #272727; font-weight: 600">Category
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
              <v-switch v-model="projects.category_required" color="primary" @change="changeCategoryRequired()"
                :disabled="showReadOnly"></v-switch>

            </v-col>
            <v-col class="switch" cols="3">
              <v-label style="font-size: 14px; color: #272727; font-weight: 600">Sub Category
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
              <v-switch v-model="projects.sub_category" color="primary" @change="changeSubCategory()"
                :disabled="showReadOnly"></v-switch>

            </v-col>
          </v-row>

          <v-col cols="12">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Description
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <span @click="showTextArea = !showTextArea"
              style="color:#3C5AAA;cursor:pointer;text-decoration: underline;"><i>{{ showTextArea ? 'Show' : 'Hide'
              }}</i></span>

            <TextArea v-if="!showTextArea" :data="projects.description" :showReadOnly="showReadOnly"
              @textData="changeDescription($event)"></TextArea>
          </v-col>




        </v-card-text>
        <v-card-actions class="mr-3">
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="cancel()">Cancel</v-btn>
            <v-btn class="save" :loading='saveClicked'  type="submit">Save</v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
      <!-- </v-container> -->
    </v-card>
  </YourDialogContent>
</template>
<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";

import TextFieldComponent from "../../components/TextFieldComponent.vue";
import UserService from "@/api/service/UserService";
// import Dropdown from '../../components/ui/Dropdown.vue'
// import CustomerService from '@/api/service/'
import TextArea from "@/components/ui/TextArea.vue";
import commonService from "@/common.service";


import EnumAutoCompleteVue from '@/components/others/EnumAutoComplete.vue';
import CategoryService from "@/api/service/Category/CategoryService";
// import AutoComplete from "../../components/ui/AutoComplete.vue";
// import TextArea from '../../components/ui/TextArea.vue';
// import TimeZone from "@/components/others/TimeZoneList.vue";
import ProjectService from "@/api/service/Project/ProjectService";
// import Editor from 'primevue/editor';
// import ProjectsModel from '@//api/model/ProjectsModel.js'
// var ProjectsModel =require('@/api/model/ProjectsModel.js')
import StageService from '@/api/service/Stage/StageService'
export default {
  name: "addProject",
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
    EnumAutoCompleteVue,
    TextArea,
  },
  data() {
    return {
      showTextArea: true,
      saveClicked: false,
      showReadOnly: false,
      stageData: [],
      type: null,
      projectCloseError:false,
      codeError: false,
      dialog: false,
      categories: [],
      backupCategories: [],
      selectedCategoryId: [],
      selectedCategories: [],
      size: {},
      userData: [],
      categoryData: [],
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      categoryRule: [(value) => !!value || "Category is Required."],
      stageRule: [(value) => !!value || "Stage is Required."],
      projects: {
        description: "",
        code: null,
        skill: 0,
        category_required: 0,
        sub_category: 0,
        quantity: 0
        // name:''
      },
    };
  },
  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
      }
    },
    // type(){
    //     if(this.type){
    //         this.showReadOnly =this.type=='edit'?false:true
    //     }

    // }
  },
  async created() {
    this.projects.stage = 'Open'
    // let data =new ProjectsModel();
    // console.log(data,"oooo")
    this.getStageDate();
    this.getUserData();
    await this.getCategory();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == 'edit' || this.viewType == 'add' ? false : true;
    }
  },
  mounted() {
    this.projects.stage = 'open'
  },
  methods: {
    categoryChanged($value) {
      this.selectedCategoryId.push($value);
      // console.log(this.selectedCategoryId,'check333')
      this.showChips()
    },
    showChips() {
      // if (this.projects && this.projects.category_id) {
      let chipRes = commonService.getMultipleChip(
        this.backupCategories,
        this.selectedCategoryId,
        "id"
      );
      
      this.categories = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupCategories),
        this.selectedCategoryId,
        "id"
      );
      this.selectedCategories = chipRes;
      this.categories = [...this.categories];
      this.projects.category_id = null;
      this.$forceUpdate()
      // }

    },
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },

    changeDescription($event) {
      this.projects.description = $event
    },


    changeSkill() {
      this.projects.skill = !this.projects.skill ? 0 : this.projects.skill
    },
    changeQuentity() {
      this.projects.quantity = !this.projects.quantity ? 0 : this.projects.quantity
    },
    changeCategoryRequired() {
      this.projects.category_required = !this.projects.category_required ? 0 : this.projects.category_required
      if (!this.projects.category_required) {
        this.projects.sub_category = false
        this.changeSubCategory()
      }
    },
    changeSubCategory() {
      if (this.projects.sub_category) {
        this.projects.category_required = true
        this.changeCategoryRequired()
      }
      this.projects.category_required = !this.projects.category_required ? 0 : this.projects.category_required
      this.projects.sub_category = !this.projects.sub_category ? 0 : this.projects.sub_category
    },
    checkCode() {
      if (this.projects && this.projects.code && this.projects.code.length > 0) {
        this.projects.code = this.projects.code.toUpperCase();
        ProjectService.findAll({ code: this.projects.code }).then((res) => {
          let $exist = res.filter(key => key.code == this.projects.code)
          this.codeExistFlag = $exist.length > 0 ? (this.projects.id && $exist[0].id == this.projects.id) ? false : true : false;
          if (this.codeExistFlag) {
            this.codeError = true
          }
          else {
            this.codeError = false
          }
        });
      }
    },

    removeCategory($item) {
      let index = this.selectedCategoryId.findIndex((k) => k == $item.id);
      if (index > -1) {
        this.selectedCategoryId.splice(index, 1);
      }

      // let categoryToAddBack = this.backupCategories.find((z) => z.id == $item.id);
      // if (categoryToAddBack) {
      //   this.categories.unshift(categoryToAddBack);
      // }
      this.showChips();
    },

    async changeStage($value) {
      if ($value === 'closed') {
        let res =await ProjectService.getProjectIssueCount({projectId:this.projects.id})
        if(res && res.length>0){
         this.projectCloseError = res[0].count >0?true:false
        }
        if(!this.projectCloseError){
          this.projects.stage = $value
        }
      }
      else {
        this.projectCloseError = false
        this.projects.stage = $value
      }
      // console.log(this.projects.stage,'stageeeeeeeeeeeeeeeeee')
      // this.getStageDate(this.projects.stage)
    },
    changeCategory($value) {
      this.projects.category = $value
      // this.getCategory(this.projects.category)
    },

    async getStageDate(id) {
      try {
        let res = await StageService.findAll(id)
        this.stageData = res
        // console.log(this.stageData, 'stageeeeeeeeeeeeeee')
      }
      catch (e) {
        this.stageData = []
        console.log(e)
      }

    },
    cancel() {
      this.$emit('closeDialog')
    },
    async getById(id) {
      let res = await ProjectService.findOne(id);
      this.projects = res[0];
      this.selectedCategoryId = this.projects && this.projects.category_id
        ? JSON.parse(this.projects.category_id)
        : [];
      //this.projects.category_id=null

      this.showChips()
      this.projects.skill = this.projects.skill ? true : false
      this.projects.quantity = this.projects.quantity ? true : false
      this.projects.category_required = this.projects.category_required ? true : false
      this.projects.sub_category = this.projects.sub_category ? true : false

    },
    async getUserData() {
      let res = await UserService.findAll();
      this.userData = res.length > 0 ? res : [];
    },
    async getCategory() {
      let res = await CategoryService.findAll();
      this.categories = res.length > 0 ? await commonService.getSort(res, 'name', 'asc') : [];
      this.backupCategories = JSON.parse(JSON.stringify(this.categories));

    },
    async save() {
      //   console.log(this.projects.description, "description");

      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError|| this.projectCloseError) {
        console.log("Not valid")
      }
      else {
        this.saveClicked = true
      this.projects.category_id = JSON.stringify(this.selectedCategoryId);
        if (this.projects.id) {
          await ProjectService.update(this.projects);
          this.$store.dispatch('setSnackbar', { text: 'Updated Successfully' })
          this.dialog = false;
          this.cancel();
          this.$emit("reload", true);
        }
        else {
          this.projects.expected_hours = 0;
          this.projects.project_class = "BPO";
          try {
            let output = await ProjectService.create(this.projects);
            this.$store.dispatch('setSnackbar', { text: 'Saved Successfully' })
            console.log(output);
            this.dialog = false;
            this.cancel();
            this.$emit("reload", true);
          } catch (e) {
            this.$store.dispatch('setSnackbar', { color: 'error', text: 'Something Went Wrong' })
          }

        }
      }
    },
  },
};
</script>
<style>
.switch {
  height: 80px;
  padding-left: 4px;
}
</style>
