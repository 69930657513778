<template>
  <PrimeDataTable
    :title="'Location'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="false"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="location"
  />

  <v-dialog persistent v-model="dialog" width="700px">
    <CreateLocation
      @closeDialog="close()"
      @reload="getLocation()"
      :id="locationId"
      :viewType="viewType"
    />
  </v-dialog>
</template>

<script>
import CreateLocation from "./CreateLocation.vue";
import PrimeDataTable from "@/components/PrimeDataTable.vue";
import LocationService from "@/api/service/Location/LocationService";
// import commonService from "@/common.service";
export default {
  components: {
    CreateLocation,
    PrimeDataTable,
  },
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      locationId: "",
      dialog: false,
      viewType: false,
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getLocation();
  },
  methods: {
    loadColumns() {
      this.columns = [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "name",
          sortfield: true,
        },
        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [{ action: "view" }, { action: "edit" }],
        },
      ];
    },

    close() {
      this.dialog = false;
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.viewType = $event.type;
        this.locationId = $event.data.id;
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.locationId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.locationId = $event.data.id;
        this.viewType = "view";
      }
    },
    async getLocation() {
      let res = await LocationService.findAll();
      this.data = res;
      console.log(this.data,"thisssss dataaaaaa")
    },
    addDiolog() {
      this.dialog = true;
      this.locationId = null;
      this.viewType = false;
    },
  },
};
</script>
