<template>
  <v-card class="mx-auto" width="350" flat style="border-radius: 2px;" @click.stop="gotoIssue()">
    <v-card-title style="display: flex; justify-content: space-between;">
      <span>
        <v-img :src="require('@/assets/Synergy-Logo-full-blue.png')" width="120px" class=""></v-img>
      </span>
      <span>
        <v-icon color="primary" class="mb-1" size="small" @click="close()">mdi-close</v-icon>
      </span>
    </v-card-title>
    <v-card-text>
      <v-card-title class="title">{{ massagaData && massagaData.title?massagaData.title:"" }}</v-card-title>
      <div style="border-radius: 2px; background-color: #EEF6FB; padding: 10px;">
        {{ massagaData && massagaData.content?massagaData.content:"" }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  data() {
    return {
      massagaData: null
    }
  },
  components: {},
  props: ["message"],
  watch: {
    message() {
      if (this.message) {
        this.massagaData = this.message
      }
    }
  },
  created(){
    if(this.message){
              this.massagaData = this.message
    }
  },
  methods: {
    close() {
      this.$emit('closeSnackBar')
    },
    gotoIssue(){
      window.location=this.massagaData.link
}
  }}
</script>
<style>
.title{
  font-size: 15px;
    font-weight: 30%;
    font-weight: bold;
    margin-left: -15px;
}
.card-top{
  margin-top: -9%;
}
</style>