<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title
          >{{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }}
          Activity
          <v-btn
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Code
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Code"
              :rules="codeRule"
              v-model="formData.code"
              @keyup="checkCode()"
              @keydown="prevent($event)"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="9">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Name
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Name"
              :rules="nameRule"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="6">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Activity Groups
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :items="activitygroups"
              item-value="id"
              item-title="name"
              density="compact"
              placeholder="Activity"
              :rules="activityRule"
              v-model="formData.activity_group_id"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="8">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Activity Order
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Activity Order"
              v-model="formData.activity_orde"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
  <script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import ActivityService from "../../../../api/service/Activity/ActivityService";
import ActivityGroupService from "../../../../api/service/ActivityGroup/ActivityGroupService";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
  },

  data() {
    return {
      showReadOnly: false,
      dialog: false,
      type: null,
      activitygroups: [],
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      activityRule: [(value) => !!value || "Activity is Required."],
      formData: {
        id: null,
        code: null,
        name: null,
        activity_group_id: null,
        lob: null,
        activity_order: null,
        menu_group: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    this.getactivity();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    prevent(e) {
  if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
},
    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();

        ActivityService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await ActivityService.findOne(id);
      this.formData = res[0];
    },

    async getactivity() {
      let res = await ActivityGroupService.findAll();
      this.activitygroups = res.length > 0 ? res : [];
      console.log(this.activitygroups, "rrrrrrr");
    },

    async save() {
      if (
        this.formData.name &&
        this.formData.code &&
        this.formData.activity_orde
      )
        if (this.formData.id) {
          await ActivityService.update(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          await ActivityService.create(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        }
    },
  },
};
</script>
  