<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title
          >{{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }}
         <b>Event</b>
          <v-btn
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
          ></v-btn
        ></v-card-title>
      </v-card-item>
      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Code
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Code"
              :rules="codeRule"
              v-model="formData.code"
              :disabled="showReadOnly"
              @keyup="checkCode()"
            />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>

          <!-- Input Field -->

          <v-col cols="9">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Name
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Name"
              :rules="nameRule"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="7" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Activity
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <AutoComplete
              :items="ActivityDetails"
              item-value="id"
              item-title="name"
              density="compact"
              placeholder="Activity"
              :rules="activityRule"
              v-model="formData.activity_id"
              @update:modelValue="Activitychanged"
              :disabled="showReadOnly"
            />

            <v-chip
              class="selectedChip"
              label
              closable
              v-for="item in selectActivity"
              :key="item"
              :close="false"
              close-icon="mdi-close-circle"
              :disabled="showReadOnly"
              close-transition="scale-transition"
              @click:close="cancelActivity(item)"
            >
              {{ item.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
  <script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import EventService from "@/api/service/Event/EventService";
import ActivityService from "../../../../api/service/Activity/ActivityService";
import commonService from "@/common.service";
export default {
  props: ["editID", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
  },
  // watch: {
  //   editID(){
  //     alert('hi')
  //     if(this.editID) {
  //       console.log(this.editID,'rubrsh')
  //     }
  //   },
  // },

  data() {
    return {
      codeError: null,
      showReadOnly: false,
      dialog: false,
      type: null,
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      activityRule: [(value) => !!value || "Activity is Required."],
      selectActivityId: [],
      backupActivity: [],
      ActivityDetails: [],
      selectActivity:[],
      formData: {
        id: null,
        code: null,
        name: null,
        activity_id: null,
      },
      chips: [],
    };
  },
  created() {

    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
    this.getactivity();
    this.editiD();

  },
  methods: {
    checkCode() {
      this.formData.code = this.formData.code.toUpperCase();
      EventService.findAll({ code: this.formData.code }).then((res) => {
        if (res.length > 0) {
          this.codeError = true;
        } else {
          this.codeError = false;
        }
      });
    },

    editiD(){
      if(this.editID) {
        this.getById(this.id);
      }
    },

    Activitychanged($value) {
      this.selectActivityId.push($value);

      this.setData();
    },

    setData() {
      this.formData.activity_id = null;
      let chipRes = commonService.getMultipleChip(
        this.backupActivity,
        this.selectActivityId,
        "id"
      );

      this.ActivityDetails = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupActivity),
        this.selectActivityId,
        "id"
      );
      this.selectActivity = chipRes;
      this.ActivityDetails = [...this.ActivityDetails];
    },

    async getactivity() {
      await ActivityService.findAll().then(($options) => {
        this.ActivityDetails = $options;
        this.backupActivity = JSON.parse(JSON.stringify(this.ActivityDetails));
      });
    },

    close() {
      this.$emit("closeDialog");
    },

    cancelActivity(data) {
      let index = this.selectActivityId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectActivityId.splice(index, 1);
      }
    this.setData();
    },
    async getById(id) {
      let res = await EventService.findAll({id:id});
      this.formData = res[0];
      console.log(res,'checkkkkkkk11111111111111111')

        // this.selectActivityId =null
        //   this.formData && this.formData.activity_id
        //     ? JSON.parse(this.formData.activity_id)
        //     : [];
        // this.setData();
    },

    async save() {
      if (this.formData.name && this.formData.code && !this.codeError) {
        this.formData.activity_id = JSON.stringify(this.selectActivityId);
        if (this.formData.id) {
          await EventService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          try {
            await EventService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.dialog = false;
            this.close();
            this.$emit("reload", true);
            this.formData = {};
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>
<style>
.selectedChip {
  background-color: #fae2ee;
  color: #ffffff;
  margin-right: 5px;
  margin-top: 5px;
  color: #333333;
}
</style>