<template>
  <div>
    <v-row class="pa-5">
      <v-col align="center">
        <v-card width="800px">
          <v-container>
            <v-row class="boderbottom">
              <v-col align="start" class="pl-5 pt-6">
                <v-row style="display: grid">
                  <v-col style="display: flex">
                    <p class="text-h4">
                      <b>{{
                        userData && userData.name ? userData.name : ""
                      }}</b>
                    </p>
                  </v-col>
                  <v-chip v-if="users && users.active==1"  style="width: fit-content" class="mb-2 mt-2"
                    >Your sign-in Method is using Email</v-chip
                  >
                  <v-chip v-else style="width: fit-content" @click="reSendEmail()" id="emailChip" class="mb-2 mt-2"
                    >Send account varification email to user</v-chip
                  >
                </v-row>
              </v-col>
              <v-col cols="2" align="end" class="">
                <v-row align="end" class="pt-5">
                  <!-- <v-icon v-if="showIcon">mdi-pencil</v-icon> -->
                  <v-btn
                    v-if="showButton"
                    class="dialog-button"
                    color="indigo-darken-1"
                    @click="save()"
                    >Save</v-btn
                  >
                  <v-icon
                    class="ml-2"
                    size="large"
                    @click="showEdit()"
                    v-if="showIcon && type == 'edit'"
                    >mdi-pencil</v-icon
                  >

                  <v-icon class="ml-2" size="large" @click="close()"
                    >mdi-close</v-icon
                  >
                </v-row>
              </v-col>
            </v-row>
            <v-row class="pt-3">
              <v-col clos="4">
                <!-- <v-avatar v-if="showAvatar"
                  color="#1258AB"
                  size="150"
                  style="font-size: 40px; color: white"
                >
                  {{
                    userData.first_name
                      ? userData.first_name.slice(0, 1) +
                        (userData.last_name
                          ? userData.last_name.slice(0, 1)
                          : "")
                      : ""
                  }}
                </v-avatar> -->
                <div v-if="showImage">
                  <uploadImage
                  :showReadOnly="showReadOnly"
                    @uploadDocument="uploadImage($event)"
                    @removeFile="removeFile"
                    :imageId="users.image_profile_id"
                    :firstName="firstName"
                    :lastName="lastName"
                    :showEdit="showIcon"
                    :themeId="this.userData.theme"
                  />
                </div>
              </v-col>
              <!-- <v-col cols="8">
                    <v-radio-group :disabled="isRadioDisabled" v-model="users.active" inline>
                          <v-radio color="primary" label="Active" :value="1"></v-radio>
                          <v-radio color="error" label="Inactive" :value="0"></v-radio>
                    </v-radio-group>
              </v-col> -->
              <v-col cols="8">
                
              <v-col  class="pb-0 pt-0" style="display: grid">
                    <v-radio-group :disabled="showReadOnly" v-model="users.active" inline>
                          <v-radio color="primary" label="Active" :value="1"></v-radio>
                          <v-radio color="error" label="Inactive" :value="0"></v-radio>
                    </v-radio-group>
              </v-col>
                <v-col class="pb-0 pt-0" style="display: grid">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >User Name
                    <span
                      v-if="!showReadOnly"
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >
                  <TextFieldComponent
                    placeholder="User Name"                    
                    :disabled="showReadOnly"
                    v-model="users.username"
                /></v-col>
                <v-col class="pb-0" style="display: grid">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Email
                    <span
                      v-if="!showReadOnly"
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >
                  <TextFieldComponent
                    placeholder="Email"
                    :disabled="true"
                    v-model="users.email"
                    :rules="emailRules"
                  />
                </v-col>
                <v-col class="pb-0 pt-0" style="display: grid">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Mobile
                    </v-label
                  >
                  <TextFieldComponent
                    @keypress="allowNumbers()"
                    :disabled="showReadOnly"
                    placeholder="Mobile"
                    v-model="users.mobile"
                /></v-col>
                <v-col class="pb-0 pt-0" style="display: grid">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >First Name
                    <span
                      v-if="!showReadOnly"
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >
                  <TextFieldComponent
                    placeholder="First Name"
                    :disabled="showReadOnly"
                    v-model="users.first_name"
                    :rules="firNameRules"
                /></v-col>

                <v-col class="pb-0 pt-0" style="display: grid">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Last Name
                    <span
                      v-if="!showReadOnly"
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                    ></span
                  ></v-label>
                  <TextFieldComponent
                    placeholder="Last Name"
                    :disabled="showReadOnly"
                    v-model="users.last_name"
                /></v-col>
                <v-col class="pb-0 pt-0" style="display: grid">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Location
                    <span
                      v-if="!showReadOnly"
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >
                  <AutoComplete
                    :items="locationData"
                    placeholder="Locations"
                    item-title="name"
                    item-value="id"
                    :rules="locationRules"
                    :disabled="showReadOnly"
                    v-model="users.location_id"
                /></v-col>
                <v-col class="pb-0 pt-0" style="display: grid">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Role
                    <span
                      v-if="!showReadOnly"
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >
                  <AutoComplete
                    :items="roleData"
                    placeholder="Role"
                    item-title="name"
                    @update:modelValue="roleChanged"
                    item-value="id"
                    :rules="roleRules"
                    :disabled="showReadOnly"
                    v-model="users.role_id"
                /></v-col>
                <v-col class="pb-0 pt-0" style="display: grid">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Department
                    <span
                      v-if="!showReadOnly"
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >
                  <EnumAutoComplete 
                   density="compact" :showReadOnly="showReadOnly" 
                   placeholder="Department" :selected="users.department" 
                   :domain="'DEPARTMENT'"
                   @changeEnumData="changeDepartment($event)" :rules="departmentRule"/>
                   </v-col>
                <v-col
                  class="pb-0 pt-0"
                  style="display: grid"
                  v-if="reportingManagerData"
                >
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Reporting Manager</v-label
                  >
                  <AutoComplete
                    :items="reportingManagerData"
                    placeholder="Reporting Manager"
                    item-title="name"
                    item-value="id"
                    :disabled="showReadOnly"
                    v-model="users.reporting_mgr_id"
                /></v-col>

                <v-col class="pb-0 pt-0" style="display: grid">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Time zone
                    <span
                      v-if="!showReadOnly"
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >

                  <TimeZoneList
                    :updateData="users.time_zone"
                    :disabled="showReadOnly"
                    @timeZoneChange="timeZoneChange"
                    :rules="timeZoneRule"
                  />
                </v-col>
                <v-col class="pb-0 pt-0" style="display: grid">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Work Timings
                    <v-btn @click="addAll" class="flaot-right" size="x-small" density="medium" variant="text" color="blue">All</v-btn>

                    <span
                      v-if="!showReadOnly"
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label>
                  <AutoComplete
                    :items="workTimingData"
                    item-value="id"
                    item-title="name"
                    density="compact"
                    placeholder="Work Timings"
                    v-model="users.work_timings"
                    @update:modelValue="workTimingChanged"
                    :disabled="showReadOnly"
                  />
                  <v-row class="pl-3 pb-8">
                    <v-chip
                      closable
                      class="selectedChip"
                      label
                      :disabled="showReadOnly"
                      v-for="item in selectedWorkTimings"
                      :key="item"
                      @click:close="reomveChips(item)"
                    >
                      {{ item.name }}
                    </v-chip>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import uploadImage from "@/components/uploadImages.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import UserService from "@/api/service/UserService";
import RoleService from "@/api/service/Role/RoleService";
import LocationService from "@/api/service/Location/LocationService";
import WorkTimingService from "@/api/service/WorkTiming/WorkTimingService";
import preferenceData from "./preferences.json";
import randomcolor from "randomcolor";
import commonService from "@/common.service";
import TimeZoneList from "@/components/others/TimeZoneList.vue";
import EnumAutoComplete from "@/components/others/EnumAutoComplete.vue";

export default {
  components: {
    TextFieldComponent,
    AutoComplete,
    uploadImage,
    TimeZoneList,
    EnumAutoComplete
  },
  data() {
    return {
      showImage: true,
      preferences: preferenceData,
      departmentRule:[(value) => !!value || "Department is Required."],
      showAvatar: false,
      showIcon: true,
      showReadOnly: true,
      showButton: false,
      userData: [],
      locationData: [],
      roleData: [],
      reportingManagerData: [],
      workTimingData: [],
      backupWorkTimingData: [],
      selectedRoleId: null,
      selectedWorkTimingId: [],
      selectedWorkTimings: [],
      worktimings: null,
      roleId: null,
      type: "edit",
      firstName: "",
      lastName: "",
      users: {
        email: null,
        mobile: null,
        first_name: null,
        last_name: null,
        username: null,
        image_profile_id: null,
      },
      emailRules: [(value) => !!value || "E-mail is Required."],
      // mobileRules: [(value) => !!value || "Mobile is Required."],
      locationRules:[(value)=>!!value|| "Location is Required "],
      roleRules:[(value)=>!!value|| "Role is Required "],
      firNameRules: [(value) => !!value || "First Name is Required."],
      // dateFormats: ["mm/dd/yyyy", "dd/mm/yyyy", "yyyy/mm/dd"],
      userDetails: JSON.parse(localStorage.getItem("user_details")),
    };
  },
  created() {
    this.getLocationData();
    this.getRoleData();
    this.getWorkTimingsData();
    if (this.$route.query.id) {
      this.getUserData(this.$route.query.id);
      this.type = this.$route.query.type == "edit" ? "edit" : "view";
    }
  },
  methods: {
    randomcolor,
    addAll() {

    },
    close() {
      this.$router.go(-1);
    },
    async getLocationData() {
      let res = await LocationService.findAll();
      this.locationData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },
    async getRoleData() {
      let res = await RoleService.findAll();
      this.roleData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },

    timeZoneChange($event) {
      this.users.time_zone = $event;
    },
    async roleChanged($event) {
      this.users.reporting_mgr_id = null;
      this.selectedRoleId = $event;
      this.getReportingManager(this.selectedRoleId);
    },

    async getReportingManager(id) {
      let res = await UserService.getUserByRoleType({ role_id: id });
      this.reportingManagerData = res.filter((user) => user.active === 1);
      this.reportingManagerData = this.reportingManagerData.length > 0 ? 
      await commonService.getSort(this.reportingManagerData, "name", "asc") : [];
    },
    
    async getWorkTimingsData() {
      let res = await WorkTimingService.findAll();
      res.splice(0,0,{"id":0,"name":"All"})
      this.workTimingData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
      this.backupWorkTimingData = JSON.parse(
        JSON.stringify(this.workTimingData)
      );
      console.log(this.backupWorkTimingData, "gooooddd");
    },
    
    changeDepartment($value) {
      this.users.department = $value
    },
    workTimingChanged($value) {
      if($value==0) {
        this.selectedWorkTimingId=[]
       for(let $timing of this.workTimingData) {
        if($timing.id>0) {
          this.selectedWorkTimingId.push($timing.id);
        }
       }
      } else {
        this.selectedWorkTimingId.push($value);
      }
        this.showChips();

    },
    showChips() {
      let chipRes = commonService.getMultipleChip(
        this.backupWorkTimingData,
        this.selectedWorkTimingId,
        "id"
      );
      this.workTimingData = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupWorkTimingData),
        this.selectedWorkTimingId,
        "id"
      );
      this.selectedWorkTimings = chipRes;
      this.workTimingData = [...this.workTimingData];
    },
    reomveChips(data) {
      let index = this.selectedWorkTimingId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedWorkTimingId.splice(index, 1);
      }
      this.showChips();
    },

    showEdit() {
      this.showReadOnly = false;
      this.showButton = true;
      this.showIcon = false;
    },
    allowNumbers(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async uploadImage($event) {
    this.users.image_url = $event.url;
    await UserService.update(this.users)
  },

  async removeFile() {
    this.users.image_profile_id = null;
    let output = await UserService.update(this.users);
    console.log("profile is gone", output);
  },
    async getUserData(id) {
      let res = await UserService.findOne({ id: id });
      this.userData = res[0];
      this.users.id = this.userData.id;
      this.users.email = this.userData.email;
      this.users.first_name = this.userData.first_name;
      this.users.last_name = this.userData.last_name;
      this.users.username = this.userData.username;
      this.users.mobile = this.userData.mobile;
      this.users.location_id = this.userData.location_id;
      this.users.role_id = this.userData.role_id;
      this.users.time_zone = this.userData.time_zone;
      this.users.active = this.userData.active;
      this.users.department = this.userData.department;
      this.users.image_profile_id = this.userData.image_profile_id;
      this.selectedWorkTimingId =
        this.userData && this.userData.work_timings
          ? JSON.parse(this.userData.work_timings)
          : [];
      console.log(this.selectedWorkTimingId, "selectedWorkTimingId");
      this.showChips();
      this.users.work_timings = null;
      if (this.users.role_id) {
        this.getReportingManager(this.users.role_id);
        this.users.reporting_mgr_id =
          this.userData && this.userData.reporting_mgr_id > 0
            ? this.userData.reporting_mgr_id
            : null;
      }
      // if(this.selectedWorkTimingId){
      // this.selectedWorkTimingId=this.userData && this.userData.work_timings ? JSON.parse(this.userData.work_timings) : []
      // // this.backupWorkTimingData=[...this.backupWorkTimingData]
      // this.showChips()
      // }

      this.firstName = this.users.first_name;
      this.lastName = this.users.last_name;
    },
    async reSendEmail(){
      try{
       let res =await UserService.reSendEmail({userId:this.users.id})
       if(res.message ==='Email sent successfully'){
        this.$store.dispatch("setSnackbar", { text: "Email sent successfully" });

       }
       else{
        
        this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Email not send",
            });
       }

      }
      catch(e){
        this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Email not send",
            });

      }
       
    },
    async save() {
      if (
        this.users.email &&
        this.users.first_name &&
        this.users.username
      ) {
        try {
          this.users.name = `${this.users.first_name} ${this.users.last_name}`;
          this.showButton = false;
          this.showIcon = true;
          this.showReadOnly = true;
          this.users.work_timings = JSON.stringify(this.selectedWorkTimingId);
          let output = await UserService.update(this.users);
          this.getUserData(this.$route.query.id);
          let userDetails =JSON.parse(localStorage.getItem('user_details'))
          if(output.data.data.id  === userDetails.id){
            let logUserData =await UserService.findOne({id:userDetails.id})
          localStorage.setItem('user_details' , JSON.stringify(logUserData[0]))
          }
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });

          this.$emit("reload", true);
          this.$router.push("/setting/identity/user");
        } catch (e) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Something Went Wrong",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
>>>.Imag {
  position: relative;
  left: 3px;
  bottom: 30px;
}
.boderbottom {
  border-bottom: 1px solid rgb(233 233 233);
}
>>>.v-text-field .v-input__details {
    -webkit-padding-start: 16px;
    padding-inline-start: 0px;
    -webkit-padding-end: 16px;
    padding-inline-end: 16px;
}
#emailChip:hover{
  cursor: pointer;
  background-color: rgb(213, 213, 253);
  color: rgb(119, 119, 246);
}
</style>
