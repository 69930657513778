<template>
<v-container fluid justify-center>
  <p class="text-h5">
    <b>Settings</b>
  </p>
  <v-row class="pa-0 ma-0" no-gutters dense>
    <v-col lg="2" md="2" sm="4" cols="12" class="pa-0 ma-0 pr-2 pt-2">
      <v-list class="settingsList">
        <div v-for="(value,key) in modules" :key="key">
          <v-list-item @click="showModule(key)" :class="key==selectedModule ? 'activeSettingList': ''">
            {{key.charAt(0).toUpperCase()+key.slice(1) }}
          </v-list-item>
          </div>
      </v-list>
    </v-col>
    <v-col lg="10" md="10" sm="10" cols="12" class="pl-2 pr-2 pt-3 white" >
      <v-card flat style="height:95vh">
        <!-- <Category v-if="modules && modules.categories" /> -->
        <!-- <IssueType v-if="modules && modules.issuetypes"/>
        <Task v-if="modules && modules.tasks"/>
        <Team v-id="modules &&modules.teams" /> -->
        
        <!-- <PrimeDataTable 
          :model="selectedModule"
          :title="selectedModule.charAt(0).toUpperCase()+selectedModule.slice(1)"
          :showFilter="true" 
          :searchOption="true"
          :selectAll="true" 
          :showDownload="true"
          :showUpload="true" 
          :showAdd="true" 
          :columnData="columns" 
          :data="data" /> -->
        </v-card>
      </v-col>
    </v-row>
</v-container>
</template>
<script>

// import { thisTypeAnnotation } from '@babel/types';
// import Category from '../settings/Category.vue'
// import IssueType from '../issues/IssueType.vue'
// import Task from './Task.vue'
// import Team from './Team.vue'
// import PrimeDataTable from '@/components/PrimeDataTable.vue'
export default {
  props:[],
  components:{
    // PrimeDataTable,
    // Category,
    // IssueType,
    // Task,
    // Team
  },
  data() {
    return {
      selectedModule:"",
      modules: {
        "categories":true,
        "issuetypes":false,
        "tasks":false,
        'teams':false
      }
    }
  },
  created() {
    this.getMostRecent();
  },
  mounted() {

  },
  updated() {

  },
  watch: {

  },
  methods: {
    getMostRecent() {
      let $storage = JSON.parse(localStorage.getItem('modules'))
      if(!$storage) {
        $storage = {"modules":{"default":"categories"}}
      }
      this.selectedModule =  $storage.modules.default
      
    },
    showModule($module) {
      this.selectedModule=$module
      let $keys = Object.keys(this.modules)
      $keys.forEach(module =>{
        console.log(module,$module)
        if(module==$module) {
          this.modules[module]=true
          this.selectedModule=$module
        } else {
          this.modules[module]=false
        }
      })
    },
    setMostRecent($module) {
      let $storage = JSON.parse(localStorage.getItem('modules'))
      if(!$storage) {
        $storage = {"modules":""}
      }
      if(!$storage.modules) {
        $storage.modules.default = ''
      }
      $storage.modules.default=$module
      localStorage.setItem(JSON.stringify($storage))
    }
  }
}
</script>
<style >
.v-list .v-list-item.v-list-item--link.theme--light.activeSettingList, .v-list.settingsList .v-list-item:hover{
  background-color: #1258AB !important;
  color: white !important;
  cursor:pointer;
  margin-right: 5px;
}
.activeSettingList {
  background-color: #1258AB !important;
  color: white !important;
  cursor:pointer;
  margin-right: 5px;
}
.settingsList  { 
  cursor:pointer;
  height:95vh;
  
}
/* .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #2b2f8e !important;
} */
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
   background-color: #1258AB !important;
  color: white !important;
  cursor:pointer;
  margin-right: 5px;
}
</style>