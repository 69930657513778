<template>
    <v-container style="text-align: center;" v-if="showData">
        <v-card class="mx-auto pa-3" max-width="600">
            <v-img style="margin: 0 auto;" src="@/assets/image/SynergyLogo.png" width="100px"></v-img>
            <v-card-title class="text-center mt-6">User Verified Successfully </v-card-title>
        </v-card>
    </v-container>
</template>
<script>
import UserService from '@/api/service/UserService';
export default {
    
  data() {
    return {
      showData: false
    }
  },
    created() {
        if (this.$route.query.userId) {
            this.getVerify(this.$route.query.userId)
        }



    },
    methods: {
        getVerify(id) {
            let res = UserService.verifyUser({ userId: id })
            if (res) {
                 this.showData=true
                setTimeout(() => {
                    window.location = 'https://synergy-login.axodesk.com/?redirectUrl=https://synergy.axodesk.com/login'
                }, 5000)
            }
        }
    }
}
</script>