<template>
  <v-card>
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>
          <b> Edit Details</b>
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>

        <v-row class="px-2 py-0 my-0">
          <v-col cols="6" class="dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Due On</v-label>

            <TextFieldComponent density="compact" placeholder="Due on" type="datetime-local" showtime hoursFormat="24"
              v-model="formData.end_date" :min="getCurrentDateTime()"  />
          </v-col>

          <v-col cols="6">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Assignee
            </v-label>
            <AutoComplete :items="users" :placeholder="'Assignee'" item-title="username" :item-value="'id'"
              v-model="formData.next_action" />
          </v-col>
        </v-row>

        <v-row class="px-2 py-0 my-0" v-if="this.skillId && this.quantityID">
          <v-col cols="6" v-if="this.quantityID">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Quantity
            </v-label>
            <TextFieldComponent density="compact" @keydown="floatValue($event)" placeholder="quantity"
              v-model="formData.quantity" />
          </v-col>

          <v-col cols="6" v-if="this.skillId">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Skill
            </v-label>
            <AutoComplete :items="skills" :placeholder="'Skill'" :item-value="'id'" :item-title="'name'"
              :rules="projectData && projectData.skill?skillRule:''" :disabled="showReadOnly" v-model="formData.skill_id" />
          </v-col>
        </v-row>
        <v-row class="px-2 py-0 my-0">
          <v-col cols="6">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Estimates
            </v-label>
            <TextFieldComponent density="compact" @keydown="floatValue($event)" placeholder="estimate"
              v-model="formData.estimate" />
          </v-col>

          <v-col cols="6">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Stage
            </v-label>

              <AutoComplete :items="stages" item-value="code" item-title="name" density="compact"
              placeholder="Select Stage"  v-model="formData.stage" 
              :disabled="showReadOnly" />
          </v-col>
        </v-row>


      </v-card-text>

      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" :loading="saveClicked" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form></v-card>
</template>

<script>
import moment from "moment";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TextFieldComponent from "../../../components/TextFieldComponent.vue";
import IssueService from "@/api/service/Issue/IssueService";
// import EnumAutoComplete from "@/components/others/EnumAutoComplete.vue";
import UserService from "@/api/service/UserService";
import StageService from "@/api/service/Stage/StageService";
import RiskService from "@/api/service/Risk/RiskService";
import ProjectService from "@/api/service/Project/ProjectService";
import commonService from "@/common.service";
export default {
  props: ["id"],
  components: {
    AutoComplete,
    TextFieldComponent,
    // EnumAutoComplete
  },
  data() {
    return {
      saveClicked: false,
      dialog: false,
      users: [],
      stages: [],
      quantityID:'',
      skillId:'',
      projectData: {},
      skills: [],
      formData: {
        end_date: null,
        next_action: null,
        submitted_by: null,
        skill_id: null,
        estimate: null,
        stage: null,
      },
      
    }
  },
  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
      }
    },
  },
  async created() {
    let wsDetails = JSON.parse(localStorage.getItem('_WS_DEFAULTS_'))
    if (wsDetails && wsDetails.project && wsDetails.project.id) {
      try {
        let  $projects = await ProjectService.findAll({id:wsDetails.project.id})
        // console.log(res,'check3333')
        this.skillId = $projects[0].skill
        this.quantityID = $projects[0].quantity
      }
      catch (e) {
        this.projectData = {}
      }
    }
    this.getSkills()
    this.getuser()
    this.getStage()
    if (this.id) {
      this.getById(this.id);
    }
  },
  methods: {
    moment,
    close() {
      this.$emit("closeDialog");
    },
    getCurrentDateTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, '0');
            const day = now.getDate().toString().padStart(2, '0');
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        },
          
    async getById(id) {
      let res = await IssueService.findOne(id);
      this.formData = res[0];
      this.formData.id = res[0].id;
      this.formData.end_date = res[0].end_date ? moment(res[0].end_date).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss')
    },
    changeStage($event) {
      this.formData.stage = $event.code
    },

    floatValue(e) {
      if (!/^\d*\.?\d{0,2}$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },


    async getuser() {
      try {
        let wsDefaults= JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
        let workSpaceId = localStorage.getItem("workSpaceId")
        let res = await UserService.getNextAction({ work_space_id: workSpaceId,account_id:wsDefaults.account.id,project_id:wsDefaults.project.id })
        this.users = res.length ? res : [];
      }
      catch (e) {
        this.users = []
      }
    },
    async getStage() {
      let res = await StageService.findAll();
      this.stages = res;
    },

    async getSkills() {
      let res = await RiskService.findAll();
      this.skills = res
    },


    async save() {
      // if (this.formData && this.formData.end_date) {
        this.saveClicked = true
        this.formData.end_date = commonService.changeTheDateToUtc(this.formData.end_date,Intl.DateTimeFormat().resolvedOptions().timeZone)
      // }
      await IssueService.update(this.formData);
      this.$store.dispatch('setSnackbar', { text: 'Details Updated Successfully' })
      this.dialog = false;
      this.close();
      this.$emit("reload");
      this.formData = {};
    }
  }
}
</script>

<style></style>