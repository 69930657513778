import ApiService from '../../service/apiServiceBoard.js';
const querystring = require('querystring')
//import CommonService from '../Common/CommonService.js';
var RutService = {
    
    getRUT: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getRUT"
            $options.method = "get"

            if ($data) {
                $options.url = "getRUT?" + querystring.stringify($data)
            }
            ApiService.request($options)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    }
};
export default RutService;