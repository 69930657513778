import socket2 from '@/Socket-Client2.js'


const SocketHandler = {

    messageViewed($issueId) {
        socket2.sendMessage('_ISSUE_VIEW_',$issueId)
    },
    sendMessage($message) {
        socket2.sendMessage('_ISSUE_VIEW_',$message)
    }
    
} 
export default SocketHandler;