<template>
  <div>
    <p>{{ time }}</p>
  </div>
</template>
  
<script>
export default {
  props: ['startAndStop', 'startTime'],
  data() {
    return {
      timeValue: 0,
      timer: null,
      timerActive: false
    };
  },

  created() {
    if (this.startTime) {

      this.timeValue = this.startTime
      this.startTimer()
    }
    else if (!this.startAndStop) {
      this.startTimer()
    }
  },
  watch: {
    startTime() {
      if (this.startTime) {
        this.timeValue = this.startTime
        this.startTimer()
      }
    },
    startAndStop() {
      if (this.startAndStop) {
        this.stopTimer()
      }
      else {
        this.startTimer()
      }
    }
  },
  computed: {
    time() {
      let seconds = this.timeValue % 60;
      let minutes = Math.floor(this.timeValue / 60) % 60;
      let hours = Math.floor(this.timeValue / (60 * 60));
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  },
  methods: {
    startTimer() {
      this.timerActive = true;
      this.timer = setInterval(() => {
        this.timeValue++;
      }, 1000);
    },
    stopTimer() {
      this.timerActive = false;
      localStorage.setItem('currentIssueHoldTime', this.timeValue)
      clearInterval(this.timer);

    }
  }
};
</script>
  