<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>
          Edit Task
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close"
            class="x-closeIcon"></v-btn></v-card-title>
      </v-card-item>
      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="6">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Account
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete class="dropdown" :items="accountData" :placeholder="'Account'" :item-title="'name'"
              :disabled="showReadOnly" :item-value="'id'" v-model="formData.account_id" :rules="accountRule" />
          </v-col>
          <v-col cols="6" class="pl-5">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Project
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete class="dropdown" :items="projectData" :placeholder="'Project'" :item-title="'name'"
              :disabled="showReadOnly" :item-value="'id'"  @update:model-value="selectProject($event)" v-model="formData.project_id" :rules="projectRule" />
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Subject
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent class="dropdown" placeholder="Subject" :rules="subjectRule" v-model="formData.name" />
          </v-col>
          <!-- <v-col>
                     <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Notes
               <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
               <TextArea  class="pb-4"
                 editorStyle="height: 100px"
                 :data="comment"
                 :showReadOnly="showReadOnly"
                 @textData="changeDescription($event)"
                 ></TextArea>
                 </v-col> -->
          <v-col cols="6" class="pt-0" v-if="selectedProject && selectedProject.category_required==1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Category
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="categoryData" :placeholder="'Category'" @update:modelValue="selectCategory($event)"
              :item-title="'name'" :disabled="showReadOnly" :item-value="'id'" v-model="formData.category_id"
              :rules="categoryRule" />
          </v-col>

          <v-col cols="6" class="pl-5" v-if="selectedProject && selectedProject.sub_category==1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Category
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="subCategoryData" :placeholder="'Sub Category'" :item-title="'name'"
              :disabled="showReadOnly" :item-value="'id'" v-model="formData.sub_category_id" :rules="subCategoryRule" />
          </v-col>

          <v-col cols="6" class="pt-0">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Task
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete class="dropdown" :items="taskData" :placeholder="'Sub Task'" :item-title="'name'"
              :disabled="showReadOnly" :item-value="'id'" v-model="formData.task_id" :rules="taskRule" />
          </v-col>

          <v-col cols="6" class="pl-5">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Task Type
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete class="dropdown" :items="issueTypeData" :placeholder="'Task Type'" :item-title="'name'"
              v-model="formData.issue_type_id" :disabled="showReadOnly" :item-value="'id'" :rules="issueTypeRule" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
 
<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import TaskService from "@/api/service/Task/TaskService";
import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
import ProjectService from "@/api/service/Project/ProjectService";
import AccountService from "@/api/service/Account/AccountService";
import CategoryService from "@/api/service/Category/CategoryService";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
import IssueService from "@/api/service/Issue/IssueService";
import commonService from "@/common.service";
// import EnumAutoCompleteVue from '@/components/others/EnumAutoComplete.vue';
export default {
  props: ["id", "parent_id", "parentIssueId"],
  components: {
    AutoComplete,
    // EnumAutoCompleteVue,
    TextFieldComponent,
  },
  watch: {
    parentIssueId() {
      if (this.parentIssueId) {
        this.showReadOnly = true
      }
      else {
        this.showReadOnly = false
      }
    }
  },
  created() {
    this.showReadOnly = this.parentIssueId ? true : false
    this.getTaskData();
    this.getIssueTypeData();
    let workSpaceId = localStorage.getItem("workSpaceId");
    this.getProject(workSpaceId);
    this.getAccounts(workSpaceId);
    // this.getSubCategoryData();
    if (this.id) {
      this.getById(this.id);
    }
  },
  data() {
    return {
      taskData: [],
      selectedProject:null,
      issueTypeData: [],
      projectData: [],
      accountData: [],
      categoryData: [],
      selectedCategoryId:[],
      categoryIddata:[],
      subCategoryData: [],
      formData: {},
      workSpaceId: "",
      projectRule: [(value) => !!value || "Project is Required."],
      accountRule: [(value) => !!value || "Account is Required."],
      subjectRule: [(value) => !!value || "Subjest is Required."],
      taskRule: [(value) => !!value || "Sub Task is Required."],
    };
  },

  methods: {
    close() {
      this.$emit("closeDialog");
    },
    async getTaskData() {
      let res = await TaskService.findAll({ "task_type": "production" });
            this.taskData = res.length > 0 ? await commonService.getSort(res, 'name', 'asc') : [];
    
    },
    async getIssueTypeData() {
      let res = await IssueTypeService.findAll();
      this.issueTypeData = res.length > 0 ? res : [];
    },
    async getProject(workSpaceId) {
      let res = await ProjectService.getWorkSpaceProject({
        work_space_id: workSpaceId,
      });
      this.projectData = res.length > 0 ? res : [];
    
    },
  
    async getAccounts(workSpaceId) {
      let res = await AccountService.getAccountByIssue({
        work_space_id: workSpaceId,
      });
      this.accountData = res.length > 0 ? res : [];
    },
    async getCategoryData(id) {
      let res = await CategoryService.categoryById({categoryId:id});
      this.categoryData = res.length > 0 ? res : [];
    },
    async getSubCategoryData(id) {
      let res = await SubCategoryService.findAll({category_id: id});
      this.subCategoryData = res.length > 0 ? res : [];
    },
    selectCategory($event) {
      if ($event>0) {
        this.formData.sub_category_id = null
        this.getSubCategoryData($event);
      }
    },
   async getCategoryId(id){
     let res= await ProjectService.findOne(id);
     this.categoryIddata=res.length > 0 ? res :[]
     this.getCategoryData(this.categoryIddata[0].category_id)

    },
    async getById(id) {
      let res = await IssueService.findOne(id);
      this.formData = res[0];
      if(this.formData.project_id){
        this.getSelectProjectData(this.formData.project_id)
        this.getCategoryId(this.formData.project_id)
      }
      console.log(this.formData,'issueServiceee')
      if (this.formData.sub_category_id) {
        // alert('hlo')
        this.getSubCategoryData(this.formData.category_id);
      }
    },
    selectProject($event){
      if($event){
        
        this.getSelectProjectData($event)
      }
    },
    async getSelectProjectData(id){
      try{
      let res =await ProjectService.findOne(id)
      this.selectedProject =res && res.length>0?res[0]:{}
      }
      catch(e){
        this.selectedProject  ={}
        console.log(e)
      }
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log("Not valid");
      } else {
        try {
          if (this.formData.id) {
            let output = await IssueService.update(this.formData);
            // this.saveTheComments()
            this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
            if (!this.parentIssueId) {
              await IssueService.updateChildIssue(output.data.data)
            }
            this.close();
            this.dialog = false;
            this.$emit("reload", true);
          }
        } catch (e) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Something Went Wrong",
          });
        }
      }
    },
  },
};
</script>
 
<style scoped>
>>>.dropdown .v-messages__message {
  line-height: 12px;
  color: red;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding-left: 0px;
  transition-duration: 150ms;
  text-align: left;
  margin-left: 0px;
}
</style>