<template>
    <V-card density="compact" flat height="90vh">
        <v-card-title class="my-3" style="display: flex;justify-content: space-between;">Live Board

            <span>
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <i class="pa-5 pi pi-file-export" style="cursor: pointer" v-bind="props" />
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in downloadMenu" :key="index" :value="index">
                            <v-list-item-title @click="exportFile(item)">{{
                                item
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </span>
        </v-card-title>

        <v-card-text>
            <div style="display: flex;width:100%;gap:1%">
                <div v-if="false"
                    style="border: 1px solid #C6C6C6C6; border-radius: 8px; overflow: hidden; height:80vh;width:20%">
                    <DashboardFilter />
                </div>
                <div style="width:79%;height: 80vh;overflow: auto;" :style="{ width: false ? '79%' : '100%' }">
                    <LiveTable :tableData="tableData" :allData="ProductionData" @printData="addData" />
                </div>
            </div>
        </v-card-text>
    </V-card>
</template>

<script>
import DashboardFilter from '@/modules/dashboard/Widgets/DashboardFilter.vue'
import LiveTable from './LiveTable.vue'
import ProductionDashboardService from "@/api/service/ProductionDashboard/ProductionDashboard";
import ExportService from "@/ExportService";
export default {
    components: { LiveTable, DashboardFilter },

    data() {
        return {
            downloadMenu: ["Excel", "Pdf", "Json"],
            ProductionData: [],
            tableData: [],
            finalExportData: [],
        }
    },

    async created() {
        await this.getProductionData();
        this.finalExportData = this.tableData
    },
    methods: {
        async getProductionData() {
            let res = await ProductionDashboardService.liveBoard();
            this.tableData = res.length > 0 ? res : [];
            console.log(this.tableData, 'whatrabala');
        },

        addData(data) {
            this.finalExportData = data

            console.log( this.tableData, 'checkDiff',this.finalExportData )
        },

        async exportFile(type) {
            let sourceData = [];
            let column;
            console.log(this.finalExportData,"99999999333")

            this.finalExportData.forEach((workspace) => {
                console.log(workspace)
                workspace.accountData.forEach((account) => {
                    console.log(account,"oooeee")
                    sourceData = [...sourceData,...account.userData]
                });
            });
            console.log(sourceData,"9999999999")


            column = [
                {
                    field: "work_space_name",
                    header: "WorkSpace",
                    style: { width: "100px", color: "" },
                    toolTip: "Name",
                    sortfield: true,
                },
                {
                    field: "account_name",
                    header: "Account",
                    style: { width: "100px", color: "" },
                    toolTip: "Name",
                    sortfield: true,
                },
                {
                    field: "project_name",
                    header: "Project",
                    style: { width: "100px", color: "" },
                    toolTip: "Name",
                    sortfield: true,
                },
                {
                    field: "user_name",
                    header: "User Name",
                    style: { width: "100px", color: "" },
                    toolTip: "User Name",
                    sortfield: true,
                },
                {
                    field: "assigned_issues",
                    header: "Assigned",
                    style: { width: "100px", color: "" },
                    toolTip: "Num",
                    sortfield: true,
                },
                {
                    field: "clarification_issues",
                    header: "Clarification",
                    style: { width: "100px", color: "" },
                    toolTip: "Num",
                    sortfield: true,
                },
                {
                    field: "hold_issues",
                    header: "Hold",
                    style: { width: "100px", color: "" },
                    toolTip: "Num",
                    sortfield: true,
                },
                {
                    field: "inprogress_issues",
                    header: "Inprogress",
                    style: { width: "100px", color: "" },
                    toolTip: "Num",
                    sortfield: true,
                },
                {
                    field: "it_issues",
                    header: "IT",
                    style: { width: "100px", color: "" },
                    toolTip: "Num",
                    sortfield: true,
                },
                {
                    field: "open_issues",
                    header: "Open",
                    style: { width: "100px", color: "" },
                    toolTip: "Num",
                    sortfield: true,
                },
                {
                    field: "pastdue_issues",
                    header: "Past Due",
                    style: { width: "100px", color: "" },
                    toolTip: "Num",
                    sortfield: true,
                },
                {
                    field: "qacompleted_issues",
                    header: "Qa completed",
                    style: { width: "100px", color: "" },
                    toolTip: "Num",
                    sortfield: true,
                },
                {
                    field: "qapending_issues",
                    header: "Qa pending",
                    style: { width: "100px", color: "" },
                    toolTip: "Num",
                    sortfield: true,
                },
                {
                    field: "completed_issues",
                    header: "Completed",
                    style: { width: "100px", color: "" },
                    toolTip: "Num",
                    sortfield: true,
                },
                {
                    field: "total_issues",
                    header: "Total",
                    style: { width: "100px", color: "" },
                    toolTip: "Num",
                    sortfield: true,
                },
            ]

            if (this.finalExportData !== this.tableData) {
                column.splice(2, 0, {
                    field: "user",
                    header: "User",
                    style: { width: "100px", color: "" },
                    toolTip: "Name",
                    sortfield: true,
                });
            }

            console.log(sourceData, 'whatshapping', type, column);
            await ExportService.export(sourceData, "Live Board", column, type);
        }

    }
}
</script>

<style></style>
