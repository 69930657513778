<template>
  <v-card elevation="0" style="border-radius: 10px">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>
          {{
            this.type === "action" && !showReadOnly
              ? "Edit"
              : showReadOnly
              ? "View"
              : "Add"
          }}
          IT Support Follow Up
          <!-- <v-avatar size="x-small" color="primary">
                        {{no_of_followups}}
                    </v-avatar> -->
          <v-btn
            style="float: right"
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row class="py-0 my-0">
          <!-- <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group
              :disabled="showReadOnly"
              v-model="formData.followup_type"
              inline
              @change="getUsers(formData.followup_type)"
              v-if="true"
            >
              <v-radio color="primary" label="Internal" :value="1"></v-radio>
              <v-radio color="error" label="External" :value="0"></v-radio>
            </v-radio-group>
          </v-col> -->
          <v-col cols="6" class="py-0 my-0">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Ticket No
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 600"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Ticket No"
              :disabled="showReadOnly"
              :rules="ticketRule"
              v-model="formData.ticket_num"
            />
          </v-col>

          <v-col cols="6" class="py-0 my-0">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
            >
              Pending With
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :disabled="showReadOnly"
              class="dropdown"
              :items="users"
              :item-title="'name'"
              :item-value="'id'"
              density="compact"
              v-model="formData.user"
              placeholder="User"
              :rules="userRule"
              v-if="true"
            />
          </v-col>

          <v-col cols="6" class="py-0 my-0">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
            >
              Pending With IT
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :disabled="showReadOnly"
              class="dropdown"
              :items="contancts"
              :item-title="'name'"
              :item-value="'id'"
              density="compact"
              v-model="formData.contact"
              placeholder="Contacts"
              :rules="contactRule"
              v-if="true"
            />
          </v-col>

          <!-- <v-col cols="5" v-if="!switchMe && !showReadOnly" class="py-0 my-0">
            <v-switch v-model="switchMe" :disabled="showReadOnly" color="primary">
              <template v-slot:label> Followup Date Required ? </template>
            </v-switch>
          </v-col> -->

          <v-col cols="6" class="py-0 my-0">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >FollowUp Date
              <span
                v-if="true"
                style="color: #cf0707; padding-left: 2px; font-weight: 600"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              :showReadOnly="showReadOnly"
              density="compact"
              placeholder="FollowUp date"
              :rules="dateRule"
              v-model="formData.followup_date"
              type="date"
            />
          </v-col>

          <v-col cols="12">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Description</v-label
            >
            <DescriptionVue
              class="pb-0"
              editorStyle="height: 120px"
              :showReadOnly="showReadOnly"
              :data="formData.description"
              @textData="changeDescription($event)"
              ref="descriptionVue"
            ></DescriptionVue>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom">
          <v-btn style="--v-btn-height: 35px" class="mr-2 cancel" @click="close()"
            >Cancel</v-btn
          >
          <v-btn
            style="--v-btn-height: 35px"
            :loading="saveClicked"
            class="save"
            type="submit"
            >Save</v-btn
          >
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
import DescriptionVue from "@/components/ui/Description.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import IssueService from "@/api/service/Issue/IssueService";
import ForumService from "@/api/service/Forum/ForumService";
import UserService from "@/api/service/UserService";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import ContactService from "@/api/service/Contact/ContactService";
import moment from "moment";

export default {
  components: {
    DescriptionVue,
    TextFieldComponent,
    AutoComplete,
  },
  props: ["issueId", "no_of_followups", "showReadOnly", "type"],
  data() {
    return {
      saveClicked: false,
      switchMe: false,
      formData: {
        ticket_num: null,
        followup_date: null,
        description: null,
        user: null,
        contact: null,
        // followup_type: null,
      },
      contancts: [],
      users: [],
      ticketRule: [(value) => !!value || "Ticket no is Required"],
      dateRule: [(value) => !!value || "Follow up is Required"],
      userRule: [(value) => !!value || "User is Required"],
      contactRule: [(value) => !!value || "Contact is Required"],
    };
  },

  created() {
    this.getUsers();
    if (this.issueId && this.type && this.type == "action") {
      this.getById(this.issueId);
    }
  },
  methods: {
    moment,
    close() {
      this.$emit("closeDialog");
    },
    changeDescription($event) {
      this.formData.description = $event;
    },

    async getById(id) {
      let res = await IssueService.getStageData({
        issueId: id,
        type: "itsupportrequired",
      });
      if (res && res.length > 0) {
        // if (res[0].it_followup_date != null) {
        //   this.switchMe = true;
        // }
        this.getUsers(res[0].followup_type);
        this.formData.ticket_num = res[0].ticket_num ? res[0].ticket_num : null;
        this.formData.followup_date = res[0].it_followup_date
          ? moment(res[0].it_followup_date).format("YYYY-MM-DD")
          : null;
        this.formData.description =
          res[0].forums && res[0].forums.description ? res[0].forums.description : null;
        this.formData.f_id =
          res[0].forums && res[0].forums.f_id ? res[0].forums.f_id : null;
        this.formData.user = parseInt(res[0].followup_user);
        this.formData.contact = parseInt(res[0].pending_with_it);
        console.log(res[0] , 'kashiehfuirgur')
        // this.formData.followup_type = res[0].followup_type == "internal" ? 1 : 0;
        console.log(res[0].forums.followup_type, "forum");
      }
    },
    // clearFollowUpDate() {
    //   this.switchMe = false;
    //   this.formData.followup_date = null;
    // },
    async getUsers() {
      this.formData.user = null;
      // if (type == 1 || type == "internal") {
        let res = await UserService.findAll();
        this.users = res && res.length > 0 ? res : [];
        console.log(this.users, "users");
      // } else if (type == 0 || type == "external") {
        let $res = await ContactService.allContacts();
        this.contancts = $res && $res.length > 0 ? $res.filter((contact) => contact.active === 1 && contact.key_type === 'itsupport') : [];
      // } else {
        // this.users = [];
      // }
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation.valid);
      } else {
        if (this.issueId) {
          if (!this.formData.description) {
            this.$refs.descriptionVue.showErrorMessage("Description is Required");
            return;
          }
          this.saveClicked = true;
          let $obj = {
            id: this.issueId,
            stage: "itsupportrequired",
            it_followup_date: this.formData.followup_date,
            ticket_num: this.formData.ticket_num,
            followup_user: this.formData && this.formData.user ? this.formData.user : "",
            pending_with_it: this.formData && this.formData.contact ? this.formData.contact : "",
            // followup_type:
            //   this.formData.followup_type && this.formData.followup_type == 1
            //     ? "internal"
            //     : "external",
          };
          console.log($obj, "kkkklllllllllll");
          await IssueService.update($obj);
          let $description =
            this.formData && this.formData.description ? this.formData.description : "";

          let data = {
            key_type: "issue",
            key_id: this.issueId,
            action: "itfollowup",
            description: $description,
          };
          await ForumService.create(data);
          this.$emit("saveDialog");
          this.close();
          this.formData = {};
          this.$emit("it-followup-updated");
        }
      }
    },
  },
};
</script>

<style></style>
