<template>
  <div>
    <v-row class="mt-7" style="border-bottom: 2px">
      <v-col>
        <v-card flat>
          <v-row class="mt-2">
            <v-col cols="3" class="ml-6">
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Model
                <span style="color:#CF0707;padding-left:2px;font-weight:700">*</span>
              </v-label>

              <AutoComplete :@change="onSelect(modelData.model)" return-object :items="listModelData" :rules="ModelRule"
                item-title="name" v-model="modelData.model" item-value="id" :disabled="showReadOnly"
                placeholder="Models" />
            </v-col>
            <v-col align="start" class="mt-8" cols="1" v-if="!showReadOnly">
              <span><v-tooltip activator="parent" location="bottom">Add Model</v-tooltip><v-icon size="25px" color="#3C5AAA" @click="addmodel()">mdi-plus-circle
                </v-icon></span>
                <span class="pl-2" v-if="false">
                  <v-tooltip activator="parent" location="bottom">Export To JSON</v-tooltip>
                <v-icon size="25px" color="#3C5AAA" @click="downloadJson()" v-if="modelData.model">mdi-download</v-icon>
                </span>
                <span class="pl-2">
                  <v-tooltip activator="parent" location="bottom">Edit</v-tooltip>
                <v-icon size="25px" color="#3C5AAA" @click="editModel()" v-if="modelData.model">mdi-pencil</v-icon>
                </span>
            </v-col>
            <v-col cols="3" v-if="false">
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Model
              </v-label>
              <TextFieldComponent v-model="modelData.name" placeholder="Label" :disabled="showReadOnly" />
            </v-col>
            <v-col align="end" class="mr-10">
              <v-icon size="25px" color="#000" @click="close()">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters dense v-if="modelData.model">
      {{ $newColumns }}
      <v-col cols="4">
        <div>
          <v-card flat>
            <v-card-title>
              Field
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <TextFieldComponent v-model="search" @input="searchTheData()" flat append-inner-icon="mdi-magnify"></TextFieldComponent>
                </v-col>
              </v-row>
              <v-row no-gutters dense class="ma-1">
                <v-col cols="12">
                  <v-list density="compact">
                    <v-list-item :class="{ active: item.isActive }" class="mousehover" v-for="(item, index) in fieldData"
                      :key="item.name" @click="setSelected(item, index)" v-model="fieldData.columnName">
                      {{ item.label }}
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-divider :thickness="1" class="border-opacity-25" vertical></v-divider>
      <v-col cols="8" class="pt-5">
        <v-card flat>
          <v-row dense class="ml-3">
            <v-col cols="8">
              <text-field-component-vue @keyup="searchTheColumn" v-model="searchValue"
                placeholder="Search Column"></text-field-component-vue>
            </v-col>
          </v-row>
          <v-row no-gutters dense>
            <v-col cols="9" class="pl-5">
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Column Name</v-label>
              <TextFieldComponent placeholder="Column Name" v-model="formData.column_name" :disabled="showReadOnly" />
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Label</v-label>
              <TextFieldComponent placeholder="Label" v-model="formData.label" :disabled="showReadOnly" />
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Data Type</v-label>
              <EnumAutoCompleteVue density="compact" v-model="formData.data_type" :selected="formData.data_type"
                :showReadOnly="viewType == 'add' || this.viewType == 'view'" :domain="'DATA-TYPE'" placeholder="Datatype"
                @changeEnumData="changeDatatype($event)" :rules="dataTypeRule" />
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Field Type</v-label>
              <EnumAutoCompleteVue density="compact" v-model="formData.field_type" :selected="formData.field_type"
                :showReadOnly="formData.data_type === 'date' || formData.data_type === 'datetime' || this.viewType == 'view'"
                :domain="'FIELD-TYPE'" placeholder="FieldType" @changeEnumData="changeEnumData($event)"
                :rules="fieldTypeRule" />

              <v-label v-if="showSource" class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Source
                Type
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <EnumAutoCompleteVue v-if="showSource" density="compact" v-model="formData.source_type"
                :selected="formData.source_type" :showReadOnly="viewType == 'add' || this.viewType == 'view'"
                :domain="'SOURCE-TYPE'" placeholder="Source" @changeEnumData="changeSource($event)" :rules="sourceRule" />
              <v-label class="pb-1" v-if="showModel" style="font-size:14px;color:#272727;font-weight:600">Source</v-label>
              <AutoComplete placeholder="Table" v-if="showModel" :item-title="'value'" :disabled="showReadOnly"
                :item-value="'value'" v-model="formData.source" :items="tableData" />
              <!-- <AutoComplete placeholder="Feild Type" v-model="formData.field_type" :disabled="showReadOnly" /> -->
              <v-label class="pb-1" v-if="showModel" style="font-size:14px;color:#272727;font-weight:600">Value</v-label>
              <TextFieldComponent v-if="showModel" placeholder="Value" v-model="formData.value"
                :disabled="showReadOnly" />
              <v-label class="pb-1" v-if="showModel" style="font-size:14px;color:#272727;font-weight:600">Text</v-label>
              <TextFieldComponent v-if="showModel" placeholder="Text" v-model="formData.text" :disabled="showReadOnly" />
              <v-label class="pb-1" v-if="showEnum" style="font-size:14px;color:#272727;font-weight:600">Domain</v-label>
              <TextFieldComponent placeholder="Text" v-if="showEnum" v-model="formData.enum_domain"
                :disabled="showReadOnly" />
              <v-checkbox label="Multi Select" v-if="showModel" color="primary"
                v-model="formData.multi_select"></v-checkbox>
              <!-- <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Width</v-label>
              <TextFieldComponent placeholder="Width" v-model="formData.width" :disabled="showReadOnly" />
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Format</v-label>
              <TextFieldComponent placeholder="Format" v-model="formData.formatters" :disabled="showReadOnly" />
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Alignment</v-label>
              <AutoComplete placeholder="Alignment" :disabled="showReadOnly" /> -->
              <v-row>
                <v-col>
                  <v-switch label="Filtered Required" color="primary" v-model="formData.filter_required"></v-switch>
                </v-col>
                <v-col>
                  <v-switch label="Column Required" color="primary" v-model="formData.column_required"></v-switch>
                </v-col>
              </v-row>
              
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600" v-if="formData.filter_required==1">Filter Sequence</v-label>
              <TextFieldComponent placeholder="Filter Sequence" v-model="formData.sequence" v-if="formData.filter_required==1" :disabled="showReadOnly" />
              
              <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600" v-if="formData.column_required==1">Table Sequence</v-label>
              <TextFieldComponent placeholder="Table Sequence" v-if="formData.column_required==1" v-model="formData.table_sequence" :disabled="showReadOnly" />


              <!-- <v-row justify="end" class="dialogBottom" > -->
              <v-row v-if="!showReadOnly">
                <v-col align="end">
                  <v-btn class="mr-2 cancel" @click="close()">Cancel</v-btn>
                  <v-btn class="save" type="submit" @click="save()">Save</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <v-dialog persistent v-model="dialog" width="700px">
    <AddListModel ref="AddListModel" @closeDialog="setFalse()" @reload="getModelData()"  :modelData="modelData.model" />
  </v-dialog>
  <v-dialog persistent v-model="newColumnDialog"  width="700px">
    <RefreshColumns :columns = "newColumns" 
    @close="newColumnDialog=false" 
    @column-updated="columnUpdated" />
  </v-dialog>
</template>

<script>

import AutoComplete from "@/components/ui/AutoComplete.vue";
import AddListModel from "./AddListModel.vue";
import EnumAutoCompleteVue from '@/components/others/EnumAutoComplete.vue';
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import ListModelService from "@/api/service/ListModel/ListModelService";
import ListModelDetailService from "@/api/service/ListModelDetail/ListModelDetailService";
import commonService from '@/common.service';
import RefreshColumns from './RefreshColumns.vue';
import exportFromJSON from "export-from-json";

export default {
  components: {
    AutoComplete,
    RefreshColumns,
    TextFieldComponent,
    AddListModel,
    EnumAutoCompleteVue
  },
  data() {
    return {
      // formdata: [],
      newColumnDialog:false,
      newColumns:[],
      search:null,
      dialog: false,
      showReadOnly: false,
      listModelData: [],
      tableData: [],
      showSource: false,
      backupFieldData:[],
      showModel: false,
      showEnum: false,
      showOption: false,
      // modelsId:'',
      modelId: null,
      modelData: {},
      fieldData: [],
      listModelDatas: {},
      formData: {},
      nameData: "",
      ModelRule: [(value) => !!value || "Models is Required."],
      nameRule: [(value) => !!value || "Name is Required."],


    };
  },
  async created() {
    await this.getModelData();
    this.getSourceData()
    if (this.$route.query.id) {
      this.modelId = parseInt(this.$route.query.id)
      let fData = this.listModelData.filter(k => k.id == this.modelId)
      this.modelData = fData[0]
      this.getField(this.$route.query.id);
    }
    if (this.$route.query.type) {
      this.viewType = this.$route.query.type
      this.showReadOnly = this.viewType == 'edit' ? false : true;
    }
  },
  methods: {
    async downloadJson(){
      let fileName = this.modelData.name;
      console.log("filename",fileName)
      console.log("fileDatatata",this.fieldData)
      let data = await this.fieldData
      const exportType = exportFromJSON.types.json;
      if (data) exportFromJSON({ data, fileName, exportType });
    },
    formatedData(){

    },
    async columnUpdated($newColumns) {
      console.log($newColumns)
      let res = await ListModelDetailService.findAll({ model_id: this.modelId })
      this.fieldData = res
      this.newColumnDialog=false
      this.backupFieldData =JSON.parse(JSON.stringify(res))
    },
    searchTheData() {
      if (this.search.length > 0) {
        let searchStr = new RegExp(this.search, "gi");
        this.fieldData = this.backupFieldData.filter((ele) => {
          return (
            ele.label.search(searchStr) > -1 ||
            ele.column_name.search(searchStr) > -1
          );
        });
      } else {
        this.fieldData = this.backupFieldData;
      }
    },
    async getSourceData() {
      let res = await commonService.getTables()
      this.tableData = res.length > 0 ? res : []
      console.log(this.tableData, "List model data")
    },
    setSelected($item, $index) {
      this.showSource = false
      this.showEnum = false
      this.showModel = false
      this.showModel = false
      this.fieldData.forEach((e) => (e.isActive = false));
      this.formData.id = $item.id
      this.formData.label = $item.label
      this.formData.column_name = $item.column_name
      this.formData.data_type = $item.data_type
      this.changeDatatype(this.formData.data_type)
      this.formData.value = $item.value
      this.formData.sequence = $item.sequence
      this.formData.source_type = $item.source_type
      this.formData.source = $item.source
      this.formData.value = $item.value
      this.formData.text = $item.text
      this.formData.enum_domain = $item.enum_domain
      this.formData.multi_select = $item.multi_select
      this.formData.width = $item.width
      this.formData.multi_select = $item.multi_select
      this.formData.table_sequence = $item.table_sequence
      this.formData.formatters = $item.formatters
      this.formData.filter_required = $item.filter_required == 1 ? true : false
      this.formData.column_required = $item.column_required == 1 ? true : false
      this.fieldData[$index].isActive = true;
      this.formData.field_type = $item.field_type
      this.changeEnumData(this.formData.field_type)
      this.changeSource(this.formData.source_type)

    },
    changeEnumData($event) {
      this.showSource = $event == 'autocomplete' || $event == 'select' ? true : false
      this.formData.field_type = $event
    },
    editModel() {
      this.dialog = true;
    },
    addmodel() {
      this.modelData={}
      this.dialog = true;
    },
    changeSource($event) {
      if ($event == 'model') {
        this.showModel = true
        this.showEnum = false
        this.showOption = false
        this.formData.options = null
        this.formData.enum_domain = null
      }
      else if ($event == 'enum') {
        this.showModel = false
        this.showEnum = true
        this.showOption = false
        this.formData.options = null
        this.formData.source = null
        this.formData.text = null
        this.formData.value = null
      }
      else {
        this.showModel = false
        this.showEnum = false
        this.showoptions = true
        this.formData.enum_domain = null
        this.formData.source = null
        this.formData.value = null
        this.formData.text = null
      }
      this.formData.source_type = $event;
    },
    // async getById(id) {
    //   // alert(id)
    //   let res = await ListModelDetailService.findAll(id);
    //   this.formData = res[0];
    //   this.modelsId=this.formData.model_id
    //   this.getmodels()
    //   // this.fieldData=res[0]
    //   console.log(this.modelsId,'check777777777')
    // },
    setFalse() {
      this.dialog = false;
    },
    changeDatatype($event) {
      if ($event == 'date' || $event == 'datetime' || $event == 'time') {
        this.formData.field_type = 'datepicker';
      }
      else {
        this.formData.field_type = null;
        this.formData == { ...this.formData }
      }
      this.formData.data_type = $event
    },
    async getModelData() {
      let res = await ListModelService.findAll();
      this.listModelData = res;
    },
    onSelect($event) {
      if ($event) {
        this.modelData.name = $event.name
        this.modelId = $event.id
        this.getField()
        

      }
    },
    async getTableSchema() {
      let $newColumns = []
      let $columns =  await ListModelService.modelSchema({ model: this.modelData.name,modelId:this.modelId })
      let $availableColumns = this.fieldData.map((obj)=>{return obj.column_name})
      console.log($availableColumns)
      for(let $column of $columns) {
        if($availableColumns.indexOf($column.column_name)<0) {
          $newColumns.push($column)
        }
      }
      this.newColumns = $newColumns
      if($newColumns.length>0) {
        this.newColumnDialog=true
      }
    },
    async getField() {
      let res = await ListModelDetailService.findAll({ model_id: this.modelId })
      this.fieldData = res
      await this.getTableSchema()
      this.backupFieldData =JSON.parse(JSON.stringify(res))

    },
    close() {
      this.$router.push('/setting/technical/model')
    },
    async save() {
      if (this.formData.id) {
        await ListModelDetailService.update(this.formData);
        this.formData = {}
        this.getField()

      } else {
        let output = await ListModelDetailService.update(this.formData);
        console.log(output);
        this.$router.push('/setting/technical/model')
        this.$emit("reload", true);
      }
    }
  },
};
</script>

<style scoped>
.mousehover:hover {
  background-color: #3c5aaa;
  color: white;
}

.active {
  background-color: #3c5aaa;
  color: white;
}

.save {
  background-color: #3c5aaa;
  color: white;
  width: 90px;
}
</style>
