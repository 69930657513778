<template>
  <PrimeDataTable :title="'HolidayTemplate'" :showFilter="true" :searchOption="true" :selectAll="true"
    :showDownload="true" :showUpload="true" :showAdd="true" :columnData="columns" :data="data" @addDiolog="addDiolog()"
    @rowAction="rowActions($event)" activity="holiday"/>

  <v-dialog persistent v-model="dialog" width="700px" >
    
      <CreateHolidayTemplate
        @closeDialog="close()"
        @reload="getHolidayTemplate()"
        :id="editId"
        :viewType="viewType"
        
      />
    </v-dialog>
</template>
  
<script>
import CreateHolidayTemplate from "./CreateHolidayTemplate.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import HolidayTemplateService from "@/api/service/HolidayTemplate/HolidayTemplateService";
// import commonService from "@/common.service";
export default {
  components: {
    CreateHolidayTemplate,
    PrimeDataTable,
  },
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      editId: null,
      dialog: false,
      viewType: false
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getHolidayTemplate();
  },
  methods: {

    loadColumns() {
      this.columns = [
        //   {
        //     field: "id",
        //     header: "Id",
        //     style: { width: "100px", color: "" },
        //     toolTip: "id",
        //     sortfield: true,
        //   },
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "name",
          sortfield: true,
        },
        {
          field: "location_name",
          header: "Location",
          style: { width: "100px", color: "" },
          toolTip: "Location",
          sortfield: true,
        },
        {
          header: "Year",
          style: { width: "100px", color: "" },
          toolTip: "year",
          sortfield: true,
          field: "year",
        },
        
        
        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "modified",
          sortfield: true,
        },
        {
          field: "active",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "active",
          sortfield: true,
        },
       
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
          ],
        },
      ];
      // testing
    },

    close() {
        this.dialog = false;
      },
      rowActions($event) {
  if ($event.type == "view") {
    this.dialog = true;
    this.editId = $event.data.id;
    this.viewType = $event.type;
  } else if ($event.type == "edit") {
    this.dialog = true;
    this.editId = $event.data.id;
    this.viewType = $event.type;
  } else if ($event.type == "select") {
    this.dialog = true;
    this.editId = $event.data.id;
    this.viewType = "view";
  }
},
  async getHolidayTemplate() {
        let res = await HolidayTemplateService.getAllList();
        this.data = res;
      },
    addDiolog() {
      this.dialog = true;
        this.editId = null;
        this.viewType= false
      }
    },
};
</script>