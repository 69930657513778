<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          Skill
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <v-row no-gutters class="mt-3 pa-3">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-label style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Code" :rules="codeRule" v-model="formData.code"
              :disabled="showReadOnly" @keyup="checkCode()" @keydown="prevent($event)" />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>
          <v-col cols="6" class="pl-4">
            <v-label class="pl-1" style="font-size: 14px; color: #272727; font-weight: 600">Score
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Score" :rules="scoreRule" v-model="formData.score"
              :disabled="showReadOnly" @keypress="allowNumbers($event)" />
          </v-col>

          <v-col cols="6">
            <v-label class="pl-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Name" :rules="nameRule" v-model="formData.name"
              :disabled="showReadOnly" />
          </v-col>
          <v-col cols="6" class="pl-4 pt-1">
            <v-label class="pl-1" style="font-size: 14px; color: #272727; font-weight: 600">Color
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <br>
            <input class=" colorPick" type="color" v-model="formData.theme">
          </v-col>
          <v-col cols="12">
            <v-label class="pl-1 mb-2"
              style="font-size: 14px; color: #272727; font-weight: 600;width: inherit;">Description &nbsp;
              <!-- <span @click="showTextArea = !showTextArea"
                style="color:#3C5AAA;cursor:pointer;text-decoration: underline;"><i>{{ showTextArea ? 'Show' : 'Hide'
                }}</i></span> -->
                </v-label>

                  <DescriptionVue  :data="formData.description" :showReadOnly="showReadOnly" @textData="changeDescription($event)"></DescriptionVue>
            <TextArea v-if=false :data="formData.description" :showReadOnly="showReadOnly"
              @textData="changeDescription($event)"></TextArea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-col class="pt-0 pb-0">
        <v-card-action class="mr-3">
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
            <v-btn class="save" type="submit">Save</v-btn>
          </v-row>
        </v-card-action>
      </v-col>
    </v-form>
  </v-card>
</template>
<script>
import TextArea from "@/components/ui/TextArea.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import AutoComplete from "@/components/ui/AutoComplete.vue"; Risk
import RiskService from "@/api/service/Risk/RiskService";
import DescriptionVue from '../../../../components/ui/Description.vue';

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    TextArea,
    DescriptionVue
    // AutoComplete
  },

  data() {
    return {
      showTextArea: true,
      showReadOnly: false,
      dialog: false,
      type: null,
      codeRule: [(value) => !!value || "Code is Required"],
      nameRule: [(value) => !!value || "Name is Required"],
      scoreRule: [(value) => !!value || "Score is Required"],
      codeError: false,
      formData: {
        id: null,
        code: null,
        name: null,
        score: null,
        description: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    changeDescription($event) {
      console.log($event , 'kyadvvdscvc')
      this.formData.description = $event;
    },

    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();

        RiskService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },

    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await RiskService.findOne(id);
      this.formData = res[0];
    },
    allowNumbers(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        if (this.formData.id) {
          await RiskService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          try {
            await RiskService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.dialog = false;
            this.close();
            this.$emit("reload", true);
            this.formData = {};
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>
  