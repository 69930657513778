<template>
    <v-card>
        <v-form @submit.prevent="save" ref="form">
            <v-card-item>
                <v-card-title>
                    <b> Edit Details</b>
                    <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close"
                        class="x-closeIcon"></v-btn>
                </v-card-title>
            </v-card-item>

            <v-card-text>
                <v-row class="px-2 py-0 my-0" no-gutters>

                    <v-col cols="12" class="pr-2">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Stage
                        </v-label>

                        <AutoComplete :items="stageData" item-value="code" item-title="name" density="compact"
                            placeholder="Select Stage" v-model="formData.stage" :disabled="showReadOnly"
                            @update:modelValue="changeStage" />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-action class="mr-3">
                <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
                    <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
                    <v-btn class="save" type="submit">Save</v-btn>
                </v-row>
            </v-card-action>
        </v-form></v-card>
</template>
<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";

export default {
    props: ["stageData"],
    components: {
        AutoComplete
    },
    data() {
        return {
            formData: {},
            changeData: null
        }
    },
    methods: {
        close() {
            this.$emit("closeDialog");
        },
        changeStage($event) {
            this.changeData = $event
        },
        save() {
            if (this.changeData) {
                this.$emit('update-stage', this.formData.stage)
            }
        }
    }
}
</script>
