<template>
  <PrimeDataTable
    :title="'Menu'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="menus"

  />
  <v-dialog persistent v-model="dialog" width="700px">
    <CreateMenu
      @closeDialog="close()"
      @reload="getMenu()"
      :id="editId"
      :viewType="viewType"
    />
  </v-dialog>
</template>
  
  <script>
import CreateMenu from "./CreateMenu.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import MenuService from "@/api/service/Menu/MenuService";
// import commonService from "@/common.service";
export default {
  components: {
    CreateMenu,
    PrimeDataTable,
  },
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      editId: null,
      dialog: false,
      viewType: false,
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getMenu();
  },
  methods: {
    loadColumns() {
      this.columns = [
        {
         field: "code",
         header: "Code",
         style: { width: "100px", color: "" },
         toolTip: "code",
          sortfield: true,
        },

        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
       
        {
          field: "parent_name",
          header: "Parent",
          style: { width: "100px", color: "" },
          toolTip: "parent",
          sortfield: true,
        },
        {
          field: "sequence_num",
          header: "Sequence Num",
          style: { width: "100px", color: "" },
          toolTip: "sequence_num",
          sortfield: true,
        },
        {
          field: "route",
          header: "Route",
          style: { width: "100px", color: "" },
          toolTip: "route",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
          ],
        },
      ];
    },

    close() {
      this.dialog = false;
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = "view";
      }
    },
    async getMenu() {
      let res = await MenuService.getParentMenuList();
      this.data = res;
    },
    addDiolog() {
      this.dialog = true;
      this.editId = null;
      this.viewType = false;
    },
  },
};
</script>
  
  
  