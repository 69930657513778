import { io } from "socket.io-client";
//const { uuid } = require('uuidv4');
//import { uuid } from 'uuidv4';


//import apiConfig from '@/api/config/config.js'
console.log(process.env)
const URL = process.env.VUE_APP_SOCKET_URL
let $auth = localStorage.getItem('_TPMIT_')

var socket
if($auth) {
    let $data = JSON.parse(localStorage.getItem("user_details"))
    let $currentUserName = $data.username
    let $uid = $data.id
    
   // let $user= JSON.parse(localStorage.getItem('_LOGGED_USER_'))
    socket = io(URL, { autoConnect: true ,
        'reconnection': true,
        'reconnectionDelay': 50,
        'reconnectionAttempts': 20
    });
   // const sessionId= uuid()
    let session =  { token:$auth,sessionId:$data.id,userId:$uid,userName:$currentUserName } ;
    socket.auth =  {session}
    console.log(session)
    localStorage.setItem("_SESSION_ID_", JSON.stringify(session))
    socket.connect()
    //socket.join($data.id)
    console.log(socket.connect(),'connectioncheck')
     console.log(socket,'getsocketconnectino')
    socket.onAny((event, ...args) => {
        console.log(event, args,'inside socketr543twg');
    });

    socket.on("connect_error", (err) => {
        if (err.message === "invalid username") {
            // this.usernameAlreadySelected = false;
        }
    });
}
export default socket;