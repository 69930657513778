<template>
  <div v-if="loading" class="skeletonBox">
    <Skeleton height="700px" width="275px" class="mb-5"></Skeleton>
  </div>
  <div v-else-if="issueData && issueData.length==0">
    <v-card flat class="elseParentCard">
      <v-row>
        <v-col class="mt-4 text-center">
          There is no work in any account.
        </v-col>
      </v-row>
    </v-card>
  </div>
  <div v-else>
    <v-card class="parentCard" v-if="issueData && issueData.length>0">
      <v-card-text>

      
      <v-row no-gutters dense>
        <v-col cols="12" v-for="issue in issueData" v-bind:key="issue">
          <v-row @click="$emit('openlistview',issue)" dense style="font-size:13px" class="py-2 issue-detail">
            <v-col cols="1" >
              <CustomAvatar2 :size="'small'" 
                :fName="issue.first_name" 
                :lName="issue.last_name" 
                :theme="issue.theme" 
                :imgUrl="issue.image_url ? issue.image_url: null"/>
            </v-col>
            <v-col cols="11" class="pl-3 issueName" >
              <div>
              {{ issue.first_name }} {{ issue.last_name }}
            <!--   <small :color="issue.stage=='login' ? 'green--text' : 'amber--text'">
              {{  issue.stage=='login' ? 'online' : 'offline' }} 
            </small> -->
            </div>
            <div>
              <span class="chip">
                {{ issue.total_tasks }}
              </span>
              &nbsp;
              <span class="chip">
                {{ issue.total_estimates ?  getHourMinutes(issue.total_estimates) : 0 }}
              </span>
              
            </div>
            </v-col>            
          </v-row>
          <hr
          class="hrline"
            style="border-style: dashed; border-color: #c6c6c6"
          />
        </v-col>
      </v-row>
    </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Skeleton from "primevue/skeleton";
import AccountService from "@/api/service/Account/AccountService";
import IssueService from "@/api/service/Issue/IssueService";
import UserService from "@/api/service/UserService";
//import moment from "moment";
//import lodash from 'lodash'
import CustomAvatar2 from '@/components/ui/CustomAvatar2.vue';
import  getHourMinutes  from '@/api/service/Common/Utils'


export default {
  components:{
    Skeleton,
    CustomAvatar2
  },
  data() {
    return {
      loading: true,
      data: [],
      accountData: [],
      issueData: [],
      wsDefaults: null,
      groupeData: [],
      userData: [],
      mainData: [],
      issueFilterData: [],
    };
  },
  async created() {
    let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
    this.wsDefaults = $wsDefaults ? JSON.parse($wsDefaults) : null;
    await this.getIssueOfOtherWorkspace();
   // await this.getAllusers();
  },
  methods:{
    getHourMinutes,
    async getAccounts() {
      let res = await AccountService.findAll();
      this.accountData = res;
      console.log(res, "response");
    },
    async getAllusers() {
      let res = await UserService.findAll();
      this.userData = res;
      console.log(this.userData, "kkkkkkkkkkkkkk");
    },
    isDateOverDue(date) {
      let currentDate = new Date();
      let dueDate = new Date(date);
      return currentDate > dueDate ? "text-red" : "text-orange";
    },
    async getIssueOfOtherWorkspace() {
      let $data = {"work_space_id":this.wsDefaults.workspace.id}
      let $issueData = await IssueService.getNextAvailableUsers($data)
      //$issueData = $issueData.data
      for(let $issue of $issueData) {
        $issue.progress_task = JSON.parse($issue.progress_task)
      }
      this.issueData = $issueData
      this.loading=false
    }
  }

}
</script>
<style scoped>
.skeletonBox {
  width: 275px;
  height: 100vh;
}

>>> .p-skeleton {
  background-color: #ececec;
  border-radius: 4px;
}

.elseParentCard {
  position: relative;
  /* top: 30px; */
  border-radius: 4px;
  width: 275px;
  height: 768px;
  overflow: hidden;
  overflow-y: auto;
  /* padding: 0px 20px 20px 20px; */
}

.parentCard {
  position: relative;
  /* top: 30px; */
  border-radius: 4px;
  width: 275px;
  min-height: 650px;
  max-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  /* padding: 0px 20px 20px 20px; */
}

.sub-header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #fff;
  padding: 0;
  height: 60px;
  align-items: end;
}

.avatarTitle {
  display: flex;
  gap: 5px;
}

.detail {
  min-height: 30px;

  margin: auto auto;
}

.heading {
  line-height: 0.8;
}

.issueName {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  color: #272727;
  font-weight: 500;
  -webkit-box-orient: vertical;
}

.inprogress {
  background-color: #e8fffc;
}

>>> .v-expansion-panel-title {
  align-items: center;
  text-align: start;
  border-radius: inherit;
  display: flex;
  font-size: 0.9375rem;
  line-height: 1;
  min-height: 48px;
  outline: none;
  padding: 2px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: rgb(219, 219, 219) !important;
}

>>> .v-expansion-panel-title--active > .v-expansion-panel-title__overlay,
.v-expansion-panel-title[aria-haspopup="menu"][aria-expanded="true"]
  > .v-expansion-panel-title__overlay {
  opacity: 0;
}

>>> .v-expansion-panel-title {
  align-items: center;
  text-align: start;
  border-radius: inherit;
  display: flex;
  font-size: 0.9375rem;
  line-height: 1;
  min-height: 35px;
  outline: none;
  padding: 2px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid;
}

>>> .v-expansion-panel-title:hover > .v-expansion-panel-title__overlay {
  opacity: 0;
}

.diffStages {
  overflow: auto;
}

>>> .diffStages .v-avatar.v-avatar--density-default {
  height: 28px;
  width: 32px;
  border-radius: 5px !important;
}

>>> .v-expansion-panel__shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0px 1px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
  border-radius: inherit;
  z-index: -1;
}

>>> .v-expansion-panel-text__wrapper {
  padding: 0px 2px;
  flex: 1 1 auto;
  max-width: 100%;
}

>>> ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

>>> ::-webkit-scrollbar:hover {
  width: 10px;
  height: 10px;
}

>>> ::-webkit-scrollbar-track {
  background: #e8f3f9;
}

>>> ::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
  border-radius: 8px;
}

.chip {
  text-transform: capitalize;
  border: 1px solid #F9C644;
  background: #FFE8AE;
  color: #000;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 4px;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
}

.issue-detail:hover{
  background-color:#f5f1cb;
  border-radius:8px;
}
.issue-detail:hover .hrline {
  display:none;
}
</style>
  