<template>
  <AvatarGroup>
    <Avatar v-for="(item, index) in avatarData" :key="index" :size="item.active ? 'small' : 'small'" shape="circle"
      @click="selectItem(item)" style="cursor:pointer;border: 0px;font-size:12px"
      :style="{ 'background-color': item.color_picker }" :class="item.select?'select':''">

      {{ item.code.slice(0, 2) }}
      <v-tooltip activator="parent" location="bottom">{{
        item.name
      }}</v-tooltip>
    </Avatar>
    <Avatar id="moreAvatars" style="border: 0;font-size:70%" :size="show ? 'large' : 'small'" shape="circle" v-if="moreItems.length > 0">
      +{{ moreItems.length }}
      <v-tooltip activator="parent" location="bottom">+ {{
        moreItems.length
      }}</v-tooltip>
    </Avatar>
  </AvatarGroup>
  <v-menu activator="#moreAvatars">
    <v-list>
      <v-list-item style="cursor:pointer" v-for="(item, index) in moreItems" :key="index">
        <v-list-item-title class="work-space-menu" @click="selectItem(item)">{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";

export default {
  props: ["avatarData", "moreItems"],
  components: {
    Avatar,
    AvatarGroup
  },
  data() {
    return {
      avatarName: ""
    }
  },
  async created() {
    this.getCurrentWS()
  },
  
  watch: {
getRefreshWs(){
  if(this.getCurrentWS){
    this.getCurrentWS()
  }else{
    this.getCurrentWS()
  }

},

getRefresh() {
  let workSpaceId =localStorage.getItem("workSpaceId")
  let wsData =this.avatarData.filter(k=>k.id==workSpaceId)
  if(wsData && wsData.length>0){
  this.selectItem(wsData[0])
  }

}
},
computed: {
getRefreshWs(){
  return this.$store.getters.getMyspace;
},
getRefresh() {
  return this.$store.getters.getRefreshWorkSpace;
},
},
  methods: {
    getCurrentWS() {
      this.currentWsId = localStorage.getItem('workSpaceId')
      if(this.currentWsId && this.avatarData.length>0){
        this.avatarData.forEach(k=> { 
          if(k.id===parseInt(this.currentWsId)){
            k.select=true
           }
           else{
            k.select=false
           }
          })
      }
    },
    selectItem($item) {
      this.$emit('selected', $item)
      this.getCurrentWS()

    },
    menuShow() { },
    getRandomColor(index) {
      const availableColors = ["#C7D4E7", "#E7CED1", "#FDEFCA", "#F5D4DF"];
      if (index >= 0 && index < availableColors.length) {
        return availableColors[index];
      }
      return "#000000";
    },
  }
}
</script>
<style scoped>
.p-avatar.p-avatar-circle:hover {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  z-index: 9999;
}

.p-avatar.p-avatar-circle:active {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  z-index: 9999;
}

>>>.p-avatar-group .p-avatar:hover {
  /* border: 2px solid #ffffff; */
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  z-index: 9999;
}
.select{
  width:3rem;
  height:3rem
}
</style>