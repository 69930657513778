<template>
  <v-card  width="100% " style="border-radius:12px; overflow :hidden;">
    <v-card-item>
      <v-card-title
        >{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
        <b>MenuGroup</b>
      <v-btn
        @click="close()"
        elevation="0"
        density="comfortable"
        icon="mdi-close"
        class="x-closeIcon"
      ></v-btn>
      </v-card-title>
    </v-card-item>

     
      <v-card-text>
      <v-form @submit.prevent="save" ref="form">
         <v-row no-gutters class="mt-3">
<v-col cols="12">
          <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Activity Group
          <span style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
        <AutoComplete
          :items="activityGroupData"
          :placeholder="'ActivityGroup'"
          :item-title="'name'"
          :disabled="showReadOnly"
          :item-value="'id'"
          v-model="formData.activity_group_id"
          :rules="ActivityGroupRule"
        />
</v-col>

<v-col cols="12">
          <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Display Name in Menu
          <span style="color:#CF0707;padding-left:2px;font-weight:700"></span></v-label>
        <TextFieldComponent
          density="compact"
          placeholder="Display Name in Menu"
          :rules="DisplayNameRule"
          v-model="formData.menu_group"
          :disabled="showReadOnly"
        />
</v-col>

<v-col cols="12">
          <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Menu Key
          <span style="color:#CF0707;padding-left:2px;font-weight:700"></span></v-label>
        <TextFieldComponent
          density="compact"
          placeholder="Menu Key"
          :rules="MenuKeyRule"
          v-model="formData.menu_key"
          :disabled="showReadOnly"
        />
</v-col>



        <v-col cols="12">
          <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Activity
          <span style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
        <AutoComplete
          :items="activityData"
          :placeholder="'Activity'"
          :item-title="'name'"
          :disabled="showReadOnly"
          :item-value="'id'"
          v-model="formData.activity_id"
          :rules="ActivityRule"
        />
        </v-col>


<v-col cols="12">
          <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Menu Order
          <span style="color:#CF0707;padding-left:2px;font-weight:700"></span></v-label>
        <TextFieldComponent
          density="compact"
          placeholder="MenuOrder"
          :rules="MenuOrderRule"
          v-model="formData.menu_order"
          :disabled="showReadOnly"
        />
</v-col>


<v-col cols="12">
          <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Route
          <span style="color:#CF0707;padding-left:2px;font-weight:700"></span></v-label>
        <TextFieldComponent
          density="compact"
          placeholder="Route"
          :rules="RouteRule"
          v-model="formData.route"
          :disabled="showReadOnly"
        />
</v-col>

        <div><h4>Actions</h4></div>
        <V-row>
          <v-col> <v-checkbox label="Create"></v-checkbox></v-col>
          <v-col> <v-checkbox label="Update"></v-checkbox></v-col>
          <v-col> <v-checkbox label="View"></v-checkbox></v-col>
          <v-col> <v-checkbox label="Delete"></v-checkbox></v-col>
          <v-col> <v-checkbox label="Cancel"></v-checkbox></v-col>
        </V-row>

         </v-row>
    </v-form>
    </v-card-text>
<v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
        </v-card-actions>
  </v-card>
</template>
  <script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import MenuGroupService from "@/api/service/MenuGroup/MenuGroupService";
import ActivityGroupService from "../api/service/ActivityGroup/ActivityGroupService.js";
import ActivityService from "../api/service/Activity/ActivityService.js";
export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
  },

  data() {
    return {
      showReadOnly: false,
      dialog: false,
      type: null,
      activityGroupData: [],
      activityData: [],
      formData: {
        id: null,
        activity_group_id: null,
        activity_id: null,
        menu_group: null,
        menu_order: null,
        route: null,
        actions: null,
        menu_key: null,
        activity_list: null,
      },
      ActivityGroupRule: [(value) => !!value || "ActivityGroup is Required."],
      ActivityRule: [(value) => !!value || "Activity is Required."],
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
      }
    },
  },
  created() {
    this.getActivityGroup();
    this.getActivity();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      console.log(id, "iddata");
      let res = await MenuGroupService.findOne(id);
      this.formData = res[0];
      console.log(this.formData, "formDataaaa");
    },

    async getActivityGroup() {
      let res = await ActivityGroupService.findAll();
      this.activityGroupData = res.length > 0 ? res : [];
      console.log(this.activityGroupData, " actgrouppppp");
    },

    async getActivity() {
      let res = await ActivityService.findAll();
      this.activityData = res;
      console.log(this.activityData, "actttttt");
    },

    async save() {
      if (this.formData.activity_group_id && this.formData.activity_id) {
        if (this.formData.id) {
          await MenuGroupService.update(this.formData);
          console.log(this.formData, "datttttt111");
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          await MenuGroupService.create(this.formData);
          console.log(this.formData, "datttttt");
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        }
      }
    },
  },
};
</script>
  