<template>
    <PrimeDataTable
      :title="'RolePermission'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="true"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="RolePermission"
     
    />
  </template>
  
  <script>
  import PrimeDataTable from "@/components/PrimeDataTable";
  import RolePermissionService from "@/api/service/RolePermission/RolePermissionService";
  // import commonService from "@/common.service";
  export default {
    components: {
      PrimeDataTable,
    },
    data() {
      return {
        data: [],
        columns: [],
        date: new Date(),
        editId:null,
        dialog: false,
        viewType:false
      };
    },
    created() {
      this.loadColumns();
      this.download = true;
      this.search = true;
      this.checkbox = true;
      this.getRolePermission();
    },
    methods: {
  
      loadColumns() {
        this.columns = [
          {
            field: "id",
            header: "Id",
            style: { width: "100px", color: "" },
            toolTip: "id",
            sortfield: true,
          },
          {
            field: "role_id",
            header: "Role Id",
            style: { width: "100px", color: "" },
            toolTip: "role_id",
            sortfield: true,
          },
          {
            field: "activity_id",
            header: "Activity Id",
            style: { width: "100px", color: "" },
            toolTip: "activity_id",
            sortfield: true,
          },
          {
            field: "action_id",
            header: "Action Id",
            style: { width: "100px", color: "" },
            toolTip: "action_id",
            sortfield: true,
          },
          {
            field: "status",
            header: "Status",
            style: { width: "100px", color: "" },
            toolTip: "status",
            sortfield: true,
          },
          {
            field: "rules",
            header: "Rules",
            style: { width: "100px", color: "" },
            toolTip: "rules",
            sortfield: true,
          },
          {
            field: "menu_group_id",
            header: "Menu Group Id",
            style: { width: "100px", color: "" },
            toolTip: "menu_group_id",
            sortfield: true,
          },
        ];
      },
  
      close() {
        this.dialog = false;
      },
      rowActions($event) {
        if ($event.type == "view") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }
        if ($event.type == "edit") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }   
  
        if ($event.type == "select") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType="view";
        }
      },
      async getRolePermission() {
        let res = await RolePermissionService.findAll();
        this.data = res;
      },
      addDiolog() {
        this.$router.push('/setting/identity/CreateRolePermission');
      }
    },
  };
  </script>
  
  
  