import GatewayService from "../../service/GatewayService.js";
import apiConfig from "@/api/config/config.js";
var $options = {};

var ResetPasswordService = {
    resetPassword: function ($data) {
        return new Promise(function (resolve, reject) {
            $options.path = 'resetPassword'
            $options.baseUrl = apiConfig.gatWayUrl
            $options.method = "POST"
            $options.data = $data
            GatewayService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
        })
    }
};

export default ResetPasswordService;
