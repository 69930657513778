<template>
  <v-card Width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b>Skill</b>
          <v-btn @click="setFalse()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <!-- <v-row no-gutters class="mt-3"> -->
        <v-row v-if="showDuplicateError" style="padding-top: 5%;padding-left: 3%;">
          <v-error>Same type of skill already exist</v-error>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="pa-2">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Project
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="projectData" item-title="name" v-model="formData.project_id" item-value="id"
              :disabled="showReadOnly" :rules="projectRule" @update:modelValue="getAleadyExits" :placeholder="'Project'" />
          </v-col>

          <v-col cols="6" class="pa-2">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Account
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="accountData" item-title="name" v-model="formData.account_id" item-value="id"
              :disabled="showReadOnly" :rules="accountRule" @update:modelValue="getAleadyExits" :placeholder="'Account'" />
          </v-col>

          <v-col cols="6" class="pa-2">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Category
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="categoryData" item-title="name" v-model="formData.category_id" @update:modelValue="getAleadyExits" item-value="id"
              :disabled="showReadOnly" :rules="CategoryRule" :placeholder="'Category'" />
          </v-col>


          <v-col cols="6" class="pa-2">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Experience
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Experience" v-model="formData.experience"
              :rules="experienceRule" :disabled="showReadOnly"></TextFieldComponent>
          </v-col>
          <v-col cols="6" class="pa-2">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Skill
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="riskData" item-title="name" v-model="formData.risk_id" item-value="id"
              :disabled="showReadOnly" :rules="skillRule" :placeholder="'Skill'" />
          </v-col>
          <!-- <v-col cols="6" class="pa-2">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Color
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <br>
            <input class=" colorPick" type="color" v-model="formData.theme" >
            </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="setFalse()">Cancel</v-btn>
          <v-btn class="save" @click="save()">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import AccountService from "@/api/service/Account/AccountService";
import ProjectService from "@/api/service/Project/ProjectService";
import RiskService from "@/api/service/Risk/RiskService";
import CategoryService from "@/api/service/Category/CategoryService";
import UserSkillService from "@/api/service/UserSkill/UserSkillService.js"
import commonService from "@/common.service";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
  },

  data() {
    return {
      formData: {
        project_id: null,
        account_id: null,
        category_id: null,
        risk_id: null,
      },
      showDuplicateError:false,
      projectData: [],
      accountData: [],
      riskData: [],
      categoryData: [],
      showReadOnly: false,
      projectRule: [(value) => !!value || "Project is Required."],
      skillRule: [(value) => !!value || "Skill is Required."],
      accountRule: [(value) => !!value || "Account is Required."],
      CategoryRule: [(value) => !!value || "Category is Required."],
      experienceRule: [(value) => !!value || "Experience is Required."],
      userDetails: JSON.parse(localStorage.getItem("user_details")),
      type: "",
      dialog: false,
      add: false,
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {

    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }

    this.getProjectData();
    this.getAccountdata();
    this.getRiskData();
    this.getCategoryData();
  },

  methods: {
    async getById(id) {
      let res = await UserSkillService.findOne({ id: id });
      this.formData = res[0];
    },

    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    async getAleadyExits(){
      if(this.formData.project_id  && this.formData.account_id && this.formData.category_id){
        try{
           let res =await UserSkillService.findAll({
            project_id:this.formData.project_id,
            account_id:this.formData.account_id,
            category_id:this.formData.category_id,
            user_id:this.userDetails.id
           })
           this.showDuplicateError =res && res.length>0?true:false
        }catch(e){
          console.log(e)
        }
      }
    },
    setFalse() {
      this.$emit("closeDialog");
    },
    async getProjectData() {
      let res = await ProjectService.findAll();
      this.projectData = res.length > 0 ? await commonService.getSort(res, 'name', 'asc') : [];
    },
    async getAccountdata() {
      let res = await AccountService.findAll();
      this.accountData = res.length > 0 ? await commonService.getSort(res, 'name', 'asc') : [];
    },
    async getRiskData() {
      let res = await RiskService.findAll();
      this.riskData = res.length > 0 ? await commonService.getSort(res, 'name', 'asc') : [];
    },
    async getCategoryData() {
      let res = await CategoryService.findAll();
      this.categoryData = res.length > 0 ? await commonService.getSort(res, 'name', 'asc') : [];
    },
    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.showDuplicateError) {
        console.log(validation.valid)
      }
      else {
        if (this.formData.id) {
          let output = await UserSkillService.update(this.formData);
          console.log(output);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
        } else {
          try {
            this.formData.user_id = this.userDetails.id
            let output = await UserSkillService.create(this.formData);
            console.log(output);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.dialog = false;
            this.setFalse();
            this.$emit("reload", true);
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    }
  },

};
</script>
<style>
.switch {
  height: 80px;
  padding-left: 4px;
}
</style>
  
  