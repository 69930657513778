<template>
  <PrimeDataTable :title="'Holiday'" :showFilter="true" :searchOption="true" :selectAll="true" :showDownload="true"
    :showUpload="true" :showAdd="true" :columnData="columns" :data="data" @addDiolog="addDiolog()"
    @rowAction="rowActions($event)" activity="holiday"/>

  <v-dialog persistent v-model="dialog" width="500px">
    <CreateHoliday @closeDialog="close()" @reload="getHoliday()" :id="editId" :viewType="viewType" />
  </v-dialog>
</template>
  
<script>
import CreateHoliday from "./CreateHoliday.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import HolidayService from "@/api/service/Holiday/HolidayService";
// import commonService from "@/common.service";
export default {
  components: {
    CreateHoliday,
    PrimeDataTable,
  },
  data() {
    return {
      data: [],
      columns: [],
      // date: new Date(),
      editId: null,
      dialog: false,
      viewType: false
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getHoliday();
  },
  methods: {

    loadColumns() {
      this.columns = [
        // {
        //   field: "id",
        //   header: "Id",
        //   style: { width: "100px", color: "" },
        //   toolTip: "id",
        //   sortfield: true,
        // },
        {
          field: "date",
          header: "Date",
          style: { width: "100px", color: "" },
          toolTip: "date",
          type:'date',
          sortfield: true,
       
        },
        {
          field: "holiday",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "holiday_description",
          sortfield: true,
        },
        {
          field: "mandatory",
          header: "Mandatory",
          type:'boolean',
          style: { width: "100px", color: "" },
          toolTip: "mandatory",
          sortfield: true,
        },

        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "modified",
          sortfield: true,
        },
        {
          field: "active",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "active",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
            // { action: "delete" },
          ],
        },
      ];
    },

    close() {
      this.dialog = false;
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = "view";
      }
    },
    async getHoliday() {
      let res = await HolidayService.findAll();
      this.data = res;
    },
    addDiolog() {
      this.dialog = true;
      this.editId = null;
      this.viewType = false
    }
  },
};
</script>