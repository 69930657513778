<template>
    <PrimeDataTable
      :title="'WorkSpace Settings '"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="true"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="workspace"
     
    />
  
    <v-dialog persistent v-model="dialog" width="700px">
      <CreateWsSetting
        @closeDialog="close()"
        @reload="getWsSetting()"
        :id="editId"
        :viewType="viewType"
        
      />
    </v-dialog>
  </template>
  
  <script>
  import CreateWsSetting from "./CreateWsSetting.vue";
  import PrimeDataTable from "@/components/PrimeDataTable";
  import WsSettingService from "@/api/service/WsSetting/WsSettingService";
  // import commonService from "@/common.service";
  export default {
    components: {
      CreateWsSetting,
      PrimeDataTable,
    },
    data() {
      return {
        data: [],
        columns: [],
        date: new Date(),
        editId:null,
        dialog: false,
        viewType:false
      };
    },
    created() {
      this.loadColumns();
      this.download = true;
      this.search = true;
      this.checkbox = true;
      this.getWsSetting();
    },
    methods: {
  
      loadColumns() {
        this.columns = [
          // {
          //   field: "id",
          //   header: "Id",
          //   style: { width: "100px", color: "" },
          //   toolTip: "id",
          //   sortfield: true,
          // },
          {
            field: "work_spaces_name",
            header: "Work Space",
            style: { width: "100px", color: "" },
            toolTip: "work_space_id",
            sortfield: true,
          },

           {
          field: "project_name",
          header: "Project",
          style: { width: "100px", color: "" },
          toolTip: "Associate Managet name",
          sortfield: true,
        },
         
          {
            field: "accounts_name",
            header: "Account",
            style: { width: "100px", color: "" },
            toolTip: "account_id",
            sortfield: true,
          },
          {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "modified",
          sortfield: true,
        },
        {
          field: "active",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "active",
          sortfield: true,
        },
          {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
            // { action: "delete" },
            // { action: "pi-users","event":"show-members",route:"/addMembers" },
          ],
        },
        ];
      },
  
      close() {
        this.dialog = false;
      },
      rowActions($event) {
        if ($event.type == "view") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }
        if ($event.type == "edit") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }   
  
        if ($event.type == "select") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType="view";
        }
      },
      async getWsSetting() {
        let res = await WsSettingService.wsSettings();
        this.data = res;
      },
      addDiolog() {
        this.dialog = true;
        this.editId = null;
        this.viewType = false
      }
    },
  };
  </script>
  
  
  