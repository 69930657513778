<template>
  <div>
    <v-table class="table" fixed-header height="77vh" >
      <thead>
        <tr>
          <th v-for="(value, key) in issueType" :key="key" class="pb-0 tr tableheader"
            style="border-bottom: 1px solid #dbdbdb !important;">
            <v-title class=" TitleTxt pl-4">{{ key }}</v-title>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="(items, key) in issueType" :key="key"
            style="box-shadow: none !important; padding: 0px 5px !important;vertical-align: baseline;background:#f1f1f1;bottom: 24px;position: relative;border-radius: 8px 8px 0px 0px;">
            <v-card @click="atClick(item)" class="pa-1 cards pt-0 onHover mt-2" elevation="-0.5" v-for="(item, index) in items" :key="index">
              <v-card-text class="cardsTxt">{{ item.name }}</v-card-text>

              <v-card-title class="pa-0 pl-3">
                <v-chip class="onText" label v-on="on">
                  {{ item.name.length < 25 ? item.name : item.name.substring(0, 22) + "..." }}
                </v-chip>
                    <v-tooltip class="my-tooltip" activator="parent" location="bottom">
                      {{ item.name }}
                    </v-tooltip>
              </v-card-title>

              <v-card-actions class="mt-2 pb-0">
                <div class="actions">
                  <v-icon size="12" color="white">mdi-account</v-icon>
                </div>
                <v-card-title style="color: #bdbdbd; font-size: medium; " class="pl-2">SZR-5</v-card-title>

                <v-spacer></v-spacer>

                <v-avatar style="background-color: #1258ab; color: white" size="30">
                  <span class="white--text font-bold">AS</span>
                </v-avatar>
              </v-card-actions>
            </v-card>
          </td>
        </tr>
      </tbody>
    </v-table>

  </div>
</template>
  
<script>
import IssueService from '@/api/service/Issue/IssueService';
export default {
  data() {
    return {
      issueType: {},
    };
  },
  props: [],
  mounted() {
    this.getIssueType()
    // this.getSearchTheData()  
  },
  methods: {

    atClick(item){
    let selectedId = item.id;
    this.$router.push({ path: "/issueView", query: { id: selectedId } });
    console.log("xxxxxxxs", selectedId);
},

    async getSearchTheData(data) {
      console.log(data, "ppppp")
      if (data.length > 0) {
        let searchData = new RegExp(data.toLowerCase(), 'gi');
        let $key = Object.keys(this.issueType)
        for (let i = 0; i < $key.length; i++) {
          let keyData = this.backupTheIssueData[$key[i]]
          let filterData = await keyData.filter(ele => {
            console.log(ele.name.toLowerCase().search(searchData))
            return ele.name.toLowerCase().search(searchData) > -1

          })
          this.issueType[$key[i]] = filterData
          this.issueType[$key[i]] = [...this.issueType[$key[i]]]

        }
      }
      else {
        this.issueType = this.backupTheIssueData
        this.issueType = {...this.issueType}
      }
    },
    async getIssueType() {
      var res = await IssueService.findIs();
      this.issueType = res;
      this.backupTheIssueData = JSON.parse(JSON.stringify(this.issueType))
      console.log(res, "testinggggggggg");
    },
  },

  computed: {
  },

}


</script>
  
<style  scoped>
v-table {
  border-spacing: 0 0.5rem;
}

>>>.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #e8f3f9;
  height: 60px;
  width: 280px
}

.TitleTxt {
  font-size: medium;
  text-align: start
}

.cards {
  width: 280px;
  padding-top: 5px;
}

.cardsTxt {
  font-size: medium;
  text-align: start
}

.onText {
  color: #311b92;
  font-size: x-small;
  font-weight: 750;
  text-align: start;
}

.table {
  background: inherit;
  max-width: fit-content;
}

>>>.tableheader {
  box-shadow: none !important;
  background: #f1f1f1 !important;
  padding: 0px 5px !important;
  min-width:280px; 
  border-radius: 8px 8px 0px 0px;
}

.actions {
  display: inline-block;
  width: 16px;
  height: 15px;
  background-color: #ffeb3b;
  border-radius: 3px;
}

.onHover:hover {
  background-color: #e8f3f9;
}

/* width */
/* ::-webkit-scrollbar {
  width:0px;
  background: transparent;

} */
::-webkit-scrollbar {
  width: 0px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(18, 88, 171);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(18, 88, 171);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e8f3f9;
}

>>>.v-table>.v-table__wrapper>table {
  width: 100%;
  border-spacing: 15px !important;
}
</style>