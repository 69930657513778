<template>
    <v-autocomplete density="compact"  
  variant="outlined" :items="timeZoneData" v-model="selectData" @update:modelValue="changeTimeZone" :placeholder="'Time Zone'" item-value="value" item-title="name" ></v-autocomplete>
</template>
<script>
import TimeZoneList from '../../api/service/timeZoneList.json'
export default{
    name:"TimeZoneList",
    props:["updateData"],
    data() {
        return{
           selectData:null,
           timeZoneData:TimeZoneList,
        }

    },
    
    watch:{
        updateData(){
            if(this.updateData){
                this.selectData = this.updateData
            }
        }
    },
    created(){
        console.log(this.timeZoneData,'checkkkk3456')
    },
    methods:{
        changeTimeZone(){
            this.$emit('timeZoneChange',this.selectData)
        }
    }
}

</script>

<style scoped>
>>>.v-field__outline {
     --v-field-border-width: 0px !important;
    border: 10px !important;
    --v-field-border-opacity: 0.38;
    align-items: stretch;
    contain: layout;
    display: flex; 
    /* background-color:#ECECEC; */
    /* height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    border: 0px solid #D7D6D6 !important;
    border-radius: 8px !important; */
}


>>>.v-field {
    cursor: text;
    background: #ECECEC !important;
    font-size: 14px;
    color: #777777;
    border-radius: 8px;
}

>>>.v-field:focus-within {
    cursor: text;
    background-color: #ffffff !important;
    border: 2px solid #3C5AAA !important;
    border-radius: 8px;
}

>>>.v-field--disabled {
    pointer-events: none;
    opacity: 0.8 !important;
}
</style>