<template>
    <v-card>
        <v-card-title>
            Permission Copy
            <v-btn flat size="x-small" @click="$emit('closeDialog')" class="float-right" icon="mdi mdi-close">
                
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-4">
            <p class="py-2 text-body-1 font-weight-black">
                Your are copying the permission from <b> {{ selectedRole.name }}</b>
            </p>
            <v-label class="py-2">
                Select the role you want to copy to
            </v-label>
            <AutoComplete 
                :items="rolesList"
                multiple
                    v-model="roleId" placeholder="Select Roles"
                density="compact" variant="outlined" name="name" value="id" 
                required=true
                @update:modelValue="roleChanged" />
                <p class="text-caption text-red text-center">
                    Please note by doing this you will overwrite the permission settings. 
                    Do it with caution.
                </p>
        </v-card-text>
        <v-card-actions>
            <v-btn variant="flat" color="primary" block  @click="applyChanges">Apply</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import RolePermissionService from '@/api/service/RolePermission/RolePermissionService'
import { AutoComplete} from '@tiburadev/vue-comp'
export default {
    props:["roles","fromRoleId"],
    components:{
            AutoComplete
        },
    data() {
        return {
            rolesList:[],
            selectedRole:{},
            roleId:null
        }
    },
    created() {
        this.syncRoles()
    },
    methods:{
        syncRoles() {
            for(let role of this.roles) {
                if(role.id!=this.fromRoleId) {
                    this.rolesList.push(role)
                } else {
                    this.selectedRole = role
                }
            }
        },
        roleChanged() {
            
        },
        async applyChanges() {
            await RolePermissionService.copyPermission({"fromRoleId":this.fromRoleId,"toRoleId":this.roleId})
            this.$store.dispatch("setSnackbar", {
              color: "success",
              text: "Copied...",
          });
          this.$emit('closeDialog')


        }
    }
}
</script>