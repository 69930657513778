<template>
  <PrimeDataTable
    :title="'Activity'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="Activity"
  />

  <v-dialog persistent v-model="dialog" width="700px">
    <CreateActivity
      @closeDialog="close()"
      @reload="getActivity()"
      :id="editId"
      :viewType="viewType"
    />
  </v-dialog>
</template>
  
  <script>
import CreateActivity from "./CreateActivity.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import ActivityService from "@/api/service/Activity/ActivityService";
// import commonService from "@/common.service";
export default {
  components: {
    CreateActivity,
    PrimeDataTable,
  },
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      editId: null,
      dialog: false,
      viewType: false,
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getActivity();
  },
  methods: {
    loadColumns() {
      this.columns = [
        // {
        //   field: "id",
        //   header: "Id",
        //   style: { width: "100px", color: "" },
        //   toolTip: "id",
        //   sortfield: true,
        // },
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "name",
          sortfield: true,
        },
        // {
        //   field: "activity_group_id",
        //   header: "Activity Group Id",
        //   style: { width: "100px", color: "" },
        //   toolTip: "activity_group_id",
        //   sortfield: true,
        // },
        // {
        //   field: "lob",
        //   header: "Lob",
        //   style: { width: "100px", color: "" },
        //   toolTip: "lob",
        //   sortfield: true,
        // },
        // {
        //   field: "activity_order",
        //   header: "Activity Order",
        //   style: { width: "100px", color: "" },
        //   toolTip: "activity_order",
        //   sortfield: true,
        // },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            // { action: "edit" },
          ],
        },
      ];
    },

    close() {
      this.dialog = false;
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
      }
      // if ($event.type == "edit") {
      //   this.dialog = true;
      //   this.editId = $event.data.id;
      //   this.viewType = $event.type;
      // }

      if ($event.type == "select") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = "view";
      }
    },
    async getActivity() {
      let res = await ActivityService.findAll();
      this.data = res;
    },
    addDiolog() {
      this.dialog = true;
      this.editId = null;
      this.viewType = false;
    },
  },
};
</script>
  
  
  