
<template>
  <div class="pt-7 pl-4">
 <v-row class="mb-2" >
   
       <v-card-title style="font-size: 25px;"><b>Accounts</b></v-card-title>
   
 </v-row>
 <v-row class="mb-2">
   <v-col class="contentL" cols="3">
     <TextFieldComponent
       append-inner-icon="mdi-magnify"
       v-model="searchQuery"
       placeholder="Search"
       @input="searchTheData()"
     />
   </v-col>
  

   <v-col cols="3" >
       <AutoComplete
         :items="['Billable', 'Non Billable']"
         placeholder="All Accounts"
       />
   </v-col>
 </v-row>
 <DataTable paginator showGridlines :rows="6" class="databale" :value="accounts" dataKey="id" tableStyle=" background: inherit;">
     <Column field="name" header="Name"></Column>
     <Column field="code" header="Code"></Column>
     <!-- <Column field="address" header="Address"></Column> -->
     <Column field="country_id" header="Country Name"></Column>
   </DataTable>

 </div>
</template>


<script>
import DataTable from "primevue/datatable";
import AccountService from "@/api/service/Account/AccountService";
import Column from "primevue/column";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import ColumnGroup from 'primevue/columngroup';   // optional
// import Row from 'primevue/row';
export default {
 components: {
   DataTable,
   Column,
   AutoComplete,
   TextFieldComponent,
   // ColumnGroup,
   // Row,
 },
 data() {
   return {
     // columns: [],
     accounts:[],

   };
 },

 created() {
   this.getAccountData() 
   // this.loadColumns();
 },
 methods: {
  searchTheData() {
    const searchQuery = this.searchQuery.toLowerCase().trim();

    if (searchQuery === '') {
      this.getAccountData();
    } else {
      this.accounts = this.accounts.filter(account => {
        const name = account.name ? account.name.toLowerCase() : '';
        const code = account.code ? account.code.toLowerCase() : '';
        const country_id = account.category ? account.category.toLowerCase() : '';
        // const stage = account.stage ? account.stage.toLowerCase() : '';

        return (
          name.includes(searchQuery) ||
          code.includes(searchQuery) ||
          country_id.includes(searchQuery) 
          // stage.includes(searchQuery)
        );
      });
    }}
    ,
  async getAccountData() {
        let workSpaceId = localStorage.getItem('workSpaceId')
        let res = await AccountService.getAccountByIssue({ work_space_id: workSpaceId });
        this.accounts = res;
      },

//    atClick(item){
//    let selectedId = item.id;
//    this.$router.push({ path: "/projectList", query: { id: selectedId } });
//    console.log("xxxxxxxs", selectedId);
// },
  
 },
};
</script>

<style scoped>
>>>.p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    border: 0px solid #dee2e6 !important;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    padding-top: 20px;
    padding-bottom: 7px;
    font-size: 14px;
    cursor: pointer;
}
>>>.p-datatable .p-datatable-tbody>tr {
  background: none !important;
}
>>>.p-datatable .p-datatable-thead>tr>th {
    background: none !important;
    width: 15% !important;
    font-weight: 800;
    border: 0px;
    border-bottom: 2px solid #ECECEC ;
}
>>>.p-paginator {
    background:none;
    color: #6c757d;
    border: solid #e9ecef;
    border-width: 0;
    /* padding: 0.5rem 1rem;  */
    border-radius: 6px;
    width: 100% !important;
    position: absolute;
    right:0;
    /* left: 50%; */
}
/*   
    width: 15%;
    border-bottom : 1px solid inherit; */
</style>