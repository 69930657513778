<template>
  <v-dialog v-model="showDialog" persistent width="30%">
    <v-card justify="center" style="border-radius: 12px;height: 200px; overflow: hidden" class="mainDialog">

      <v-row class="ml-3 mr-3 mt-3">
        <v-col class="text-h6 pa-0">Confirmation</v-col>
        <v-col align="end" class="pa-0"><v-btn @click="closeDialog()" elevation="0" density="comfortable"
            icon="mdi-close"></v-btn></v-col>
      </v-row>
      <!-- <v-card-title> Confirmation </v-card-title> -->
      <v-card-content class="pa-4 text-center">
        You are logging out before the Shift Closing time. Do you want to keep your session active ?
      </v-card-content>
      <v-card-actions class="pl-3 cardAction mt-2">
        <v-row class="dialogBottom" justify="center">
          <v-btn class="save" style="width: auto;" elevation="" @click="setStore">Keep Active</v-btn>
          <v-btn style="width: auto;" @click="setLogout()" class="save">Logout</v-btn>
        </v-row>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
<script>
import UserService from "@/api/service/UserService";
import moment from "moment";
export default {
  data() {
    return {
      showDialog: true
    }
  },
  methods: {
    moment,
    setStore() {
      this.$store.commit("SET_NORMAL", false);
      this.$router.push('/logout');
    },
    async setLogout() {
    /*  let $val = JSON.parse(localStorage.getItem("USER_SESSIONS"));
      let $obj = {};
      if ($val) {
        $obj.id = $val.id;
        $obj.end_date = moment().format("YYYY-MM-DD HH:mm:ss");
       // await UserService.updateSessions($obj);*/
       this.$store.commit('SET_LOGOUT_ACTION',false)
       await UserService.clearSessions({"sessionId":localStorage.getItem('sessionId')})
      //this.$store.commit("SET_NORMAL", false);
      //localStorage.removeItem("USER_SESSIONS");
      this.$router.push('/logout')
    },
    closeDialog() {
       this.$store.commit('SET_LOGOUT_ACTION',false)

      this.$store.commit("SET_NORMAL", false);
    }
  }
}
</script>
<style></style>