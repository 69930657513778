<template>
  <v-card width="100%" height="100%" style="overflow: auto" flat>
    <v-form @submit.prevent="save" ref="form" v-model="value">
      <v-card-item>
        <v-card-title>
          {{ viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add" }}
          <b> Report Subscription</b>
          <v-btn @click="close()" elevation="0" density="comfortable" class="x-closeIcon">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn class="newSave" type="submit" elevation="0">Save</v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text class="pl-7 pt-7 pr-7">
        <v-row no-gutters>
          <v-col cols="2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Choose Report
              <!-- <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <AutoComplete
              :items="riskData"
              item-title="name"
              v-model="formData.report_id"
              item-value="id"
              :disabled="showReadOnly"
              :placeholder="'Choose Report'"
            />
          </v-col>

          <v-col cols="2" class="mx-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Report Name
              <!-- <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Report Name"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="2" class="mr-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >List Model Filter
              <!-- <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <AutoComplete
              :items="riskData"
              item-title="name"
              v-model="formData.filter_options"
              item-value="id"
              :disabled="showReadOnly"
              :placeholder="'List Model Filter'"
            />
          </v-col>

          <v-col cols="2" class="mr-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Export As
              <!-- <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <AutoComplete
              :items="riskData"
              item-title="name"
              v-model="formData.export_options"
              item-value="id"
              :disabled="showReadOnly"
              :placeholder="'Export As'"
            />
          </v-col>

          <v-col cols="1">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Schedule My Report
            </v-label>
            <v-switch
              class="ml-3"
              color="primary"
              v-model="formData.schedule_my_report"
              :disabled="showReadOnly"
              @click="formData.schedule_my_report = !formData.schedule_my_report"
              :label="formData.schedule_my_report ? 'Yes' : 'No'"
              :model-value="formData.schedule_my_report ? true : false"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Email
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Email"
              :rules="emailRule"
              v-model="formData.email_to"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Cc Email
              <!-- <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Cc Email"
              v-model="formData.cc_email"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>

        <v-row no-gutters v-if="formData.schedule_my_report">
          <v-col cols="2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Schedule Date
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              >
            </v-label>
            <TextFieldComponent
              density="compact"
              :rules="dateRule"
              type="date"
              v-model="formData.schedule_day"
              :placeholder="'Schedule Date'"
              :disabled="showReadOnly"
            />
            <v-error v-if="dateError">Date Is Required</v-error>
          </v-col>

          <v-col cols="2" class="mx-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Schedule Time
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="HH:MM"
              :rules="timeRule"
              type="time"
              v-model="formData.schedule_at"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="2" class="mr-2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Interval
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :items="intervalData"
              item-title="name"
              v-model="formData.interval"
              item-value="id"
              :disabled="showReadOnly"
              :rules="intervalRule"
              :placeholder="'Interval'"
            />
          </v-col>

          <v-col cols="2">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Frequency
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Frequency"
              :rules="frequencyRule"
              v-model="formData.frequency"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import JobService from "@/api/service/Jobs/JobsService";
import moment from "moment";
export default {
  components: {
    AutoComplete,
    TextFieldComponent,
  },

  data() {
    return {
      dateError: false,
      showReadOnly: false,
      intervalData: [
        { id: 1, name: "Week" },
        { id: 1, name: "Month" },
        { id: 1, name: "PerDay" },
        { id: 1, name: "PerHour" },
        { id: 1, name: "Minutes" },
      ],
      nameRule: [(value) => !!value || "Name is Required."],
      // ccRule: [(value) => !!value || "CC is Required."],
      reportRule: [(value) => !!value || "Report Name is Required."],
      emailRule: [(value) => !!value || "Email is Required."],
      frequencyRule: [(value) => !!value || "Frequency is Required."],
      dateRule: [(value) => !!value || "Date is Required."],
      timeRule: [(value) => !!value || "Time is Required."],
      intervalRule: [(value) => !!value || "Interval is Required."],
      // intervalRule: [(value) => !!value || "Interval is Required."],
      formData: {
        report_id: null,
        name: null,
        filter_options: null,
        export_options: null,
        schedule_my_report: false,
        email_to: null,
        cc_email: null,
        schedule_day: null,
        schedule_at: null,
        interval: null,
        frequency: null,
      },
    };
  },

  watch: {
    $route: {
      handler(newRoute) {
        if (newRoute.query.type) {
          this.viewType = newRoute.query.type;
          this.showReadOnly = this.viewType === "edit" ? false : true;
        }

        if (newRoute.query.id) {
          this.getById(newRoute.query.id);
        }
      },
      immediate: true,
    },
  },

  // watch: {
  //     id() {
  //         if (this.id) {
  //             this.formData = {};
  //             this.getById(this.id);
  //         }
  //     },
  // },
  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      alert("id");
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    moment,
    close() {
      this.$router.go(-1);
    },
    async getById(id) {
      let res = await JobService.reportListOne(id);
      this.formData = res[0];
      this.formData.schedule_day = res[0].schedule_day
        ? moment(res[0].schedule_day).format("YYYY-MM-DD ")
        : moment().format("YYYY-MM-DD ");
    },

    async save() {
      this.dateError = this.formData.schedule_day ? false : true;
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation.valid);
      } else {
        if (this.formData.id) {
          await JobService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.close();
          this.$emit("reload", true);
          console.log(this.formData, "hhhhhhhhhhhh");
          this.formData = {};
        } else {
          try {
            await JobService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.close();
            this.$emit("reload", true);
            this.formData = {};
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>
<style scoped>
v-error {
  color: red;
  padding-left: -16px;
  padding-left: 2%;
  font-size: 12px;
  padding-left: unset;
  position: relative;
  /* margin-top: 8px; */
  top: -15px;
}

.newSave {
  position: absolute;
  right: 75px;
  background: #1258ab;
  color: #fff;
}
</style>
