<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th class="first-column"></th>
          <th class="headerElem" v-for="workspace in uniqueWorkspaces" :key="workspace">
            <div class="headerText">{{ workspace }}</div>
          </th>
          <th class="headerElem total-column total-header-cell">
            <div class="headerText total-header">Total</div>
          </th>
        </tr>
      </thead>
      <tbody class="scrollable-body">
        <tr v-for="account in ProductionData" :key="account.subquery.account_id">
          <td>
            <span style="font-weight: bold" class="headerElem account-name">
              {{ account.subquery.account_name }}</span>
          </td>
          <td v-for="workspace in uniqueWorkspaces" :key="workspace">
            <div @click="
              issueData(
                account.workspace_data,
                workspace,
                account.subquery.account_id,
                account.workspace_data.id
              )
              " class="workspace-cell" style="font-weight: bold; cursor: pointer">
              {{ getCellValue(account.workspace_data, workspace) || "-" }}
            </div>
          </td>

          <td class="total-column-cell">
            <span style="font-weight: bold">
              {{
                this.switchData === "estimate"
                ? getAccountTotal(account).toFixed(2)
                : getAccountTotal(account)
              }}
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="total-row">
          <td class="total-column-cell">Total</td>
          <td style="background-color: #eef6fb;width: 30px;" class="total-column-cell"
            v-for="workspace in uniqueWorkspaces" :key="workspace">
            <span style="font-weight: bold">
              {{
                this.switchData === "estimate"
                ? getWorkspaceTotal(workspace).toFixed(2)
                : getWorkspaceTotal(workspace)
              }}</span>
          </td>
          <td class="total-column-cell">
            <span style="font-weight: bold">
              {{ this.switchData === "estimate" ? getTotal().toFixed(2) : getTotal() }}
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import ProductionDashboardService from "@/api/service/ProductionDashboard/ProductionDashboard";
export default {
  props: ["filterData", "switchData"],
  data() {
    return { ProductionData: null };
  },
  created() {
    this.getProductionData();
  },
  computed: {
    uniqueWorkspaces() {
      const workspaces = new Set();

      if (this.ProductionData) {
        this.ProductionData.forEach((account) => {
          const parsedData = JSON.parse(account.workspace_data);
          if (parsedData) {
            parsedData.forEach((workspace) => {
              workspaces.add(workspace.name);
            });
          }
        });
      }

      return Array.from(workspaces);
    },
  },
  watch: {
    filterData() {
      if (this.filterData) {
        console.log(this.filterData, "kishorefilr");
        this.getFilterDashboardData(this.filterData);
      }
    },
  },
  methods: {
    async getProductionData() {
      let res = await ProductionDashboardService.findAll();
      this.ProductionData = res.length > 0 ? res : [];
    },

    async getFilterDashboardData(data) {
      console.log(data.timeData && data.timeData.data_value, "kishoredata");
      let obj = {};

      if (data.priority && data.priority.length > 0) {
        // If priority is provided, set it in the obj as an array
        obj.priority = [...data.priority];
      }

      if (data.timeData && data.timeData.data_value) {
        // If timeData.data_value is provided, set it in the obj
        obj.timeData = data.timeData.data_value;
      }

      let res = await ProductionDashboardService.findAll(obj);
      this.ProductionData = res.length > 0 ? res : [];
    },

    getCellValue(workspaceData, workspace) {
      const data = JSON.parse(workspaceData);
      const workspaceItem = data.find((ws) => ws.name === workspace);

      // Check the switchData value to determine what to display
      if (this.switchData === "estimate") {
        return workspaceItem?.estimates?.toFixed(2) || "-";
      } else {
        // Default to showing "issues" when switchData is not set or has a different value
        return workspaceItem ? workspaceItem.issue || "-" : "-";
      }
    },
    getAccountTotal(account) {
      let total = 0;
      if (account.workspace_data) {
        const parsedData = JSON.parse(account.workspace_data);
        if (this.switchData === "estimate") {
          total = parsedData.reduce(
            (sum, workspace) => sum + (workspace.estimates || 0),
            0
          ); // Add toFixed(2) here
        } else {
          total = parsedData.reduce((sum, workspace) => sum + (workspace.issue || 0), 0);
        }
      }
      return total;
    },

    getWorkspaceTotal(workspace) {
      let total = 0;
      if (this.ProductionData) {
        this.ProductionData.forEach((account) => {
          const parsedData = JSON.parse(account.workspace_data);
          const workspaceData = parsedData.find((ws) => ws.name === workspace);

          if (workspaceData) {
            total +=
              this.switchData === "estimate"
                ? workspaceData.estimates || 0 // Add toFixed(2) here
                : workspaceData.issue || 0;
          }
        });
      }
      return total;
    },

    issueData(workspaceData, workspaceName, accountId) {
      const parsedWorkspaceData = JSON.parse(workspaceData);
      const clickedWorkspace = parsedWorkspaceData.find(
        (workspace) => workspace.name === workspaceName
      );

      if (clickedWorkspace) {
        // Emitting the event with Workspace ID and Account ID
        this.$emit("workspace-clicked", {
          workspaceId: clickedWorkspace,
          accountId: accountId,
        });
      }
    },

    getTotal() {
      let total = 0;
      if (this.ProductionData) {
        this.ProductionData.forEach((account) => {
          total += this.getAccountTotal(account);
        });
      }
      return total;
    },
  },
};
</script>

<style scoped>
/* Add a class to the "Total" column to keep it fixed */

table {
  border-collapse: collapse;
  white-space: nowrap;
  width: fit-content;
}


.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
}

/* Set the header and "Total" column to be fixed */
thead {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
}

/* Add padding and adjust white-space for the account name */
td.account-name {
  padding: 8px;
  /* Adjust the padding as needed */
  white-space: nowrap;
  position: relative;
  z-index: 4;
  /* Set a higher z-index to bring it to the front */
}

.scrollable-body {
  overflow-x: auto;
  max-height: 300px;
  /* Set the desired max height for the scrollable area */
}

.table-container {
  overflow-x: auto;
  max-height: 600px;
}

/* Add a class to the "Total" column to keep it fixed */
td.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
}

/* Set the header and "Total" column to be fixed */
thead {
  position: sticky;
  top: 0;
  background: #fff;
}

/* Add padding and adjust white-space for the account name */
td.account-name {
  padding: 8px;
  /* Adjust the padding as needed */
  white-space: nowrap;
}

.scrollable-body {
  overflow: auto;
  max-height: 300px;
  /* Set the desired max height for the scrollable area */
  z-index: -1;
  /* Set the desired max height for the scrollable area */
}

.headerElem {
  white-space: nowrap;
  height: 15em;
}

.headerText {
  /* transform: rotate(-90deg); */
  position: relative;
  word-break: break-all;
  font-size: 16px;
  font-weight: 500;
}

td {
  padding: 6px;
  height: 42px;
  /* width: 50px; */
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

th:nth-child(1) {
  width: 150px !important;
  position: sticky;
  left: 0;
  background: #eef6fb;
  z-index: 3;
}

td:nth-child(1) {
  width: 150px !important;
  position: sticky;
  left: 0;
  text-align: left !important;
  font-size: 14px;
  font-weight: 700;
  background-color: #eef6fb;
}

th:last-child {
  /* width: 150px !important; */
  position: sticky;
  right: 0;
  z-index: 3;
}

td:last-child {
  /* width: 150px !important; */
  position: sticky;
  right: 0;
  font-size: 14px;
  font-weight: 700;
}

th:nth-child(odd) {
  /* width: 50px; */
}

td:nth-child(odd) {
  /* width: 50px; */
}

td:nth-child(even) {
  background: #e1eaef;
}

/* Add these styles to your existing styles */

/* Set the first and last columns to stay fixed */
td.account-name,
td.total-column,
th.headerElem:nth-child(1),
th.headerElem.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
}

/* Set the first header cell to stay fixed */
th.headerElem:nth-child(1) {
  left: 0;
}

.total-header-cell {
  background-color: #eef6fb;
  /* Set the background color for the "Total" header cell */
}

/* Add a class for the "Total" value cells */
.total-column-cell {
  background-color: #eef6fb;
}

th.headerElem.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
  width: 20px !important;
}

/* Set the styles for the "Total" column header text */
.headerText.total-header {
  /* transform: rotate(-90deg); */
  position: relative;
  word-break: break-all;
  font-size: 16px;
  font-weight: 500;
}

/* Set the last header cell to stay fixed */
th.headerElem.total-column {
  position: sticky;
  left: auto;
  background-color: #eef6fb;
  z-index: 3;
  width: 20px !important;
}

/* Set the styles for the text within the header cells */
.headerText {
  transform: rotate(-90deg);
  position: relative;
  word-break: break-all;
  font-size: 16px;
  font-weight: 500;
  width: 50px;
  overflow: visible;
  position: relative;
  top: 60px;
}

/* Set the styles for the first column */
td.account-name {
  padding: 8px;
  /* Adjust the padding as needed */
  white-space: nowrap;
  position: relative;
  z-index: 4;
  /* Set a higher z-index to bring it to the front */
}

/* Adjust other styles as needed */

.total-row {
  position: sticky;
  bottom: 0;
  background-color: #eef6fb;
  z-index: 3;
}

.total-row td {
  font-weight: bold;
}

.headerElem:nth-child(even) {
  background: #e1eaef;
  width: 20px !important;
}


.first-column {
  width: auto;
  /* Let the width be determined by content */
}

/* Add a general style for other columns */
.table-container th:not(.first-column),
.table-container td:not(.first-column) {
  width: 30px !important;
  overflow: hidden;
  /* You can customize other styles here */
}
</style>
