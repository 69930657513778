<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="6" class="pb-0" style="display: grid">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Time Zone
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <time-zone-list-vue v-model="formData.time_zone" />
          </v-col>
        </v-row>
        <v-row v-if="showReadOnly">
          <v-col cols="6" class="pb-0 pt-0" style="display: grid">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Date Format
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <EnumAutoCompleteVue
              density="compact"
              v-model="formData.dateFormat"
              :selected="formData.dateFormat"
              :showReadOnly="viewType == 'add' || this.viewType == 'view'"
              :domain="'DATEFORMAT'"
              placeholder="Date Format"
              @changeEnumData="changeDateFormat($event)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col align="end" class="dialogBottom">
            <v-btn class="mr-2 cancel"  @click="$router.go(-1)">Cancel</v-btn>
            <v-btn class="save" type="submit" @click="save()">Save</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EnumAutoCompleteVue from "@/components/others/EnumAutoComplete.vue";
import TimeZoneListVue from "@/components/others/TimeZoneList.vue";
import UserService from "@/api/service/UserService";

export default {
  components: {
    EnumAutoCompleteVue,
    TimeZoneListVue,
  },
  data() {
    return {
      showReadOnly: false,
      userData: [],
      formData: {},
      userDetails: JSON.parse(localStorage.getItem("user_details")),
    };
  },
  created() {
    console.log(this.userDetails, "jhhjhhjhjjh");
    this.formData.id = this.userDetails.id;
    if (this.userDetails.id) {
      this.getUserData(this.userDetails.id);
    }
  },
  methods: {
    changeDateFormat($event) {
      this.formData.dateFormat = $event;
    },
    async getUserData(id) {
      let res = await UserService.findOne({ id: id });
      this.userData = res[0];
      console.log(this.userData, "thrrrrrrrrrrrrrr");
      this.formData.time_zone = this.userData.time_zone;
    },
    async save() {
      if (this.formData) {
        try {
          let output = await UserService.update(this.formData);
          console.log(output);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });

          this.$emit("reload", true);
        } catch (e) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Something Went Wrong",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.dialogBottom {
  /* position: fixed;*/
  bottom: 12px;
  z-index: 100;
  width: 100%;
  right: 12px;
  padding: 16px;
  white-space: break-spaces;
  border-radius: 0px 0px 0px 10px;
  background: none !important;
  /*border-top: 1px solid #d7d7d7;*/
}
</style>
