<template>
   <v-layout v-if="viewAll">
      <v-navigation-drawer location="right" v-model="drawer" temporary>
         <v-card style="width: 50vw;">
            <IndexView v-if="viewId" :itemId="viewId" :processView="indexView" />
         </v-card>
      </v-navigation-drawer>
      <v-main style="width: 0px !important;">
         <div class="container">
            <div class="text-h6 font-weight-bold mb-2">Process Lead Dashboard</div>

            <SelectionFilter class="mb-2" @selected-values="handleSelectedValues" />

            <div style="display:flex;flex-wrap: wrap;">

               <div v-if="showAccount" style="width:290px;float: left">
                  <v-card flat class="pa-2 mb-2" style="display:flex;justify-content:space-between;height: 40px; width: 278px;">
                     <div style="width:100%;display: flex;">
                        <button id="activator" class="d-flex pt-1">
                           <v-icon class="">mdi-filter-outline</v-icon>

                           <v-menu activator="parent" v-model="menu" transition="scale-transition" :close-on-content-click="false"
                              location="bottom">
                              <v-list width="300px">
                                 <DashboardFilter :type="'openIssue'" :process="true" :existFilterData="filterData" @applyChange="handleSelectedValues"
                                    @close="filterDialog = false" />
                              </v-list>
                           </v-menu>
                        </button>
                        
                        <span class="text-subtitle-1 font-weight-bold mb-2">Unassigned</span>
                        &nbsp; &nbsp;
                       
                     </div>
                     <div style="align-items:flex-end">
                        <span ref="searchContainer" v-if="searchClicked">
                           <TextFieldComponent id="search" v-model="search" flat append-inner-icon="mdi-magnify">
                           </TextFieldComponent>
                        </span>
                        <v-icon style="align-items:flex-end" v-else  @click.stop="toggleSearch">mdi-magnify</v-icon>
                                              
                     </div>
                  </v-card>
                  <UnAssignedIssues :filterData="filterData" :searchData="search" @draweView="openView" 
                  @open-issue-update="setUnAssignedIssue"/>
               </div>

               <div v-bind:class="dynamicClass" >
                  <v-card flat class="pa-1 mb-2" style="display: flex;align-items: baseline; justify-content: space-between;">
                     <div style="width:100%" class="text-subtitlte-1 pa-1 font-weight-bold mb-2">
                        Tasks from Current shift</div>
                     <v-tooltip location="bottom" :text='showAll ? "Maximize" : "Minimize"'>
                        <template v-slot:activator="{ props }">
                           <v-icon class="iconToggle" v-bind="props" @click="closeAll">{{
                              showAll ? "mdi-window-maximize" : "mdi-window-minimize"
                           }}</v-icon>
                        </template>
                     </v-tooltip>
                  </v-card>
                  <ProcessBoard :maxValue="showOnly" @draweView="openView" />
                  <v-card  flat v-if="showAll" style="text-align: left;" 
                  class="pa-1 text-subtitle-1 font-weight-bold my-3">
                  Task from other Shifts
               </v-card>
                  <UserByShifts v-if="showAll" @draweView="openView" />
               </div>


               <div v-if="showAll">
                  <v-card flat class="pa-2 mb-2" style="display: flex; justify-content: left;">
                     <span class="text-subtitle-1 font-weight-bold">Workload in All Accounts</span>
                  </v-card>
                  <AllWorkSpaceUser @openlistview="showIssueForUser" />
               </div>
            </div>

         </div>
         <v-dialog persistent v-model="showlistview" style="z-index=900">
            <v-card>
               <v-card-title>
                  <span >
                  <CustomAvatar2 v-if="false" :size="'small'" 
                :fName="selectedUser.first_name" 
                :lName="selectedUser.last_name" 
                :theme="selectedUser.theme" 
                :imgUrl="selectedUser.image_url ? selectedUser.image_url: null"/>
               </span>
               {{  selectedUser.first_name }} {{ selectedUser.last_name }} Open Tasks
                  <v-icon @click="showlistview=false" class="float-right mdi mdi-close"/>
                  
               </v-card-title>
               <v-card-text>         
                  
                  <ListView   :issueData="userIssues"/>
            </v-card-text>
            </v-card>
         </v-dialog>
      </v-main>
   </v-layout>
</template>

<script>
import CustomAvatar2 from '@/components/ui/CustomAvatar2.vue';

import DashboardFilter from '../Widgets/DashboardFilter.vue'
import AllWorkSpaceUser from './AllWorkSpaceUser.vue'
import UnAssignedIssues from './UnAssignedIssues.vue'
import ProcessBoard from './ProcessBoard.vue'
import SelectionFilter from './SelectionFilter.vue'
import UserByShifts from './UserByShifts.vue'
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import IndexView from '@/modules/issues/views/Index.vue'
import ListView from '@/modules/issues/List.vue'
import IssueService from '@/api/service/Issue/IssueService'
export default {
   components: {
      DashboardFilter,
      CustomAvatar2,
      ListView,
      UnAssignedIssues,
      SelectionFilter,
      ProcessBoard,
      AllWorkSpaceUser,
      UserByShifts,
      TextFieldComponent,
      IndexView
   },
   data() {
      return {
         selectedUser:{},
         userIssues:[],
         showlistview:false,
         UnAssignedIssuesCount:0,
         searchClicked: false,
         viewAll: false,
         menu:false,
         indexView: false,
         viewId: null,
         drawer: false,
         search: null,
         showAll: true,
         showOnly: false,
         showAccount: true,
         selectedValues: {},
         filterData: {},
         wsDefaults: null
      }
   },
   watch: {
      refreshOnUser() {
         if (this.refreshOnUser) {
            this.openView()
         } else {
            this.openView()
         }
      }
   },
   computed: {
      refreshOnUser() {
         return this.$store.getters.getAssignee;
      },
      dynamicClass() {
         if (!this.showAll && !this.showAccount) {
            return 'boardSection'
         }
         else if (!this.showAll || !this.showAccount) {
            return 'allBoardSection'
         } else {
            return 'defaultBoardSection'
         }
      },
   },
   created() {
      this.wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
      if (this.wsDefaults) {
         this.wsDefaults = JSON.parse(this.wsDefaults);
         if (!this.wsDefaults.workspace) {
            this.getSelectselectValue(this.wsDefaults)
            return;
         }
      } else {
         this.getSelectselectValue(this.wsDefaults)
         return;
      }
      this.viewAll = true
      let excitsFilter = localStorage.getItem("filterData");
      if(excitsFilter){
         this.filterData=JSON.parse(excitsFilter)
      }

   },
   methods: {
      async showIssueForUser($user) {
         this.selectedUser = $user
         let $res = await IssueService.findAllIssues({next_action:$user.id});
         this.userIssues= $res
         this.showlistview=true

      }, 
      setUnAssignedIssue(data) {
         
         this.UnAssignedIssuesCount = data
      },
      getSelectselectValue() {
         this.$store.dispatch("setSnackbar", {
            text: "Please Select Workspace/Account/Project first",
         });
         this.$router.push("/showWorkspaces");
         localStorage.setItem("_WS_REDIRECT_", '/dashBoard/processLead')
      },
      openView(item) {
         let status = this.$store.getters.getMyspace;
         localStorage.setItem("_WS_DEFAULTS_", JSON.stringify({ "workspace": { "id": item.work_space_id, "name": item.work_space_name } }))
         localStorage.setItem("workSpaceId", item.work_space_id);

         // this.$router.push({ path: "/issueView2", query: { id: item.id } });
         this.$store.dispatch('setIssueInView', item.id)
         let wsDefaults = {
            workspace: { id: item.work_space_id, name: item.work_space_name },
            account: { id: item.account_id, name: item.account_name },
            project: { id: item.project_id, name: item.project_name }
         }
         let wsDefaultsData = JSON.stringify(wsDefaults)
         localStorage.setItem("_WS_DEFAULTS_", wsDefaultsData);
         // console.log(wsDefaultsData,'alert111111111')
         this.$store.commit("SET_REFRESH_BREADCRUMP", true)
         this.$store.commit("CHANGE_MY_SPACE", !status);
      },
      closeAll() {
         this.showAccount = !this.showAccount
         this.showAll = !this.showAll
         this.showOnly = !this.showOnly
      },
      handleSelectedValues(data) {
         this.menu =false
         this.filterData = data
         localStorage.setItem('filterData',JSON.stringify(this.filterData))
      },
      toggleAccount() {
         this.showAccount = !this.showAccount
      },
      toggleAll() {
         this.showAll = !this.showAll
      },

      toggleSearch() {
         this.searchClicked = !this.searchClicked;
         if (this.searchClicked) {
            document.addEventListener('click', this.handleOutsideClick);
         } else {
            document.removeEventListener('click', this.handleOutsideClick);
         }
      },
      handleOutsideClick(event) {
         const textField = this.$refs.searchContainer;
         if (textField && !textField.contains(event.target)) {
            this.searchClicked = false;
            document.removeEventListener('click', this.handleOutsideClick);
         }
      },
   }
}
</script>

<style scoped>
.container {
   background: #f2f7fd;
   padding: 10px 20px;
   height: 90vh;
   width: 100%;
   overflow-x: auto;
}

.defaultBoardSection {
   max-width: calc(100% - 580px);
   overflow: auto;
   margin-right: 15px;
}

.allBoardSection {
   max-width: calc(100% - 340px);
   overflow: auto;
   margin-right: 15px;
}

.boardSection {
   max-width: 94vw;
   overflow: auto;
   margin-right: 15px;
}
>>>.v-field {
    background: #fff;
    width: 320px !important;
    margin-right: 4px;
    z-index: 3;
}


.iconToggle:hover {
   color: #1258ab !important;
}

>>>.v-navigation-drawer {
   width: 50% !important;
   position: absolute !important;
   top: 72px !important;
   height: 90vh !important;
}

>>>::-webkit-scrollbar {
    width: 6px;
    height:6px;
}

>>>::-webkit-scrollbar:hover {
    width: 10px;
    height: 10px;
}

>>>::-webkit-scrollbar-track {
    background: #e8f3f9;
}

>>>::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.12);
    border-radius: 8px;
}

</style>