<template>
  <PrimeDataTable
    :title="'WorkTiming'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="worktiming"
  />

  <!-- <v-dialog persistent v-model="dialog" width="700px">
    <CreateWorkTiming
      @closeDialog="close()"
      @reload="getWorkTiming()"
      :id="editId"
      :viewType="viewType"
  /></v-dialog> -->
</template>
  
  <script>
// import CreateWorkTiming from "./CreateWorkTiming.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import WorkTimingService from "@/api/service/WorkTiming/WorkTimingService";
// import commonService from "@/common.service";
export default {
  components: {
    // CreateWorkTiming,
    PrimeDataTable,
  },
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      editId: '',
      dialog: false,
      viewType: false,
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getWorkTiming();
  },
  methods: {
    loadColumns() {
      this.columns = [
      
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "name",
          sortfield: true,
        },
        {
          field: "total_hours",
          header: "Total Hours",
          style: { width: "100px", color: "" },
          toolTip: "Total Hours",
          sortfield: true,
        },
        
        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "modified",
          sortfield: true,
        },
        {
          field: "active",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "active",
          sortfield: true,
        },
        
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: false,
          rowActions: [
            { action: "view" },
            { action: "edit" },
          ],
        },
      ];
    },

    close() {
      this.dialog = false;
    },
    rowActions($event) {
      if ($event.type == "view") {
       this.editId = $event.data.id;
       this.$router.push({ path: '/setting/organisation/CreateWorkTiming', query: {id:$event.data.id,type:$event.type}  });
        this.viewType = $event.type;
       
      }
      if ($event.type == "edit") {
        this.$router.push({ path: '/setting/organisation/CreateWorkTiming', query: {id:$event.data.id,type:$event.type}  });
        this.editId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
       this.$router.push({ path: '/setting/organisation/CreateWorkTiming', query: {id:$event.data.id,type:$event.type}  });
        this.editId = $event.data.id;
        this.viewType = "view";
      }
      if ($event.type == "delete") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = "view";
      }
    },
    async getWorkTiming() {
      let res = await WorkTimingService.findAll();
      this.data = res;
    },
    addDiolog() {
     this.$router.push('/setting/organisation/CreateWorkTiming');
      this.editId = null;
      this.viewType = false;
    },
  },
};
</script>
  
  
  