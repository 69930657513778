<template>
    <PrimeDataTable
      :title="'Menu Group'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="true"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="menugroup"
     
    />
  
    <v-dialog persistent v-model="dialog" width="700px">
      <CreateMenuGroup
        @closeDialog="close()"
        @reload="getMenuGroup()"
        :id="editId"
        :viewType="viewType"
        
      />
    </v-dialog>
  </template>
  
  <script>
  import CreateMenuGroup from "./CreateMenuGroup.vue";
  import PrimeDataTable from "@/components/PrimeDataTable";
  import MenuGroupService from "@/api/service/MenuGroup/MenuGroupService";

  export default {
    components: {
        CreateMenuGroup,
      PrimeDataTable,
    },
    data() {
      return {
        data: [],
        columns: [],
        date: new Date(),
        editId:"",
        dialog: false,
        viewType:false
      };
    },
    created() {
      this.loadColumns();
      this.download = true;
      this.search = true;
      this.checkbox = true;
      this.getMenuGroup();
    },
    methods: {
      loadColumns() {
        this.columns = [
          {
            field: "id",
            header: "Id",
            style: { width: "100px", color: "" },
            toolTip: "Id",
            sortfield: true,
          },
          {
            field: "activity_group_id",
            header: "Activity Group Id",
            style: { width: "100px", color: "" },
            toolTip: "Activity Group Id",
            sortfield: true,
          },
          {
            field: "activity_id",
            header: "Activity Id",
            style: { width: "100px", color: "" },
            toolTip: "Activity Id",
            sortfield: true,
          },
          {
            field: "menu_group",
            header: "Menu Group",
            style: { width: "100px", color: "" },
            toolTip: "Menu Group",
            sortfield: true,
          },
          {
            field: "menu_order",
            header: "Menu Order",
            style: { width: "100px", color: "" },
            toolTip: "Menu Order",
            sortfield: true,
          },
          {
            field: "route",
            header: "Route",
            style: { width: "100px", color: "" },
            toolTip: "Route",
            sortfield: true,
          },
          {
            field: "actions",
            header: "Actions",
            style: { width: "100px", color: "" },
            toolTip: "Actions",
            sortfield: true,
          },
          {
            field: "menu_key",
            header: "Menu Key",
            style: { width: "100px", color: "" },
            toolTip: "Menu Key",
            sortfield: true,
          },
          {
            field: "activity_list",
            header: "Activity List",
            style: { width: "100px", color: "" },
            toolTip: "Activity List",
            sortfield: true,
          },
          {
            field: "action",
            header: "Action",
            style: { width: "100px", color: "" },
            toolTip: "Action",
            sortfield: true,
            rowActions:[
                {action:"view"},
                {action:"edit"},
                {action:"delete"},
            ],
          }
        ];
      },
  
      close() {
        this.dialog = false;
      },
      rowActions($event) {
        if ($event.type == "view") {
          this.dialog = true;
          this.editId = $event.data.id;
          console.log(this.editId,'$eventiddata')
          this.viewType=$event.type;
        }
        if ($event.type == "edit") {
          this.dialog = true;
          this.editId = $event.data.id;
           console.log(this.editId,'$eventiddata')
          this.viewType=$event.type;
        }   
  
        if ($event.type == "select") {
          this.dialog = true;
          this.editId = $event.data.id;
           console.log(this.editId,'$eventiddata')
          this.viewType="view";
        }
      },
      async getMenuGroup() {
        let res = await MenuGroupService.findAll();
        this.data = res;
        console.log(this.data,"rubeshhhh")
      },
      addDiolog() {
        this.dialog = true;
        this.editId = null;
      }
    },
  };
  </script>
  
  
  