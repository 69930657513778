<template>
    <v-row class="py-0 my-0">
      <!-- {{ data }} -->
      <template v-for="item in data" :key="item.id">
        <v-col :cols="item.no_col" class="pt-0">
          <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">{{ item.name }}</v-label>
          
          <TextFieldComponent v-if="item.field_type === 'textfield'" :placeholder="item.name" :disabled="showReadOnly"
            v-model="udfFormData[item.name]" @input="formChanged" />
  
          <v-select v-if="item.field_type === 'select'" density="compact" variant="outlined" v-model="udfFormData[item.name]" 
          :items="item.source_type" :item-title="'name'" :item-value="'id'"
          :placeholder="item.name" @update:modelValue="formChanged"></v-select>
  
          <AutoComplete v-if="item.field_type === 'autocomplete'" v-model="udfFormData[item.name]" 
          :items="item.source_type" :item-title="'name'" :item-value="'id'"
          :placeholder="item.name" @update:modelValue="formChanged" />
  
          <CheckBox v-model="formData[item.name]" v-if="item.field_type === 'checkbox'" :placeholder="item.name"
          :color="'primary'"  @change="formChanged" />
  
          <v-textarea v-if="item.field_type === 'textarea'" v-model="udfFormData[item.name]"
            :maxlength="maxlength ? maxlength : 120" single-line density="compact" variant="outlined" class="topsearch"
            @input="formChanged"></v-textarea>
  
          <v-switch class="Switch" color="primary" v-model="udfFormData[item.name]" v-if="item.field_type === 'switch'"
            :placeholder="item.name" @change="formChanged"></v-switch>
  
            <div v-if="item.field_type === 'radiobutton'">
          <RadioButton v-for="(option, optionIndex) in item.options" :key="optionIndex" v-model="udfFormData[item.name]"
            :value="option.id" :label="option.name" :color="'primary'" @change="formChanged" />
        </div>
        </v-col>
      </template>
    </v-row>
  </template>
  
  <script>
  // impo
  import TextFieldComponent from "@/components/TextFieldComponent.vue";
  import AutoComplete from "@/components/ui/AutoComplete.vue";
  import RadioButton from "@tiburadev/vue-comp/src/ui/RadioButton.vue";
  // import RadioButton from "@/components/ui/RadioButton.vue";
  import CheckBox from "@tiburadev/vue-comp/src/ui/CheckBox.vue";
  // import TextArea from "@/components/ui/TextArea.vue";
  export default {
    props: ['data', 'status'],
    components: {
      TextFieldComponent,
      AutoComplete,
      RadioButton,
      CheckBox
      // TextArea
    },
    data() {
      return {
        udfFormData: {}
      }
    },
    watch: {
      isSubCategoryChanged() {
        this.udfFormData = {}
        this.formChanged()
      }
    },
    computed: {
      isSubCategoryChanged() {
        return this.status
      }
    },
  
    methods: {
      formChanged() {
        this.$emit('formChanged', this.udfFormData)
      },
    }
  
  }
  </script>
  
  <style></style>