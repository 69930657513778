<template>
    <v-dialog persistent v-model="showDialog" width="500px">
        <v-card class="text-center rounded-lg">
            <v-card-title color="indigo">
                Session Expired!!
            </v-card-title>
            <v-card-text class="pa-10 pb-0">
                Hello <b>{{ userData && userData.username ? userData.username : "" }}</b> your session is expired
                Please enter the password to <b>Login</b> or <b>Logout</b>
                <v-form @submit.prevent="login" ref="form">
                    <!-- <v-col class="pa-0 pt-2">
                        <TextFieldComponent class="mt-4" density="compact" placeholder="Your Email" type="email"
                        :showReadOnly="true"
                            append-inner-icon="mdi-account-outline" :rules="[rules.userRule, rules.emailValidation]"
                            v-model="email" variant="outlined">
                        </TextFieldComponent>
                    </v-col> -->
                    <v-col class="pa-0 pt-2">
                        <TextFieldComponent density="compact" @click:append-inner="showPassword = !showPassword"
                            placeholder="Password" :rules="passwordRule" v-model="password" variant="outlined"
                            v-bind:type="[showPassword ? 'text' : 'password']"
                            :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @input="showError = false">
                        </TextFieldComponent>
                        <v-error v-show="showError">Incorrect Password</v-error>
                    </v-col>
                </v-form>

            </v-card-text>
            <v-card-action class="pa-7 pt-0">
                <v-col class="pa-3 pt-2">

                    <v-btn block color="indigo" type="submit" size="large" :disabled="loading" :loading="loading"
                        class="text-none mb-4" @click="login()">Login</v-btn>
                </v-col>
                <v-col class="pa-3 pt-2">
                    <span class="logout" @click="logout()">Logout</span>
                </v-col>
            </v-card-action>

        </v-card>

    </v-dialog>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import UserService from "@/api/service/UserService";
import TimerService from "@/api/service/TimeSheet/TimerService.js";
export default {
    components: {
        TextFieldComponent,
    },
    data() {
        return {
            showError: false,
            showDialog: true,
            password: null,
            loading: false,
            showPassword: false,
            emailValidation: "",
            passwordRule: [(value) => !!value || "Password is Required."],
            email: null,
            rules: {
                userRule: (value) => !!value || "Email is Required.",
            },
        };
    },
    created() {
        this.userData = JSON.parse(localStorage.getItem('user_details'))
        this.email = this.userData.email

    },
    methods: {
        async login() {
            this.loading = true
            this.showError = false
            if (this.password) {
                try {
                    let response = await UserService.fireBaseLogin({ email: this.email, password: this.password, appId: process.env.VUE_APP_APP_ID })
                    console.log(response, "balaganesh checking")
                    if (response.token) {
                        try {
                            let userRes = await UserService.authenticate(
                                {
                                    "token": response.token,
                                    "deviceId": localStorage.getItem('deviceId'),
                                    "deviceInfo": window.navigator.userAgent

                                })
                            if (userRes && userRes.data && userRes.data.desks[0] && userRes.data.desks[0].token) {
                                let $time = {};
                                localStorage.removeItem('_WORK_TIMING_');
                                localStorage.setItem('TOKEN', userRes.data.desks[0].token)
                                await TimerService.processTime($time, "login", true);
                                window.location.reload()
                                this.$store.commit('SET_EXPIRE_SESSION', false)
                                this.loading = false
                            }
                            else {

                                this.loading = false
                                this.showError = true
                            }

                        }
                        catch (e) {
                            this.loading = false
                            console.log(e)

                        }
                    }
                }
                catch (e) {
                    if (e.message === 'Invalid credential') {
                        console.log(e)
                        this.$store.commit('SET_EXPIRE_SESSION', false)
                        this.loading = false
                        this.showError = true
                    }
                }
            }

        },
        logout() {
            localStorage.clear();
            window.location.reload()
        }
    }
}
</script>
<style>
.logout {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}
</style>