<template>
    <v-card flat style="border-radius: 12px;border:2px solid #f1f3f8">
        <v-card-item>
            <v-card-title>
                {{ selectedCard ? `Configure your widget ` : 'Select your widget Type' }}
                <v-btn flat icon="mdi mdi-close" class="mt-1 float-right" density="compact" size="small"
                    @click="$emit('closeDialog')" />
            </v-card-title>
        </v-card-item>
        <v-card-text>
            <v-row dense>
                <v-col cols="12" class="pa-1" v-if="!selectedCard">
                    <CardSelection :menuOptions="widgets" @cardSelected="cardSelected" />
                </v-col>
            </v-row>
            <v-row dense style="background-color:#f1f3f8" v-if="selectedCard">
                <v-col cols="3">
                    <v-card flat width="100%" style="height:100%">
                        <v-card-title>
                            {{ formData && formData.widgetType ? formData.widget_type.toUpperCase() : '' }}
                        </v-card-title>
                        <v-card-text>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-label>
                                        Widget Title
                                    </v-label>
                                    <TextFieldComponent hide-details @blur="setTitle" :model-value="formData.title" />
                                </v-col>
                                <v-col cols="12">
                                    <v-label>
                                        Group Level 1
                                    </v-label>
                                    <AutoComplete hide-details :items="groups" item-value="id" item-title="name"
                                        density="compact" placeholder="Select Group 1" v-model="formData.group1"
                                        @update:modelValue="changeInGroup" />
                                </v-col>
                                <v-col cols="12">
                                    <v-label>
                                        Group Level 2
                                    </v-label>
                                    <AutoComplete hide-details :items="groups" item-value="id" item-title="name"
                                        density="compact" placeholder="Select Group 2" v-model="formData.group2"
                                        @update:modelValue="changeInGroup" />
                                </v-col>
                                <v-col cols="12">
                                    <v-label>
                                        Workspace
                                    </v-label>
                                    <AutoComplete hide-details :items="workspaces" item-value="id" multiple
                                        item-title="name" density="compact" placeholder="Select Workspace"
                                        v-model="formData.defaultFilters.work_space_id" />
                                </v-col>
                                <v-col cols="12">
                                    <v-label>
                                        Project
                                    </v-label>
                                    <AutoComplete hide-details :items="projects" item-value="id" multiple item-title="name"
                                        density="compact" placeholder="Select Project"
                                        v-model="formData.defaultFilters.project_id" />
                                </v-col>
                                <v-col cols="12">
                                    <v-label>
                                        Account
                                    </v-label>
                                    <AutoComplete hide-details :items="accounts" item-value="id" multiple item-title="name"
                                        density="compact" placeholder="Select Account"
                                        v-model="formData.defaultFilters.account_id" />
                                </v-col>
                                <v-col cols="12">
                                    <v-label>
                                        Work Timings
                                    </v-label>
                                    <AutoComplete hide-details :items="workTimings" item-value="id" multiple
                                        item-title="name" density="compact" placeholder="Select Work Timing"
                                        v-model="formData.defaultFilters.work_timing_id" />
                                </v-col>
                                <v-col cols="12">
                                    <v-switch v-model="formData.groupWithin" color="primary" density="compact"
                                        label="Group within the card" />
                                </v-col>

                                <v-col cols="12" v-if="false" class="mt-2">
                                    <p class="text-caption">
                                        Setting Default Workspace allows the users to see the data based on their default
                                        setting in the user session
                                    </p>
                                </v-col>
                                <!-- <v-col cols="6" class="mt-2">
                                    <v-btn @click="previewChanges" color="primary" block>Preview</v-btn>
                                </v-col> -->
                                <v-col cols="12" class="mt-2">
                                    <v-btn @click="apply" color="primary" block>Apply</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <Board :config="formData" />
                </v-col>
                <v-col cols="3">
                    <DashboardFilter :existFilterData="formData.filters" @filterchanged="filterChanged" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import Board from './Board.vue'
import ProjectService from '@/api/service/Project/ProjectService'
import AccountService from '@/api/service/Account/AccountService'
import WorkSpaceService from '@/api/service/WorkSpace/WorkSpaceService'
import WorkTimingService from '@/api/service/WorkTiming/WorkTimingService'
import { TextFieldComponent } from '@tiburadev/vue-comp'
import AutoComplete from "@/components/ui/AutoComplete.vue";
import DashboardFilter from './DashboardFilter.vue'
import { CardSelection } from '@tiburadev/vue-comp'
import IssueWidgetType from './WidgetTypesByIssue'
export default {
    components: {
        Board,
        AutoComplete,
        TextFieldComponent,
        CardSelection,
        DashboardFilter
    },
    props: ["widgetType", 'settingData', "selectedIndex"],
    data() {
        return {
            workspaces: [],
            workTimings: [],
            projects: [],
            accounts: [],
            formData: {
                type: "issue",
                grouping: [],
                groupWithin: false,
                defaultFilters: {
                    work_space_id: null,
                    project_id: null,
                    account_id: null,
                },
                filters: {}
                
            },
            selectedCard: null,
            groups: [
                { "id": "none", "name": "None" },
                { "id": "account", "name": "Account" },
                { "id": "project", "name": "Project" },
                { "id": "user", "name": "User" },
                { "id": "workspace", "name": "Workspace" },
                { "id": "category", "name": "Category" },
                { "id": "subcategory", "name": "Sub Catgory" },
                { "id": "task", "name": "Task" },
                { "id": "issuetype", "name": "Issue Type" },
                { "id": "stage", "name": "Stage" }

            ],
            widgets: {
                "columns": 2,
                "height": "140px",
                "showSearchBox": false,
                "outlineOnHover": true,
                "outlineOnSelection": true,
                "actions": [],
                "multiSelection": false,
                "selectedValue": "code",
                "scrollContainerHeight": "70vh",
                options: []
            }
        }
    },
    created() {
        this.$store.commit("SET_WIDGET_UPDATED", false);
        if (this.widgetType == 'issue') {
            this.widgets.options = IssueWidgetType
            this.formData.type = 'issue'
        }
        this.getWorkTimings()
        this.getWorkspaces();
        this.getProjects()
        this.getAccounts()

        if (this.settingData) {
            this.cardSelected(this.settingData)
        }
        // this.getDefaultFilters()
    },
    watch: {
        settingData() {
            if (this.settingData) {
                this.cardSelected(this.settingData)
            }
        }
    },
    methods: {
        changeInGroup() {
            this.formData.grouping = [this.formData.group1, this.formData.group2]
        },
        setTitle($event) {
            //    console.log($event.target._value, 'kasddddd')
            this.formData.title = $event.target._value
        },
        async getAccounts() {
            this.accounts = [{ "id": 0, "name": "Default Project" }]
            let $accounts = await AccountService.findAll()
            this.accounts = this.accounts.concat($accounts)
            this.formData.account_id = 0
        },
        async getProjects() {
            this.projects = [{ "id": 0, "name": "Default Project" }]
            let $projects = await ProjectService.findAll()
            this.projects = this.projects.concat($projects)
            this.formData.project_id = 0
        },
        async getWorkspaces() {
            this.workspaces = [{ "id": 0, "name": "Default Workspace" }]
            let $workspaces = await WorkSpaceService.getWorkSpaceByUser();
            this.workspaces = this.workspaces.concat($workspaces)
            this.formData.work_space_id = 0
        },
        async getWorkTimings() {
            this.workTimings = [{ "id": 0, "name": "Default WorkTiming" }]
            let $timings = await WorkTimingService.findAll()
            this.workTimings = this.workTimings.concat($timings)
            this.formData.work_timing_id = 0
        },
        cardSelected($item) {
            this.selectedCard = $item
            switch ($item.type) {
                case 'custom':
                    break;
                default:
                    this.formData.title = $item && $item.title ? $item.title : null
                    this.formData.filters = $item && $item.filters ? $item.filters : null
                    this.formData.type = $item && $item.type ? $item.type : null
                    this.formData.widget_type = $item && $item.widgettype ? $item.widgettype : null
                    this.formData.group1 = $item && $item.group1 ? $item.group1 : null
                    this.formData.group2 = $item && $item.group2 ? $item.group2 : null
                    this.formData.grouping = [$item.group1, $item.group2];
                    this.formData.defaultFilters = $item && $item.defaultFilters ? $item.defaultFilters : { work_space_id: null, project_id: null, account_id: null };
                    break;

            }
            console.log(this.formData, "oooooooo")
        },
        validateGroup() {

        },
        group1Changed() {

        },
        group2Changed() {

        },
        filterChanged($filter) {
            this.formData.filters = $filter
        },
        workSpaceChanged($item) {
            this.formData.work_space_id = $item
        },
        accountChanged($item) {
            this.formData.account_id = $item
        },
        projectChanged($item) {
            this.formData.project_id = $item
        },
        previewChanges() {
            this.$store.commit("SET_WIDGET_UPDATED", true);
        },
        apply() {
            this.$store.commit("SET_WIDGET_UPDATED", true);
            this.$emit('applychanges', this.formData, this.selectedIndex)
            console.log(this.formData, this.selectedIndex, 'balabro')
        }
    }
}
</script>
