<template>
<v-row>
    <v-col cols="4" class="py-0" style="display: flex; flex-wrap: wrap;" >
 <v-col cols="6"  v-for="item in dashBoardCardData" :key="item">
     <v-hover v-slot="{ isHovering, props }" open-delay="100">
        <v-card
        style="display: flex; justify-content: center; align-items: center; padding: 10px;"
        height="100%"
        width="100%"
          v-bind="props"
          :elevation="isHovering ? 16 : 0"
          :class="{ 'on-hover': isHovering }"
        >
        <div style="display: flex; justify-content: space-between;">
            <div class="mr-7">
                <span>
                    {{ item.name }}
                </span> <br>
                <h2 >
                  {{ item.hour}}
                  <span class="DashbordCartText">Hours</span>
                </h2>
            </div>
            <div   style="display: flex;  align-items: center;">
                <v-sheet 
                rounded
                :color="item.bgcolor"
                :height="42"
                class="imageInSheet"
                :width="42"
              >
                <v-img v-bind:src="item.src" width="200px" class="pa-3"></v-img>
              </v-sheet>
            </div>
        </div>

    </v-card>
    </v-hover>
</v-col>
</v-col>


<v-col cols="3"> 
    <v-card class="pa-3" flat height="100%">
        <div style="display: flex; justify-content: space-between; padding: 10px;">
            <div >
                <span>
                    Productivity Ratio
                </span> <br>
                <h2>
                    2:4
                </h2>
            </div>
            <div   style="display: flex;  align-items: center;">
                <v-sheet
                rounded
                color="#F8CECE"
                :height="42"
                class="imageInSheet"
                :width="42"
              >
              <v-img :src="require('@/assets/image/chartBar.svg')" width="200px" class="pa-3"></v-img>
              </v-sheet>
            </div>
        </div>
        <div style="display: flex; justify-content: space-between; padding: 10px;">
            <div >
                <span>
                    Mid Shift
                </span> <br>
                <h2>
                    3:4
                </h2>
            </div>
            <div >
                <span>
                    Night Shift
                </span> <br>
                <h2>
                    3:4
                </h2>
            </div>
        </div>
        <div align="center" class="pb-2" ><span style="color:#FF0C46">5 task having mutiple cycles</span></div>
    </v-card>
    
</v-col>
<v-col class="pr-7"  cols="5">
    <v-card height="100%" flat class="cardimage">
            <v-row class="pl-7 pr-7">
                <v-col cols="12" >
                    <v-row>
            <v-col class="pa-7" v-for="test in productivityTotals" :key="test"
              >{{test.name}}
              <v-col class="pa-0 pt-2">
                <h2>
                  {{test.hours}}<span class="DashbordCartText">&nbsp;Hours</span>
                </h2>
              </v-col>
            </v-col>
          </v-row>
                </v-col>
                </v-row>
        </v-card>
</v-col>
</v-row>
</template>

<script>
export default {
    data(){
        return{
            productivityTotals:[
                {
                    name:'IAL',
                    hours:30
                },
                {
                    name:'Hidary',
                    hours:30
                },
                {
                    name:'IAL',
                    hours:30
                },
                {
                    name:'IAL',
                    hours:30
                },
                {
                    name:'IAL',
                    hours:30
                },
                {
                    name:'IAL',
                    hours:30
                },
            ],
            dashBoardCardData: [
{
  name: "Productivity",
  hour: 0,
  code: "production",
  bgcolor: "#F8ECCE",
  src: require("../../assets/image/clock.svg"),
},
{
  name: "Meeting",
  hour: 0,
  code: "meeting",
  bgcolor: "#D7EAF4",
  src: require("@/assets/image/command.svg"),
},
{
  name: "Break",
  code: "break",
  hour: 0,
  bgcolor: "#F9E3F5",
  src: require("@/assets/image/cupcake.svg"),
},
{
  name: "Others",
  code: "others",
  hour: 0,
  bgcolor: "#F8F6CE",
  src: require("@/assets/image/color.svg"),
},
],
               
        }
    }

}
</script>


<style>
.cardimage{
            background-image:url("../../assets/image/backgroundimg.svg");
            background-repeat:no-repeat;
            /* padding-top: 10px; */
        }
</style>