import ApiService from '../../service/apiServiceMasterWs.js';
const querystring = require('querystring')
var WorkSpaceService = {

    findAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "workspaces"
            $options.method = "get"

            if ($data) {
                $options.url = "workspaces?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    getWorkSpaceByUser: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getWorkSpaceByUser"
            $options.method = "get"

            if ($data) {
                $options.url = "getWorkSpaceByUser?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    getAccessNextAction: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getAccessNextAction"
            $options.method = "get"

            if ($data) {
                $options.url = "getAccessNextAction?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    getMySpace: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getMySpace"
            $options.method = "get"

            if ($data) {
                $options.url = "getMySpace?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getIssuesForWorkspace: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "showWorkSpaces"
            $options.method = "get"

            if ($data) {
                $options.url = "showWorkSpaces?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
  
    findOne: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "workspaces/" + $data
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    update: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "workspaces/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "workspaces" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    create: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "workspaces";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    getProjects:function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "projectsOnWorkspace"
            $options.method = "get"

            if ($data) {
                $options.url = "projectsOnWorkspace?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getAccounts:function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "accountsOnWorkspace"
            $options.method = "get"

            if ($data) {
                $options.url = "accountsOnWorkspace?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });
    }

};
export default WorkSpaceService;