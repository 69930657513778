<template>
    <v-autocomplete density="compact"  @update:modelValue="changeState" v-model="state"  item-value="id" item-title="name"  variant="outlined" :items="stateData" :placeholder="''"></v-autocomplete>
</template>
<script>
import stateList from '../../api/service/states.json'
import lodash from 'lodash';

export default {
    name: "StateList",
    props:['country','stateId'],
    data() {
        return {
            selectedId:null,
            state:null,
            stateName:null,
            stateData: [],
            $stateList:stateList
        }

    },
    watch:{
        stateId(){
            if(this.stateId){
                this.state = this.stateId
                this.selectedId =this.stateId
            }
        }
    },
    methods:{

        getFilterData(code){
           let list = stateList
           console.log(list,'statelistr34jhnrjn')
            this.stateData =lodash.get(stateList,code)

            // this.stateName = this.stateData[0].name
            // console.log(this.stateName,'finaldatab3rbr3brb')

            this.selectedId =this.stateId
            this.state = this.stateId
        },
        changeState($value){
            this.selectedId = $value;
            this.state = null;
            // this.selectedStateName = this.stateData.find((state) => state.id === $value)?.name;
            // this.$emit('update:modelValue', $value);
            // console.log(this.selectedStateName,"nameeeeeeeeee")
        }
    },
    
}

</script>
<style scoped>
>>>.v-field__outline {
    --v-field-border-width: 0px !important;
    border: 10px !important;
    --v-field-border-opacity: 0.38;
    align-items: stretch;
    contain: layout;
    display: flex;
    /* background-color:#ECECEC; */
    /* height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    border: 0px solid #D7D6D6 !important;
    border-radius: 8px !important; */
}

>>>.v-field {
    cursor: text;
    background: #ECECEC !important;
    font-size: 14px;
    color: #777777;
    border-radius: 8px;
}

>>>.v-field:focus-within {
    cursor: text;
    background-color: #ffffff !important;
    border: 2px solid #3C5AAA !important;
    border-radius: 8px;
}
</style>