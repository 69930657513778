<template>
  <v-menu :activator="activateMenu" :close-on-content-click="false">
    <v-list max-height="350px" style="width: 100% !important" :style="type == 'sort' ? 'min-width: 170px;' : ''">
      <v-list-item v-for="(item, index) in options" :key="index" :value="index" @click="action(index, item)">
        <template v-if="showAvatar" v-slot:prepend>
          <!-- <CustomAvatar class="ml-1 mr-1" :size="'small'" :userId="item.id" /> -->
          <CustomAvatar2 class="ma-0 mb-2" :size="'medium'" :fName="item.first_name ? item.first_name : item.first_Name"
            :lName="item.last_name" :imgUrl="item.image_profile_id ? item.image_profile_id : null" :theme="item.theme">
          </CustomAvatar2>
        </template>
        <v-list-item-title class="ml-3 mt-1" style="display: flex;gap: 10px;">
          <v-checkbox v-if="type == 'sort'" @click="changeSort(item)"></v-checkbox>
          <div style="display: flex;align-items: center;">
            {{ item.title ? item.title : item.display_value ? item.display_value : item.name }}
          </div>
          <v-list-item-subtitle  v-if="item.subtitle && type !='follower'">
            {{ item.subtitle }}
          </v-list-item-subtitle>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import moment from "moment-timezone";
import UserService from "@/api/service/UserService";
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
export default {
  props: ["activator", "type", "options", "showAvatar",],
  components: {
    CustomAvatar2,
  },
  data() {
    return {
      activeIndex: 0,
      sortValue:[],
      activateMenu: null,
      selected: false,
      userDetails: JSON.parse(localStorage.getItem("user_details"))
    };
  },
  watch: {
    activator() {
      this.activateMenu = this.activator;
    },
  },
  created() {
    this.activateMenu = this.activator;
  },
  methods: {
    moment,
    changeSort(data){
      const indexToRemove = this.sortValue.findIndex(
        (item) => item.value === data.value
      );
      if (indexToRemove > -1) {
        this.sortValue.splice(indexToRemove, 1);
      } else {
        this.sortValue.push(data);
      }
      this.$emit('sortData' , this.sortValue)
    },
    async setLogoutAction() {
      try {
        let $session = await UserService.findSessions({ "sessionId": localStorage.getItem("sessionId") });
        if ($session) {
          //alert(moment())
          //alert(moment($session[0].proposed_end_date).to
          let $d1 = moment(new Date()).tz('UTC')
          let $d2 = moment(new Date($session[0].proposed_end_date)).tz('UTC')



          let $balance = $d1.diff($d2, 'seconds')
          // alert($balance)
          //moment().diff(moment(new Date($session[0].proposed_end_date).toLocaleString()),'hours')
          if ($balance < 60) {
            this.$store.commit('SET_SHIFT_IN_DUE', false)
          } else {
            this.$store.commit('SET_SHIFT_IN_DUE', true)
          }
          this.$store.commit('SET_LOGOUT_ACTION', true)
        }
      }
      catch (e) {
        this.$store.commit('SET_SHIFT_IN_DUE', false)
        this.$store.commit('SET_LOGOUT_ACTION', true)

      }
    },
    async action(index, item) {

      if (item.action) {

        if (item.title && item.title.toLowerCase() == 'logout') {
          await this.setLogoutAction()
          /*  let $sessions = await UserService.findSessions({user_id:this.userDetails.id,end_date:'null'});
            let $val = {};
            if($sessions && $sessions.length>0){
              $val = $sessions[0];
            }else{
              $val= JSON.parse(localStorage.getItem("USER_SESSIONS"));
            }
           
            let $expires = moment().diff(moment($val.proposed_end_date), 'seconds') > 0;
          
            if($expires){
              this.$store.commit("SET_OT_DIALOGUE", true);
              this.$store.commit("SET_NORMAL", false);
            }else{
              this.$store.commit("SET_NORMAL", true);
              this.$store.commit("SET_OT_DIALOGUE", false);
            } */
        } else {
          // this.$router.push(item.action)
          this.$router.push({
            path: item.action,
            query: item.query,
          });
        }
      } else {
        this.activeIndex = index;
      }
      if(this.type!='sort'){
      this.$emit("menu-selected", item);
      }
      /*if (index == 1 && item.title == "Logout") {
            localStorage.removeItem("TOKEN");
            localStorage.removeItem("user_details");
            window.location = "http://" + this.host + "/login";
        } else if (index == 0 && item.title == "User Profile") {
            this.$router.push("/userprofile");
        }*/
    },
    getAvatarName(value) {
      let name =
        value && value.first_name
          ? value.first_name.charAt(0)
          : "" + value && value.last_name
            ? value.last_name.charAt(0)
            : "";
      return name;
    },
  },
};
</script>
<style scoped>
>>>.v-input__details {
  align-items: flex-end;
  display: flex;
  font-size: 0.75rem;
  font-weight: 400;
  grid-area: messages;
  letter-spacing: 0.0333333333em;
  line-height: normal;
  min-height: 22px;
  padding-top: 6px;
  overflow: hidden;
  justify-content: space-between;
  display: contents;
}

>>>.v-checkbox .v-selection-control {
  min-height: 0;
}

>>>.v-selection-control__input input {
  cursor: pointer;
  width: 100%;
  opacity: 0;
}

>>>.v-input {
  display: grid;
  flex: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  --v-input-chips-margin-top: 2px;
  width: 35px;
}

>>>.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
  padding-inline-start: 5px;
  padding-inline-end: 5px;
}

.image {
  width: 35px;
  height: 35px;
}

>>>::-webkit-scrollbar {
  width: 6px;
  /* height: 6px; */
}

/* Track */
>>>::-webkit-scrollbar-track {
  background: #e8f3f9;
}

/* Handle */
>>>::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
}</style>