<template>
  <CreateModule :module="moduleData" :activity="activityData" :activityGroup="activityGroupData"
  @save="save()" @close="close()" />
</template>


<script>
import { CreateModule } from '@tiburadev/vue-comp'
import ModuleService from '@/api/service/Module/ModuleService.js';
import ActivityService from '@/api/service/Activity/ActivityService.js'
import ActivityGroupService from '@/api/service/ActivityGroup/ActivityGroupService.js'

export default{
    components:{
        CreateModule,
    },
    data(){
        return{
          moduleData:[],
          activityData:[],
          activityGroupData:[]
        }
    },
    created(){
      this.getModule()
      this.getActivity()
      this.getActivityGroup()
    },
    methods:{
         async getModule(){
             let res= await ModuleService.findAll()
             this.moduleData=res
             console.log(this.moduleData,'checkk')
         },
         async getActivity(){
            let res= await ActivityService.findAll()
            this.activityData=res
            console.log(this.activityData,'check111')
         },
         async getActivityGroup(){
            let res= await ActivityGroupService.findAll()
            this.activityGroupData=res
         },
         save(){
          alert('save the Data')
         },
         close(){
          alert('Go back Home')
         }

    }
}
</script>
<style scoped>
</style>