<template>
     <v-container fluid class="pa-0 ma-0">
   
      <div class="grid-container" v-for="rowData in layout" v-bind:key="rowData"
      :style="getColumnLayout(rowData)">
      <!-- {{ getColumnLayout(rowData) }} -->
        <div  v-for="columnData  in rowData.columns" v-bind:key="columnData" class="grid-item" :class="{'mt-10': columnData.id == '2'}"
        style="display:flex"
            :style="getColumnStyle(columnData)">
            {{ columnData.id }}
            <!-- {{ rowData }} -->
            <Board v-if="columnData.widget" :config="columnData.widget" />
            &nbsp;

      </div>

    </div>
    </v-container>
</template>
<script>
import Board from '../dashboard/Widgets/Board.vue'
// import LayoutDesigner from './Children.vue'
export default {
   components:{
    Board
    // LayoutDesigner
   },
    props:["layout"],
    data() {
        return {
            // layouts:{
            //     "rows":[
            //         {
            //             "height":"85vh",
            //             "columns":[
            //               {
            //                 "width":"300px",
                        
            //               },
            //               {
            //                 "width":"auto",
            //                 "rows":[
            //                     {
            //                         "height":"55vh",
            //                         "columns":[
            //                             {
            //                                 "width":"650px"
            //                             }
            //                         ]
            //                     },
            //                     {
            //                         "height":"55vh",
            //                         "columns":[
            //                             {
            //                                 "width":"650px"
            //                             }
            //                         ]
            //                     }
            //                 ]
                            
            //               },
            //               {
            //                 "width":"300px"
            //               }

            //             ]
            //         }
            //     ]
            // }
        }
    },
    created() {
        console.log(this.layout ,'layoutFor child')
        // if(this.layout) {
        //     this.layouts.rows = this.layout
        // }
    },
    methods:{
        createColumn($row) {
            alert('second')
            $row.columns.push({"width":"50vw"})
        },
        getColumnLayout($column) {
            let $gridColumns = 'grid-template-columns: '
          
            for(let column of $column.columns) {
                console.log(column)
                $gridColumns = $gridColumns+ ` ${column.width} `
            }
                $gridColumns =$gridColumns+`; height: ${$column.height}`;

            return $gridColumns;

        },
        getColumnStyle($column) {
            return `width:${$column.width} important!`
        }
    }
}
</script>
<style >
.grid-container {
  display: grid;
  padding: 1px;
  height:85vh;
}
.grid-item {
    resize: both;
  /* background-color: rgba(255, 255, 255, 0.8);
  border: 1px dashed rgba(0, 0, 0, 0.8); */
  padding: 5px;
  /* text-align: center; */
  overflow: auto;
}
</style>