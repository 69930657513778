<template>
  <v-card flat class="pa-3" min-height="90vh">
    <v-card-title> Projects </v-card-title>
    <v-card-text>
      <v-row no-gutters dense class="rowheader pa-2">
        <v-col cols="3" class="text-left"> Name </v-col>
        <v-col cols="1" class="text-center"> Total </v-col>
        <v-col cols="1" class="text-center"> Open </v-col>
        <v-col cols="1" class="text-center"> Past due </v-col>
        <v-col cols="1" class="text-center"> Critcal </v-col>
        <v-col cols="1" class="text-center"> CFI </v-col>
        <v-col cols="1" class="text-center"> In Progress </v-col>
        <v-col cols="1" class="text-center"> Week end Mails </v-col>

        <v-col cols="2" class="text-left"> Last created </v-col>
      </v-row>
      <div class="ifNoAccount" v-if="projects && projects.length === 0">
        <h3>No project available in selected WorkSpace</h3>
      </div>
      <v-row v-else dense v-for="project in projects" :key="project">
        <v-col cols="3" class="text-left">
          <span class="link" @click="getLink(project)">
            <b>{{ project.code }}</b> - {{ project.name }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToIssueListPage(project, 'total')">
            {{ project.no_of_issues }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToIssueListPage(project, 'open')">
            {{ project.open_issue }}
          </span>
        </v-col>

        <v-col cols="1" class="text-center">
          <span class="link" @click="goToIssueListPage(project, 'pastDue')">
            {{ project.past_due }}
          </span>
        </v-col>

        <v-col cols="1" class="text-center">
          <span class="link" @click="goToIssueListPage(project, 'critical')">
            {{ project.critcal_issues }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToIssueListPage(project, 'cfi')">
            {{ project.cfi }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToIssueListPage(project, 'inprogress')">
            {{ project.in_progress }}
          </span>
        </v-col>
        <v-col cols="1" class="text-center">
          <span class="link" @click="goToIssueListPage(project, 'weekEnd')">
            {{ project.week_end_emails }}
          </span>
        </v-col>
        <v-col cols="2" class="text-left date">
          {{
            project.last_updated ? moment(project.last_updated).format("MM/DD/YY") : ""
          }}

          {{ project.last_updated ? moment(project.last_updated).fromNow() : "" }}
        </v-col>
        <v-divider />
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      workSpaceId: 1,
      accountId: 1,
      workspace: {},
      projects: [],
      columns: [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "id",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "name",
          sortfield: true,
        },
        {
          field: "Open",
          header: "Open Issues",
          style: { width: "100px", color: "" },
          toolTip: "Open",
          sortfield: true,
        },
        {
          field: "updated",
          header: "Last Updated",
          style: { width: "100px", color: "" },
          toolTip: "Last Updated",
          sortfield: true,
        },
      ],
    };
  },
  watch: {
    getRefresh() {
      if (this.getRefresh) {
        // await this.getStart()
        this.getProjects();
      } else {
        // await this.getStart()
        this.getProjects();
      }
    },
  },
  computed: {
    getRefresh() {
      return this.$store.getters.getMyspace;
    },
  },
  async created() {
    this.getProjects();
  },
  mounted() {},
  methods: {
    moment,

    getLink($project) {
      let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
      $wsDefaults = JSON.parse($wsDefaults);
      $wsDefaults.project = { id: $project.id, name: $project.name };
      localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults));
      // localStorage.setItem("_WS_DEFAULTS_",JSON.stringify($wsDefaults))
      this.$store.commit("SET_REFRESH_BREADCRUMP", true);
      // let url = localStorage.getItem('_WS_REDIRECT_')
      this.$router.push("/showAccountForWorkspaces");
    },
    goToIssueListPage($project, type) {
      let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
      $wsDefaults = JSON.parse($wsDefaults);
      if ($project.id !== 0) {
        $wsDefaults.project = { id: $project.id, name: $project.name };
        $wsDefaults.account = { id: 0, name: "All Accounts" };
        localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults));
        if (type === "open") {
          this.$router.push("/issueconsolidated?stage=open");
        } else if (type === "critical") {
          this.$router.push("/issueconsolidated?priority=critical");
        } else if (type === "pastDue") {
          this.$router.push("/issueconsolidated?pastdue=true");
        } else if (type === "total") {
          this.$router.push("/issueconsolidated");
        } else if (type === "cfi") {
          this.$router.push("/issueconsolidated?cfi=true");
        } else if (type === "inprogress") {
          this.$router.push("/issueconsolidated?stage=inprogress");
        } else {
          this.$router.push("/issueconsolidated?weekEndEmail=true");
        }
      } else {
        $wsDefaults.project = { id: $project.id, name: $project.name };
        $wsDefaults.account = { id: 0, name: "All Projects" };
        localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults));
        if (type === "open") {
          this.$router.push("/issueconsolidated?stage=open");
        } else if (type === "critical") {
          this.$router.push("/issueconsolidated?priority=critical");
        } else if (type === "pastDue") {
          this.$router.push("/issueconsolidated?pastdue=true");
        } else if (type === "total") {
          this.$router.push("/issueconsolidated");
        } else if (type === "cfi") {
          this.$router.push("/issueconsolidated?cfi=true");
        } else if (type === "inprogress") {
          this.$router.push("/issueconsolidated?stage=inprogress");
        } else {
          this.$router.push("/issueconsolidated?weekEndEmail=true");
        }
      }
    },
    getProjects() {
      this.$store.commit("SET_REFRESH_BREADCRUMP", false);
      let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
      $wsDefaults = JSON.parse($wsDefaults);
      this.workSpaceId = $wsDefaults.workspace.id;
      // this.accountId = $wsDefaults.account.id
      delete $wsDefaults.account;
      delete $wsDefaults.project;
      delete $wsDefaults.category;
      delete $wsDefaults.issue;
      localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults));

      this.$store.commit("SET_REFRESH_BREADCRUMP", true);
      WorkSpaceService.getProjects({ workSpaceId: this.workSpaceId })
        // .then(($projects) => {
        //   this.workspace = $projects.workspace;
        //   this.projects = $projects.projects;
        // })
        .then(($projects) => {
          this.workspace = $projects.workspace;
          this.projects = $projects.projects;

          // Calculate aggregate statistics across all projects
          const totalIssues = this.projects.reduce(
            (total, project) => total + project.no_of_issues,
            0
          );
          const totalOpenIssues = this.projects.reduce(
            (total, project) => total + project.open_issue,
            0
          );
          const totalPastDue = this.projects.reduce(
            (total, project) => total + project.past_due,
            0
          );
          const totalCritical = this.projects.reduce(
            (total, project) => total + project.critcal_issues,
            0
          );
          const totalCFI = this.projects.reduce(
            (total, project) => total + project.cfi,
            0
          );
          const totalWeekendemail = this.projects.reduce(
            (total, project) => total + project.week_end_emails,
            0
          );
          const totalInprogress = this.projects.reduce(
            (total, project) => total + project.in_progress,
            0
          );

          // Create an object representing "All Projects" with aggregate statistics
          const allProjects = {
            id: 0,
            code: "All",
            name: "All Projects",
            no_of_issues: totalIssues,
            open_issue: totalOpenIssues,
            past_due: totalPastDue,
            critcal_issues: totalCritical,
            cfi: totalCFI,
            week_end_emails: totalWeekendemail,
            in_progress: totalInprogress,
          };

          // Add "All Projects" entry to the beginning of the projects array
          this.projects.unshift(allProjects);

          console.log(this.projects, "catchh");
        });
    },
  },
};
</script>
<style>
.rowheader {
  background-color: #d7eaf4;
  font-weight: 600;
}

.link {
  color: #000;
  text-decoration: none;
}

.link:hover {
  color: #000;
  text-decoration: underline;
  cursor: pointer;
}

.open-issues {
  background-color: #e2d6f8;
  color: #272727;
  font-weight: 10px;
  font-weight: 400;
}

.date {
  color: orange;
}
</style>
