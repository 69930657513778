<template>
	<table fixed-header class="table">
		<thead>
			<tr class="mainHead">
				<th class="heads" v-for="header in headers" :key="header.key">
					{{ header.label }}
				</th>
			</tr>
		</thead>
		<tbody>
			<template v-for="(workspace, workspaceIndex) in tableData" :key="workspaceIndex">
				<tr>
					<th :colspan="headers.length + 1" style="text-align:justify">
						<div class="my-3">
							<span class="headd">
								{{ workspace.workSpaceName }}
							</span>
						</div>
					</th>
				</tr>
				<template v-for="(account, accountIndex) in workspace.accountData" :key="`account-${accountIndex}`">
					<!-- {{ account }} -->
					<tr class="bodyRow" :style="{ fontWeight: expandedRows.includes(accountIndex) && workspaceIndex == selectedWorkSpace ? 'bold' : 'normal' }">
						<td >
							{{ account.accountName }}
						</td>
						<td v-for="header in headers2" :key="header.key">
							<!-- {{ header.key }} -->
							<span>{{ account[header.key] }}</span>
						</td>
						<v-icon v-if="expandedRows.includes(accountIndex) && workspaceIndex == selectedWorkSpace"
							@click="toggleDetails(accountIndex, workspaceIndex)"
							style="position: absolute;right: 40px;top: 15px;">mdi-chevron-up-circle-outline</v-icon>
						<v-icon v-else
							@click="toggleDetails(accountIndex, workspaceIndex)"
							style="position: absolute;right: 40px;top: 15px;">mdi-chevron-down-circle-outline</v-icon>
					</tr>

					<template v-if="expandedRows.includes(accountIndex) && workspaceIndex == selectedWorkSpace">
						<tr class="bodyRow extraRow" v-for="(account, index) in account.userData" :key="index">
							<td v-for="header in exheaders" :key="header.key" :colspan="header.key === 'user' ? 2 : 1">
                                <span v-if="header.label === 'User'" :style="{ fontWeight: account[header.key] ? 'normal' : 'bold', color: account[header.key] ? 'black' : 'red' }">
                                    {{ account[header.key] ? account[header.key] : 'Un Assigned' }}
                                </span>
								<span v-else>{{ account[header.key] }}</span>
							</td>
						</tr>
					</template>
				</template>


			</template>
		</tbody>
	</table>
</template>

<script>
// import ProductionDashboardService from "@/api/service/ProductionDashboard/ProductionDashboard";
export default {
	props: ['tableData', 'allData'],
	data() {
		return {
			selectedWorkSpace: '',
			expandedRows: [],
			expandedData: [],
			headers: [
				{ key: 'account_name', label: 'Account' },
				{ key: 'total_issues', label: 'Total' },
				{ key: 'open_issues', label: 'Open' },
				{ key: 'assigned_issues', label: 'Assigned' },
				{ key: 'inprogress_issues', label: 'In Progress' },
				{ key: 'qapending_issues', label: 'QA Pending' },
				{ key: 'clarification_issues', label: 'Clarification' },
				{ key: 'hold_issues', label: 'Hold' },
				{ key: 'it_issues', label: 'IT' },
				{ key: 'pastdue_issues', label: 'Past Due' },
				// { key: 'user', label: 'User' },
				{ key: 'completed_issues', label: 'Closed/Completed Today' },
			],

			headers2: [
				{ key: 'total_issues', label: 'Total' },
				{ key: 'open_issues', label: 'Open' },
				{ key: 'assigned_issues', label: 'Assigned' },
				{ key: 'inprogress_issues', label: 'In Progress' },
				{ key: 'qapending_issues', label: 'QA Pending' },
				{ key: 'clarification_issues', label: 'Clarification' },
				{ key: 'hold_issues', label: 'Hold' },
				{ key: 'it_issues', label: 'IT' },
				{ key: 'pastdue_issues', label: 'Past Due' },
				// { key: 'user', label: 'User' },
				{ key: 'completed_issues', label: 'Closed/Completed Today' },
			],
			exheaders: [
				{ key: 'user_name', label: 'User' },
				{ key: 'total_issues', label: 'Total' },
				{ key: 'open_issues', label: 'Open' },
				{ key: 'assigned_issues', label: 'Assigned' },
				{ key: 'inprogress_issues', label: 'In Progress' },
				{ key: 'qapending_issues', label: 'QA Pending' },
				{ key: 'clarification_issues', label: 'Clarification' },
				{ key: 'hold_issues', label: 'Hold' },
				{ key: 'it_issues', label: 'IT' },
				{ key: 'pastdue_issues', label: 'Past Due' },
				{ key: 'completed_issues', label: 'Closed/Completed Today' },
			],
		};
	},

	methods: {
		async toggleDetails(accountIndex, workspaceIndex) {
			if(this.expandedRows.includes(accountIndex)){
				let index = this.expandedRows.findIndex(k=>k == accountIndex)
                this.expandedRows.splice(index,1)
			}
			else{
				this.expandedRows.push(accountIndex)
				this.selectedWorkSpace = workspaceIndex
			}
		},
		combineAt(n, a, b) {
			return a.reduce((ac, cv, ci) => {
				if (n === ci) {
					// Insert elements from array 'b' at position 'n'
					ac.push(...b, cv);
				} else {
					// Insert element from array 'a'
					ac.push(cv);
				}
				return ac;
			}, []);
		},

		merge(a1, a2, i) {
			return [...a1.slice(0, i), ...a2, ...a1.slice(i)]
		}

	}

};
</script>


<style scoped>
.table {
	border-spacing: 0 5px;
	width: 100%;
	overflow: auto;
    padding-bottom: 5%;
}

thead {
	position: sticky;
	top: 0;
	/* background: #fff; */
	z-index: 1;
}

thead>tr {
	height: 40px;
}

thead>tr>.heads {
	border-bottom: 2px solid #0cb99a;
	border-top: 2px solid #0cb99a;
}

thead>tr>.heads:nth-child(1) {
	border-left: 2px solid #0cb99a;
	border-radius: 4px 0 0 4px;
}

thead>tr>.heads:last-child {
	border-right: 2px solid #0cb99a;
	border-radius: 0 4px 4px 0;
}

tbody>tr>td:nth-child(1) {
	border-radius: 4px 0 0 4px;
}

tbody>tr>td:last-child {
	border-radius: 0 4px 4px 0;
}


tbody>tr {
	padding: 50px 0;
	height: 50px;
	text-align: center;
}

.mainHead {
	background-color: #edf5f4;
	border: 2px solid #0cb99a;
	border-radius: 4px;
	font-weight: 700;
	font-size: 12px;
	padding: 4px 15px;
	text-align: center;
}

.headd {
	background-color: #fdf4f4;
	border: 1.5px solid #ffa1a1;
	padding: 4px 10px;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 600;
	text-align: left;
}

.bodyRow {
	position: relative;
	background-color: #edf5f4;
	padding: 15px 10px;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 400;
}

.extraRow {
	background-color: #FEF7E5;
}
</style>
