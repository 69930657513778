const IssueWidgetWidgetTypes = [
    {
        "title":"Team Board",
        "code":{"widgettype":"teamboard","type":"issue","group1":"user","group2":"stage"},
        "subTitle":"Board",
        "description":"Track status by Widget grouped by Resource/Stage for the given workspace "
    },
    {
        "title":"Account Board",
        "code":{"widgettype":"accountboard","type":"issue","group1":"project","group2":"account"},
        "subTitle":"Board",
        "description":"Know the status of your account grouped by workspace/account/stage"
    },
    {
        "title":"Stage Board",
        "code":{"widgettype":"stageBoard","type":"issue","group1":"stage","group2":"user"},
        "subTitle":"Board",
        "description":"Know the status of your account grouped by stage/user"
    },
    {
        "title":"Overdue Board",
        "code":{"widgettype":"overdue","type":"issue","group1":"project","group2":"due_on"},
        "subTitle":"Board",
        "description":"Get yourself updated with issues that are over due date"
    },
    {
        "title":"Watch List",
        "code":{"widgettype":"watch","type":"issue","group1":"account","group2":"category"},
        "subTitle":"Board",
        "description":"Keep track of the issues that are currently in watch list"
    },
    {
        "title":"Assigned to me",
        "code":{"widgettype":"myboard","type":"issue","group1":"account","group2":"stage"},
        "subTitle":"Board",
        "description":"All issues that are assigned to me"
    },
    {
        "title":"QA Board",
        "code":{"widgettype":"qa","type":"issue","group1":"account","group2":"subcategory"},
        "subTitle":"Board",
        "description":"QA Task that are pending"
    },
    {
        "title":"Availability Board",
        "code":{"widgettype":"availability","group1":"workspace","group2":"user"},
        "subTitle":"Board",
        "description":"Know that status of resources in other workspace"
    },
    {
        "title":"Error Board",
        "code":{"widgettype":"error","group1":"user","account":""},
        "subTitle":"Board",
        "description":"List of errors by Resources/Project/Account"
    }
]
export default IssueWidgetWidgetTypes;