<template>
  <div>
    <v-row class="pt-4 pb-5 pl-3">
      <v-text class="sub-title">
        Attachments
      </v-text>
      <uploadFiles keyType="IS" :keyId="issueId" v-if="stage!='closed' && showEdit" @documentsData="documentUploaded" :type="'icon'" />
    </v-row>

    <v-table fixed-header>
      <thead>
        <tr>
          <th class="text-left" style="background-color: rgba(224, 243, 253)">
            Name
          </th>
          <th class="text-left" style="background-color: rgba(224, 243, 253)">
            User
          </th>
          <th class="text-left" style="background-color: rgba(224, 243, 253)">
            Date
          </th>
          <th class="text-left" style="background-color: rgba(224, 243, 253)">
            Download
          </th>
        </tr>
      </thead>
      <tbody>

        <template v-if="documents && documents.length > 0">
          <tr v-for="item in documents" :key="item.name">
            <td>{{ item && item.doc_name ? item.doc_name : "" }}</td>
            <td>{{ item && item.users && item.users.name ? item.users.name : "" }}</td>
            <td>
              {{ item && item.created ? getTheDate(item.created) : "" }}
            </td>
            <td>
              <i class="pi pi-download download-icon" @click="downloadDoc(item)"></i>
            </td>
          </tr>
        </template>

        <template v-else>
          <tr>
            <td colspan="4" class="text-center">There is no data</td>
          </tr>
        </template>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import documentService from "@/api/service/Document/DocumentService";
//import IssueService from "@/api/service/Issue/IssueService";
import uploadFiles from "@/components/uploadFiles.vue";
export default {
  props: ['issueId', "refreshAmt",'stage',"showEdit"],
  watch: {
    
    issueId() {
      // alert(this.issueid)
      this.getDocuments()
    },
    refreshAmt() {
      if (this.refreshAmt) {
        this.getDocuments();
      }
      else {

        this.getDocuments();
      }
    }
  },
  components: {
    uploadFiles,
  },

  data() {
    return {
      documents: []
    }
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    getTheDate(date) {
      let $date = new Date(date);
      let formattedDate = $date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      return formattedDate;
    },
    downloadDoc(item) {
      documentService.download({ id: item.id }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("name", "downloadLink");
        link.setAttribute("download", response.headers["x-suggested-filename"]); //or any other extension
        document.body.appendChild(link);
        link.click();
      });


    },
    documentUploaded($data) {
      console.log($data)
      this.getDocuments()
    },

    async getDocuments() {
      // alert(this.issueId)
      documentService.findAll({
        key_type: "IS",
        key_id: this.issueId,
      }).then($documents => {
        this.documents = $documents;
      }).catch(error => {
        this.documents = [];
        console.log(error);
      })
    }
  }
}
</script>


<style scoped>
.padding-table-columns td {
  padding: 0 30px 0 0;
}

.file-input-container .v-input__slot {
  height: 40px;
  /* Adjust the height value as per your requirements */
}


>>>.pi-download:before:hover {
  content: "\e956";
  background-color: aliceblue;
  padding: 41%;
  border-radius: 13%;
}

.view-all {
  text-align: center;
  background: #dddbdb70;
  font-weight: bold;
  color: #4f63ff;
  border-radius: 7px;
  cursor: pointer;
}

.download-icon {
  font-size: 1rem;
  /* margin: 1%; */
  padding: 4%;
  border-radius: 3px;
  cursor: pointer;
}

.download-icon:hover {
  font-size: 1rem;
  background-color: aliceblue;
  border-radius: 3px;
  width: 30px;
  height: 30px;
}

.sub-title {
  font-weight: 600;
  font-size: 15px;
  color: #8e8a8a;
}


>>>.v-table {
  --v-table-header-height: 35px;
  border-radius: inherit;
  line-height: 1.5;
  max-width: 100%;
}

>>>.v-table.v-table--fixed-header>.v-table__wrapper>table>thead>tr>th {
  background: rgb(var(--v-theme-surface));
  box-shadow: inset 0 -1px 0 rgba(var(--v-border-color), var(--v-border-opacity));
  z-index: 1;
  font-size: 15px;
  font-weight: bold;
}

>>>.v-table__wrapper {
  border-radius: inherit;
  overflow: auto;
  max-height: 150px !important;
}

>>>::-webkit-scrollbar {
  width: 6px;
  /* height: 6px; */
}

/* Track */
>>>::-webkit-scrollbar-track {
  background: #e8f3f9;
}

/* Handle */
>>>::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
}
</style>