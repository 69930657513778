<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b>ScoreCards</b>
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="6">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Code" :rules="codeRule" v-model="formData.code"
              :disabled="showReadOnly" @keyup="checkCode()" @keydown="prevent($event)" />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>

          <v-col cols="6" class="pl-3">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Name" :rules="nameRule" v-model="formData.name"
              :disabled="showReadOnly" />
          </v-col>

          <v-col cols="6" class=" ">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Data type
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <EnumAutoCompleteVue density="compact" v-model="formData.data_type" :selected="formData.data_type"
              :showReadOnly="viewType == 'add' || this.viewType == 'view'" :domain="'DATA-TYPE'" placeholder="Datatype"
              @changeEnumData="changeDatatype($event)" :rules="dataTypeRule" />
          </v-col>

          <v-col cols="6" class="pl-3 ">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Field type
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <EnumAutoCompleteVue density="compact" v-model="formData.field_type" :selected="formData.field_type"
              :showReadOnly="formData.data_type === 'date' || formData.data_type === 'datetime' || this.viewType == 'view'"
              :domain="'FIELD-TYPE'" placeholder="FieldType" @changeEnumData="changeFiedtype($event)"
              :rules="fieldTypeRule" />
          </v-col>

          <v-col cols="12" class=" " v-if="showSource">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Source
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <EnumAutoCompleteVue density="compact" v-model="formData.source_type" :selected="formData.source_type"
              :showReadOnly="viewType == 'add' || this.viewType == 'view'" :domain="'SOURCE-TYPE'" placeholder="Source"
              @changeEnumData="changeSource($event)" :rules="sourceRule" />
          </v-col>

          <v-col cols="6" v-if="showModel">
            <v-label class="pb-1" style="font-size:14px:color:#272727;font-weight:600">Model
              <span style="color:#CF0707;padding-left:2px;font-weight:700">*</span>
            </v-label>
            <TextFieldComponent density="compact" placeholder="Model" :disabled="showReadOnly" :rules="modelRule"
              v-model="formData.model" />

            <!-- <AutoComplete :items="listModelData" item-title="value" v-model="formData.model_id" item-value="value"
              :rules="ModelRule" :disabled="showReadOnly" :placeholder="'Model'" /> -->
          </v-col>
          <v-col cols="6" class="pl-3 " v-if="showModel">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Display Value
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Text" :disabled="showReadOnly" :rules="displayValueRule"
              v-model="formData.display_value" />

          </v-col>

          <v-col cols="6" class="" v-if="showModel">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Data Value
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" :disabled="showReadOnly" placeholder="Value" :rules="dataValueRule"
              v-model="formData.data_value" />

          </v-col>

          <v-col cols="6" class="" v-if="showEnum">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Domain
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" :disabled="showReadOnly" placeholder="Domain" :rules="domainRule" @keyup="stageToUpperCase()"
              v-model="formData.enum_domain" />
          </v-col>

          <!-- <v-col cols="6" class="pl-3 " v-if="showEnum">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Display Value
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Display Value" :rules="mandatoryRule" v-model="formData.enum_display_value" />

          </v-col> -->

          <v-col cols="12" class="" v-if="showOption">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Option Value
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Option" :disabled="showReadOnly"
              v-model="formData.options" />
          </v-col>

        </v-row>
      </v-card-text>

      <v-card-actions class="mr-3 mb-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" :loading="saveClicked" type="submit">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import AutoComplete from "@/components/ui/AutoComplete.vue";
import ScoreCardService from "@/api/service/ScoreCard/ScoreCardService.js";
import EnumAutoCompleteVue from "@/components/others/EnumAutoComplete.vue";
// import commonService from "@/common.service";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    EnumAutoCompleteVue
  },

  data() {
    return {
      saveClicked: false,
      showReadOnly: false,
      dialog: false,
      type: null,
      codeError: false,
      showSource: false,
      showModel: false,
      showEnum: false,
      showOption: false,
      formData: {
        code: null,
        name: null,
        data_type: null,
        field_type: null,
        source_type: null,
      },
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      dataTypeRule: [(value) => !!value || "Data Type is Required."],
      fieldTypeRule: [(value) => !!value || "Field Type is Required."],
      sourceRule: [(value) => !!value || "Source is Required."],
      domainRule: [(value) => !!value || "Domian is Required."],
      dataValueRule: [(value) => !!value || "Data value is Required."],
      modelRule: [(value) => !!value || "Model is Required."],
      displayValueRule: [(value) => !!value || "Display value is Required."],
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await ScoreCardService.findOne(id);
      console.log(res ,id, "dksdnnnnnnnnnn")
      this.formData = res[0];
      this.showSource = this.formData.field_type == 'autocomplete' || this.formData.field_type == 'select' ? true : false
      this.showModel = this.formData.source_type == 'model' ? true : false
      this.showEnum = this.formData.source_type == 'enum' ? true : false
      this.showOption = this.formData.source_type == 'options' ? true : false

    },

    stageToUpperCase(){
      this.formData.enum_domain = this.formData.enum_domain.toUpperCase();
    },

    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },

    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        ScoreCardService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);
          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },

    changeDatatype($event) {
      if ($event == 'date' || $event == 'datetime') {
        this.formData.field_type = 'datepicker';
      }
      else {
        this.formData.field_type = null;
        this.formData == { ...this.formData }
      }
      this.formData.data_type = $event;
      this.showEnum = false;
      this.showModel = false;
      this.showSource = false;
      this.showOption = false;
    },

    changeFiedtype($event) {
      this.showSource = $event == 'autocomplete' || $event == 'select' ? true : false
      this.formData.field_type = $event;
    },
    changeSource($event) {
      if ($event == 'model') {
        this.showModel = true
        this.showEnum = false
        this.showOption = false
        this.formData.options = null
        this.formData.enum_domain = null
        this.formData.enum_display_value = null
      }
      else if ($event == 'enum') {
        this.showModel = false
        this.showEnum = true
        this.showOption = false
        this.formData.options = null
        this.formData.model = null
        this.formData.display_value = null
        this.formData.data_value = null
      }
      else if ($event == 'options') {
        this.showModel = false
        this.showEnum = false
        this.showOption = true
        this.formData.options = null
        this.formData.model = null
        this.formData.display_value = null
        this.formData.data_value = null
      }
      else {
        this.showModel = false
        this.showEnum = false
        this.showoptions = true
        this.formData.enum_domain = null
        this.formData.model = null
        this.formData.display_value = null
        this.formData.data_value = null
        this.formData.enum_display_value = null
      }
      this.formData.source_type = $event;
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        if (this.formData.id) {
          this.saveClicked=true
          console.log(this.formData, "check1111");
          await ScoreCardService.update(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          console.log(this.formData, "check1111");
          await ScoreCardService.create(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        }
      }
    },
  },
};
</script>
<style scoped>
>>>.v-btn--icon .v-icon {
  --v-icon-size-multiplier: .7;
}

.x-closeIcon {
  right: 10px;
}

>>>.v-text-field .v-input__details {
  padding-inline-start: 0px;
  padding-inline-end: 16px;
}

/* >>>. .v-messages__message {
    line-height: 12px;
    color: red;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    padding-left: 0px;
    transition-duration: 150ms;
    text-align: left;
    margin-left: 0px;
} */</style>
    