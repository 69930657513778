<template>
    <v-autocomplete density="compact"  
  variant="outlined" :items="countryData"  :placeholder="'Time Zone'" ></v-autocomplete>
</template>
<script>
import country from '../../api/service/country.json'
export default{
    name:"CountryList",
    data() {
        return{
            countryData:country,
        }

    },
    // methods:{
    //     changeTime(){
    //         this.$emit('timeZoneChange',this.timeValue)
    //     }
    // }
}

</script>

<style scoped>
>>>.v-field__outline {
      --v-field-border-width: 0px !important;
    border: 10px !important;
    --v-field-border-opacity: 0.38;
    align-items: stretch;
    contain: layout;
    display: flex; 
    /* background-color:#ECECEC; */
    /* height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    border: 0px solid #D7D6D6 !important;
    border-radius: 8px !important; */
}


>>>.v-input__control {
    display: flex;
    grid-area: control;
    background-color:#ECECEC; 
    border-radius: 8px;
}
>>>.v-field:focus-within {
    cursor: text;
    background-color:#ffffff !important;
    border: 2px solid #3C5AAA !important;
    border-radius: 8px;
}
</style>