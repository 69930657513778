import ApiService  from "@/api/service/apiService"
// import queryString from 'querystring'
export default {
    async getAll(modelName,$filters)  {
        return  ApiService.request({
            "url":$filters ? `${modelName}?filters=${JSON.stringify($filters)}` : modelName,
            method:"get"
        }).then (response => {
            return Promise.resolve(response.data.data)
        }).catch(error => {
            return Promise.reject(error)
        })
    }
}