<template>
  <PrimeDataTable
    :title="'ActivityGroup'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="ActivityGroup"

  />

  <v-dialog persistent v-model="dialog" width="700px">
    <CreateActivityGroup
      @closeDialog="close()"
      @reload="getActivityGroup()"
      :id="editId"
      :viewType="viewType"
    />
  </v-dialog>
</template>
  
  <script>
import CreateActivityGroup from "./CreateActivityGroup.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import ActivityGroupService from "@/api/service/ActivityGroup/ActivityGroupService";
// import commonService from "@/common.service";
export default {
  components: {
    CreateActivityGroup,
    PrimeDataTable,
  },
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      editId: null,
      dialog: false,
      viewType: false,
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getActivityGroup();
  },
  methods: {
    loadColumns() {
      this.columns = [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "name",
          sortfield: true,
        },
        {
          field: "module_groups",
          header: "Module Groups",
          style: { width: "100px", color: "" },
          toolTip: "module_groups",
          sortfield: true,
        },
        {
          field: "module_order",
          header: "Module Order",
          style: { width: "100px", color: "" },
          toolTip: "module_order",
          sortfield: true,
        },
        {
          field: "group_order",
          header: "Group Order",
          style: { width: "100px", color: "" },
          toolTip: "group_order",
          sortfield: true,
        },
        {
          field: "module_icon",
          header: "Module Icon",
          style: { width: "100px", color: "" },
          toolTip: "module_icon",
          sortfield: true,
        },
        {
          field: "group_icon",
          header: "Group Icon",
          style: { width: "100px", color: "" },
          toolTip: "group_icon",
          sortfield: true,
        },
        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view"},
            // { action: "edit" },
          ],
        },
      ];
    },

    close() {
      this.dialog = false;
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
      }
      // if ($event.type == "edit") {
      //   this.dialog = true;
      //   this.editId = $event.data.id;
      //   this.viewType = $event.type;
      // }

      if ($event.type == "select") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = "view";
        
      }
    },
    async getActivityGroup() {
      let res = await ActivityGroupService.findAll();
      this.data = res;
    },
    addDiolog() {
      this.dialog = true;
      this.editId = null;
      this.viewType = false;
    },
  },
};
</script>
  
  
  