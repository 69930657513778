<template>
    <div class="border-round border-1 surface-border p-4 surface-card">
        <div class="flex mb-3">
            <!-- <Skeleton shape="circle" size="4rem" class="mr-2"></Skeleton> -->
            <div>
                <div style="display: flex; justify-content: space-between;">
                    <Skeleton width="5rem" class="mb-2"></Skeleton>
                    <Skeleton width="25rem" class="mb-2"></Skeleton>
                </div>

                <Skeleton width="10rem" class="mb-2"></Skeleton><br>

                <div style="display: flex;">
                    <Skeleton width="5rem" class="mb-2"></Skeleton>
                    <Skeleton width="5rem" class="mb-2 mx-1"></Skeleton>
                    <Skeleton width="5rem" class="mb-2 mx-1"></Skeleton>
                    <Skeleton width="5rem" class="mb-2 mx-1"></Skeleton>
                    <Skeleton width="5rem" class="mb-2 mx-1"></Skeleton>
                    <Skeleton width="5rem" class="mb-2"></Skeleton>
                </div>
            </div><br>
        </div>

        <div style="display: flex; gap: 20px">
            <div style="float: left; width: 74%;">
                <Skeleton width="10rem" class="mb-2"></Skeleton>
                <Skeleton width="100%" height="100px"></Skeleton>
                <br>
                <Skeleton width="10rem" class="mb-2"></Skeleton>
                <Skeleton width="100%" height="100px"></Skeleton>
                <br>
                <Skeleton width="10rem" class="mb-2"></Skeleton>
                <Skeleton width="100%" height="100px"></Skeleton>
                <br>

                <Skeleton width="5rem" class="mb-2"></Skeleton>
                <Skeleton width="100%" height="50px"></Skeleton>
                <!-- <div class="flex justify-content-between mt-3">
                <Skeleton width="4rem" height="2rem"></Skeleton><br>
                <Skeleton width="4rem" height="2rem"></Skeleton>
            </div> -->

            </div>

            <div style="width: 24%;">

                <Skeleton width="100%" height="310px"></Skeleton>
                <br>

                <!-- <div style="background-color: #ffffff;padding: 10px 20px; display:flex ;border-radius: 6px;" width="100%" height="310px">

                    <div style="float: left; width: 20%;">
                        <Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
                    </div>

                    <div style="">
                        <Skeleton width="10rem" class="mb-2"></Skeleton>
                        <Skeleton width="10rem" class="mb-2"></Skeleton>
                    </div>

                </div> -->
                <Skeleton width="100%" height="310px"></Skeleton>
                <br>

                <Skeleton width="100%" height="310px"></Skeleton>
            </div>
        </div>

    </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';
export default {
    components: {
        Skeleton,
    }

}
</script>

<style></style>