<template>
    <span style="padding-top:60px;">
        <v-btn  id="list-activator"  flat   :class="getClass(index)" style="font-size:16px;font-weight:500">
            {{ selectedOption && selectedOption.name?selectedOption.name:"Select Board" }}
            <v-icon   size="small">mdi-arrow-down-drop-circle-outline</v-icon>
        </v-btn>
        <v-menu activator="#list-activator"  open-on-hover>
      <v-list>
        <v-list-item
          v-for="item in dashboards" v-bind:key="item"
          @click="selectItem(item)"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
       
</span>
</template>
<script>
import DashboardWidgetService from '@/api/service/DashboardWidget/DashboardWidgetService'
//import MenuComponent from '@tiburadev/vue-comp'
export default {
    props:["updateList"],
    components:{
  //      MenuComponent
    },
    watch:{
        updateList() {
           if(this.updateList){
           let $item  = Object.assign({},this.updateList)
           this.selectedOption=$item
           if($item && $item.id){
            let index = this.dashboards.findIndex(k=>k.id==$item.id)
            if(index<0){
            this.dashboards.push($item)
            }
           } 
           }
           else{
            this.$forceUpdate()
           }
        }
    },
    data() {
        return {
            selectedOption:"0",
            dashboards:[],
            userData:null,
        }
    },
    async created() {
        let user =localStorage.getItem('user_details')
        this.userData =user?JSON.parse(user):{}
        await this.getDashboard()
        
    },
    methods:{
        getSelectedOption() {
           return this.selectedOption=="0" ? 'Select Board' : this.selectedOption.name
        },
        selectItem($item) {
            this.selectedOption=$item
            localStorage.setItem('selecteDashboard',$item.id)
            this.$emit('dashboard-selected',$item)
        },
        getClass() {
            return this.defaultClass ? this.defaultClass : 'default-selection'
        },
        async getDashboard() {
            this.dashboards = await DashboardWidgetService.getDashboardData()
            // console.log(this.dashboards,"dashboardsyyyyyyrrrrrr")
            
        }
    }
}
</script>
<style scoped>

.default-selection{
    background-color:transparent;
    color:#000;
    border:0px;
    font-size:14px;

}
.active {
    background-color: transparent;
    color:#1258AB;
    border:0px;

}
</style>