<template>
  <div style="height: 84vh">
    <v-form @submit.prevent="save()">
      <v-row class="pt-25">
        <v-col cols="10">
          <v-card-title
            class="v-card__title subheading black--text text-uppercase"
          >
            <span class="mt-16">{{ formData.name }}</span></v-card-title
          >
        </v-col>

        <v-col>
          <v-row justify="end" class="dialogBottom pt-6">
            <v-icon v-if="showEditIcon" class="pr-5 mt-1" size="large" @click="showIcon()">mdi-pencil</v-icon>
            <v-btn v-if="showButton"  class="save" color="indigo-darken-1" size="medium" @click="save()">Save</v-btn>
            <v-icon class="pl-4 mt-1" size="large" @click="close()" >mdi-close</v-icon>
          </v-row>
        </v-col>
        <v-divider></v-divider>
      </v-row>

      <v-row>
        <v-col
          cols="4"
          style="place-content: center; display: grid;"
        >
          <uploadImage
          :showReadOnly="showReadOnly"
            :showEdit="openImage"
            @uploadDocument="uploadImage($event)"
            @removeFile="removeFile"
            :imageId="formData.logo_profile_id"
          :firstName="formData.name"
          />

          <!-- <img src="https://tibura.axodesk.com/img/user.93c099b6.svg" alt="" style="height: 20vh" /> -->
        </v-col>

        <v-col cols="8 " class="mt-5">
          <V-col class="pa-0 col">
            <v-label class="pb-1 lable"
              >Name
              <!-- <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span> -->
            </v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Name"
              :rules="nameRule"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </V-col>

          <V-col class="pa-0 col">
            <v-label class="pb-1 lable">First Line Of Address</v-label>
            <TextFieldComponent
              density="compact"
              placeholder="First Line Of Address"
              v-model="formData.address1"
              :disabled="showReadOnly"
            />
          </V-col>

          <V-col class="pa-0 col">
            <v-label class="pb-1 lable">Second Line Of Address</v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Second Line Of Address"
              v-model="formData.address2"
              :disabled="showReadOnly"
            />
          </V-col>

          <V-col class="pa-0 col">
            <v-label class="pb-1 lable">City </v-label>
            <TextFieldComponent
              :item-value="'code'"
              :item-title="'name'"
              v-model="formData.city"
              :disabled="showReadOnly"
            />
          </V-col>

          <V-col class="pa-0 col">
            <v-label class="pb-1 lable">Zip </v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Zip"
              v-model="formData.zip_code"
              @keypress="allowNumbers($event)"
              :disabled="showReadOnly"
            />
          </V-col>

          <V-col class="pa-0 col">
            <v-label class="pb-1 lable">Country</v-label>
            <country
              :item-value="'code'"
              :item-title="'name'"
              @update:modelValue="changeContry"
              v-model="formData.country_id"
              :disabled="showReadOnly"
            />
          </V-col>

          <V-col class="pa-0 col">
            <v-label class="pb-1 lable">State</v-label>
            <State
              :item-value="'id'"
              ref="stateRef"
              :item-title="'name'"
              :stateId="formData.state_id"
              :disabled="showReadOnly"
            />
          </V-col>

          <!-- <V-col class="pa-0" style="display: grid; width: 50%">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Currency</v-label>
            <AutoComplete :items="['INR', 'SGD', 'USD']" density="compact" placeholder="Currency" :disabled="showReadOnly"
              v-model="formData.currency" />
          </V-col> -->
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import country from "@/components/others/country.vue";
import State from "@/components/others/State.vue";
// import City from "@/components/others/City.vue";
import uploadImage from "@/components/uploadImages.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import AutoComplete from "@/components/ui/AutoComplete.vue";
import CompanyService from "@/api/service/Company/CompanyService";
import "primeicons/primeicons.css";

export default {
  components: {
    TextFieldComponent,
    // AutoComplete,
    country,
    State,
    uploadImage,
  },
  data() {
    return {
      showReadOnly: true,
      showButton:false,
      showEditIcon:true,
      openImage: true,
      nameRule: [(value) => !!value || "Name is Required."],
      formData: {},
    };
  },
  created() {
    this.getAll();
  },
  watch: {},
  methods: {
    close() {
      this.$router.go(-1);
    },
    showIcon(){
      this.showReadOnly = false;
      this.showButton = true;
      this.showEditIcon = false;
    },
    showEdit() {
      this.showReadOnly = false;
      this.openImage = false;
    },

    async getAll() {
      let res = await CompanyService.findAll();
      this.formData = res[0];
      console.log(this.formData.logo_profile_id ,'kashif')
      this.changeContry(this.formData.country_id);
    },
    changeContry($value) {
      this.countryCode = $value;
      this.$refs.stateRef.getFilterData(this.countryCode);
      this.$refs.stateRef.state = null;
    },
    uploadImage($event) {
      this.formData.logo_profile_id = $event.id;
    },
    async removeFile() {
      this.formData.logo_profile_id = " ";
      let output = await CompanyService.update(this.formData);
      this.getAll()
      console.log("profile is gone", output);
    },
    allowNumbers(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async save() {
      console.log(this.$refs.stateRef.selectedId);
      this.formData.state_id = this.$refs.stateRef.selectedId;
      if (this.formData.id) {
        await CompanyService.update(this.formData);
        this.dialog = false;
        let res = await CompanyService.findOne(this.formData.id);
        console.log("getttng dataaaaa", res);
        this.formData = res[0];
        this.showButton=false;
        this.showEditIcon=true;
        this.showReadOnly=false;
        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
      } else {
        await CompanyService.create(this.formData);
        this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
        this.dialog = false;
        this.formData = {};
      }
    },
  },
};
</script>

<style scoped>
.Company {
  margin-left: auto;
  margin: auto 200px 0 px 80px;
}

.dialogBottom .save {
  background-color: #3c5aaa;
  color: #fff;
  width: 60px;
}

.col {
  display: grid;
  width: 50%;
}

>>>.Imag {
  position: relative;
  left: 38px;
  bottom: 30px;
}

</style>
