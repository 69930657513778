<template>
  <div>
    <v-form @submit.prevent="resetPassword" ref="form">
      <v-row class="pa-5">
        <v-col cols="12">
          <!-- <v-row>
          <v-col cols="6" class="pb-0" style="display: grid">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Old Password
                    <span
                      v-if="!showReadOnly"
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >
                  <TextFieldComponent
                    placeholder="Old Password"
                    :disabled="showReadOnly"
                    :rules="emailRules"
                    v-model="oldPassword"
                  />
                  <v-span>

                  </v-span>
                </v-col>
        </v-row> -->
          <v-row>
            <v-col cols="6" class="pb-0 pt-0" style="display: grid">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >New Password
                <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                ></v-label
              >
              <TextFieldComponent
                :disabled="showReadOnly"
                placeholder="New Password"
                :rules="passwordRule"
                v-model="newPassword"
                :type="show1 ? 'text' : 'password'"
                name="input-10-2"
                :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="show1 = !show1"
              />
              <!-- <div v-if="passwordError">{{ passwordError }}</div> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pb-0 pt-0" style="display: grid">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Confirm New Password
                <span
                  v-if="!showReadOnly"
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                ></v-label
              >
              <TextFieldComponent
                placeholder="Confirm New Password"
                :disabled="showReadOnly"
                :rules="passwordRule"
                :type="show2 ? 'text' : 'password'"
                name="input-10-2"
                v-model="confirmPassword"
                :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="show2 = !show2"
              />
              <div v-if="passwordError" style="color: red">{{ message }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="end" class="dialogBottom">
              <v-btn class="mr-2 cancel"  @click="$router.go(-1)">Cancel</v-btn>
              <v-btn class="save" type="submit">Save</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import FireBaseUserService from "@/api/service/User/FirebaseService"
import ResetPasswordService from "@/api/service/User/ResetPasswordService";
import config from "../../api/config/config.js";
export default {
  components: {
    TextFieldComponent,
  },
  data() {
    return {
      passwordRule: [
        (value) => {
          if (value) return true;
          return "Password is required";
        },
        (value) => {
          if (/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}/.test(value))
            return true;
          return "Password must contain at least lowercase letter, one number, a special character and one uppercase letter";
        },
      ],
      oldPassword: "",
      show1: false,
      show2: false,
      newPassword: "",
      confirmPassword: "",
      message: null,
      passwordError: false,
      userDetails: JSON.parse(localStorage.getItem("user_details")),
    };
  },
  methods: {
    // async save(){
    //   //  if(this.oldPassword){
    //     try{
    //       alert('hiii')
    //       let response = await FireBaseUserService.fireBaseLogin({ email:this.userDetails.email,password: this.oldPassword,appId:config.appId})
    //       console.log(response,'checkkkk1111111111')
    //     }
    //     catch(e){
    //       console.log(e)
    //     }
    //   //  }
    // },
    async resetPassword() {
      // var params = this.$route.query;
      // console.log(params,'hlooo')
      try{
        if (this.passwordRule.every((rule) => rule(this.newPassword) === true)) {
       if (
         this.newPassword === this.confirmPassword &&
         this.newPassword !== "" &&
         this.confirmPassword !== ""
       ) {
         this.passwordError = false;
         var res = await ResetPasswordService.resetPassword({
           uid: this.userDetails.uid,
           newPassword: this.newPassword,
           appId: config.appId,
         });
         console.log(res);
         this.$store.dispatch("setSnackbar", { text: "Password Updated Successfully" });
       } else {
         this.passwordError = true;
         this.message = "Password does not match";
       }
     } else {
       this.passwordError = true;
       this.message = "Password does not meet the requirements";
     }
        }
        catch(e){
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Something Went Wrong",
          });
        }
      
    },
  },
};
</script>

<style scoped>
.dialogBottom {
  bottom: 12px;
  z-index: 100;
  width: 100%;
  right: 12px;
  padding: 10px;
  white-space: break-spaces;
  border-radius: 0px 0px 0px 10px;
  background: none !important;
}
</style>
