import * as xlsx from "xlsx";
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as FileSaver from 'file-saver';

import lodash from 'lodash';

class ExportService {
    async export(sourceData,fileName,layoutColumns,type){
        let $fileName = fileName ?fileName:'';
              if (type.toLowerCase() == 'excel') {              
                  await this.exportExcel(sourceData,$fileName,layoutColumns) 
              }
              else if (type.toLowerCase() == 'json') {
                let data = await this.formattedData(sourceData,layoutColumns)
                  const exportType = exportFromJSON.types.json;
                //   console.log(data,exportType)
                  if (data) exportFromJSON({ data, $fileName, exportType });
  
              }
              else {
                let data = await this.formattedData(sourceData,layoutColumns)
                  this.exportAsPDF(data, $fileName)
              }

    }
    async exportExcel(sourceData,fileName,layoutColumns) {
        let data = await this.formattedData(sourceData,layoutColumns);
        const worksheet = xlsx.utils.json_to_sheet(data);
        // const max_width = this.dataSource.reduce((w, r) => Math.max(w, r.name.length), 10);
        if (data && data.length > 0) {
            worksheet["!cols"] = []
            Object.keys(data[0]).forEach(data => {
                let width = { width: 20 }
                console.log(data, 'called')
                worksheet['!cols'].push(width)
            })
        }
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, fileName);
    }
    formattedData(dataSource,layoutColumns) {
        let selectedColumns = layoutColumns
        // console.log(dataSource,"jjjeee")
        let data = JSON.parse(JSON.stringify(dataSource))
        let $data = JSON.parse(JSON.stringify(dataSource))
        let formateData = []
        for (let i = 0; i < $data.length; i++) {
            let $obj = {}
            for (let j = 0; j < selectedColumns.length; j++) {
                if (selectedColumns[j]['header'].length > 0) {
                    // console.log(selectedColumns[j]['header'])
                    if ((selectedColumns[j]['type'] == 'date' || selectedColumns[j]['type'] == 'datetime' || selectedColumns[j].field == 'created' || selectedColumns[j].field == 'modified')) {
                        $obj[selectedColumns[j]['header']] = this.formatDate(lodash.get(data[i], selectedColumns[j].field), selectedColumns[j].field)
                    }
                    else if (selectedColumns[j].field == 'active' || selectedColumns[j].header.toLowerCase() == 'status') {
                        $obj[selectedColumns[j]['header']] = data[i].active == 1 ? true : false
                    }
                    else if (selectedColumns[j]['header'].toLowerCase() != 'action') {
                        $obj[selectedColumns[j]['header']] = lodash.get(data[i], selectedColumns[j].field)
                    }
                }

            }
            formateData.push($obj)
        }
        return formateData
    }
    saveAsExcelFile(buffer, fileName) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
    
    formatDate(dateString, type) {

        if (!dateString) {
            return ''
        }
        else if (type == 'date') {
            const date = new Date(dateString);
            return date.toLocaleDateString()// moment(date).utcOffset('+05:30')
        }
        else {
            const date = new Date(dateString);
            //return moment(date).toLocal()
            return date.toLocaleString();
        }
    }

    exportAsPDF(data, fileName) {
        const headers = this.setPDFHeader(data);
        const fontSize = this.calculateFontSize(headers[0].length);
        const body = this.setPDFBody(data);
        // console.log(headers,"headerssssssssss")
        // console.log(body,"bodyyyyyyyyy")
        const doc = new jsPDF();
        autoTable(doc, { html: "#dt2" });
        doc["autoTable"]({
            styles: {
                cellPadding: 0.5,
                fontSize: fontSize,
            },
            headStyles: {
                fillColor: "#3f51b5",
                textColor: "#fff",
                halign: "center",
            },
            bodyStyles: {
                halign: "center",
            },
            margin: {
                left: 5,
                right: 5,
            },
            tableLineWidth: 1,
            head: headers,
            body: body,
        });
        doc.save(fileName);
    }
    setPDFHeader(data) {
        return [
            Object.keys(data[data.length - 1]).map(
                (item) =>
                    `${item.charAt(0).toUpperCase()}${item.substr(1, item.length)}`
            ),
        ];
    }
    calculateFontSize(count) {
        return count ? 50 / count : count;
    }
    setPDFBody(data) {
        return data.map((item) => {
            const keys = Object.keys(item);
            const values = [];
            keys.forEach((key) => {
                if (typeof item[key] === "object" && item[key] !== null) {
                    values.push(
                        item[key].name
                            ? item[key].name
                            : item[key].code
                                ? item[key].code
                                : item[key].name
                    );
                } else {
                    values.push(item[key]);
                }
            });
            return values;
        });
    }
    downloadPDF() {
        // console.log(data, "000999")
        const doc = new jsPDF();
        let header = this.getTableHeader()
        console.log(header, doc)
        doc.autoPrint({
            head: [['Name', 'Age']],
            body: this.data.map(({ name, code }) => [name, code])
        });
        doc.save('data.pdf');
    }
}
export default new ExportService();