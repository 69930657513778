<template>
  <div class="pt-7 pl-4">
  <v-row >
    <v-card-title style="font-size: 25px;">Board</v-card-title>
  </v-row>
  <v-row>
    <v-col class="contentL" cols="4">
      <TextFieldComponent
        append-inner-icon="mdi-magnify"
        v-model="searchQuery"
        placeholder="Search"
        @input="searchTheData()"
      />
    </v-col>
    <v-col cols="4"></v-col>

    <v-col cols="3" class="contentR right">
      <v-col class="right">
        <v-card-text class="textt" style="margin-top: -7px;"
          >Group By</v-card-text
        >
        <AutoComplete
          :items="['Billable', 'Non Billable']"
          placeholder="None"
        />
      </v-col>
      <v-col>
        <TextFieldComponent
          label="Insights"
        />
      </v-col>
    </v-col>
  </v-row>
  <BoardCards ref="BoardCards" class="overflw" />
</div>
</template>
  
  <script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import BoardCards from "@/Plans/BoardCards.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
export default {
  components: {
    AutoComplete,
    BoardCards,
    TextFieldComponent,
  },
  data(){
    return{
      searchQuery:null
    }
  },
  methods:{
    searchTheData(){
      this.$refs.BoardCards.getSearchTheData(this.searchQuery)
    }
  }
};
</script>
  
  <style scoped>
.overflw{
  overflow-x:auto;
  overflow-y:auto;
}
.contentL {
  display: flex;
  margin-top: 10px;
}

.contentR {
  position: absolute;
  right: 5px;
}

.right {
  display: flex;
}

.textt {
  font-size: medium;
  position: absolute;
  left: -75px;
  font-size: medium;
}
</style>
  