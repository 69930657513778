<template>
  <v-dialog v-model="dialogD" width="800">
    <v-card>
      <v-card-title>
        <span class="text-h5">Delete Task</span>
      </v-card-title>
      <v-card-text> Are you really going to do this with me ?? </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green-darken-1" variant="text" @click="dialogD = false">
          Never
        </v-btn>
        <v-btn color="green-darken-1" variant="text" @click="deleteData()">
          Gladly
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <PrimeDataTable :title="'Sub Task'" :showFilter="true" :searchOption="true" :selectAll="true" :showDownload="true"
    :showUpload="false" :showAdd="true" :columnData="columns" :data="data" @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"     activity="task"
/>
  <v-dialog persistent v-model="dialog" width="700px">
    <AddTask ref="AddTask" @closeDialog="setFalse()" @reload="getTask()" :id="taskId" :viewType="viewType" />
  </v-dialog>
</template>

<script>
import PrimeDataTable from "@/components/PrimeDataTable";
import commonService from "@/common.service";
import AddTask from "./AddTask.vue";
// import deleteDialog from "@/modules/settings/deleteDialog.vue" ;
import TaskSevcise from "@/api/service/Task/TaskService";
export default {
  components: {
    AddTask,
    PrimeDataTable,
    // deleteDialog,
  },
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      taskId: '',
      dialog: false,
      dialogD: false,
      ViewType: false
    };
  },
  created() {

    if (this.$route.query.from == 'topMenu') {
      this.addDiolog()
    }
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getTask();
  },
  methods: {
 
    loadColumns() {
      this.columns = [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: false,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: false,
        },
        {
          field: "task_type",
          header: "Sub Task-Type",
          style: { width: "100px", color: "" },
          toolTip: "Task Type",
          sortfield: false,
        },
        
        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "modified",
          sortfield: true,
        },
        {
          field: "active",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "active",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: false,
          rowActions: [
            { action: "view" },
            { action: "edit" },
          ],
        },
      ];
    },
    setFalse() {
      this.dialog = false;
    },

    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.taskId = $event.data.id;
        this.viewType = $event.type;
        console.log("view");
      }
      if ($event.type == "edit") {
        console.log("edit");
        this.dialog = true;
        this.taskId = $event.data.id;
        this.viewType = $event.type;
      }
      if ($event.type == "select") {
        this.dialog = true;
        this.taskId = $event.data.id;
        this.viewType = "view";
      }
      if ($event.type == "delete") {
        this.dialogD = true;
        this.taskId = $event.data.id;
        console.log("delete")
      }
    },
    async getTask() {
      let res = await TaskSevcise.findAll();
      this.data = res;
    },
    addDiolog() {
      this.dialog = true;
      this.taskId = null;
      this.viewType = false;
    },

    addFilter() {
      this.$refs.FilterDrawer.dialog = true;
      this.$refs.FilterDrawer.size = commonService.largeDialog();
    },
  },
};
</script>
