<template>
    <PrimeDataTable
      :title="'ScoreCardTemplate'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="true"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="SCORECARDSTEMPLATE"
     
    />
  
    <v-dialog persistent v-model="dialog" width="700px">
      <CreateScoreCardTemplate
        @closeDialog="close()"
        @reload="getScoreCardTemplate()"
        :id="editId"
        :viewType="viewType"
        
      />
    </v-dialog>
  </template>
  
  <script>
  import CreateScoreCardTemplate from "./CreateScoreCardTemplate.vue";
  import PrimeDataTable from "@/components/PrimeDataTable";
  import ScoreCardTemplateService from "@/api/service/ScoreCardTemplate/ScoreCardTemplateService";
  // import commonService from "@/common.service";
  export default {
    components: {
        CreateScoreCardTemplate,
      PrimeDataTable,
    },
    data() {
      return {
        data: [],
        columns: [],
        date: new Date(),
        editId:null,
        dialog: false,
        viewType:false
      };
    },
    created() {
      this.loadColumns();
      this.download = true;
      this.search = true;
      this.checkbox = true;
      this.getScoreCardTemplate();
    },
    methods: {
  
      loadColumns() {
        this.columns = [
          {
            field: "code",
            header: "Code",
            style: { width: "100px", color: "" },
            toolTip: "code",
            sortfield: true,
          },
          {
            field: "name",
            header: "Name",
            style: { width: "100px", color: "" },
            toolTip: "name",
            sortfield: true,
          },
          // {
          //   field: "score_ids",
          //   header: "Score Ids",
          //   style: { width: "100px", color: "" },
          //   toolTip: "score_ids",
          //   sortfield: true,
          // },
          {
            field: "action",
            header: "Action",
            style: { width: "100px", color: "" },
            toolTip: "Action",
            sortfield: false,
            rowActions: [{ action: "view" }, { action: "edit" }],
          },
        ];
      },
  
      close() {
        this.dialog = false;
      },
      rowActions($event) {
        if ($event.type == "view") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }
        if ($event.type == "edit") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType=$event.type;
        }   
  
        if ($event.type == "select") {
          this.dialog = true;
          this.editId = $event.data.id;
          this.viewType="view";
        }
      },
      async getScoreCardTemplate() {
        let res = await ScoreCardTemplateService.findAll();
        this.data = res;
      },
      addDiolog() {
        this.dialog = true;
        this.editId = null;
      }
    },
  };
  </script>
  
  
  