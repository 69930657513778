<template>
    <v-card flat class="pa-3 " min-height="90vh">
       <v-card-title>
        Workspaces
       </v-card-title>
       <v-card-text>
        <v-row no-gutters dense class="rowheader pa-2">
            <v-col cols="5" class="text-left">
                Name
            </v-col>
            <v-col cols="3" class="text-center">
                Total Projects
            </v-col>
            <v-col cols="2" class="text-left">
                Total Accounts 
            </v-col>
        </v-row>
        <v-row  dense v-for="workspace in workspaces" :key="workspace">
            <v-col cols="5" class="text-left ">
                <span class="link" @click="getLink(workspace)">
                    <b>{{ workspace.code }}</b> - {{ workspace.name }}
                </span> 
            </v-col>
            <v-col cols="3" class="text-center">
                <v-chip class="open-issues">
                   {{ workspace.no_of_projects }}
                </v-chip>
            </v-col>
            <v-col cols="2" class="text-left date">
                <v-chip class="open-issues">
                {{ workspace.no_of_accounts }}
                </v-chip>
            </v-col>
            <v-col cols="12">
                <v-divider />
            </v-col>
        </v-row>
                <!-- <router-link :to="getLink(account)" class="link">
                   
                </router-link> -->
            <!-- </v-col>
           
            <v-divider />
        </v-row> -->
        <!-- </v-col></v-row> -->
       </v-card-text>
    </v-card>
    </template>
    <script>
    import WorkSpaceService from '@/api/service/WorkSpace/WorkSpaceService'
    import moment from 'moment'
    export default {
        components:{
               // PrimeDataTable
        },
        data() {
           
            return {
                workSpaceId:1,
                workspaces:{},
                columns:[
                {
                    field: "code",
                    header: "Code",
                    style: { width: "100px", color: "" },
                    toolTip: "id",
                    sortfield: true,
                },
                {
                    field: "name",
                    header: "Name",
                    style: { width: "100px", color: "" },
                    toolTip: "name",
                    sortfield: true,
                },
                {
                field: "Open",
                header: "Open Issues",
                style: { width: "100px", color: "" },
                toolTip: "Open",
                sortfield: true,
                },
                {
                field: "updated",
                header: "Last Updated",
                style: { width: "100px", color: "" },
                toolTip: "Last Updated",
                sortfield: true,
                }
            ]
            }
        },
        computed: {
           
        },
        watch:{
          
        },
        created() {
            this.$store.commit("SET_REFRESH_BREADCRUMP",false)
            let $wsDefaults = localStorage.getItem('_WS_DEFAULTS_')
            if($wsDefaults) {
                $wsDefaults  = JSON.parse($wsDefaults)
                delete $wsDefaults.workspace
                delete $wsDefaults.account
                delete $wsDefaults.project
                delete $wsDefaults.category
                delete $wsDefaults.issue
            } else {
                $wsDefaults = {}
            }
            localStorage.setItem("_WS_DEFAULTS_",JSON.stringify($wsDefaults))    
            this.$store.commit("SET_REFRESH_BREADCRUMP",true) 
            this.getWorkspaces()
          
        },
        mounted() {
    
        },
        methods: {
            moment,
            getLink($account) {
                console.log($account,"hello je")
                let status = this.$store.state.getRefreshWorkSpace;
                let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_")
                $wsDefaults = JSON.parse($wsDefaults)
                $wsDefaults.workspace ={"id":$account.id,"name":$account.name}
                localStorage.setItem("workSpaceId",$account.id)
                localStorage.setItem("_WS_DEFAULTS_",JSON.stringify($wsDefaults))
                this.$store.commit("SET_REFRESH_BREADCRUMP",true)
                this.$store.commit("SET_REFRESH_WORK_SPACE", !status);
                this.$router.push(`/showProjectForWorkspaces?workSpaceId=${$account.id}`)
                //return 
            },
            getWorkspaces() {
                WorkSpaceService.getIssuesForWorkspace({id:this.workSpaceId})
                .then($response =>{
                    this.workspaces = $response
                    }).catch(error =>{
                    console.log(error)
                })
            }
        }
    }</script>
    <style>
        .rowheader {
            background-color:#D7EAF4;
            font-weight:600;
        }
        .link {
            color:#000;
            text-decoration: none;
        }
        .link:hover {
            color:#000;
            text-decoration: underline;
        }
        .open-issues {
            background-color:#E2D6F8;
            color:#272727;
            font-weight:10px;
            font-weight:400;
        }
        .date {
            color:orange;
        }
    
    </style>