import TimeSheetService from "./TimeSheetService"
const excludes = ['meeting', 'break', 'login', 'logout']
const TimerService = {
    processTime: async function (data, taskType, $start,$taskSelected) {
        //data.company_id = userData.company_id
        /*if($start) {
            let $timer = localStorage.getItem('timer')
            $timer = JSON.parse($timer)
            if($timer) {
                await TimeSheetService.processTime2(data,$timer.type,false);
            }
        }*/
        if (excludes.includes(taskType)) {
            data.customer_id = 0
            data.issue_id = 0
            data.project_id = 0
            data.customer_id = 0
            data.description = taskType
        }
        if($taskSelected) {
            data.task_id=$taskSelected.id
        }
        // data.resource_id = userData.id
        data.stage = 'pending'
        data.actual_hrs = 0
        //  data. end_date = moment(new Date()).tz('utc')
        //data.start_date = moment(new Date()).utcOffset("+05:30").format('YYYY/MM/DD HH:mm:ss')
        //data.entry_date = moment(new Date()).utcOffset("+05:30").format('YYYY/MM/DD HH:mm:ss')
        //let task = await TaskService.findAll({ code : value ? value: "LOGIN" })
        //data.task_id = task[0].id
        //data.description = value ? value:"LOGIN"
        //alert(localStorage.getItem('TOKEN'))
        if ($start) {
            $start = 'start'
        } else {
            $start = 'stop'
        }
        await TimeSheetService.processTime2(data, taskType, $start);

    }
}
export default TimerService;