<template>
  {{ isLoaderRequired }}
  <DefaultLayout v-if="getAuth" />
  <LoginLayout v-if="!getAuth" />
  
  <v-snackbar
    style="margin-top: 80px"
    v-model="snackbar.showing"
    :timeout="2000"
    :top="true"
    :color="snackbar.color"
    location="top"
  >
    {{ snackbar.text }}
  </v-snackbar>
</template>


<script>
import { mapState } from "vuex";
import DefaultLayout from "./Layout/DefaultLayout.vue";
import LoginLayout from "./Layout/LoginLayout.vue";

export default {
  components: {
    DefaultLayout,
    LoginLayout,
  },
  data() {
    return {
      getAuth: false,
      // snackbar:true,
      // snackbartext:'checkingggg'
    };
  },
  created() {
    this.getAuthentication();
  },
  computed: {
    ...mapState(["snackbar"]),
  },
  methods: {
    getAuthentication() {
      let token = localStorage.getItem("TOKEN");
      if (token) {
        this.getAuth = true;
      } else {
        console.log("working");
      }
    },
  },
};
</script>
<style lang="scss">
@import "../src/scss/style.scss";
// .body{
//   background-color:#F2F7FD;
// }
</style>