<template>
  <PrimeDataTable
    :title="'Sub Task Group'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="taskgroup"

  />

  <v-dialog persistent v-model="dialog" width="700px">
    <CreateTaskGroup
      @closeDialog="close()"
      @reload="getTaskGroup()"
      :id="editId"
      :viewType="viewType"
    />
  </v-dialog>
</template>
  
  <script>
import CreateTaskGroup from "./CreateTaskGroup.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import TaskGroupService from "@/api/service/TaskGroup/TaskGroupService";
// import commonService from "@/common.service";
export default {
  components: {
    CreateTaskGroup,
    PrimeDataTable,
  },
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      editId: null,
      dialog: false,
      viewType: false,
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getTaskGroup();
  },
  methods: {
    loadColumns() {
      this.columns = [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "TaskGroup",
          sortfield: true,
        },

        {
          field: "category_name",
          header: "Category",
          style: { width: "100px", color: "" },
          toolTip: "Category",
          sortfield: true,
        },
        {
          field: "sub_category_name",
          header: "Sub Category",
          style: { width: "100px", color: "" },
          toolTip: "SubCategory",
          sortfield: true,
        },
        {
          field: "task_name",
          header: "Sub Task",
          style: { width: "100px", color: "" },
          toolTip: "Task",
          sortfield: true,
        },
        // {
        //   field: "issueType_name",
        //   header: "Issue Type",
        //   style: { width: "100px", color: "" },
        //   toolTip: "Issue Type",
        //   sortfield: true,
        // },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: false,
          rowActions: [{ action: "view" }, { action: "edit" }],
        },
      ];
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = "view";
      }
    },
    async getTaskGroup() {
      let res = await TaskGroupService.findAllgroup();
      this.data = res;
      console.log(this.data, "kassss");
    },
    
    close() {
      this.dialog = false;
    },
    addDiolog() {
      this.dialog = true;
      this.editId = null;
      this.viewType = false;
    },
  },
};
</script>
  
  
  