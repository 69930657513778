<template>
  <v-card Width="100%" style="border-radius: 12px; overflow: auto">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b>Sub-Category</b>
          <v-btn @click="setFalse()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="subCategory.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="4">
            <v-label calss="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
            </v-label>
            <TextFieldComponent density="compact" placeholder="Code" v-model="subCategory.code" :rules="codeRule"
              :disabled="showReadOnly" @keyup="checkCode()" @keydown="prevent($event)" />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>

          <v-col cols="9">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Name" v-model="subCategory.name" :rules="nameRule"
              :disabled="showReadOnly"></TextFieldComponent>
          </v-col>
          <v-col cols="9" class="dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Category Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="categoryData" item-title="name" v-model="subCategory.category_id" item-value="id"
              :disabled="showReadOnly" :rules="CategoryRule" :placeholder="'Category Name'" />
          </v-col>

          <!-- <v-col cols="9" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Udfs
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :items="udfsData"
              item-title="name"
              v-model="subCategory.udf_required"
              item-value="id"
              :disabled="showReadOnly"
              :placeholder="'Select Udfs'"
              @update:modelValue="udfsChanged"
            />
            <v-chip
              closable
              class="selectedChip"
              label
              :disabled="showReadOnly"
              v-for="item in selectedUdf"
              :key="item"
              @click:close="removeChips(item)"
            >
              {{ item.name }}
            </v-chip>
          </v-col> -->
        </v-row>
        <v-col class="switch">
          <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">QA Required
            <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
          <v-switch color="primary" v-model="subCategory.qa_required" :disabled="showReadOnly"
            :label="subCategory.qa_required ? 'Yes' : 'No'"
            :model-value="subCategory.qa_required ? true : false"></v-switch>

        </v-col>

      </v-card-text>
      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="setFalse()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
import categoryService from "@/api/service/Category/CategoryService";
import commonService from "@/common.service";
import udfservice from "@/api/service/Udfs/UdfsService.js";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
  },

  data() {
    return {
      name: null,
      showReadOnly: false,
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      CategoryRule: [(value) => !!value || "Category Name is Required."],
      type: "",
      dialog: false,
      add: false,
      selectedUdfId: [],
      backUpUdfData: [],
      selectedUdf: [],
      categoryData: [],
      wtError: false,
      subCategoryData: [],
      udfsData: [],
      stageData: [
        { value: "open", display: "Open" },
        { value: "close", display: "Close" },
      ],
      codeError: false,
      chips: [],
      subCategory: {
        code: null,
        name: null,
        qa_required: "true",
        udf_required: null
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
        this.type1 = this.type;
      }
    },
    // type() {
    //   if (this.type) {
    //     this.showReadOnly = this.type == "edit" ? false : true;
    //   }
    // },
  },
  created() {
    this.getCategoryData();
    this.getSubcategoryData();
    this.getUdfsData();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
      console.log(this.viewType, "viewwwwwwwwww type");
    }
  },
  methods: {
    async getUdfsData() {
      await udfservice.findAll().then(async ($options) => {
        this.udfsData =
          $options.length > 0
            ? await commonService.getSort($options, "name", "asc")
            : [];
        this.backUpUdfData = JSON.parse(
          JSON.stringify(this.udfsData)
        );
        console.log(this.backUpUdfData, "udfdata");
      });
    },

    udfsChanged($value) {
      this.selectedUdfId.push($value);
      this.setData();
    },
    setData() {
      let chipRes = commonService.getMultipleChip(
        this.backUpUdfData,
        this.selectedUdfId,
        "id"
      );
      this.udfsData = commonService.getObjectSliceMethod(
        JSON.stringify(this.backUpUdfData),
        this.selectedUdfId,
        "id"
      );
      this.selectedUdf = chipRes;
      this.name = null;
      this.udfsData = [...this.udfsData];
      this.subCategory.udf_required = null;
    },

    removeChips(data) {
      let index = this.selectedUdfId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedUdfId.splice(index, 1);
      }
      this.setData();
      // this.wtError = this.selectedUdfId.length == 0;
    },
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode() {
      if (this.subCategory.code && this.subCategory.code.length > 0) {
        this.subCategory.code = this.subCategory.code.toUpperCase();

        SubCategoryService.findAll({ code: this.subCategory.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.subCategory.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.subCategory.id && $exist[0].id == this.subCategory.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    setFalse() {
      this.$emit("closeDialog");
    },

    async getById(id) {
      let res = await SubCategoryService.findOne(id);
      this.subCategory = res[0];
      this.selectedUdfId = this.subCategory && this.subCategory.udf_required
        ? JSON.parse(this.subCategory.udf_required)
        : [];

      console.log(this.selectedUdfId, 'kkkkaaa')
      this.setData();
    },
    async getSubcategoryData() {
      let res = await SubCategoryService.findAll();
      this.subCategoryData = res.length > 0 ? res : [];
    },
    async getCategoryData() {
      let res = await categoryService.findAll();
      this.categoryData = res.length > 0 ? await commonService.getSort(res, 'name', 'asc') : [];
    },

    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {
        console.log("Not valid")
        if (this.selectedUdfId.length == 0) {
          // this.wtError = true;
        }
      } else {
        this.subCategory.udf_required = JSON.stringify(
          this.selectedUdfId
        );
        console.log(this.selectedUdfId, 'kasif')
        if (this.id) {
          let output = await SubCategoryService.save(this.subCategory);
          console.log(output);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.setFalse();
          this.dialog = false;
          this.$emit("reload", true);
        } else {
          //   console.log(this.projects, "prject Data");
          try {
            let output = await SubCategoryService.create(this.subCategory);
            console.log(output);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.setFalse();
            this.dialog = false;
            this.$emit("reload", true);
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>
<style>
.switch {
  height: 80px;
  padding-left: 4px;
}

.selectedChip {
  background-color: #fae2ee;
  color: #ffffff;
  margin-right: 5px;
  margin-top: 5px;
  color: #333333;
}

/* .v-switch .v-label {
    -webkit-padding-start: 10px;
    padding-inline-start: 10px;
    font-size: 14px;
    color: #272727;
    font-weight: 600;
} */
</style>

