<template>
    <v-card elevation="0" style="border-radius:10px ">
        <v-form @submit.prevent="save()" ref="form">
            <v-card-item>
                <v-card-title>
                    Raise Notice
                    <v-btn style="float: right;" @click="close()" elevation="0" density="comfortable"
                        icon="mdi-close"></v-btn>
                </v-card-title>
            </v-card-item>
            <!-- <v-col cols="12" class="pl-5 pr-2"> -->
            <v-card-text>

                <!-- <v-row no-gutters dense class="py-2">
                    <ButtonGroup :items="actionData" text="display_value" value="data_value" :selected="activeAction"
                        returnObject=false @item-selected="(obj) => { setActiveButton(obj) }" />
                </v-row> -->

                <v-row no-gutters dense class="py-2" v-if="activeAction === 'modify'">

                    <v-col cols="3" class="pt-0 pr-2">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Started At
                            <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                        <TextFieldComponent density="compact" :max="getCurrentDateTime()" min='2023-07-04'
                            placeholder="Started Time" type="datetime-local" showtime hoursFormat="12"
                            v-model="formData.start_date" :rules="startTimeRule" @input="updateEndDateRange" />
                        <v-error v-if="dateError">Enter Start Date First</v-error>
                    </v-col>

                    <v-col cols="3" class="pt-0 pr-2">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Ended At
                            <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                        <TextFieldComponent density="compact" :max="getCurrentDateTime()" placeholder="Ended Time"
                            type="datetime-local" showtime hoursFormat="12" v-model="formData.end_date" :rules="endTimeRule"
                            :min="minEndDate" />
                        <v-error v-if="dateError2">Ended At date cannot be before Started At</v-error>
                    </v-col>
                    <v-col cols="2" class="pt-0 pr-2 text-center">
                        <v-label class="pb-1"
                            style="font-size: 14px; color: #272727; font-weight: 600 ; margin-bottom: 10px;">Actual
                            Hours
                            <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                        <br /><span><b> {{ formData.actual_hrs }}</b></span>
                    </v-col>
                </v-row>

                <v-row no-gutters dense>
                    <v-col cols="12">
                        <v-label class="pb-1"
                            style="font-size: 14px; color: #272727; font-weight: 600">Description</v-label>
                        <TextArea class="pb-4" editorStyle="height: 100px" :data="formData.description"
                            @textData="changeDescription($event)"></TextArea>
                    </v-col>
                </v-row>

            </v-card-text>
            <!-- </v-col> -->

            <v-card-action class="mr-3">
                <v-row justify="end" class="dialogBottom" v-if="activeAction === 'modify'">
                    <v-btn style=" --v-btn-height: 35px;" class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
                    <v-btn style=" --v-btn-height: 35px;" class="save btn" type="submit">Save</v-btn>
                </v-row>
                <v-row justify="end" class="dialogBottom" v-if="activeAction === 'delete'">
                    <v-btn style=" --v-btn-height: 35px;" class="save btn" @click="deleteEnrty">Confirm</v-btn>
                </v-row>
            </v-card-action>
        </v-form>
    </v-card>
</template>
    
<script>
import moment from 'moment'
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import TextArea from "@/components/ui/TextArea.vue";
import TimeSheetService from "@/api/service/TimeSheet/TimeSheetService";
import IssueService from "@/api/service/Issue/IssueService";
import commonService from "@/common.service";
import TaskService from '@/api/service/Task/TaskService';
// import { ButtonGroup } from "frontendcomponent"

export default {
    props: ['id', 'viewType', 'taskType1'],
    components: {
        TextFieldComponent,
        TextArea,
        // EnumAutoCompleteVue,
        // AutoComplete,
        // ButtonGroup
    },
    data() {
        return {
            startTimeRule: [(value) => !!value || "Start Time is Required."],
            endTimeRule: [(value) => !!value || "End Time is Required."],
            dateError: false,
            modify: false,
            dateError2: false,
            activeAction: 'modify',
            actionData: [
                { display_value: 'Modify', data_value: 'modify' },
                { display_value: 'Delete', data_value: 'delete' }
            ],
            // typeRule: [(value) => !!value || "Task type is Required"],
            minEndDate: null,
            formData: {},
            issueData: [],
            issueId: '',
            taskData: []
        }
    },

    watch: {
        'formData.end_date': function () {
            this.calculateActualHours('end');
        },
        "formData.start_date": function () {
            this.calculateActualHours('start');
        },
    },
    created() {
        this.getTaskDetails()
        if (this.id) {
            this.getById(this.id);
        }

        // this.formData = this.getDefaults()
        // this.getTask();
        // this.getIssues();
    },
    methods: {
        getTaskDetails() {
            TaskService.findAll({ task_type: ['login', 'logout'] }).then(res => {
                this.taskData = res && res.length > 0 ? res : []
            }).catch(e => {
                console.log(e)
            })

        },
        setActiveButton(button) {
            this.activeAction = button
            console.log(button, 'hihihihihh')
        },

        async deleteEnrty() {
            let obj = {
                id: this.formData.id,
                delete_request: 1,
                new_description: this.formData.description
            }
            await TimeSheetService.update(obj);
            this.$store.dispatch("setSnackbar", { text: "Deleted Successfully" });
            this.$emit("reload", true);
            this.$emit("closeDialog");
        },
        async getById(id) {
            let res = await TimeSheetService.findAll({ id: id });
            this.formData = res[0];
            this.formData.start_date = res[0].start_date ? moment(res[0].start_date).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')
            this.formData.end_date = res[0].end_date ? moment(res[0].end_date).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')
            // this.issueChanged(this.formData.issue_id)
            // console.log(this.formData, 'alert000')
            console.log(this.formData, "oooooooeee")
        },
        async calculateActualHours(type) {
            // if (this.formData.start_date && this.formData.end_date) {
            //     if (await this.checkTask()) {
            //         this.formData.start_date = type == 'start' ? this.formData.start_date : this.formData.end_date
            //         this.formData.end_date = type == 'end' ? this.formData.end_date : this.formData.start_date
            //     }
            //     const startTime = moment(this.formData.start_date);
            //     const endTime = moment(this.formData.end_date);
            //     const diffMilliseconds = endTime.diff(startTime);
            //     const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));
            //     const diffSeconds = ((diffMilliseconds % (1000 * 60)) / 1000).toFixed(1);
            //     this.formData.actual_hrs = parseFloat(`${diffMinutes}.${diffSeconds}`);
            // } else if (!this.formData.start_date) {
            //     this.dateError = true;
            // }
            if (this.formData.start_date && this.formData.end_date) {
                const startTime = moment(this.formData.start_date);
                const endTime = moment(this.formData.end_date);

                if (await this.checkTask()) {
                    this.formData.start_date = type == 'start' ? this.formData.start_date : this.formData.end_date
                    this.formData.end_date = type == 'end' ? this.formData.end_date : this.formData.start_date
                }
                // Calculate the difference in milliseconds
                const diffMilliseconds = endTime.diff(startTime);

                if (diffMilliseconds < 0) {
                    this.dateError2 = true;
                    this.formData.actual_hrs = 0;
                    return;
                } else if (diffMilliseconds == 0) {
                    this.formData.actual_hrs = 0;
                    this.dateError2 = false;
                } else {
                    this.dateError2 = false;
                    // Convert milliseconds to hours and minutes
                    const diffDuration = moment.duration(diffMilliseconds);
                    const hoursDiff = Math.floor(diffDuration.asHours());
                    const minutesDiff = Math.floor(diffDuration.asMinutes()) % 60;
                    console.log(minutesDiff,hoursDiff,diffDuration,"hello balaaaa",diffMilliseconds)
                    // Format the difference as HH:mm
                    const diff = hoursDiff * 60 +minutesDiff
                    console.log(diff,"hello balaaaaa")
                    this.formData.actual_hrs = diff? diff : 0;
                }
            } else if (!this.formData.start_date) {
                this.dateError = true;
            }
        },
        checkTask() {
            let data = this.taskData.filter(k => k.id == this.formData.task_id)
            if (data && data.length > 0) {
                return true
            }
            return false
        },

        getCurrentDateTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, "0");
            const day = now.getDate().toString().padStart(2, "0");
            const hours = now.getHours().toString().padStart(2, "0");
            const minutes = now.getMinutes().toString().padStart(2, "0");
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        },
        moment,
        getDefaults() {
            return {
                entry_date: new Date(),
                project_id: this.selectedIssueData.project_id,
                issue_id: this.selectedIssueData.issueId,
                account_id: this.selectedIssueData.account_id,
                // "start_date":new Date(),
                task_id: this.selectedIssueData.task_Id,
                stage: "pending",
            };
        },
        updateEndDateRange() {
            if (this.formData.start_date) {
                const startedDateTime = new Date(this.formData.start_date);
                const minEndDate = new Date(startedDateTime.getTime() + 60000); // Add 1 minute to the startedDateTime
                this.minEndDate = minEndDate.toISOString().slice(0, -8);
                this.dateError = false;
            }
            if (this.formData.end_date) {
                this.dateError2 = true;
                const startedDate = moment(this.formData.start_date).format(
                    "YYYY-MM-DD"
                );
                const endedDate = moment(this.formData.end_date).format("YYYY-MM-DD");
                if (endedDate < startedDate) {
                    this.dateError2 = true;
                    this.formData.actual_hrs = "00:00";
                } else {
                    this.dateError2 = false;
                }
            } else {
                this.minEndDate = null;
            }
        },


        async getIssues() {
            let accountId = localStorage.getItem("_ACCOUNT_ID_");
            let projectId = localStorage.getItem("_PROJECT_ID_");
            let workSpaceId = localStorage.getItem("workSpaceId");
            let res = await IssueService.getIssueData({
                accountId: accountId,
                projectId: projectId,
                workSpaceId: workSpaceId,
            });
            for (let i = 0; i < res.length; i++) {
                this.issueData.push(res[i].ds_issue_list);
            }
            this.issueData = [...this.issueData];
            this.issueData = this.issueData.map((issue) => ({
                ...issue,
                referenceName: `${issue.reference_num} - ${issue.name}`,
            }));
            console.log(this.issueData, "issue11111111");
        },

        async issueChanged(id) {
            let res = await IssueService.findAll({ id: id });
            this.selectedIssueData = res[0];
            console.log(this.selectedIssueData, "alert222");
            if (id) {
                // alert(id)
                this.issueId = id
            }
            this.issueId = this.selectedIssueData && this.selectedIssueData.id ? this.selectedIssueData.id : '';
            this.formData.project_id = this.selectedIssueData && this.selectedIssueData.project_id ? this.selectedIssueData.project_id : '';
            this.formData.account_id = this.selectedIssueData && this.selectedIssueData.account_id ? this.selectedIssueData.account_id : '';
            this.formData.task_id = this.selectedIssueData && this.selectedIssueData.task_id ? this.selectedIssueData.task_id : this.formData.task_id;
            this.formData.issue_id = this.selectedIssueData && this.selectedIssueData.id ? this.selectedIssueData.id : '';
        },
        changeDescription($event) {
            this.formData.description = $event;
        },
        async save() {
            let validation = await this.$refs.form.validate();
            if (!validation.valid || this.dateError || this.dateError2) {
                console.log("Something Went Error");
            } else {
                let userData = JSON.parse(localStorage.getItem("user_details"));
                this.formData.resource_id = userData.id;
                this.formData.entry_date = await commonService.changeTheDateToUtc(
                    this.formData.start_date,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                );
                this.formData.start_date = await commonService.changeTheDateToUtc(
                    this.formData.start_date,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                );
                this.formData.end_date = await commonService.changeTheDateToUtc(
                    this.formData.end_date,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                );
                let obj = {
                    id: this.formData.id,
                    new_description: this.formData.description,
                    new_actual_hrs: this.formData.actual_hrs,
                    new_start_date: this.formData.start_date,
                    new_end_date: this.formData.end_date
                }
                await TimeSheetService.update(obj);
                this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
                this.$emit("reload", true);
                this.$emit("closeDialog");
            }
        },

        close() {
            this.$emit("closeDialog");
        },
    },
};
</script>
    
<style></style>