<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto">
    <v-card-item>
      <v-card-title>{{ viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add" }}
        KraReview
        <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
      </v-card-title>
    </v-card-item>

    <v-form @submit.prevent="save()" ref="form" v-model="value">
      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Finance Year
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="finance year" :rules="codeRule" v-model="formData.fy" @keydown="prevent1($event)"
              :disabled="showReadOnly" />
          </v-col>

          <v-col cols="6" class="pl-3">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">User
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="userData" item-title="name" v-model="formData.user_id" item-value="id"
              :rules="ModelRule" :disabled="showReadOnly" :placeholder="'User'" />
          </v-col>

          <v-col cols="6" >
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Reviewer
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="userData" item-title="name" v-model="formData.reviewer_id" item-value="id"
              :rules="ModelRule" :disabled="showReadOnly" :placeholder="'Reviewer'" />
          </v-col>

          <!-- <v-col cols="6" class="pl-3">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Final Score
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="final score" :rules="codeRule" v-model="formData.final_score"
              :disabled="showReadOnly" />
          </v-col> -->

          <v-col cols="6" class="pl-3">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Template
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="scoreTData" item-title="name" v-model="formData.template_id" item-value="id"
              :rules="ModelRule" :disabled="showReadOnly" :placeholder="'Template'" />
          </v-col>

          <v-col cols="6">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Review Date
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="DD/MM/YYYY" type="date" :rules="codeRule" v-model="formData.review_date"
              :disabled="showReadOnly" />
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" :loading='saveClicked' type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import UserService from "@/api/service/UserService";
import KraReviewService from "@/api/service/KraReview/KraReviewService";
import ScoreCardTemplateService from "@/api/service/ScoreCardTemplate/ScoreCardTemplateService";
import moment from "moment";
export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete
  },

  data() {
    return {
      saveClicked: false,
      showReadOnly: false,
      dialog: false,
      type: null,
      userData: [],
      scoreTData: [],
      formData: {
        id: null,
        fy: null,
        user_id: null,
        reviewer_id: null,
        template_id: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    this.getUserData()
    this.getscoreTData()
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    moment,
    close() {
      this.$emit("closeDialog");
    },
    prevent1(e) {
      if (!/^\d*\.?\d{0,2}$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    async getById(id) {
      console.log(id)
      let res = await KraReviewService.findOne(id);
      this.formData = res[0];
      this.formData.review_date = res[0].review_date ? moment(res[0].review_date).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')
    },

    async getUserData() {
      let res = await UserService.findAll()
      this.userData = res
    },

    async getscoreTData() {
      let res = await ScoreCardTemplateService.findAll()
      this.scoreTData = res
    },

    async save() {
      if (this.formData.id) {
        this.saveClicked = true
        await KraReviewService.update(this.formData);
        this.dialog = false;
        this.close();
        this.$emit("reload", true);
        this.formData = {};
      } else {
        await KraReviewService.create(this.formData);
        this.dialog = false;
        this.close();
        this.$emit("reload", true);
        this.formData = {};
      }

    },
  },
};
</script>
  