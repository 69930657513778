
<template>
  <v-card flat>
  <v-card-title>By Trend</v-card-title>
      <Chart
      type="line"
      :data="chartData"
    />
    <!-- </v-card-text>
   
  </div> -->
  </v-card>
</template>

<script>
import Chart from "primevue/chart";
export default {
  components: {
    Chart,
  },
  data() {
    return {
      chartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            data: [65, 59, 80, 81, 56, 55, 40,20,0,100],
          }
        ],
      }
    }
  },
}
</script>
<style scoped>
>>>.p-chart {
    /* position: relative; */
    height: 170px;
}
</style>