<template>
  <v-layout>
    <v-navigation-drawer v-model="drawer"  location="left" temporary 
      style="position:fixed;top:72px;height:100vh"
      class="side-menu"
    >
       <v-list density="compact">
      <!--  <v-list-item >
        <img :src="require('@/assets/Synergy-Logo-full-blue.png')" class="text-right" style="height:32px"/>
      </v-list-item> -->
        <div v-for="(item,index) in menuData" :key="item">
        <v-list-item class="menu-item" :prepend-icon="item.prependIcon" :title="item.title" :value="item.value" @click="changeMenu(index)"></v-list-item>
      </div>
    
    <!--   <v-list-item style="position:fixed;top:80%"
          prepend-avatar=
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0DlYpRPZ_JvAtiiu8dXEazvTCgzHmIFh5Eg&usqp=CAU"
          :title="userData && userData.name?userData.name:''" :subtitle="userData && userData.email?userData.email:''">
        </v-list-item> -->
      </v-list>


      <v-main>
      </v-main>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      menuData:[
        {
          prependIcon:"mdi-account-outline",
          title:"My Profile",
          value:"myprofile",
          router:'/user'
        },
        {
          prependIcon:"mdi-folder-open-outline",
          title:"Project",
          value:"project",
          router:'/projectList'
        },
        {
          prependIcon:"mdi-alert-octagon-outline",
          title:"Issue Type",
          value:"schedules",
          router:'/IssueType'
        },
        {
          prependIcon:"mdi-format-list-checkbox",
          title:"Task",
          value:"task",
          router:'/Task'
        },
        {
          prependIcon:"mdi-id-card",
          title:"Category",
          value:"category",
          router:'/Category'
        },
        {
          prependIcon:"mdi-file-tree-outline ",
          title:"Sub Category",
          value:"subcategory",
          router:'/subcategory'
        },
        {
          prependIcon:"mdi-clipboard-text-multiple-outline", 
          title:"Issue List",
          value:"issuelist",
          router:'/issuelist'
        },
        {
          prependIcon:"mdi-clipboard-text-multiple-outline", 
          title:"List Model",
          value:"issuelist",
          router:'/listmodel'
        }
      ],
      userData:null,
      drawer: false,
      items: [
        { title: 'Home', icon: 'mdi-view-dashboard' },
        { title: 'About', icon: 'mdi-forum' },
      ],
    }
  },

  created() {
    this.userData = JSON.parse(localStorage.getItem("user_details"))
  },
  methods:{
    changeMenu(data){
      this.$router.push(this.menuData[data].router)
    }
  }
}
</script>
<style>
.side-menu{
  background-color: #E8F3F9;
}
.menu-item{
  font-size:12px;
  line-height:24px;
  color:#272727;
}
.menu-item:hover {
  background-color: #1258AB;
  color:#ffffff;
}
</style>