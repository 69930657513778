<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b>Sub Task Group</b>
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Code" :rules="codeRule" v-model="formData.code"
              :disabled="showReadOnly" @keyup="checkCode()" @keydown="prevent($event)" />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>
          <v-col class="" cols="12">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="TaskGroup" :rules="nameRule" v-model="formData.name"
              :disabled="showReadOnly" />
          </v-col>

          <!-- Input Field -->
          <v-col cols="6" class=" dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Project
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="projectData" :placeholder="'Project'" :item-title="'name'" @update:modelValue="changeProject($event)" :disabled="showReadOnly"
              :item-value="'id'" v-model="formData.project_id" :rules="projectRule" />
          </v-col>

          <v-col cols="6" class="pl-3 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Category
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="categoryData" :placeholder="'Category'" :item-title="'name'" :disabled="showReadOnly"
              :item-value="'id'" v-model="formData.category_id" @update:modelValue="selectCategory()"
              :rules="categoryRule" />
          </v-col>

          <v-col cols="6" class=" dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Category
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="subCategoryData" :placeholder="'Sub Category'" :item-title="'name'"
              :disabled="showReadOnly" :item-value="'id'" v-model="formData.sub_category_id" :rules="subCategoryRule" />
          </v-col>

          <v-col cols="6" class=" pl-3 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Task
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="taskData" :placeholder="'Task'" :item-title="'name'" :disabled="showReadOnly"
              :item-value="'id'" v-model="formData.task_id" :rules="taskRule" />
          </v-col>
         
          <v-col cols="6" class=" pl-3 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Task Type
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="issueTypeData" :placeholder="'Task Type'" :item-title="'name'" :disabled="showReadOnly"
              :item-value="'id'" v-model="formData.issue_type_id" :rules="issueTypeRule" />
          </v-col>
         
        </v-row>
      </v-card-text>

      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import TaskGroupService from "@/api/service/TaskGroup/TaskGroupService.js";
import TaskService from "@/api/service/Task/TaskService";
import CategoryService from "@/api/service/Category/CategoryService";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
import ProjectService from "@/api/service/Project/ProjectService";
import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
import commonService from "@/common.service";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
  },

  data() {
    return {
      showReadOnly: false,
      dialog: false,
      type: null,
      taskData: [],
      projectData: [],
      issueTypeData: [],
      categoryData: [],
      codeError: false,
      subCategoryData: [],
      formData: {
        code: null,
        name: null,
        category_id: null,
        sub_category_id: null,
        task_id: null,
      },
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      categoryRule: [(value) => !!value || "Category is Required."],
      subCategoryRule: [(value) => !!value || "Sub Category is Required."],
      taskRule: [(value) => !!value || "Task is Required."],
      projectRule: [(value) => !!value || "Project is Required."],
      issueTypeRule: [(value) => !!value || "Issue Type is Required."],
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    this.getTaskData();
    this.getProjectData();
    this.getIssueTypeData();
    // this.getSubCategoryData();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {

    changeProject($event){
      if($event){
        this.formData.category_id =null
        this.formData.sub_category_id =null
        this.categoryData =[]
        this.subCategoryData =[]
        this.getCategoryData($event)
      }

    },
    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await TaskGroupService.findOne(id);
      this.formData = res[0];
      if (this.formData.sub_category_id && this.formData.category_id ) {
        this.getCategoryData(this.formData.project_id);
        this.getSubCategoryData(this.formData.category_id);
      }
    },

    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        TaskGroupService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);
          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },

    async getCategoryData(projectId) {
      try{
      let $projects = await ProjectService.findAll({ id: projectId });
          this.selectedProject =
            $projects && $projects.length > 0 ? $projects[0] : {};
          let $categoryId = $projects[0].category_id;
          let res = await CategoryService.categoryById({
            categoryId: $categoryId ? $categoryId : "[]",
          });
      // let res = await CategoryService.findAll();
      this.categoryData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
      }
      catch(e){
        console.log(e)
      }
    },
    selectCategory() {
      this.getSubCategoryData();
      this.formData.sub_category_id = null;
    },

    async getSubCategoryData() {
      let res = await SubCategoryService.findAll({
        category_id: this.formData.category_id,
      });
      this.subCategoryData = res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },


    async getTaskData() {
      let res = await TaskService.findAll({task_type: "production"} );
      this.taskData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },
    async getProjectData() {
      try{
      let res = await ProjectService.findAll();
      this.projectData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
      }
      catch(e){
        this.projectData =[]
        console.log(e)
      }
    },
    async getIssueTypeData() {
      try{
      let res = await IssueTypeService.findAll();
      this.issueTypeData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
      }
      catch(e){
        this.issueTypeData =[]
        console.log(e)
      }
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        if (this.formData.id) {
          await TaskGroupService.save(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          console.log(this.formData, "check1111");
          await TaskGroupService.create(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        }
      }
    },
  },
};
</script>
  