<template>
  <v-container fluid justify-center>
    <v-row>
    <p class="text-h5  mt-8 pl-5 mb-0">
      <b>BackLog</b>
    </p>
  </v-row>
   
  <v-row  class="mt-1 pl-6">
        <v-breadcrumbs :items="crums">
          <template v-slot:title="{ item }">
            <span style="text-transform: capitalize;
              color: #7d7d7dbf;">{{ item.title }}</span>
          </template>
        </v-breadcrumbs>
      </v-row>

    <v-row class="pa-0 ma-0 pt-100px" no-gutters dense>
      <v-col lg="3" xl="2" md="4" sm="4" cols="12" class="pa-0 ma-0 pr-2 pt-3">
        <v-list class="settingsList" style="height: 84vh">
          <div v-for="(item, key) in menu" :key="key">
            <v-list-item @click="selectedData(item, key)"
              :style="item.active ? { 'background-color': '#0d5dba', 'color': '#fff' } : {}">
              <v-row>
                <v-col cols="9">{{ item.name }}</v-col>
                <v-col cols="">
                  <span>
                    <v-chip class="chip"  label >{{ item.counts }}</v-chip>
                  </span>
                </v-col>
              </v-row>
            </v-list-item>
          </div>
        </v-list>
      </v-col>
      <v-col lg="9" xl="10" md="8" sm="10" cols="12" class="pl-2 pr-2 pt-3 white">
        <v-card flat style="height: 84vh; overflow: auto; overflow-x: hidden">
          <!-- <BacklogTableVue :data="data" @rowAction="rowActions($event)" /> -->
          <PrimeDataTable
    :title="selectedMenu"
    :showFilter="false"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="false"
    :showAdd="false"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="issues"
  />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CategoryService from "@/api/service/Category/CategoryService";
// import BacklogTableVue from "./BacklogTable.vue";
import IssueService from "@/api/service/Issue/IssueService";
import PrimeDataTable from '../components/PrimeDataTable.vue';
// import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import ProjectService from "@/api/service/Project/ProjectService";
import AccountService from "@/api/service/Account/AccountService";

export default {
  props: [],
  components: {
    // BacklogTableVue,
    PrimeDataTable,
  },
  data() {
    return {
      dataId: null,
      columns: [],
      workSpaceData: [],
      data: [],
      crums:[],
      menu: [],
      selectedWorkspaceId: null,
      selectedMenu: null
    };
  },
  created() {
    this.getMenuData();
    this.getbreadcrumsData();
    // this.getWorkSpaceId();
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = false;
    this.chip = true;
  },

  methods: {

    loadColumns() {
      this.columns = [
        {
          field: "name",
          header: "Subject",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
        },
        {
          field: "issue_type_name",
          header: "Issue Type",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        {
          field: "owner_name",
          header: "Owner",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
          avatar:true,
          f_name:'first_Name',
          l_name:'first_Name',
          theme:'theme'
        },
        {
          field: "next_action_name",
          header: "Next Action",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
          avatar:true,
          f_name:'first_Name',
          l_name:'first_Name',
          theme:'theme'
        },
        {
          field: "project_name",
          header: "Project",
          style: { width: "100px", color: "" },
          toolTip: "Associate Managet name",
          sortfield: true,
        },
        {
          field: "task_name",
          header: "Task",
          style: { width: "100px", color: "" },
          toolTip: "description",
          sortfield: true,
        },
        // {
        //   field: "user_name",
        //   header: "User",
        //   style: { width: "100px", color: "" },
        //   toolTip: "Category Name",
        //   sortfield: true,
        // },
        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
        {
          field: "stage",
          header: "Stage",
          style: { width: "100px", color: "" },
          toolTip: "Stage",
          sortfield: true,
        },
        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        // {
        //   field: "action",
        //   header: "Action",
        //   style: { width: "100px", color: "" },
        //   toolTip: "Action",
        //   sortfield: true,
        //   rowActions: [
           
        //     { action: "view" }
        //   ],
        // },
      ];
    },

    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.IssueId = $event.data.id;
        this.viewType = $event.type;
        console.log("view");
        this.$router.push({ path:"/issueView", query: { type: this.viewType,id:this.IssueId } });

      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.IssueId = $event.data.id;
        this.viewType = $event.type;
        this.$router.push({ path:"/AddIssue", query: { type: this.viewType,id:this.IssueId } });

        console.log("edit");
      }
      if ($event.type == "select") {
        this.dialog = true;
        this.IssueId = $event.data.id;
        this.viewType = $event.type;
        this.$router.push({ path:"/issueView", query: { type: this.viewType,id:this.IssueId } });

        console.log("select");
      }
    },
    async getbreadcrumsData(){
       let projectId = localStorage.getItem('_PROJECT_ID_')
       let account_id = localStorage.getItem('_ACCOUNT_ID_')
       console.log(projectId,"11111111111111") 
       let res = await ProjectService.findOne({id:projectId})
       let res2 = await AccountService.findOne(account_id)
       console.log("project",res)
       let $obj =
        {
          title: res[0].name,
          disabled: false,
          href: 'projectList',
        }
       let $obj2 =
        {
          title: res2[0].name,
          disabled: false,
          href: 'accountList',
        }
       this.crums[0] = $obj;
       this.crums[1] = $obj2;
       console.log("66666 worksapce",this.crums)

    },

    async getMenuData() {
      var id = localStorage.getItem("workSpaceId");
      this.selectedWorkspaceId = id;
      try {
        const res = await CategoryService.getBackLogMenu({
          work_space_id: this.selectedWorkspaceId,
        });
        this.menu =await res.map((item) => ({
          id: item.id,
          name: item.name,
          counts: item.counts
        }));
      
        this.selectedData(this.menu[0],0)
      } catch (error) {
        console.error("Error retrieving menu data:", error);
      }
    },


    // rowActions($event) {
    //   this.dialog = true;
    //   this.dataId = $event.data.id;
    //   this.viewType = $event.type;
    
    //   this.$router.push({ path: "/issueView", query: { type: this.viewType, id: this.dataId } });
    // },

    // getWorkSpaceId(){
    //   var res = localStorage.getItem("workSpaceId")
    //   this.selectedWorkspaceId = res
    // },

    async selectedData(item,i) {
      try{
        this.selectedMenu = item.name
      this.menu.map((k, index) => i == index ? k.active = true : k.active = false)
      var res = await IssueService.getBackLogData({
        category_id: item.id,
        work_space_id: this.selectedWorkspaceId,
      });
      this.data = res.length > 0 ? res : null
      console.log("ressssss",res)
    }
    catch(error){
      console.log(error)
    }
    },

  },
};
</script>

<style>

.v-list .v-list-item.v-list-item--link.theme--light.activeSettingList,
.v-list.settingsList .v-list-item:hover{
  background-color:#1258ab !important;
  color: white !important;
  cursor: pointer;
  margin-right: 0px;
  width:100%;
  
}
.v-list .v-list-item.v-list-item--link.theme--light.activeSettingList,
.v-list.settingsList .v-list-item:hover, .chip{
  color:white;
  background-color: #dee2e6;
}
.chip{
  background-color: white;
  color: black; 
}

.v-list .v-list-item.v-list-item--link.theme--light.activeSettingList,
.v-list.settingsList .v-list-item:hover{
color:white;
/* background-color:white; */
}


.settingsList {
  cursor: pointer;
  height: 95vh;
}


.p-datatable .p-datatable-tbody>tr>td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 14px;
  cursor: pointer;
}

.avatar {
  width: 1rem;
  height: 1rem;
  border-radius: 20%;
}

.v-avatar.v-avatar--density-default {
  border-radius: 100%;
}

.p-datatable .p-column-header-content {
  display: flex;
  align-items: center;
  font-size: 14px;
}
</style>
