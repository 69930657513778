<template>
    <v-card flat>
        <v-card-title>
            New Columns
            <v-icon @click="$emit('close')" small class="mdi mdi-close float-right" />
        </v-card-title>
        <v-card-text>
            <v-row no-gutters class="pa-1 font-weight-bold" dense style="background-color:#cbd6f5">
                <v-col cols="4" >
                    Column 
                </v-col>
                <v-col cols="4">
                    Data Type & Length
                </v-col>
            </v-row>
            <v-row no-gutters  class="pa-1"  dense v-for="column in columns" v-bind:key="column">
                <v-col cols="4">
                    {{ column.column_name }}
                </v-col>
              
                <v-col cols="4">
                    {{ column.data_type }}
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="updateNewColumns" block class="save">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import ListModelDetailService  from '@/api/service/ListModelDetail/ListModelDetailService';
export default {
    props:["columns"],
    data(){
        return {

        }
    },
    methods:{
       async updateNewColumns() {
            let $newColumns = await ListModelDetailService.updateNewColumns(this.columns)
            this.$emit('column-updated',$newColumns)
        }
    }
}
</script>
<style scoped>
.save {
    background-color:#3C5AAA;
    color:white;
}</style>