<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>
          <b>Copy Issue</b>
          <v-btn @click="$emit('closeDialog')" elevation="0" density="comfortable" icon="mdi-close"
            class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">

          <v-col cols="12" class="pl-4">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Subject
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Name" :rules="nameRule" v-model="subject"
              :disabled="showReadOnly" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="$emit('closeDialog')">Cancel</v-btn>
          <v-btn class="save" type="submit">Create</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import IssueService from "@/api/service/Issue/IssueService";


export default {
  props: ["item"],
  components: {
    TextFieldComponent
  },
  created(){
   if(this.item && this.item.name){
    this.subject =this.item.name
   }
  },

  data() {
    return {
      subject: this.item,
      nameRule: [(value) => !!value || "Subject is Required."],

    }
  },
  methods: {

    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {
        console.log("Not valid")
      }
      else {
        if (this.item.id) {
          try {
            let data = {
              name: this.subject,
              id: this.item.id
            }
            let res = await IssueService.copyIssue(data)
            console.log(res)
            this.$emit("closeDialog");
          }
          catch (e) {
            console.log(e)
          }
        }
      }
    },
  },
};

</script>
    