<template>
  <v-card
    flat
    v-if="issueData && issueData.stage == 'clarification'"
    dense
    class="pa-0 ma-0 rounded"
    :elevation="0"
  >
    <v-card-title class="pl-0 pa-0 ma-0 mb-3" dense>
      <span style="font-size: 16px; font-weight: 600; color: #272727">
        Clarification/FollowUp details
      </span>
      <v-btn
        icon
        density="compact"
        @click="openDialog('editDescription', 'clarification')"
        variant="text"
        class="btn-color"
      >
        <img
          style="width: 16px; height: 16px"
          src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
        />
      </v-btn>
      <v-btn
        icon
        density="compact"
        @click="openDialog('expandDescription', 'clarification')"
        variant="text"
        class="btn-color"
      >
        <img
          style="width: 20px; height: 18px"
          src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/expand.png"
      /></v-btn>
    </v-card-title>
    <v-card-text style="background-color: #fdd766">
      <v-row>
        <v-col cols="3" v-if="issue && issue.followup_type">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >Clarification Type</v-label
          >
          <div class="content">
            {{ issue && issue.followup_type ? issue.followup_type : "" }}
          </div>
        </v-col>
        <v-col cols="3" v-if="issue && issue.clarification_type">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >Clarification</v-label
          >
          <div class="content">
            {{ issue && issue.clarification_type ? issue.clarification_type : "" }}
          </div>
        </v-col>
        <v-col cols="3" v-if="issue && issue.followup_user">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >Pending With
          </v-label>

          <div class="content">
            {{ issue && issue.pending_with_name ? issue.pending_with_name : "" }}
          </div>
        </v-col>
        <v-col cols="3" v-if="issue && issue.followup_date">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >FollowUp Date
          </v-label>
          <div class="content">
            {{
              issue &&
              issue.followup_date &&
              moment(issue.followup_date).format("MM/DD/YYYY") != "Invalid date"
                ? moment(issue.followup_date).format("MM/DD/YYYY")
                : ""
            }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card
    flat
    v-else-if="issueData && issueData.stage == 'hold'"
    dense
    class="pa-0 ma-0 rounded"
    :elevation="0"
  >
    <v-card-title class="pl-0 pa-0 ma-0 mb-3" dense>
      <span style="font-size: 16px; font-weight: 600; color: #272727">
        Hold details
      </span>
      <v-btn
        icon
        density="compact"
        @click="openDialog('editDescription', 'hold')"
        variant="text"
        class="btn-color"
      >
        <img
          style="width: 16px; height: 16px"
          src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
        />
      </v-btn>
      <v-btn
        icon
        density="compact"
        @click="openDialog('expandDescription', 'hold')"
        variant="text"
        class="btn-color"
      >
        <img
          style="width: 20px; height: 18px"
          src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/expand.png"
      /></v-btn>
    </v-card-title>
    <v-card-text style="background-color: #e0f3fd53">
      <v-row>
        <v-col cols="3" v-if="issue && issue.followup_type">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >Type</v-label
          >
          <div class="content">
            {{ issue && issue.followup_type ? issue.followup_type : "" }}
          </div>
        </v-col>
        <!-- <v-col cols="3" v-if="issue && issue.hold_type">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >Hold</v-label
          >
          <div class="content">
            {{ issue && issue.hold_type ? issue.hold_type : "" }}
          </div>
        </v-col> -->
        <v-col cols="3" v-if="issue && issue.pending_with_name">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >Pending With</v-label
          >
          <div class="content">
            {{ issue && issue.pending_with_name ? issue.pending_with_name : "" }}
          </div>
        </v-col>
        <v-col cols="3" v-if="issue && issue.followup_date">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >FollowUp Date
          </v-label>
          <div class="content">
            {{
              issue &&
              issue.followup_date &&
              moment(issue.followup_date).format("MM/DD/YYYY") != "Invalid date"
                ? moment(issue.followup_date).format("MM/DD/YYYY")
                : ""
            }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card
    flat
    v-else-if="issueData && issueData.stage == 'itsupportrequired'"
    dense
    class="pa-0 ma-0 rounded"
    :elevation="0"
  >
    <v-card-title class="pl-0 pa-0 ma-0 mb-3" dense>
      <span style="font-size: 16px; font-weight: 600; color: #272727">
        IT Support Required
      </span>
      <v-btn
        icon
        density="compact"
        @click="openDialog('editDescription', 'itsupportrequired')"
        variant="text"
        class="btn-color"
      >
        <img
          style="width: 16px; height: 16px"
          src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
        />
      </v-btn>
      <v-btn
        icon
        density="compact"
        @click="openDialog('expandDescription', 'itsupportrequired')"
        variant="text"
        class="btn-color"
      >
        <img
          style="width: 20px; height: 18px"
          src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/expand.png"
      /></v-btn>
    </v-card-title>
    <v-card-text style="background-color: #e0f3fd53">
      <v-row>
        <v-col cols="3" v-if="issue && issue.followup_type">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >Type</v-label
          >
          <div class="content">
            {{ issue && issue.followup_type ? issue.followup_type : "" }}
          </div>
        </v-col>
        <v-col cols="3" v-if="issue && issue.ticket_num">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >Ticket Number</v-label
          >
          <div class="content">
            {{ issue && issue.ticket_num ? issue.ticket_num : "" }}
          </div>
        </v-col>
        <v-col cols="3" v-if="issue && issue.pending_with_name">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >Pending With</v-label
          >
          <div class="content">
            {{ issue && issue.pending_with_name ? issue.pending_with_name : "" }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" v-if="issue && issue.it_followup_date">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >IT Support FollowUp Date
          </v-label>
          <div class="content date">
            {{
              issue &&
              issue.it_followup_date &&
              moment(issue.it_followup_date).format("MM/DD/YYYY") != "Invalid date"
                ? moment(issue.it_followup_date).format("MM/DD/YYYY")
                : ""
            }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card
    flat
    v-else-if="issueData && issueData.stage == 'followup'"
    dense
    class="pa-0 ma-0 rounded"
    :elevation="0"
  >
    <v-card-title class="pl-0 pa-0 ma-0 mb-3" dense>
      <span style="font-size: 16px; font-weight: 600; color: #272727">
        FollowUp Details
      </span>
      <v-btn
        icon
        density="compact"
        @click="openDialog('editDescription', 'followup')"
        variant="text"
        class="btn-color"
      >
        <img
          style="width: 16px; height: 16px"
          src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
        />
      </v-btn>
      <v-btn
        icon
        density="compact"
        @click="openDialog('expandDescription', 'followup')"
        variant="text"
        class="btn-color"
      >
        <img
          style="width: 20px; height: 18px"
          src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/expand.png"
      /></v-btn>
    </v-card-title>
    <v-card-text style="background-color: #e0f3fd53">
      <v-row>
        <v-col cols="3" v-if="issue && issue.followup_type">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >Followup Type</v-label
          >
          <div class="content">
            {{ issue && issue.followup_type ? issue.followup_type : "" }}
          </div>
        </v-col>
        <v-col cols="3" v-if="issue && issue.followup_date">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >FollowUp Date
          </v-label>
          <div class="content date">
            {{
              issue &&
              issue.followup_date &&
              moment(issue.followup_date).format("MM/DD/YYYY") != "Invalid date"
                ? moment(issue.followup_date).format("MM/DD/YYYY")
                : ""
            }}
          </div>
        </v-col>

        <v-col cols="3" v-if="issue && issue.no_of_followups">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600"
            >No Of FollowUp
          </v-label>
          <div>
            {{ issue && issue.no_of_followups ? issue.no_of_followups : "" }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-dialog v-model="recurringDialog" width="650px" persistent>
    <CreateClarificationVue
      style="overflow: auto"
      :showDetails="'view'"
      :type="'action'"
      :showReadOnly="showReadOnly"
      :issueId="issueId"
      @closeDialog="cancel('clarification')"
    ></CreateClarificationVue>
  </v-dialog>
  <v-dialog v-model="holdDialog" persistent :width="600">
    <CreateHold
      @closeDialog="cancel('hold')"
      ref="ShowMailDetails"
      :type="'action'"
      :showReadOnly="showReadOnly"
      :issueId="issueData.id"
    />
  </v-dialog>

  <v-dialog v-model="flpDialog" persistent :width="600">
    <CreateFollowUp
      @closeDialog="cancel('followup')"
      ref="ShowMailDetails"
      :type="'action'"
      :issueId="issueData.id"
      :showReadOnly="showReadOnly"
      :no_of_followups="issueData && issueData.no_of_followups"
      @followup-updated="getNoOfFollowups"
    />
  </v-dialog>
  <v-dialog v-model="itFlpDialog" persistent :width="600">
    <CreateItFollowup
      @closeDialog="cancel('itsupportrequired')"
      :type="'action'"
      :showReadOnly="showReadOnly"
      ref="ShowMailDetails"
      :issueId="issueData.id"
      :no_of_followups="issueData.no_of_followups"
    />
  </v-dialog>
</template>
<script>
import moment from "moment";
import IssueService from "@/api/service/Issue/IssueService";
import CreateClarificationVue from "./CreateClarification.vue";
import CreateHold from "./CreateHold.vue";
import CreateItFollowup from "./CreateItFollowup.vue";
import CreateFollowUp from "./CreateFollowUp.vue";
export default {
  components: {
    CreateClarificationVue,
    CreateHold,
    CreateFollowUp,
    CreateItFollowup,
  },
  data() {
    return {
      issue: null,
      recurringDialog: false,
      holdDialog: false,
      contacts: [],
      users: [],
      allUsers: [],
      flpDialog: false,
      itFlpDialog: false,
    };
  },
  props: ["issueData", "reload"],
  watch: {
    reload() {
      if (this.issueData.stage == "clarification") {
        this.getClarification();
      } else if (this.issueData.stage != "clarification") {
        this.getStageData();
      }
    },
    issueData() {
      if (this.issueData.stage == "clarification") {
        this.getClarification();
      } else if (this.issueData.stage != "clarification") {
        this.getStageData();
      }
    },
  },
  created() {
    if (this.issueData.stage == "clarification") {
      this.getClarification();
    } else if (this.issueData.stage != "clarification") {
      this.getStageData();
    }
  },

  methods: {
    moment,
    async getClarification() {
      let res = await IssueService.getClarificationDetails({
        issueId: this.issueData.id,
        type: "clarification",
      });
      this.issue = res[0];
    },

    async getStageData() {
      let res = await IssueService.getStageData({
        issueId: this.issueData.id,
        type: this.issueData.stage,
      });
      this.issue = res[0];
      console.log(this.issue, "Hello userssss");
    },
    openDialog($event, type) {
      if ($event == "editDescription") {
        this.recurringDialog = type == "clarification" ? true : false;
        this.holdDialog = type == "hold" ? true : false;
        this.itFlpDialog = type == "itsupportrequired" ? true : false;
        this.flpDialog = type == "followup" ? true : false;
        this.issueId = this.issueData.id;
        this.showReadOnly = false;
      } else {
        this.recurringDialog = type == "clarification" ? true : false;
        this.holdDialog = type == "hold" ? true : false;
        this.itFlpDialog = type == "itsupportrequired" ? true : false;
        this.flpDialog = type == "followup" ? true : false;
        this.issueId = this.issueData.id;
        this.showReadOnly = true;
      }
    },
    cancel(type) {
      if (type == "clarification") {
        this.recurringDialog = false;
        this.getClarification();
      } else if (type == "hold") {
        this.holdDialog = false;
        this.getStageData();
      } else if (type == "itsupportrequired") {
        this.itFlpDialog = false;
        this.getStageData();
      } else if (type == "followup") {
        this.flpDialog = false;
        this.getStageData();
      }
    },
    getNoOfFollowups() {
      this.no_of_followups = this.issueData.no_of_followups;
    },
  },
};
</script>
<style scoped>
.content {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
}

.date {
  font-weight: 600;
}

.box {
  background-color: #e4c489;
}
</style>
