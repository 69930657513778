<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b>Category</b>
          <v-btn @click="setFalse()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <v-row no-gutters class="mt-3">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="category.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <!-- Input Field -->
          <v-col cols="4">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Code" :rules="codeRule" v-model.trim="category.code"
              :disabled="showReadOnly" @keyup="checkCode()" @keydown="prevent($event)" />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>

          <!-- <v-col offset="1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Status
            </v-label>
              <v-switch v-model="category.active" class="Switch" color="primary"  :disabled="showReadOnly"></v-switch>
          </v-col> -->

          <!-- Input Field -->

          <v-col cols="12">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Name" :rules="nameRule" v-model="category.name"
              :disabled="showReadOnly" />
          </v-col>
        </v-row>
      </v-card-text>
      <!-- Cancle And Save Button -->
      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import categoryService from "@/api/service/Category/CategoryService";
import ProjectService from "@/api/service/Project/ProjectService";
// import CategoryService from "@/api/service/Category/CategoryService";

export default {
  props: ["id", "viewType", "project_id", "category_id"],
  components: {
    TextFieldComponent,
  },

  data() {
    return {
      categoryId: [],
      showReadOnly: false,
      categoryData: [],
      stageData: [
        { value: "open", display: "Open" },
        { value: "close", display: "Close" },
      ],
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      dialog: false,
      type: null,
      codeError: false,
      category: {
        name: null,
        category: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.category = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    this.getCategoryData();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode() {
      if (this.category.code && this.category.code.length > 0) {
        this.category.code = this.category.code.toUpperCase();

        categoryService.findAll({ code: this.category.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.category.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.category.id && $exist[0].id == this.category.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },

    setFalse() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await categoryService.findOne(id);
      this.category = res[0];
    },
    async getCategoryData() {
      let res = await categoryService.findAll();
      this.categoryData = res.length > 0 ? res : [];
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        if (this.category.id) {
          await categoryService.save(this.category);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.category = [];
        }
        else {
          try {
            let output = await categoryService.create(this.category);
            console.log(output);
            if (this.project_id) {
              let projectRes = await ProjectService.findOne(this.project_id)
              console.log(projectRes, "kkkkkkkk", projectRes[0].category_id)
              let outputData = projectRes && projectRes[0] && projectRes[0].category_id ? JSON.parse(projectRes[0].category_id) : []
              outputData.push(output.data.data.id)
              console.log(outputData, "hellod")
              projectRes[0].category_id = JSON.stringify(outputData)
              await ProjectService.update(projectRes[0])
            }
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.dialog = false;
            this.setFalse();
            this.$emit("reload", true);
            this.category = [];
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
>>>.v-switch .v-selection-control {
  min-height: 0px;
}

</style>
