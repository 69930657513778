<template>
    <v-row class="d-flex" style="height: 100%;justify-content: center;align-items: center;display: flex;">
        <v-col cols="4"> 
            <v-card>
                <h1>gfdghghgdhg</h1>
            </v-card>
        </v-col>
    </v-row>
           <!-- <v-card class="pa-md-4 mx-lg-auto" max-width="600"
           >
            <v-btn
              variant="text"
              color="teal-accent-4"
              @click="reveal = false"
            >
              Close
            </v-btn> 
          </v-card>   -->
  </template>

  <script>
  export default {
    data: () => ({
 
    }),
  }
</script>

<style>



</style>