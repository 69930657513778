<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto">
    <v-card-item>
      <v-card-title
        >{{ viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add" }}
        Holiday Template
        <v-btn
          @click="close()"
          elevation="0"
          density="comfortable"
          icon="mdi-close"
          class="x-closeIcon"
        ></v-btn>
      </v-card-title>
    </v-card-item>

    <v-form @submit.prevent="save()" ref="form" v-model="value">
      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="pr-7">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Code
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Code"
              :rules="codeRule"
              v-model="formData.code"
              @input="checkCode"
              :disabled="showReadOnly"
              @keydown="prevent($event)"
            />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>

          <v-col cols="7">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Name
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Name"
              :rules="nameRule"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </v-col>

          <v-col cols="7" class="pr-7 dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Location
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :items="locations"
              :placeholder="'Location'"
              :item-title="'name'"
              :rules="locationRule"
              :disabled="showReadOnly"
              v-model="formData.location_id"
              :item-value="'id'"
            />
          </v-col>

          <v-col cols="5">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Year
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              :placeholder="getYear()"
              :rules="yearRule"
              :maxlength="4"
              v-model="formData.year"
              :disabled="showReadOnly"
              @keydown="prevent1($event)"
            />
          </v-col>
          
          <v-col cols="12" class=" dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Holiday
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :items="holidayTemplates"
              item-value="id"
              item-title="holiday"
              density="compact"
              placeholder="Holiday"
              v-model="formData.holiday_description"
              @update:modelValue="holidayChanged"
              :disabled="showReadOnly"
            />
            <v-error v-if="wtError">Holiday is Required.</v-error>
            <v-chip
              closable
              class="selectedChip"
              label
              :disabled="showReadOnly"
              v-for="item in selectedHolidays"
              :key="item"
              @click:close="removeChips(item)"
            >
              {{ item.holiday }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import HolidayTemplateService from "@/api/service/HolidayTemplate/HolidayTemplateService";
import HolidayService from "@/api/service/Holiday/HolidayService";
import commonService from "@/common.service";
import LocationService from "@/api/service/Location/LocationService";
export default {
  props: ["id", "viewType"],
  components: {
    // HolidayTemplateDetail,
    TextFieldComponent,
    AutoComplete,
  },

  data() {
    return {
      holiday: null,
      selectData: [],
      codeError: false,
      selectedHolidayId: [],
      backupHollidayData: [],
      selectedHolidays: [],
      showReadOnly: false,
      dialog: false,
      type: null,
      wtError: false,
      holidayTemplates: [],
      locations: [],
      codeRule: [(value) => !!value || "Code is Required."],
      yearRule: [
        (value) => !!value || "Year is required",
        (value) => /^\d+$/.test(value) || "Year must be numeric",
      ],
      //  [(value) => !!value || "Year is Required."],
      // codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      holidayRule: [(value) => !!value || "Holiday is Required."],
      locationRule: [(value) => !!value || "Location is Required."],
      formData: {
        id: null,
        code: null,
        name: null,
        year: null,
        holiday_description: null,
        location_id: null,
      },
      chips: [],
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  async created() {
    await this.getHoliday();
    await this.getLocation();
    if (this.id) {
      await this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },

  methods: {
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    prevent1(e) {
      if (!/^\d*\.?\d{0,2}$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },

    getYear() {
      const d = new Date();
      let year = d.getFullYear();
      return year;
    },
    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        HolidayTemplateService.findAll({ code: this.formData.code }).then(
          (res) => {
            let $exist = res.filter((key) => key.code == this.formData.code);
            this.codeExistFlag =
              $exist.length > 0
                ? this.formData.id && $exist[0].id == this.formData.id
                  ? false
                  : true
                : false;
            if (this.codeExistFlag) {
              this.codeError = true;
            } else {
              this.codeError = false;
            }
          }
        );
      }
    },

    holidayChanged($value) {
      this.wtError = false;
      this.selectedHolidayId.push($value);
      this.setData();
    },

    setData() {
      let chipRes = commonService.getMultipleChip(
        this.backupHollidayData,
        this.selectedHolidayId,
        "id"
      );
      this.holidayTemplates = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupHollidayData),
        this.selectedHolidayId,
        "id"
      );
      this.selectedHolidays = chipRes;
      this.holiday = null;
      this.holidayTemplates = [...this.holidayTemplates];
      this.formData.holiday_description = null;
    },
    async getHoliday() {
      await HolidayService.findAll().then(async ($options) => {
        this.holidayTemplates =
          $options.length > 0
            ? await commonService.getSort($options, "name", "asc")
            : [];
        this.backupHollidayData = JSON.parse(
          JSON.stringify(this.holidayTemplates)
        );
        console.log(this.backupHollidayData, "holiday template data");
      });
    },
    close() {
      this.$emit("closeDialog");
    },

    // cancelHoliday(data) {
    //   let index = this.selectedHolidayId.findIndex(k => k == data.id)
    //   if (index > -1) {
    //     this.selectedHolidayId.splice(index, 1)
    //   }
    //   this.setData()
    // },
    removeChips(data) {
      let index = this.selectedHolidayId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedHolidayId.splice(index, 1);
      }
      this.setData();
      this.wtError = this.selectedHolidayId.length == 0;
    },

    holidayDetailFunction($value) {
      if ($value) {
        this.selectData.push($value);
      }
    },
    async getById(id) {
      let holidayTemp = await HolidayTemplateService.findOne(id);
      this.formData = holidayTemp[0];
      this.selectedHolidayId =
        this.formData && this.formData.holiday_description
          ? JSON.parse(this.formData.holiday_description)
          : [];
      this.setData();
    },
    async getLocation() {
      let res = await LocationService.findAll();
      this.locations =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (
        !validation.valid ||
        this.codeError ||
        this.selectedHolidayId.length == 0
      ) {
        console.log(validation.valid);
        if (this.selectedHolidayId.length == 0) {
          this.wtError = true;
        }
      } else {
        this.formData.holiday_description = JSON.stringify(
          this.selectedHolidayId
        );

        if (this.formData.id) {
          await HolidayTemplateService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          try {
            await HolidayTemplateService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.dialog = false;
            this.close();
            this.$emit("reload", true);
            this.formData = {};
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>
<style>
.selectedChip {
  background-color: #fae2ee;
  color: #ffffff;
  margin-right: 5px;
  margin-top: 5px;
  color: #333333;
}
</style>