<template>
  <v-card elevation="0" style="border-radius:10px ">
    <v-form @submit.prevent="save()" ref="form" >
      <v-card-item>
        <v-card-title>
          Timesheet
          <v-btn style="float: right;" @click="close()" elevation="0" density="comfortable" icon="mdi-close"></v-btn>
        </v-card-title>
      </v-card-item>  
        <v-col cols="12" class="pl-5 pr-2">
          <v-card-text>
              <v-row no-gutters dense>
                <v-col cols="12" class="py-2">
                  <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
                      <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
                      </v-label>
                      <br/>
                  {{timeSheetDefaults.refNum }} {{ timeSheetDefaults.name }}
                </v-col>
                <v-col cols="3" class="dropdown">
                     <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Date
                      <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
                    </v-label>
                      <br/>
                      <b>
                      {{ moment(formData.start_date).format('MM/DD/YYYY ') }}</b>
                      <br/>
                      <small>
                      {{ moment(formData.start_date).format('dddd ') }}
                    </small>
               </v-col>
               <v-col cols="4" class="pt-0">
                  <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Account
                    <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                   <br/><b> {{ timeSheetDefaults.accountName }}</b>
                </v-col>
   
              <v-col cols="4" class="pt-0">
                  <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Project
                    <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                    <br/><b> {{ timeSheetDefaults.projectName }}</b>
                </v-col>
              </v-row>
              <v-row no-gutters dense class="py-2">  
            
   
              <v-col cols="12" class="pt-0 pr-2">
                  <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Task-Type [E]
                    <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                  <EnumAutoCompleteVue class="dropdown" density="compact"  v-model="formData.task_type" :showReadOnly="showReadOnly"  :selected="formData.task_type"
                  :disabled="showReadOnly"  :domain="value" placeholder="Sub Task-Type " @changeEnumData="changeTask($event)" :rules="taskTypeRule"/> 
                </v-col>

              <v-col cols="4" class="pt-0 pr-2">
                  <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Started At
                     <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                     
                  <TextFieldComponent density="compact" :max="getCurrentDateTime()" min='2023-07-04' placeholder="Started Time" type="datetime-local" showtime hoursFormat="24" :disabled="showReadOnly" v-model="formData.start_date" :rules="startTimeRule" @input="updateEndDateRange"
                   />
                  <v-error v-if="dateError" style="position: relative; top: -5px; margin-left: 2px;">Enter Start Date First</v-error>             
                </v-col>

              <v-col cols="4" class="pt-0 pr-2">
                  <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Ended At
                      <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                  <TextFieldComponent density="compact" :max="getCurrentDateTime()"  placeholder="Ended Time" type="datetime-local" showtime hoursFormat="24" :disabled="showReadOnly" v-model="formData.end_date" :rules="endTimeRule" :min="minEndDate"/>
                  <v-error v-if="dateError2" >Ended At date cannot be before Started At</v-error>                
                </v-col>
              <v-col cols="2" class="pt-0 pr-2 text-center">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600 ; margin-bottom: 10px;">Time Spent
                      <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                 <br/><span><b> {{ formData.actual_hrs }}</b> {{formData.actual_hrs?'Minutes':''}}</span>
              </v-col>
              <v-col cols="12">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Remarks</v-label>
                <TextArea  class="pb-4"
                  editorStyle="height: 100px"
                  :disabled="showReadOnly"
                  :data="formData.description"
                  :showReadOnly="showReadOnly"
                  @textData="changeDescription($event)"
                  ></TextArea>
            </v-col>
            </v-row>

            </v-card-text>
           </v-col>
          <v-card-action class="mr-3">
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn style=" --v-btn-height: 35px;" class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
            <v-btn style=" --v-btn-height: 35px;" class="save btn" type="submit">Save</v-btn>
          </v-row>
        </v-card-action>
    </v-form>
  </v-card>
  </template>
  
  <script>
  import moment from 'moment'
  import TextFieldComponent from "@/components/TextFieldComponent.vue";
  import TextArea from "@/components/ui/TextArea.vue";
  import TimeSheetService from "@/api/service/TimeSheet/TimeSheetService";
  import TaskService from "@/api/service/Task/TaskService";
  import EnumAutoCompleteVue from '@/components/others/EnumAutoComplete.vue';
  import commonService from "@/common.service";

  export default {
    props:['timeSheetDefaults','viewType'],
  components:{
    TextFieldComponent,
    TextArea,
    EnumAutoCompleteVue
  },
  data(){
      return{
        // startTimeRule: [(value) => !!value || "Start Time is Required."],
    startTimeRule: [
      (value) => {
        if (this.dateError) {
          return true; 
        }
        return !!value || "Start Time is Required.";
      }
    ],


        endTimeRule: [(value) => !!value || "End Time is Required."] ,
        taskTypeRule: [(value) => !!value || "Sub Task-Type is Required."],
        dateError: false,
        dateError2: false,
        // typeRule: [(value) => !!value || "Task type is Required"],
        minEndDate: null,
        showReadOnly:false,
        uploadData:[],
        value: "taskType",
        formData:{},
        tasks:[],
 }
  },

  watch: {
    timeSheetDefaults() {
      console.log(this.timeSheetDefaults,'mmmmmmmmm')
      // this.formData = this.getDefaults() 
    },
    'formData.end_date': function() {
    this.calculateActualHours();
  },
    'formData.start_date': function() {
    this.calculateActualHours();
  }
  },  
  created(){
    // this.formData = this.getDefaults() 
    this.getTasks()
    console.log(this.timeSheetDefaults,'alert11111')
    this.formData.actual_hrs=this.timeSheetDefaults.hours;
    this.formData.start_date=this.timeSheetDefaults.startedAt ? moment(this.timeSheetDefaults.startedAt).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')
    this.formData.end_date=this.timeSheetDefaults.endedAt ? moment(this.timeSheetDefaults.endedAt).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')
    this.formData.description=this.timeSheetDefaults.remarks;
    this.formData.task_type=this.timeSheetDefaults.taskName;
    this.showReadOnly=this.viewType
    console.log(this.formData,'alert9999')
  },
  methods:{
    calculateActualHours() {
    if (this.formData.start_date && this.formData.end_date) {
      const startTime = moment(this.formData.start_date);
      const endTime = moment(this.formData.end_date);
      
      // Calculate the difference in milliseconds
      const diffMilliseconds = endTime.diff(startTime);

      if (diffMilliseconds < 0) {
        this.dateError2 = true
        this.formData.actual_hrs = '00';
        return; 
      }
      else if (diffMilliseconds == 0){
        this.formData.actual_hrs = '00'; 
        this.dateError2 = false
     }
     else{
      this.dateError2 = false
      var minutes = endTime.diff(startTime, "seconds") / 60;
      minutes = minutes ? parseFloat(minutes.toFixed(2)) : 0;

      this.formData.actual_hrs = minutes;

      // // Convert milliseconds to hours and minutes
      // const diffDuration = moment.duration(diffMilliseconds);
      // const hoursDiff = Math.floor(diffDuration.asHours());
      // const minutesDiff = Math.floor(diffDuration.asMinutes());
      
      // // Format the difference as HH:mm
      // const diff = `${String(hoursDiff).padStart(2, '0')}:${String(minutesDiff).padStart(2, '0')}`;
      // this.formData.actual_hrs = diff !== '00' ? diff : '24:00';
      console.log(this.formData.actual_hrs,  'jjvvjdvbhfbjvdb')
    }
    }

    else if (!this.formData.start_date){
      this.dateError = true
    }
  },

  getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  },
    moment,
    getDefaults() {
      return {
        "entry_date":new Date(),
        "project_id":this.timeSheetDefaults.projectId,
        "issue_id":this.timeSheetDefaults.issueId,
        "account_id":this.timeSheetDefaults.accountId,
        // "start_date":new Date(),
        // "task_id":this.timeSheetDefaults.taskId,
        "stage":"pending"
      }
    },
    updateEndDateRange() {
      if (this.formData.start_date) {
        const startedDateTime = new Date(this.formData.start_date);
        const minEndDate = new Date(startedDateTime.getTime() + 60000); // Add 1 minute to the startedDateTime
        this.minEndDate = minEndDate.toISOString().slice(0, -8);
        this.dateError = false;
      }
       if(this.formData.end_date){
        this.dateError2 = true;
        const startedDate = moment(this.formData.start_date).format('YYYY-MM-DD')
        const endedDate = moment(this.formData.end_date).format('YYYY-MM-DD')
      if (endedDate < startedDate) {
        this.dateError2 = true;
        this.formData.actual_hrs = '00';
      }
      else{
        this.dateError2 = false;
      }

      }
       else {
        this.minEndDate = null;
      }
    },
 async   changeTask($value) {
      // console.log($value,'alert4545455')
      this.formData.task_type = $value;
      if(this.formData.task_type=="production"){
      this.formData.task_id=this.timeSheetDefaults.taskId
      // alert('production')
      } else {
        let res = await TaskService.findAll({'task_type':$value})
      this.formData.task_id=res[0].id
      console.log(res,'alert4545455')
      }
    },
  

  async getTasks() {
    this.task = await TaskService.findAll()
  },
  changeDescription($event){
    this.formData.description = $event;
  },
  async save(){ 
    let validation = await this.$refs.form.validate()
        if (!validation.valid || this.dateError || this.dateError2) {
          console.log("Something Went Error")
        }
        else{  
      let userData =JSON.parse(localStorage.getItem('user_details'))
      this.formData.resource_id =userData.id   
      this.formData.entry_date = await commonService.changeTheDateToUtc(this.formData.start_date,Intl.DateTimeFormat().resolvedOptions().timeZone);
      this.formData.start_date = await commonService.changeTheDateToUtc(this.formData.start_date,Intl.DateTimeFormat().resolvedOptions().timeZone);
      this.formData.end_date = await commonService.changeTheDateToUtc(this.formData.end_date,Intl.DateTimeFormat().resolvedOptions().timeZone);
      // let hour =
      //     this.formData && this.formData.actual_hrs
      //       ? this.formData.actual_hrs.split(":").join(".")
      //       : 0;
      // this.formData.actual_hrs=hour
      await TimeSheetService.create(this.formData)
      this.$store.dispatch('setSnackbar',{text:'Saved Successfully'})
      this.$emit("closeDialog");
      this.$emit("reload", true);
      let status =this.$store.state.getTimeReload
      this.$store.commit('SET_TIME_RELOAD', !status)
      this.formData = {}
    }
  },
  
  close() {
        this.$emit("closeDialog");
      },
  },
  
  }
  </script>
  
  <style scoped>
  /* >>> .v-field__field .v-field__input {
    cursor: text;
    background-color:#ECECEC !important;
    font-size: 13px;
    padding-right: 0;
    padding-left: 1px;
    color: #777777;
    border-radius: 8px !important;
} */
  </style>