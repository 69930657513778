<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
      <v-card-title
        >
        Next Action
      <v-btn
        @click="close()"
        elevation="0"
        density="comfortable"
        icon="mdi-close"
        class="x-closeIcon"
      ></v-btn
    >   </v-card-title>
      </v-card-item>
        
        <v-card-text>
          <v-row no-gutters class="mt-3 pa-2">
                <v-col cols="12">
                    <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Notes
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
              <TextArea  class="pb-4"
                editorStyle="height: 200px"
                :data="comment"
                :showReadOnly="showReadOnly"
                @textData="changeDescription($event)"
                ></TextArea>
                </v-col>
            
            
              <v-col cols="4">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Status[E] 
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                    >*</span
                  ></v-label
                >
                <EnumAutoCompleteVue
            class="dropdown"
            density="compact"
            v-model="formData.stage"
            :selected="formData.stage"  
            :domain="'projectStage'"
            placeholder="Status"
            :rules="statusRule"
            @changeEnumData="changeStage($event)"
          />
                
              </v-col>
              <v-col cols="4" class="pl-5">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >NextAction                                        
                  <span
                    v-if="!showReadOnly"
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                    >*</span
                  ></v-label
                >
                <AutoComplete class="dropdown" :items="userData" :placeholder="'Next Action'" :item-title="'name'" :disabled="showReadOnly"
                  :item-value="'id'" v-model="formData.next_action" :rules="nextactionRule"  />
                
              </v-col>
              <v-col cols="4" class="pl-5">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Priority [E]
                  <span v-if="!showReadOnly"
                    style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                <EnumAutoCompleteVue density="compact" v-model="formData.priority" class="dropdown" :selected="formData.priority" :domain="'PRIORITY'" placeholder="Priority"
                  @changeEnumData="changePriority($event)"  :rules="priorityRule" />
                
              </v-col>
              </v-row>   
        </v-card-text>
    
        <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-actions>

      </v-form>
 </v-card>
</template>

<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import EnumAutoCompleteVue from '@/components/others/EnumAutoComplete.vue';
import TextArea from "@/components/ui/TextArea.vue";
import UserService from "@/api/service/UserService";
import IssueService from "@/api/service/Issue/IssueService";
import ForumService from "@/api/service/Forum/ForumService";
export default {
    props:['id'],
    components:{
        // TextFieldComponent,
        TextArea,
        EnumAutoCompleteVue,
        AutoComplete
    },
     data(){
        return{
        dialog: false,
        comment:'',
        formData: {},
        userData:[],
        workSpaceId:'',
        issueData:[],
        notesRule: [(value) => !!value || "Notes is Required."],
        statusRule: [(value) => !!value || "Status is Required."],
        nextactionRule: [(value) => !!value || "Nextaction is Required."],
        priorityRule: [(value) => !!value || "Priority is Required."],
        }
     },

    created(){
        let workSpaceId = localStorage.getItem('workSpaceId')
        if(this.id){
            this.getById(this.id);
        }
        this.getUserData(workSpaceId)
        console.log(this.formData,'check22222222222')
    },
    methods:{
        async getUserData(id) {
      let res = await UserService.getUserByWorkSpace({'work_space_id':id});
      this.userData = res
    //   console.log(this.userData,'check22222222')
    },
    async getById(id) {
      let res = await IssueService.findOne(id)
      this.formData = res[0];
      console.log(this.formData,'noteeeeeeeeeee')
    },
        changeStage($value){
      this.formData.stage = $value
    },
        changePriority($event) {
      this.formData.priority = $event
    },
    changeDescription($event) {
      this.comment = $event
    },
        close() {
      this.$emit("closeDialog");
    },
    async saveTheComments() {
      let data = {
        key_type: "issue",
        key_id: this.id,
        description: this.comment,
      };
      await ForumService.create(data);
      this.comment = null;
    //   this.showButton=false
      // this.getForumData();
    },
    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid) {
        console.log("Not valid")
      }
      else {
        try{
      if(this.formData.id){
        console.log(this.formData,'checkkkkkkk')
        let output = await IssueService.update(this.formData);
        this.saveTheComments()
        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          console.log(output);
          this.close()
          this.dialog = false;
          this.$emit("reload", true);
      }
  }catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
    }
    // async save() {
    //     alert('hloo')
    //     let output = await IssueService.update(this.formData);
    //     console.log(output)
    //     this.dialog = false;
    //     this.close();
    //     this.$emit("reload", true);
    //     this.formData = {};

    // },


    }
}
}
</script>

<style>

</style>