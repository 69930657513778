<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b>Stage</b>
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>
      <v-card-text>

        <v-row no-gutters class="mt-3">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="4">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Code" v-model="formData.code" :disabled="showReadOnly"
              :rules="codeRule" @keydown="prevent($event)" @keyup="checkCode()" />
          </v-col>
          <v-col cols="4" class="ml-3">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sequence
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="sequence" v-model="formData.sequence"
              @input="filterNonNumeric" :disabled="showReadOnly" />
          </v-col>



          <v-col cols="9">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Name" v-model="formData.name" :disabled="showReadOnly"
              :rules="nameRule" />
          </v-col>
        </v-row>

        <!-- <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row> -->
      </v-card-text>
      <v-card-action class="mr-3">
        <!-- Cancle And Save Button -->
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly && !ShowSave">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>

  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import AutoComplete from "@/components/ui/AutoComplete.vue";
import StageService from "@/api/service/Stage/StageService";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    // AutoComplete
  },

  data() {
    return {
      value: false,
      showReadOnly: false,
      ShowSave: false,
      dialog: false,
      type: null,
      numericInput: "",
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      formData: {
        id: null,
        name: null,
        user_id: null,
        code: null,
        sequence: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" || this.viewType == "Add" ? false : true;

    }
  },
  methods: {
    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toLowerCase();

        StageService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    prevent(e) {
  if (!/^[a-zA-Z_]+$/.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
},



    filterNonNumeric() {
      this.numericInput = this.numericInput.replace(/[^0-9]/g, "");
    },

    close() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await StageService.findOne(id);
      this.formData = res[0];
      if (this.formData.name.toLowerCase() == 'open' ||
        this.formData.name.toLowerCase() == 'pending' ||
        this.formData.name.toLowerCase() == 'completed' ||
        this.formData.name.toLowerCase() == 'closed' ||
        this.formData.name.toLowerCase() == 'inprogress' ||
        this.formData.name.toLowerCase() == 'clarification') {
        this.ShowSave = this.viewType == "edit" ? false : true;
      }
    },

    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid) {
        console.log("Not valid")
      }
      else {

        if (this.formData.id) {
          await StageService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          await StageService.create(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        }
      }
    },
  },
};
</script>
