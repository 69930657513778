<template>
    <div class="px-7 mt-4" style="height: 100vh;">

      <v-row class="mt-0">
          <v-col cols="4" class="px-0" >
              <Skeleton height="105vh"></Skeleton>
        </v-col>
  
          <v-col cols="2" >
              <Skeleton  height="60vh" class="mb-3"></Skeleton>
              <Skeleton  height="43vh"></Skeleton>
        </v-col>
  
          <v-col cols="6" >
            <v-row>
              <v-col cols="6" class="px-0" >
                <Skeleton height="73vh"></Skeleton>
              </v-col>
              
              <v-col cols="6">
                <Skeleton height="17vh" class="mb-3" ></Skeleton>
                <Skeleton height="17vh" class="mb-3" ></Skeleton>
                <Skeleton height="17vh" class="mb-3" ></Skeleton>
                <Skeleton height="17vh" ></Skeleton>
              </v-col>
            </v-row>
            
            <v-row>
              <Skeleton height="30vh"></Skeleton>
            </v-row>
        </v-col>
      </v-row>

    </div>
  </template>
  <script>
  import Skeleton from "primevue/skeleton";
  export default {
    components: {
      Skeleton,
    },
  };
  </script>
  
  <style>
  </style>